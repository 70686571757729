import React, { useEffect } from "react";
//import ModuleSplit from 'components/ModuleSplit'
import Colule from "~/components/Colule";
// import BannerHorizontal from 'components/banners/BannerHorizontal';
import TipsHorizontal from "~/components/TipsHorizontal";
import { asideMappingFactory } from "~/utility/aside";
import { getGaInfo } from "~/utility/gaInfo";
import ErrorBoundary from "../ErrorBoundary";
import { useWagonHeight } from "./wagonHeightHook";

const Wagon = (props) => {
  const gaInfo = getGaInfo(props, `w${props.coluleTitle}`);
  const asideMapping = asideMappingFactory({
    headerSuffix: "",
    gaInfo: gaInfo,
  });

  const ref = useWagonHeight(props.name);
  return (
    <div ref={ref}>
      <ErrorBoundary>
        <Colule
          {...props.colule}
          coluleTitle={props.coluleTitle}
          coluleUrl={props.coluleUrl}
          coluleHeaders={props.coluleHeaders}
          noSectionHeaders={props.noSectionHeaders}
          asideHeaderSuffix={props.asideHeaderSuffix}
          gaInfo={gaInfo}
        >
          {props.skipTips ? (
            <hr />
          ) : (
            <TipsHorizontal items={props.tips} gaInfo={gaInfo} />
          )}
          {/* <BannerHorizontal {...props.banner_horizontal}/> */}
          {(props.splits || []).map(asideMapping)}
        </Colule>
      </ErrorBoundary>
    </div>
  );
};

export default Wagon;
