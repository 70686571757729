import React from "react";
import log from "../utility/log";
import ga from 'react-ga4';

/* 


get store state and read what you need

you can export from App.jsx and import it here,
but some ppl are voicing concerns about using 
single store in server side setting


*/
let withTracker;

if (process.env.BUILD_TARGET !== "server") {
  // const ReactGA = require("react-ga4");

  // UA-29511539-1 - real teraz.sk
  // ReactGA.initialize('UA-99001419-1' /*, {debug: true}*/);
  const init = () => {
    // const options = (!COOKIES_ACCEPTED) ? {
    //   gaOptions: {
    //     storage: 'none',
    //     storeGac: false,
    //   }
    // } : null;
    const options = null;
    ga.initialize("G-EFWCHJGC1X");

    // ga.plugin.require("linkid", {
    //   levels: 8,
    // });
  };

  init();

  withTracker = (WrappedComponent) => {
    const trackPage = (page) => {
      log.debug("pageview", page);
      // if (!COOKIES_ACCEPTED) {
      //   return
      // }
      ga.set({
        page,
      });
      // ReactGA.pageview(page);
      ga.send("pageview");
      const env = `https://www.teraz.sk/essentials/scripts/environment.php?tplp=teraz&t=teraz&ref=${encodeURIComponent(
        page
      )}`;
      fetch(env).catch((e) => console.error("enviroment fetch failed", e));
    };

    const HOC = (props) => {
      const page = props.location.pathname + props.location.search;
      trackPage(page);
      return <WrappedComponent {...props} />;
    };

    
    return HOC;
  };
} else {
  withTracker = (a) => a;
}

export default withTracker;
/* global COOKIES_ACCEPTED */
