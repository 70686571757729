import React, { Component } from "react";
import { apiUrlTeraz } from "~/utility/api";
import { connect } from "react-redux";
import { fetchWagon } from "~/store/actions";
import { wagonMapStateToProps } from "~/store/mappers";
import WagonObjektivom from "~/components/wagons/WagonObjektivom";

class WagonObjektivomContainer extends Component {
  constructor(props) {
    super(props);
    const tipsPage = this.props.tipsPage || 0;
    this.url = `${apiUrlTeraz}api_rest.php?module=RestApi&method=objektivom&tipsPage=${tipsPage}`;
  }

  UNSAFE_componentWillMount() {
    const { dispatch } = this.props;
    dispatch(fetchWagon(this.url, this.props.required));
  }

  render() {
    return (
      <WagonObjektivom name={this.props.name} {...this.props[this.url]} gaInfo={this.props.gaInfo} />
    );
  }
}

export default connect(wagonMapStateToProps)(WagonObjektivomContainer);
