import { documentLet } from "./global";

function handleError(oError) {
  console.log(oError);
  // throw new URIError(`loadScript ${oError.target.src} failed`);
  console.error(`loadScript ${oError.target.src} failed`);
}

interface LoadScriptConfig {
  async: boolean;
  defer: boolean;
}

export default function loadScript(
  url: string,
  onLoad?: (e: Event) => any,
  config: LoadScriptConfig = {async: false, defer: false}
) {
  if (process.env.BUILD_TARGET == "client") {
    const tag = documentLet.createElement("script");
    tag.type = "text/javascript";
    tag.async = config.async;
    tag.defer = config.defer;
    tag.src = url;
    if (onLoad) {
      tag.addEventListener("load", onLoad);
    }
    // tag.onerror = handleError;
    tag.addEventListener("error", handleError);
    const s = documentLet.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(tag, s);
  }
}
