import React, { PureComponent } from "react";
export default class BoxPriamePrenosyNRSR extends PureComponent {
  render() {
    return (
        <a
        href="/zive-vysielanie-tv-nrsr"
        className="boxPriamePrenosyNRSR"
      >
        <p>Priame prenosy z&nbsp;Národnej rady</p>
        <span className="btn-more-info">zobraziť viac</span>
      </a>
    );
  }
}




