import React, { PureComponent, Component } from "react";
import { imgSprite } from "~/config/images";
import { Link } from "~/components/Elements";
import { GAInfo, getGaInfo } from "~/utility/gaInfo";

type OffCanvasProps = {
  headerSuffixUrl: string;
  menu: MenuItemT[];
  gaInfo: GAInfo;
};

type BaseMenuItemT = { url: string; name: string };
type MenuItemT = { sub: BaseMenuItemT[]; activeUrl: string } & BaseMenuItemT;

class OffCanvas extends PureComponent<OffCanvasProps> {
  render() {
    return (
      <nav className={"offCanvas"}>
        <ul className="sideMenu list-reset">
          {(this.props.menu ? this.props.menu : []).map((m, i) => (
            <MenuItem
              activeUrl={this.props.headerSuffixUrl}
              {...m}
              key={i}
              gaInfo={this.props.gaInfo}
            />
          ))}
        </ul>
      </nav>
    );
  }
}

function getCreateSubmenu(gaInfo: GAInfo) {
  return (m: BaseMenuItemT, i: number) => {
    return (
      <li key={i}>
        <Link to={m.url} gaInfo={gaInfo}>
          {m.name}
        </Link>
      </li>
    );
  };
}

class MenuItem extends Component<MenuItemT & { gaInfo: GAInfo }> {
  state = {
    isSubActive: false,
  };

  constructor(props) {
    super(props);
    this.toggleSub = this.toggleSub.bind(this); // this.createSubmenu = this.createSubmenu.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      isSubActive: "/" + nextProps.activeUrl === nextProps.url,
    });
  }

  toggleSub() {
    this.setState({
      isSubActive: !this.state.isSubActive,
    });
  }

  render() {
    const m = this.props;
    const gaInfo = getGaInfo(this.props, "offcanvas");
    const createSubmenu = getCreateSubmenu(gaInfo);
    return (
      <li>
        <Link to={m.url || ""} gaInfo={gaInfo}>
          {m.name || ""}
        </Link>

        {m.sub && m.sub.length > 0 ? (
          <button className="sideMenu-toggle" onClick={this.toggleSub}>
            <svg className={`${!this.state.isSubActive ? "hidden" : ""} icon`}>
              <use xlinkHref={imgSprite + "#chevron-up"} />
            </svg>
            <svg className={`${this.state.isSubActive ? "hidden" : ""} icon`}>
              <use xlinkHref={imgSprite + "#chevron-down"} />
            </svg>
          </button>
        ) : null}

        {m.sub ? (
          <ul
            className={`${
              this.state.isSubActive ? "is-active" : ""
            } sideMenu-submenu list-reset`}
          >
            {m.sub.map(createSubmenu)}
          </ul>
        ) : null}
      </li>
    );
  }
}

export default OffCanvas;
