export const getClassNamesSize = (n) => {
  switch (n) {
    case 2:
      return "col col-6 col-md-6 col-sm-6 col-xs-12";

    case 3:
      return "col col-4 col-md-4 col-sm-6 col-xs-12";

    case 4:
      return "col col-3 col-md-3 col-sm-6 col-xs-12";

    case 6:
      return "col col-2 col-md-4 col-sm-6 col-xs-12";

    default:
      return "col col-2 col-md-4 col-sm-6 col-xs-12";
  }
};
