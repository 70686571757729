import React, { Component } from "react";
import PhotoGalleriesListing from "~/components/photogallery/PhotoGalleriesListing";
import { apiUrlTeraz } from "~/utility/api";
// import data from 'data/api/pages/photo_galleries_listing'
import { headerSuffix, selectPage, fetchPage } from "~/store/actions";
import { connect } from "react-redux";
import { pageMapStateToProps } from "~/store/mappers";
import LoaderIndex from "~/containers/LoaderIndex";
/**
 * Listing fotogalerii
 *
 */

class PhotoGalleriesListingContainer extends Component {
  update(props) {
    // const { dispatch } = props
    // if(!props.isLoading) {
    //   const cat = props && props.category
    //     ? props.category.name : null
    //   const catUrl = (props && props.category && props.category.url)
    //     ? props.category.url : null
    //   const themeClassName = catUrl ? "theme_"+catUrl : null
    //   dispatch( headerSuffix(cat, catUrl) )
    //   dispatch( themeClass(themeClassName) )
    // }
    const qs = new URLSearchParams(props.location.search);
    const page = qs.get("p") || 1;
    const url = `${apiUrlTeraz}api_rest.php?module=RestApi&method=photoGalleriesListing&page=${page}`;
    const { dispatch } = this.props;
    dispatch(selectPage(url));
    dispatch(fetchPage(url));
    dispatch(headerSuffix("Fotodenník", "fotodennik"));
  }

  UNSAFE_componentWillMount() {
    this.update(this.props); //FIXME
  }

  // componentDidUpdate(){
  //   this.update(this.props)
  // }
  // componentWillReceiveProps(nextProps){
  //   this.update(nextProps)
  // }
  render() {
    return (
      <div>
        <PhotoGalleriesListing {...this.props} />
        <LoaderIndex gaInfo={this.props.gaInfo} />
      </div>
    );
  }
}

export default connect(pageMapStateToProps)(PhotoGalleriesListingContainer);
