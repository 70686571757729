import React, { Component } from "react";
import { PhotoGalleryRow } from "~/components/photogallery/PhotoGalleryElements";
import Pagination from "~/components/Pagination";
export default class PhotodiaryListing extends Component {
  render() {
    const pagination = (
      <Pagination
        page={this.props.page}
        page_count={this.props.page_count}
        pathname={this.props.location.pathname}
      />
    );
    return (
      <section className="section section--foto border-top">
        <div className="container">
          <div className="row">
            <div className="col col-10 col-sm-12 col-push-1 col-push-sm-0">
              <div className="fotoHeader">
                <h2 className="fotoHeader-title text-center">
                  {this.props.title || "Fotodenník"}
                </h2>
              </div>

              {pagination}
              <PhotoGalleryRow
                items={this.props.galleries || Array(20).fill({})}
                key="fotogalerie"
              />
              {pagination}

              <div className="fotoHeader">
                <h2 className="fotoHeader-title text-center">Foto dňa</h2>
              </div>
              <PhotoGalleryRow
                url="/fotografia-dna"
                link_text="Ďalšie foto dňa"
                items={this.props.foto_dna || Array(4).fill({})}
                key="fotodna"
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}
