import clsx from "clsx";
import React from "react";
import { Link } from "~/components/Elements";
import img from "./img/banner-kalendarium_150x300.png";
const url = "https://kalendarium.tasr.sk/light/";

const HeaderBanner = (props) => {
  return (
    <div className={clsx("bannerBase", props.className)}>
      <Link to={url}>
        <div className="bannerBase-inner-inner-bg ">
          <img
            src={img}
            alt="Prehľad očakávaných udalostí dnešného dňa"
            width="300"
            height="150"
            style={{marginBottom: "1em"}}
            loading="lazy"
          ></img>
        </div>
      </Link>
    </div>
  );
};

export default HeaderBanner;
