import React, { Component } from "react";
import PhotodiaryArticle from "~/components/photogallery/PhotodiaryArticle";
//TODO replace with API call
//import data from 'data/api/pages/photo_gallery_article'
import { apiUrlTeraz } from "~/utility/api";
import {
  themeClass,
  headerSuffix,
  selectPage,
  fetchPage,
} from "~/store/actions";
import { connect, ConnectedProps } from "react-redux";
import { pageMapStateToProps } from "~/store/mappers";
import setHttpStatus from "~/utility/httpStatus";
import LoaderIndex from "~/containers/LoaderIndex";
import { RouteComponentProps } from "react-router";
import UpdatingPage from "./UpdatingPage";
import { GAInfoProp } from "~/utility/gaInfo";

class PhotodiaryArticleContainer extends UpdatingPage<PhotodiaryArticleContainerProps> {
  update() {
    const { dispatch } = this.props;

    if (!this.props.isLoading) {
      /*
       * const cat = this.props && this.props.category 
        ? this.props.category.name : null
        */
      const catUrl =
        this.props && this.props.category && this.props.category.url
          ? this.props.category.url
          : null;
      const themeClassName = catUrl ? "theme_" + catUrl : null;
      dispatch(headerSuffix("Fotodenník", "fotodennik"));
      //dispatch( headerSuffix(cat, catUrl) )
      dispatch(themeClass(themeClassName));

      const url = this.getUrl(this.props);
      if (!url) {
        //Throw 404
        setHttpStatus(this, "404");
      } else {
        dispatch(selectPage(url));
        dispatch(fetchPage(url));
      }
    }
  }

  getUrl(props: PhotodiaryArticleContainerProps) {
    const params = props.match.params;
    const matches = /(\d+)-fotografia\.html/.exec(params.photo_slug);
    if (!matches) {
      return null;
    }

    const photoId = matches[1];
    const url = `${apiUrlTeraz}api_rest.php?module=RestApi&method=photoGalleryArticle&photoId=${photoId}`;
    return url;
  }

  render() {
    return (
      <div>
        <PhotodiaryArticle {...this.state} {...this.props} />
        <LoaderIndex gaInfo={this.props.gaInfo} />
      </div>
    );
  }
}

const connector = connect(pageMapStateToProps);
type PhotodiaryArticleContainerProps = ConnectedProps<typeof connector> &
  RouteComponentProps<{ photo_slug: string }> &
  GAInfoProp;
export default connector(PhotodiaryArticleContainer);
