import React, { Component } from "react";
import { PhotoGalleryRow } from "~/components/photogallery/PhotoGalleryElements";
import { ImgFree, Link } from "~/components/Elements";
import { imgSprite } from "~/config/images";
import MetaTags from "~/components/base/MetaTags";
import type { MetaTagsProps } from "../base/MetaTags";
import ScrollHere from "../ScrollHere";
import { FacebookShareButton } from "../Facebook";

type PhotodiaryProps = {
  gallery: Array<any>;
  top_photo: any;
  title: string;
  title_header?: string;
  next_galleries: Array<any>;
  history: any;
};
export default class Photodiary extends Component<PhotodiaryProps> {
  constructor(props: PhotodiaryProps) {
    super(props);
    (this as any).handleKey = this.handleKey.bind(this);
  }

  handleKey(e: Event) {
    const gallery = this.props.gallery || [];

    const isTopPhoto = (e) => e.id === this.props.top_photo;

    if ((e.key && e.key === "ArrowLeft") || (e.which && e.which === 12)) {
      const topPhotoIndex = gallery.findIndex(isTopPhoto);

      if (topPhotoIndex !== -1) {
        const urlPrev =
          gallery[(gallery.length + topPhotoIndex - 1) % gallery.length].url;
        this.props.history.push(urlPrev);
      }
    } else if (
      (e.key && e.key === "ArrowRight") ||
      (e.which && e.which === 12)
    ) {
      const topPhotoIndex = gallery.findIndex(isTopPhoto);

      if (topPhotoIndex !== -1) {
        const urlNext =
          gallery[(gallery.length + topPhotoIndex + 1) % gallery.length].url;
        this.props.history.push(urlNext);
      }
    }
  }

  componentDidMount() {
    if (process.env.BUILD_TARGET !== "server") {
      window.addEventListener("keydown", this.handleKey);
    }
  }

  componentWillUnmount() {
    if (process.env.BUILD_TARGET !== "server") {
      window.removeEventListener("keydown", this.handleKey);
    }
  }

  render() {
    const gallery = this.props.gallery || Array(4).fill({});

    const isTopPhoto = (e) => e.id === this.props.top_photo;

    const topPhoto = gallery.find(isTopPhoto) || {}; //this.props.top_photo || {}

    // debugger;
    const topPhotoIndex = gallery.findIndex(isTopPhoto);
    const urlNext =
      gallery[(gallery.length + topPhotoIndex + 1) % gallery.length].url;
    const urlPrev =
      gallery[(gallery.length + topPhotoIndex - 1) % gallery.length].url;
    const metaData: MetaTagsProps = {
      url: topPhoto.canonical_url,
      title: topPhoto.title,
      description: topPhoto.desc,
      image: topPhoto.image,
      authorUrl: "TASR",
      time: {
        year: new Date().getFullYear(),
      },
      objectType: "article",
    };
    const jsonLdData = {
      "@context": "http://schema.org",
      "@type": "Article",
      mainEntityOfPage: topPhoto.canonical_url,
      headline: topPhoto.title,
      image: {
        "@type": "ImageObject",
        url: topPhoto.image,
      },
      datePublished: topPhoto.date_ISO,
      publisher: {
        "@type": "Organization",
        name: "TASR",
        logo: {
          "@type": "ImageObject",
          url: `http://www.tasr.sk/templates/tasr/assets/images/footerlogo_tasr.png`,
          width: 82,
          height: 15,
        },
      },
      author: {
        "@type": "Organization",
        name: "TASR",
      },
      description: topPhoto.decs,
    };
    const canonicalUrl =
      process.env.BUILD_TARGET !== "server" ? window.location.href : "";
    return (
      <section className="section section--foto">
        <div className="container">
          <div className="row">
            <div className="col col-10 col-sm-12 col-push-1 col-push-sm-0">
              <div className="fotoHeader row">
                <div className="col col-3 col-sm-12">
                  {/*
                  <Link className="fotoHeader-back" to="/fotodennik">
                   &lt; späť na fotodenník
                  </Link>    
                  */}
                  &nbsp;
                </div>

                <div className="col col-12 col-sm-12">
                  <h2 className="fotoHeader-title text-center">
                    {this.props.title_header || "Fotodenník"}
                  </h2>
                </div>
              </div>

              <article className="fotoArticle module">
                {/* <h1 className="fotoArticleTitle text-center">
                 {topPhoto.title}
                </h1> */}

                <PhotoControl gallery={gallery} topPhoto={topPhoto} />

                <ScrollHere />
                <div className="slider slider--foto">
                  <div className="media media--xl media--foto">
                    <ImgFree
                      to={topPhoto.url}
                      src={topPhoto.image}
                      title={topPhoto.title}
                    />
                    <div className="media-content">
                      <div className="media-text row">
                        <div className="col col-10 col-sm-12 col-push-1 col-push-sm-0">
                          <time dateTime={topPhoto.date_ISO}>
                            {topPhoto.date}
                          </time>
                          <FacebookShareButton
                            className="pull-right"
                            canonicalUrl={canonicalUrl}
                          />
                          <h3> {topPhoto.title} </h3>
                          <div>{topPhoto.desc}</div>
                        </div>
                      </div>
                    </div>
                    <div className="media-footer text-right">
                      <small className="color-gray-dark">
                        Zdroj: {topPhoto.source}
                      </small>
                    </div>
                  </div>

                  <Link to={urlPrev}>
                    <button className="owl-prev">
                      <span className="visuallyhidden">predchádzajúce</span>
                      <svg className="icon">
                        <use xlinkHref={`${imgSprite}#chevron-double-left`} />
                      </svg>
                    </button>
                  </Link>

                  <Link to={urlNext}>
                    <button className="owl-next">
                      <span className="visuallyhidden">ďalšie</span>
                      <svg className="icon">
                        <use xlinkHref={`${imgSprite}#chevron-double-right`} />
                      </svg>
                    </button>
                  </Link>
                </div>
              </article>

              <PhotoGalleryRow
                title="Ďalšie fotogalérie"
                url="/fotodennik"
                link_text="Ďalšie fotogalérie"
                items={this.props.next_galleries || Array(4).fill({})}
                key="dalsie"
              />
            </div>
          </div>
        </div>
        {metaData ? <MetaTags data={metaData} /> : null}
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(jsonLdData),
          }}
        />
      </section>
    );
  }
}
interface PhotoControlProps {
  gallery: Array<any>;
  topPhoto: any;
}

class PhotoControl extends Component<PhotoControlProps> {
  containerEl: HTMLDivElement | null;
  selectedEl: HTMLAnchorElement;

  constructor(props) {
    super(props);
    this.scrollToSelected = this.scrollToSelected.bind(this);
  }

  scrollToSelected() {
    if (this.containerEl && this.selectedEl) {
      this.containerEl.scrollLeft =
        this.selectedEl.offsetLeft - this.containerEl.clientWidth / 2;
    }
  }

  componentDidMount() {
    this.scrollToSelected();
  }

  componentDidUpdate() {
    this.scrollToSelected();
  }

  render() {
    const gallery = this.props.gallery;
    const topPhoto = this.props.topPhoto;
    const fotoControlsItems = gallery.map((v, i) => {
      const isCurrent = topPhoto.id && v.id === topPhoto.id;
      const refFn = isCurrent
        ? (e: HTMLAnchorElement) => {
            this.selectedEl = e;
          }
        : (e: HTMLAnchorElement) => {};
      return (
        <Link
          className={"fotoControl " + (isCurrent ? " is-active" : "")}
          to={v.url}
          key={i}
          xref={refFn}
        >
          <img
            className="fotoControl-img"
            src={v.image_thumb}
            alt={v.title}
            loading="lazy"
          />
        </Link>
      );
    });
    return (
      <div className="fotoControls" ref={(e) => (this.containerEl = e)}>
        {fotoControlsItems}
      </div>
    );
  }
}
