import React, { Component } from "react";
import { Link } from "~/components/Elements";
import HeaderBannerConfig from "./HeaderBannerConfig";

class HeaderMain extends Component {
  render() {
    const props = this.props;
    let promoLeft = null;
    let promoRight = null;
    const promo_l = props.promo_left;

    if (
      promo_l &&
      ((promo_l.title && promo_l.title.length > 0) ||
        (promo_l.annotation && promo_l.title.annotation > 0) ||
        (promo_l.image && promo_l.title.image > 0))
    ) {
      promoLeft = (
        <div className="headerLeft hidden-md hidden-sm hidden-xs">
          {/* <article className={`promo promo--left ${promo_l.image ? "promo--withImage" : ""}`}> */}
          <article className={`promo promo--left`}>
            <a className="promo-link" href={promo_l.url}>
              <h1 className="promo-heading">{promo_l.title}</h1>
              {promo_l.annotation}
            </a>
            {promo_l.image ? (
              <a href={promo_l.url} className="promo-img">
                <img
                  src={promo_l.image}
                  className="promo-img"
                  title={promo_l.title}
                  alt=""
                />
              </a>
            ) : null}
          </article>
        </div>
      );
    }

    const promo_r = props.promo_right;

    if (
      promo_r &&
      ((promo_r.title && promo_r.title.length > 0) ||
        (promo_r.annotation && promo_r.title.annotation > 0) ||
        (promo_r.image && promo_r.title.image > 0))
    ) {
      promoRight = (
        <div className="headerRight hidden-md hidden-sm hidden-xs">
          <article className="promo promo--right">
            <a className="promo-link" href={promo_r.url}>
              <div className="promo-body">
                <h1 className="promo-heading">{promo_r.title}</h1>
                {promo_r.annotation ? promo_r.annotation.slice(0, 50) : null}
              </div>
            </a>
            {promo_r.image ? (
              <a href={promo_r.url} className="promo-img">
                <img
                  src={promo_r.image}
                  className="promo-img"
                  title={promo_r.title}
                  alt=""
                />
              </a>
            ) : null}
          </article>
        </div>
      );
    }

    return (
      <header className="headerMain js-headerMain">
        <div className="container border-bottom-primary">
          <div className="headerWrapper">
            <div className="headerMiddle">
              <div className="headerMiddleLogo">
                <h1 className="logo logo--lg">
                  <Link to="/" gaInfo={this.props.gaInfo}>
                    <span className="logo-prefix">TERAZ</span>
                  </Link>
                  <Link
                    to={
                      props.headerSuffixUrl ? "/" + props.headerSuffixUrl : "/"
                    }
                    gaInfo={this.props.gaInfo}
                  >
                    <span className="logo-suffix">{props.headerSuffix}</span>
                  </Link>
                </h1>
              </div>
              <div className="headerMiddleInfo text-center">
                <span className="d-block text-bold p-b-5 companyDesc">
                Spravodajský portál Tlačovej agentúry Slovenskej republiky
                </span>
              </div>
              <div className="headerMiddleInfo text-center">
                <span className="headerMiddleInfo-item">
                  {props.dowName} <strong>{props.dateName}</strong>
                </span>
                <br className="hidden-sm hidden-md hidden-lg hidden-xl" />
                {/* {props.anniversary ? (
                  <span key="anniversary" className="headerMiddleInfo-item">
                    &nbsp;{" "}
                    <strong>
                      <a
                        className="link link--default"
                        href={props.anniversary.url}
                      >
                        {props.anniversary.title}
                      </a>
                    </strong>
                  </span>
                ) : null} */}
                {props.nameday
                  ? [
                      <span key="nameday" className="headerMiddleInfo-item">
                        Meniny má <strong>{props.nameday}</strong>
                      </span>,
                      <br
                        key="namedaybr"
                        className="hidden-md hidden-lg hidden-xl"
                      />,
                    ]
                  : null}
              </div>
            </div>
            {promoLeft}
            {promoRight}
          </div>
        </div>
        <HeaderBannerConfig />        
      </header>
    );
  }
}

export default HeaderMain;
