import clsx from "clsx";
import React from "react";
import { Link } from "~/components/Elements";

const NajnovsieSpravy = (props) => {
  return (
    <div className={clsx("module", props.className)}>
      <Link to="/najnovsie">
        <div className="bannerBase-inner-inner-bg bannerNajnovsieSpravy">
          <div className="txt">Najnovšie správy na&nbsp;Teraz.sk</div>
          <div className="button">Zobraziť najnovšie správy</div>
        </div>
      </Link>
    </div>
  );
};

export default NajnovsieSpravy;
