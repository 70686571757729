import React from "react";

//import {LinkImgFree, Link} from 'components/Elements'
const OtherWebsTASR = (props) => (
  <div className="module">
    <div className="moduleHeader">
      <h3 className="moduleHeader-title">Ostatné weby TASR</h3>
    </div>
    <div className="moduleMain">
      <ul>
        <li>
          <a href="http://newsnow.sk" rel="noopener" target="_blank">
            NewsNow
          </a>
        </li>

        <li>
          <a href="http://preferencie.teraz.sk" rel="noopener" target="_blank">
            Preferencie
          </a>
        </li>

        <li>
          <a href="http://skolskenoviny.sk" rel="noopener" target="_blank">
            Školské noviny
          </a>
        </li>
      </ul>
    </div>
  </div>
);

export default OtherWebsTASR;
