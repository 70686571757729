import React, { Component } from "react";
import WagonVtedy from "~/components/wagons/WagonVtedy";
import { apiUrlVtedy } from "~/utility/api";
import { connect } from "react-redux";
import { fetchWagon } from "~/store/actions";
import { wagonMapStateToProps } from "~/store/mappers";

class WagonVtedyContainer extends Component {
  constructor(props) {
    super(props);
    this.url = `${apiUrlVtedy}homepage`;
  }

  UNSAFE_componentWillMount() {
    const { dispatch } = this.props;
    dispatch(fetchWagon(this.url, this.props.required));
  }

  render() {
    return <WagonVtedy {...this.props[this.url]} gaInfo={this.props.gaInfo} />;
  }
}

export default connect(wagonMapStateToProps)(WagonVtedyContainer);
