import React, { Component, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import BaseApp from "./BaseApp";
import { reportError } from "./utility/api";
import $ from "jquery";
import { AdvertisementAll } from "./components/base/Advertisement";
import Notifications from "./components/Notifications";
import "./style/sass/main.scss";
import { windowLet } from "~/utility/global";
import store from "./store/store";
import axios from "axios";

if (process.env.BUILD_TARGET == "client") {
  // window.addEventListener("error", function(errorEvent){
  window.onerror = function (errorEvent: ErrorEvent) {
    console.log(arguments);
    console.log("got error", errorEvent);
    reportError(errorEvent);
    throw errorEvent;
  };

  // });
}

const supportsHistory = "pushState" in windowLet.history;



class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNotifications: true,
      hideMobile: false
    };
  }

  UNSAFE_componentWillMount() {
    if (process.env.BUILD_TARGET === "client") {
      // this.oldDollar = window.$
      window.$ = $;
    }

    const getIp = async () => {
      const res = await axios.get("https://api.ipify.org/?format=json");
      return res.data.ip;
    };

    // ip tasr
    // const ipTASR = '195.46.72.';
    // const ipKubo = '87.197.146.153';
    // if (window.orientation !== "undefined") {

    function hasTouchSupport() {
      return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    }

    if (process.env.BUILD_TARGET === "client") {
      const isPushNotificationSupported = "PushManager" in window;
    
      const isMobile = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      if (!isPushNotificationSupported) {
        this.setState({
          hideMobile: true,
        })
      }
    }    

    // getIp().then((ip)=>{
    //   if(ip === '87.197.146.153' ||
    //   ip === '62.197.241.122' ||
    //   ip.startsWith(ipTASR)){
    //     this.setState({
    //       showNotifications: true,
    //     })
    //   }
    // })   
  }


  componentDidMount(): void {
     
  }
  
  render(){        


    return (
      <AdvertisementAll>
        <Router forceRefresh={!supportsHistory}>
          <Provider store={store}>
            { (this.state.showNotifications && !this.state.hideMobile) &&
              <Notifications />
            }
            <BaseApp />
          </Provider>
        </Router>
      </AdvertisementAll>
    );
  }
}

export default App;
