import React, { Component } from "react";
import { apiUrlTeraz } from "~/utility/api";
import { connect } from "react-redux";
import { fetchWagon } from "~/store/actions";
import { wagonMapStateToProps } from "~/store/mappers";
import Wagon from "~/components/wagons/Wagon";

class WagonSportContainer extends Component {
  constructor(props) {
    super(props);
    const tipsPage = this.props.tipsPage || 0;
    this.url = `${apiUrlTeraz}api_rest.php?module=RestApi&method=wagonSections&sections=4&type=sport&tipsPage=${tipsPage}`;
  }

  UNSAFE_componentWillMount() {
    const { dispatch } = this.props;
    dispatch(fetchWagon(this.url, this.props.required));
  }

  render() {
    const coluleHeaders = [
      {
        url: "/futbal",
        name: "Futbal",
      },
      {
        url: "/hokej",
        name: "Hokej",
      },
      {
        url: "/tenis",
        name: "Tenis",
      },
      {
        url: "/cyklistika",
        name: "Cyklistika",
      },
      {
        url: "/tag/motorizmus",
        name: "Motošport",
      },
    ];
    return (
      <Wagon
        name={this.props.name}
        {...this.props[this.url]}
        coluleTitle="Šport"
        coluleUrl="/sport"
        coluleHeaders={coluleHeaders}
        noSectionHeaders={true}
        asideHeaderSuffix="v Športe"
        gaInfo={this.props.gaInfo}
      />
    );
  }
}

export default connect(wagonMapStateToProps)(WagonSportContainer);
