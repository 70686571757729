import React, { Component } from "react";
import {
  PhotoGalleryRow,
  PhotoTop,
} from "~/components/photogallery/PhotoGalleryElements";
import { Link } from "~/components/Elements";
import OwlCarousel from "~/components/OwlCarousel";
import { getGaInfo } from "~/utility/gaInfo";
import { useWagonHeight } from "./wagonHeightHook";
export default function WagonObjektivom(props) {
  const ref = useWagonHeight(props.name);
  const gaInfo = getGaInfo(props, "Objektivom");
  const topPhotos = props.top_photos || [];
  return (
    <section className="section section--foto border-top" ref={ref}>
      <div className="container">
        {/* <div className="fotoHeader">
          <h2 className="fotoHeader-title text-center">
          Objektívom
          </h2>
          </div> */}
        <div className="l-columns l-columns--objektivom clearfix">
          <div className="l-main pull-left">
            <div className="module module--foto module--fotoMain">
              <div className="moduleHeader">
                <h2 className="moduleHeader-title">Objektívom</h2>
              </div>
              <div className="moduleMain">
                <OwlCarousel
                  className="slider slider--foto slider--objektivom"
                  options={{
                    items: 1,
                    autoWidth: false,
                    autoHeight: process.env.BUILD_TARGET !== "server",
                  }}
                >
                  {topPhotos.map((p, i) => (
                    <PhotoTop {...p} key={i} gaInfo={gaInfo} />
                  ))}
                  <div className="media media--xl media--foto" key="next">
                    <div className="media-content">
                      <h4
                        className="media-title"
                        style={{
                          marginLeft: "120px",
                        }}
                      >
                        <Link to="/fotodennik" gaInfo={gaInfo}>
                          Ďalšie fotogalérie
                        </Link>
                      </h4>
                    </div>
                  </div>
                </OwlCarousel>
              </div>
              <div className="moduleFooter clearfix">
                <strong className="pull-right">
                  <Link to="/fotodennik" gaInfo={gaInfo}>
                    Ďalšie fotogalérie &gt;
                  </Link>
                </strong>
              </div>
            </div>
          </div>

          <div className="l-sidebar pull-right">
            <PhotoGalleryRow
              title="Foto dňa"
              url="/fotografia-dna"
              link_text="Ďalšie foto dňa"
              items={props.new_photos || Array(2).fill({})}
              fullWidth={true}
              key="fotografia-dna"
              gaInfo={gaInfo}
            />
            <PhotoGalleryRow
              title="Foto TASR"
              url="/foto-tasr"
              link_text="Ďalšie foto TASR"
              items={props.next_galleries || Array(2).fill({})}
              fullWidth={true}
              key="dalsie"
              gaInfo={gaInfo}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
