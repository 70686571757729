import React, { Component } from "react";
import socialMediaConfig from "~/config/socialMedia";

class LDJson extends Component {
  render() {
    return (
      <div>
        {/*
        <script type="application/ld+json">
         {`
        {
        "@context": "http://schema.org",
        "@ type": "WebSite",
        "name": "TERAZ",
        "alternateName": "Teraz.sk",
        "url": "https://www.teraz.sk",
        "logo": "https://www.teraz.sk/images/logo.png",
        }
        `}
        </script>*/}
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "http://schema.org",
              "@type": "Organization",
              name: "TERAZ",
              url: "https://www.teraz.sk",
              sameAs: [
                socialMediaConfig.facebook,
                socialMediaConfig.twitter,
                socialMediaConfig.instagram,
                socialMediaConfig.facebook,
              ],
              logo: "https://www.teraz.sk/images/logo.png",
            }),
          }}
        />
      </div>
    );
  }
}

export default LDJson;
