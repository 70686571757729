import React, { Component, createRef, Ref } from "react";
export default class ScrollHere extends Component {
  myRef;
  constructor(props) {
    super(props);

    this.myRef = createRef();
  }

  componentDidMount() {
    setTimeout(() => {
      var body = document.body;
      var docEl = document.documentElement;
      var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
      var clientTop = docEl.clientTop || body.clientTop || 0;
      const headerTopHeight =
        document.querySelector(".js-headerTop").offsetHeight || 0;
      const top =
        this.myRef.current.getBoundingClientRect().top +
        scrollTop -
        clientTop -
        headerTopHeight;
      window.scroll({
        top,
        behavior: "smooth",
      });
    }, 500);
  }

  render() {
    return <div ref={this.myRef} />;
  }
}
