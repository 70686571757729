import { Component } from "react";

export default class TasrTheme extends Component {
  render() {
    return null;
    /* return (
      <aside className="interstitial l-main-box border-top border-bottom">
        <div className="row">
          <div className="col col-4 col-md-12">
            <h3 className="interstitial-heading">
              Téma TASR<br /> 
              na tento týždeň
            </h3>
          </div>
          <div className="col col-8 col-md-12">
            <span className="interstitial-icon"><img src={imgLogoTasr} alt="TASR" /></span>
            <strong className="interstitial-title"><a href={this.props.url}>{this.props.title}</a></strong>
          </div>
        </div>
      </aside>
    )
    */
  }
}
