import React, { PureComponent } from "react";
import InnerHTML, { cyrb53 } from "../../base/InnerHtml";

function getId(content) {
  return cyrb53(content.substring(0, 80)).toString(16);
}
export default class StaticPanel extends PureComponent<{ content: string }> {
  render() {
    return (
      <InnerHTML
        className="staticPanel"
        html={this.props.content}
        id={getId(this.props.content)}
      />
    );
  }
}
