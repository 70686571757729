import React from "react";
import { imgSprite, imgLogoAd } from "~/config/images";
import { Link as LinkE } from "~/components/Elements";
import socialMediaConfig from "~/config/socialMedia";
import LDJson from "~/components/base/LDJson";
import { getGaInfo, withGaInfo } from "~/utility/gaInfo";

const Footer = (props) => {
  const gaInfo = getGaInfo(props, "footer");
  const Link = withGaInfo(LinkE, gaInfo);
  return (
    <footer className="footer">
      <div className="footer-top container">
        <div className="row">
          <div className="col col-4 col-sm-12 col-push-4 col-push-sm-0 text-center">
            <div className="footer-logo logo logo--sm">
              <Link to="/">
                <span className="logo-prefix">TERAZ</span>
                <span className="logo-suffix">.SK</span>
              </Link>
            </div>
          </div>
          <div className="col col-4 col-sm-12 col-push-4 col-push-sm-0">
            <ul className="footer-networks networks pull-right list-reset">
              <li>
                <a href={socialMediaConfig.facebook} rel="noopener">
                  <svg className="icon">
                    <use xlinkHref={imgSprite + "#facebook"} />
                  </svg>
                  <span className="visuallyhidden">Facebook page</span>
                </a>
              </li>
              <li>
                <a href={socialMediaConfig.twitter}>
                  <i className="icon icon--x gray"></i>
                  <span className="visuallyhidden">Twitter page</span>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener"
                  href="https://www.instagram.com/terazsk/"
                >
                  <i className="icon icon--instagram gray"></i>
                </a>              
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener"
                  href="https://www.threads.net/@terazsk"
                >
                  <i className="icon icon--threads gray"></i>
                </a>
              </li>
              <li>
                <Link to="/rss">
                  <svg className="icon">
                    <use xlinkHref={imgSprite + "#rss"} />
                  </svg>
                  <span className="visuallyhidden">RSS feed</span>
                </Link>
              </li>
            </ul>
          </div>
          <div className="col col-4 col-sm-12 col-pull-8 col-pull-sm-0">
            <strong className="footer-contact">
              <a href={socialMediaConfig.mail}>Kontakt pre Vaše námety</a>
            </strong>
          </div>
        </div>
      </div>
      <div className="footer-bottom container border-top text-center">
        <ul className="footer-menu menu menu--sm list-reset clearfix">
          <li>
            <Link to="/o-portali">O portáli</Link>
          </li>
          <li>
            <Link to="/appky">Aplikácie</Link>
          </li>
          <li>
            <Link to="/redakcia">Redakcia portálu</Link>
          </li>
          <li>
            <Link to="/reklama">Reklama na webe</Link>
          </li>
          <li>
            <Link to="/podmienky-pouzivania">Podmienky používania </Link>
          </li>
          <li>
            <Link to="/pravidla-ochrany-osobnych-udajov">
              Pravidlá ochrany osobných údajov
            </Link>
          </li>
        </ul>

        <div className="footer-copy">
          <p>
          TERAZ MEDIA, a.s., Dúbravská cesta 14 841 04 Bratislava, IČO: 47345136, EV 40/22/SWP
          </p>
          <p>
            Copyright &copy;{" "}
            <a href="http://www.tasr.sk" target="_blank" rel="noopener">
              TASR
            </a>{" "}
            a TERAZ MEDIA a.s. 2017. Publikovanie alebo ďalšie šírenie obsahu
            správ zo zdrojov Tlačovej agentúry Slovenskej republiky (TASR) alebo
            TERAZ MEDIA a.s.
            <br />
            je bez predchádzajúceho písomného súhlasu TASR alebo TERAZ MEDIA
            a.s. výslovne zakázané. Pre získanie služieb TASR si pozrite ponuku
            na{" "}
            <a href="http://www.tasr.sk" target="_blank" rel="noopener">
              www.tasr.sk
            </a>
            .<br />
            Obsah stránok je generovaný redakčným systémom{" "}
            <a
              className="link link--black"
              href="https://adit.sk"
              target="_blank"
              rel="noopener"
            >
              Malina
            </a>
            . Výsledkový servis:{" "}
            <a
              className="link link--black"
              href="http://www.livescore.in/sk/"
              target="_blank"
              rel="noopener"
            >
              Livescore
            </a>
            .
            {/* <a className="link link--black" href="#">Verzia bez grafiky</a> */}
          </p>
          <p>
            Web design and technology by{" "}
            <a
              className="link link--black"
              href="https://adit.sk"
              target="_blank"
              rel="noopener"
            >
              ADIT Agency
            </a>
            .
          </p>
        </div>
        <a
          className="footer-ad"
          href="https://adit.sk"
          target="_blank"
          rel="noopener"
        >
          <img src={imgLogoAd} alt="ADIT Agency Logo" />
          <span className="visuallyhidden">ADIT Agency</span>
        </a>
      </div>
      <LDJson />
    </footer>
  );
};

export default Footer;
