import React, { useEffect } from "react";
import InnerHTML from "../base/InnerHtml";
import { useConsenstString } from "./Web2mediaHooks";


const Banner = () => {
  let consentString = "consentstring";
  let fisrtPartConsent = '<ins class="asm_async_creative" style="display:inline-block; width:300px; height:600px;text-align:left; text-decoration:none;" data-asm-cdn="cdn.web2media.sk" data-asm-host="turbo.web2media.sk" data-asm-params="pid=717&gdpr_consent=['
  let lastPartConsent = ']"></ins><script src="//cdn.web2media.sk/adasync.min.js" async type="text/javascript"></script> '
  
  
  let htmlConsentString = useConsenstString(fisrtPartConsent, consentString, lastPartConsent);

  return (
    <InnerHTML
      className=""
      html={htmlConsentString}
    ></InnerHTML>
  );
  
  
};

export const Web2MediaBanner300 = Banner;

export default Banner;
