import { Component } from "react";
import { withRouter } from "react-router";

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    const isPhotoRegex = /\/(fotodennik|foto-tasr|fotografia-dna)\/.+\//;

    if (
      this.props.location !== prevProps.location &&
      !this.props.location.pathname.match(isPhotoRegex)
    ) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
