import React, { Component } from "react";
import {
  themeClass,
  headerSuffix,
  selectPage,
  fetchPage,
} from "~/store/actions";
import { connect } from "react-redux";
import { pageMapStateToProps } from "~/store/mappers"; //import AssignmentActions from 'containers/AssignmentActions'
import axios from "axios";
import AgregatorSocSietiPage from "~/components/pages/AgregatorSocSietiPage";
import dataResults from './prez2024_results_emp.json';

class PageAgregatorSocSieti extends Component {   
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            dataLegend: {},
            showPage: true,
        };
    }
     

    // loadJSON method to open the JSON file.
    loadChartJSON(path, success, error) {
    var xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          success(JSON.parse(xhr.responseText));
        }
        else {
          error(xhr);
        }
      }
    };
    xhr.open('GET', path, true);
    xhr.send();
  }
    
  
  myData = (data) => {
    // Output only the details on the first post    
    this.setState({
        data: data
      });
    // this.setState({ dataGraph: data});
  }
    

  UNSAFE_componentWillMount() {
    const getIp = async () => {
      const res = await axios.get("https://api.ipify.org/?format=json");
      return res.data.ip;
    };

    // List of allowed IPs
    const allowedIPs = [
      '195.46.72.',
      '87.197.146.153',
      '95.102.148.135',
      '81.92.251.116',
      '62.197.241.122',
    ];

    getIp().then((ip) => {
      if (allowedIPs.some((allowedIP) => ip.startsWith(allowedIP))) {
        this.setState({
          showPage: true,
        });
      }
    });
  }


  componentDidMount() {
    const html = {};    
    // this.loadChartJSON("https://www.tasr.sk/referendum2023.json", this.myData,'jsonp');
    // this.loadChartJSON("https://www.tasr.sk/prez2024_legend.json", this.myDataLegend,'jsonp');
    // this.loadChartJSON("https://www.tasr.sk/prez2024_r2_results.json", this.myData,'jsonp');
  }

  render() {
    const { data } = this.state;
    return(
      (this.state.showPage) ? <AgregatorSocSietiPage dataSocial={data} />   : <div></div>      
    )
    // return <Volby2023Page dataLegend={data} dataGraph={data} />;
  }
}

export default connect(pageMapStateToProps)(PageAgregatorSocSieti);
