import React, { Component, useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, deleteToken, onMessage } from "firebase/messaging";
import { getCookie, setCookie } from "~/utility/cookies";
import { apiUrlTeraz } from "~/utility/api";

function Notifications() {    
    
    const firebaseConfig = {
        apiKey: "AIzaSyDDjDZ_qtTUWpRGsoEt62Dcqzx5w76l2TQ",
        authDomain: "teraz-11562.firebaseapp.com",
        databaseURL: "https://teraz-11562.firebaseio.com",
        projectId: "teraz-11562",
        storageBucket: "teraz-11562.appspot.com",
        messagingSenderId: "1096297727261",
        appId: "1:1096297727261:web:f1de5f0efe8dcff06f0df4",
        measurementId: "G-64NTKPJYNK"
      };

    const fapp = initializeApp(firebaseConfig);
    const messaging = getMessaging(fapp);    

    const [ userResponded, setUserResponded ] = useState(false);

    onMessage(messaging, function(payload){
        console.log("Received message ", payload);
        
        const notificationTitle = payload.notification.title;
        const notificationOptions = {
          body: payload.notification.body,
          icon: payload.notification.icon || 'https://www.teraz.sk/favicon/apple-icon-57x57.png',
            data: { 
              url: payload.data.url
            },
        };
      
        if (process.env.BUILD_TARGET === "client") {
            navigator.serviceWorker.ready.then((registration) => {
                registration.showNotification(notificationTitle, notificationOptions);
              });

            // self.registration.showNotification(notificationTitle, notificationOptions);
        }
    });

      function saveTokenCookie(tokenId){
        setCookie('tokenId', tokenId, null);
      }
      

    async function getFBToken(){
        console.log("PERMISSION: ", Notification.permission);

        if(Notification.permission === 'granted'){
            getToken(messaging, {vapidKey: "BILWBDxmThYEfRC4QtBLMwLXla9K9UWOvjWRoMG-0HlyJlzzlyF8SG8t9y6ATklAD2UjpCLJPLVQ9U00jPBXgeU"}).then((currentToken) =>{
                const token = currentToken;    
                console.log('Notification permission granted.');
                console.log('FCM Token:', token);

                const savedToken = getCookie('tokenId');
                if(savedToken !== token){
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                    };

                    fetch(apiUrlTeraz + 'subscribe-token/'+token, requestOptions)
                        .then(response => {
                            response.text();
                        });
                }                
            });        

        }else if(Notification.permission === 'default'){
            getToken(messaging, {
                vapidKey:
                "BILWBDxmThYEfRC4QtBLMwLXla9K9UWOvjWRoMG-0HlyJlzzlyF8SG8t9y6ATklAD2UjpCLJPLVQ9U00jPBXgeU",
            })
                .then((currentToken) => {                                    
                    if (currentToken) {
                        saveTokenCookie(currentToken);
                        const token = currentToken;
                        const requestOptions = {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                        };
        
                        fetch(apiUrlTeraz + 'subscribe-token/'+token, requestOptions)
                            .then(response => {
                                response.text();
                            });
                        
                    } else {
                        // Show permission request UI
                        console.log("No registration token available. Request permission to generate one.");
                    }
                })
                .catch((err) => {
                console.log("An error occurred while retrieving token. ", err);
                });
        }

    }

    if((Notification.permission === 'granted' || Notification.permission === 'denied')){
        getFBToken();
    }

    function showNotificationPopup(){        
        return getCookie('showNotificationPopup') === '1';
    }

    async function enableNotificationsAndClose(){
        await getFBToken().then(() => {
            setCookie('showNotificationPopup', '1', 30);
            setUserResponded(true);
        });
      }
    
      function disableNotificationsAndClose(){
        setCookie('showNotificationPopup', '1', 30);
        setUserResponded(true);
      }

      console.log('SHOW POPUP? :', showNotificationPopup());
    return (
        <>        
        {(!(userResponded) && !(showNotificationPopup()) && !(Notification.permission === 'granted') && (Notification.permission !== 'denied')) &&
        <div>
            <div className="popup-wrapper">
                <h1 className="logo logo--md"><span className="logo-prefix">TERAZ</span><span className="logo-suffix">.SK</span></h1>
                <p>Povoľte upozornenia aby vám neušli najnovšie správy z portálu TERAZ.sk</p>
                <div className="popup-actions">
                    <button className="btn-popup btn-popup--grey" onClick={disableNotificationsAndClose}>Nemám záujem</button>
                    <button className="btn-popup btn-popup--blue" onClick={enableNotificationsAndClose}>Povoliť</button>      
                </div>              
            </div>
        </div>
      }
      </>
    );
}

export default Notifications;

