import React, { Component } from "react";
import PropTypes from "prop-types";
export default class BannerHorizontal extends Component {
  static propTypes = {
    src: PropTypes.string,
    title: PropTypes.string,
  };

  render() {
    return this.props.src ? (
      <div className="banner banner--horizontal">
        <a href={this.props.url} target="_blank" rel="noopener">
          <img
            src={this.props.src}
            title={this.props.title}
            alt="banner"
            style={this.props.img_style}
            loading="lazy"
          />
        </a>
      </div>
    ) : null;
  }
}
