import React from "react";
import loadScript from "../../utility/loadScript";
import { windowLet, documentLet } from "~/utility/global";
// const gemiusIdentificator = "dvXrEEbWwNvztr9fPtjVm7S4XrE_UmLYGSlrifBLijX.t7";
export const requireMaster = (masterId) => {
  if (!windowLet.adoceanMasters) {
    windowLet.adoceanMasters = [];
  }

  if (windowLet.adoceanMasters.indexOf(masterId) === -1) {
    windowLet.ado.master({
      id: masterId,
      server: "sk.adocean.pl",
    });
    windowLet.adoceanMasters.push(masterId);
  }
};

const GemiusHeatMap = () => {
  loadScript("https://ghmsk.hit.gemius.pl/hmapxy.js", () => {
    if (process.env.BUILD_TARGET !== "server") {
      windowLet.ghmxy_align = "center";
      windowLet.ghmxy_type = "absolute";
      windowLet.ghmxy_identifier =
        "ApWV9KdsbQMQ4T5OHxsrj6e174DIrwNsSOFVoGGoYWH.T7";
    }
  }, {async: true, defer: true});
};

export const fnGemius = () => {
  windowLet.pp_gemius_identifier =
    "dvXrEEbWwNvztr9fPtjVm7S4XrE_UmLYGSlrifBLijX.t7";
  
  windowLet.pp_gemius_init_timeout = 8000;
  windowLet.pp_gemius_use_cmp = true;

  function gemius_pending(i) {
    windowLet[i] =
      windowLet[i] ||
      function () {
        var x = (windowLet[i + "_pdata"] = windowLet[i + "_pdata"] || []);
        x[x.length] = arguments;
      };
  }

  gemius_pending("gemius_hit");
  gemius_pending("gemius_event");
  gemius_pending("pp_gemius_hit");
  gemius_pending("pp_gemius_event");
  (function (d, t) {
    try {
      var gt = d.createElement(t),
        s = d.getElementsByTagName(t)[0],
        l = "http" + (location.protocol === "https:" ? "s" : "");
      gt.setAttribute("async", "async");
      gt.setAttribute("defer", "defer");
      gt.src = l + "://gask.hit.gemius.pl/xgemius.js";
      s.parentNode.insertBefore(gt, s);
    } catch (e) {}
  })(documentLet, "script");
};

export const fnGemiusEvent = (consent) => {
  windowLet.pp_gemius_gdpr_applies = consent;

  function gemius_pending(i) {
    windowLet[i] =
      windowLet[i] ||
      function () {
        var x = (windowLet[i + "_pdata"] = windowLet[i + "_pdata"] || []);
        x[x.length] = arguments;
      };
  }

  gemius_pending("gemius_hit");
  gemius_pending("gemius_event");
  gemius_pending("pp_gemius_hit");
  gemius_pending("pp_gemius_event");
  (function (d, t) {
    try {
      var gt = d.createElement(t),
        s = d.getElementsByTagName(t)[0],
        l = "http" + (location.protocol === "https:" ? "s" : "");
      gt.setAttribute("async", "async");
      gt.setAttribute("defer", "defer");
      gt.src = l + "://gask.hit.gemius.pl/xgemius.js";
      s.parentNode.insertBefore(gt, s);
    } catch (e) {}
  })(documentLet, "script");
}

const AdoceanHeader = () => {
  loadScript("https://sk.adocean.pl/files/js/ado.js", () => {
    if (typeof windowLet.ado !== "object") {
      windowLet.ado = {};

      windowLet.ado.config = windowLet.ado.preview = windowLet.ado.placement = windowLet.ado.master = windowLet.ado.slave = function () {};
    }

    windowLet.ado.config({
      mode: "new",
      xml: false,
      characterEncoding: true,
    });
    windowLet.ado.preview({
      enabled: true,
      emiter: "sk.adocean.pl",
    });
    requireMaster("9raQXGN7N20FgcckjJL6XBbMYzJQkxKbBD455gB8FcP.07");
  });
};

const checkApproves = () => {
  if (process.env.BUILD_TARGET !== "server") {
    document.addEventListener("changeApproves", function (e) {
      let approves = e.detail;
      
      if (indexOfLoad == 0) {

        indexOfLoad++
      }
    });
  }
}
export let indexOfLoad = 0;
export class AdvertisementAll extends React.Component {
  constructor(props) {
    super(props);
    checkApproves();
    AdoceanHeader();
    fnGemius();
    GemiusHeatMap();
  }

  render() {
    return this.props.children;
  }
}
/* global COOKIES_ACCEPTED */
