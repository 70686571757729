import parse5 from "parse5";
import { flatten } from "lodash";
import React from "react";
import { v4 as uuid } from "uuid";
import loadScript from "../../utility/loadScript";
import log from "../../utility/log";

export function cyrb53(str, seed = 0) {
  let h1 = 0xdeadbeef ^ seed,
    h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 =
    Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^
    Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 =
    Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^
    Math.imul(h1 ^ (h1 >>> 13), 3266489909);
  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
}

const isBrowser = !!(
  process.env.BUILD_TARGET !== "server" &&
  typeof document !== "undefined" &&
  typeof window !== "undefined"
);

const findScripts = (node) => {
  if (!isBrowser) {
    return;
  }

  if (node.tagName && node.tagName === "script") {
    const src = node.attrs.find((x) => x.name === "src");

    if (src) {
      return src.value; // return (node.childNodes || []).map((n) => n.value);
    } else {
      try {
        const script = (node.childNodes || []).map((n) => n.value).join();
        // eslint-disable-next-line no-eval
        eval(script);
      } catch (e) {
        log.error("Error during executing script");
        log.error(e);
      }
    }
  }

  return flatten((node.childNodes || []).map(findScripts));
};

type PropT = { html: string; className?: string; span?: boolean; id?: string };
type StateT = { id: string; scripts: any[] };
class InnerHTML extends React.Component<PropT, StateT> {
  elWrapper: null | HTMLDivElement | HTMLSpanElement;

  constructor(props, context) {
    super(props, context);
    this.state = this.initialState(props);
  }

  clean() {
    if (process.env.BUILD_TARGET === "server") {
      return null;
    }

    if (!this.state.id || !isBrowser) {
      return null;
    }

    const node = document.getElementById(this.state.id);

    if (!node) {
      return;
    }

    while (node.hasChildNodes()) {
      node.removeChild(node.lastChild);
    }
  }

  parse(props: PropT) {
    if (!isBrowser) {
      return;
    }

    const html = (props || {}).html;

    if (!html || typeof html !== "string") {
      return;
    }

    return findScripts(parse5.parseFragment(html));
  }

  initialState(props) {
    return {
      id: (props || {}).id || uuid(),
      scripts: this.parse(props),
    };
  }

  shouldComponentUpdate(nextProps) {
    return this.props.html !== nextProps.html;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.html === nextProps.html) {
      return;
    }

    this.clean();
    this.setState(this.initialState(nextProps));
  }

  componentDidMount() {
    this.componentDidUpdate();
  }

  componentDidUpdate() {
    if (!isBrowser) {
      return;
    }

    const scripts = (this.state || {}).scripts || [];
    scripts.forEach((src) => {
      log.info("loading script", src);
      loadScript(src);
    });
  }

  render() {
    if (typeof this.props.html !== "string") {
      return null;
    }

    return React.createElement(this.props.span ? "span" : "div", {
      id: this.state.id,
      className: this.props.className,
      ref: (el) => {
        this.elWrapper = el;
      },
      dangerouslySetInnerHTML: {
        __html: this.props.html,
      },
    });
  }
}

export default InnerHTML;
