import React, { PureComponent } from "react";
import asideMapping from "../../utility/aside";
import LoaderIndex from "~/containers/LoaderIndex";
import { subscribeToXML } from "~/utility/api";



type SubscribeXMLPageProps = {
    // canonical_url: string;
    // totalShareCount: number;
    image: string;
    // image_caption: string;
    // description: string;
    title: string;
    url: string;
    html: string;
  }

export default class SubscribeXMLPage extends PureComponent<SubscribeXMLPageProps> {
    constructor(props) {
      super(props);
      this.state = {
        formMessages: [],
        showXMLURLMessage: false
      };
    }

    isValidUrl(string) {
        try {
          new URL(string);
          return true;
        } catch (err) {
          return false;
        }
      }
    
    isValidEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    clearFormMessages(){        
        this.setState(prevState => ({
            formMessages: []
        }))
    }

    subscribeToXML(e: Event) {
        e.preventDefault();

        this.clearFormMessages();            

        var errors = [];
        const portal_name: string = e.target.portal_name.value;
        const portal_url: string = e.target.portal_url.value;
        const portal_country: string = e.target.country.value;
        const contact_name: string = e.target.full_name.value;
        const contact_email: string = e.target.email.value;
        const contact_phone: string = e.target.phone.value;

        const data = {
            "portal_name": portal_name,
            "portal_url": portal_url,
            "portal_country": portal_country,
            "contact_name": contact_name,
            "contact_email": contact_email,
            "contact_phone": contact_phone,
        };


        if(!this.isValidUrl(portal_url)){
            this.setState(prevState => ({
                formMessages: [...prevState.formMessages, 'URL nie je v správnom formáte']
            }))
            errors.push('URL nie je v správnom formáte');
        }
        if(!this.isValidEmail(contact_email)){
            this.setState(prevState => ({
                formMessages: [...prevState.formMessages, 'Email nie je v správnom formáte']
            }))
            errors.push('Email nie je v správnom formáte');
        }

        if(errors.length != 0){
            return;
        }

          subscribeToXML(data).then((data) => {
            this.setState(prevState => ({
                formMessages: [...prevState.formMessages, 'Ďakujeme'],
                showXMLURLMessage: true
            }))
          });
      }
  
    render() {
        const props = this.props;    

        (this as any).subscribeToXML = this.subscribeToXML.bind(this);
    

    return (
        <div>
            <div className="container static-page">                
                <h1 onClick={this.showMess} className="color-blue w-tag">
                    XML ODBER
                    <div className="tag">beta verzia</div>
                </h1>            

                <div className="subscribe-xml-text">
                    <p>
                        V rámci pilotného projektu zameraného na informovanie verejnosti ponúka TASR v roku 2024 fyzickým aj právnickým osobám prevádzkujúcim internetové portály možnosť prihlásiť sa na odber časti agentúrneho spravodajstva v skrátenej forme <b>ZDARMA</b>.
                    </p>                

                    <p>
                        Podmienkou na odber stručných spravodajských informácií spolu s ilustračnou fotografiou je <b>REGISTRÁCIA</b>.
                    </p>

                    <p>
                        V prípade otázok kontaktujte: <a href="mailto:obchod@tasr.sk">obchod@tasr.sk</a>
                    </p>
                
                </div>

                <div className="subscribe-xml-form-wrapper">
                    <form
                        id="subscribeToXMLForm"
                        name="subscribeToXMLForm"
                        onSubmit={this.subscribeToXML}
                        className={this.state.showXMLURLMessage ? 'hidden' : ''}
                    >
                    <h2 className="color-blue text-left b">Registrácia</h2>
                    <div className="form-row">
                        <div className="subscribe-xml-form">
                            <h2>Informácie o portáli</h2>
                            <div className="form-group">
                                <label htmlFor="portal-name">Názov portálu</label>
                                <input type="text" name="portal_name" id="portal-name" required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="portal-url">URL portálu</label>
                                <input type="text" name="portal_url" id="portal-url" placeholder="https://www.nazovportalu.sk" required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="country">Krajina</label>
                                <input type="text" name="country" id="country" required />
                            </div>
                        </div>
                        
                        <div className="subscribe-xml-form">
                            <h2>Kontaktná osoba</h2>
                            <div className="form-group">
                                <label htmlFor="full-name">Meno a priezvisko</label>
                                <input type="text" name="full_name" id="full-name" required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input type="email" name="email" id="email" required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="phone">Telefón</label>
                                <input type="phone" name="phone" id="phone" required />
                            </div>
                        </div>
                    </div>   

                    <div className="form-checkbox-wrapper">
                        <input type="checkbox" id="gdpr" name="gdpr" required />
                        <label htmlFor="gdpr">Súhlasím s <a href="/podmienky-pouzivania-xml-odber" target="_blank">podmienkami používania</a> a so spracovaním osobných údajov</label>    
                    </div>             

                    <div className="form-message">
                        <ul>
                        {this.state.formMessages.map((message, i) => {
                            return (<li>{message}</li>)                            
                        })}
                        </ul>
                    </div>
                    
                    <button type="submit">Odoslať</button>
                    </form>

                    <div className="form-message">
                        {this.state.showXMLURLMessage &&
                            <div>
                            <div>Ďakujeme,<br />
                            URL odberu: <a href="https://www.teraz.sk/xml-verejnost" target="_blank">https://www.teraz.sk/xml-verejnost</a></div>
                            </div>
                        }
                    </div>
                </div>

                <div className="subscribe-xml-text">
                    <h3 className="b">Chcete použiť XML kanál na svojom webe?</h3>
                
                    Podmienky publikovania XML kanálov na internetových stránkach:
                    <ol>
                        <li>Cez XML kanál je možné publikovať náhľad obrázku, titulok správy a časť správy, ktorá sa nachádza v XML linku.</li>
                        <li>Pri každej správe musí byť odklik na pôvodnú správu uverejnenú na Teraz.sk s textom viac, čítať ďalej, alebo textom podobného významu.</li>
                        <li>Nie je možné správy sťahovať a publikovať na stránkach v plnom znení.</li>
                        <li>Nie je možné fotografie sťahovať, upravovať a publikovať pri správach, ktoré nie sú súčasťou XML linku.</li>
                        <li>V prípade, že správy chete publikovať v plnom znení, kontaktujte obchodné oddelenie TASR – <a href="mailto:obchod@tasr.sk">obchod@tasr.sk</a></li>
                        <li>Súčasťou XML linku sú aj správy označené ako OTS (Originálna textová služba).</li>
                        <li>Publikovanie správ cez XML kanál je podmienené registráciou a súhlasom s týmito podmienkami.</li>
                        <li>Porušenie týchto podmienok bude považované za konanie v rozpore s autorským zákonom a prevádzkovateľ portálu TERAZ.sk si vyhradzuje právo postupovať na náhradu škody v zmysle platných zákonov SR.</li>
                        <li>TASR si vyhradzuje právo tieto podmienky kedykoľvek zmeniť.</li>
                    </ol>
                </div>

            </div>
        <LoaderIndex />
        </div>
    );
}
};



