//
// export const apiUrlTeraz = 'https://www.teraz.adit.sk:444/'
export const apiUrlTeraz =
  process.env.RAZZLE_API_TERAZ || "https://www.teraz.sk/";
export const apiUrlTASR =
  process.env.RAZZLE_API_TERAZ || "https://www.tasr.sk/";
// export const apiUrlTabletTv = 'https://tv.adit.sk:444/ci/api/'
export const apiUrlTabletTv =
  process.env.API_TABLET_TV || "https://www.tasrtv.sk/ci/api/";
export const apiUrlVtedy =
  process.env.API_VTEDY || "https://www.vtedy.sk/export/";
// export const apiUrlWebmagazin = 'https://webmagazin.teraz.adit.sk:444/'
export const apiUrlWebmagazin =
  process.env.API_WEBMAGAZIN || "https://webmagazin.teraz.sk/";
import log from "./log";
//
type Component = {
  cancel_fetch: boolean | null | undefined;
  setState: (state: Record<string, any>) => void;
};
export function fetchJson(url: string) {
  log.info("start", url);
  log.time(url);
  return fetch(url, {
    redirect: "follow",
  })
    .then(
      (res) => {
        if (res.ok) {
          //var contentType = res.headers.get("content-type");
          //if(contentType && contentType.indexOf("application/json") !== -1) {
          log.timeEnd(url);
          return res.json(); //}
        }

        throw new Error(`Not ok ${url} ${res.status}`);
      },
      (err) => {
        log.error("fetch problem", url);
        throw err;
      }
    )
    .catch((err) => {
      log.error("problem parsing repsonse", url);
      throw err;
    });
}

// TODO
interface PocasieI {
  city: string;
  symbol: number | string;
  temperature: number;
  description: string;
}
function apiPocasie(url: string, component: Component) {
  const p = fetchJson("https://pocasie.teraz.sk/api/" + url);
  return p.then((data: PocasieI): void => {
    if (component.cancel_fetch) {
      // component.setState({ ...data, loading: false });
    } else {
      component.setState({ ...data, loading: false });
    }
  });
}

export function reportError(error: ErrorEvent) {
  const navigator = window.navigator
    ? {
        userAgent: window.navigator.userAgent,
        plugins: window.navigator.plugins,
        appName: window.navigator.appName,
        appVersion: window.navigator.appVersion,
        platform: window.navigator.platform,
        productSub: window.navigator.productSub,
      }
    : null;
  console.dir(error);
  const { message, lineno, colno, timeStamp, filename } = error;
  const body = {
    url: document.URL,
    error: error.error,
    message,
    lineno,
    colno,
    timeStamp,
    filename,
    time: Date(),
    navigator,
  };
  return Promise.resolve();
  // TODO fixme
  // eslint-disable-next-line no-unreachable
  return fetch(`${apiUrlTeraz}api_rest.php?module=RestApi&method=error`, {
    method: "POST",
    //headers: {
    //  'Accept': 'application/json',
    //  'Content-Type': 'application/json'
    //},
    body: JSON.stringify(body),
  }).catch((err) => {
    log.error("errorReport fetch error", err);
  });
}
export function reportBug(text: string) {
  const data = new URLSearchParams();
  data.append("text", text);
  data.append("url", document.URL);
  return fetch(`${apiUrlTeraz}api_rest.php?module=RestApi&method=reportBug`, {
    method: "POST",
    body: data,
  }).then(
    (value) => {
      log.info(value);
      log.info(value.text());
    },
    (reason) => {
      log.error("reportBug", reason);
    }
  );
}

export function registerRSS(email: string) {
  const data = new URLSearchParams();
  data.append("email", email);
  // data.append("url", document.URL);
  return fetch(`${apiUrlTeraz}api_rest.php?module=RestApi&method=registerRssEmail&email=${email}`, {
    method: "POST",
    // body: data,
  }).then(
    (res) => {
      if (res.ok) {
        return res.json(); //}
      }

      throw new Error(`Not ok`);
    },
    (err) => {
      log.error("Email is not ok");
      throw err;
    }
  );
}

export function subscribeToXML(formData) {
  // const data = new URLSearchParams();
  // data.append("email", e);
  console.log("FORM: ", formData);

  // data.append("url", document.URL);
//  return;
  return fetch(`https://www.teraz.sk/api/xml/index.php/api/xml/index.php`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(
      formData
    ),
  }).then(
    (res) => {
      if (res.ok) {
        return res.json(); //}
      }

      throw new Error(`Not ok`);
    },
    (err) => {
      log.error("Email is not ok");
      throw err;
    }
  );
}

export { apiPocasie };
