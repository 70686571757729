import React, { Component } from "react";
import { apiUrlTeraz } from "~/utility/api";
import { connect } from "react-redux";
import { fetchWagon } from "~/store/actions";
import { wagonMapStateToProps } from "~/store/mappers";
import Wagon from "~/components/wagons/Wagon";

class WagonPublicistikaContainer extends Component {
  constructor(props) {
    super(props);
    const tipsPage = this.props.tipsPage || 0;
    this.url = `${apiUrlTeraz}api_rest.php?module=RestApi&method=wagonSections&sections=5645&type=publicistika&tipsPage=${tipsPage}`;
  }

  UNSAFE_componentWillMount() {
    const { dispatch } = this.props;
    dispatch(fetchWagon(this.url, this.props.required));
  }

  render() {
    const coluleTitle = "Publicistika";
    const coluleUrl = "/publicistika";
    const coluleHeaders = [
      // {url: '/slovensko', name: 'Slovensko'},
      // {url: '/svet', name: 'Svet'},
      // {url: '/ekonomika', name: 'Ekonomika'},
      // {url: '/regiony', name: 'Regióny'},
    ];
    return (
      <Wagon
        name={this.props.name}
        {...this.props[this.url]}
        coluleTitle={coluleTitle}
        coluleUrl={coluleUrl}
        coluleHeaders={coluleHeaders}
        noSectionHeaders={true}
        asideHeaderSuffix="v Publicistike"
        gaInfo={this.props.gaInfo}
      />
    );
  }
}

export default connect(wagonMapStateToProps)(WagonPublicistikaContainer);
