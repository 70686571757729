import React from "react";

const Image = (props) => {
  if (props.test) {
    return props.children;
  } else {
    return <div className="placeholder" />;
  }
};

export default Image;
export const PlaceholderImg = (props) => (
  <div className="aspectRatio">
    <div className="aspectRatio-fill placeholder" />
    <div className="aspectRatio-content" />
  </div>
);
export const PlaceholderLinkImg = (props) => (
  <a href="#" className="media-img">
    <PlaceholderImg />
  </a>
);
