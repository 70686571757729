import React, { Component } from "react";
import { Link, Line } from "~/components/Elements";
import { imgSprite } from "~/config/images";
import PropTypes from "prop-types";

/*
 * TODO: extract owlcarousel into new chunk
 */
//import Bundle from 'containers/Bundle'
//const OwlCarousel = (props) => (
//  <Bundle load={import('components/OwlCarousel')} {...props}/>
//)
import OwlCarousel from "~/components/OwlCarousel";
import InnerHTML, { cyrb53 } from "./base/InnerHtml";
import log from "~/utility/log";
import { GAInfo } from "~/utility/gaInfo";


export function itemId(time: string, title: string, link: string) {
  return cyrb53(`${time}|${title}|${link}`).toString(16);
}

export interface ItemI {
  title: string;
  annotation: string;
  time: string;
  link?: string;
  gaInfo?: GAInfo;
}
export type PropT = { items: ItemI[] };
export default class RightNow extends Component<PropT> {
  static propTypes = {
    items: PropTypes.array,
  };

  render() {
    log.log(this.props.items);
    debugger;
    const items = (this.props.items || Array(6).fill({})).map((v, i) => (
      <Item {...v} key={i} />
    ));
    return (
      <div className="module">
        <div className="l-columns clearfix">
          <div className="moduleHeader l-main">
            <h3 className="moduleHeader-title">
              <Link to="/prave-teraz">
                Práve teraz{" "}
                <svg className="icon color-primary">
                  <use xlinkHref={imgSprite + "#rss"} />
                </svg>
              </Link>
            </h3>
          </div>
        </div>
        <div className="moduleMain moduleMain--rightNow">
          <div className="rightNow rightNow--horizontal slider slider--rightNow">
            <OwlCarousel
              onChange={(event) => {
                /* if(event.item.index === event.item.count){
            this.context.history.push('/prave-teraz')
            } */
              }}
            >
              {/*<div className="rightNow-item">
               SEPARATOR
              </div>*/}
              {items}
              <div className="rightNow-item owl-item">
                {/* <time className="rightNow-time" dateTime="">{props.time || <Line/>}</time> */}
                <div className="rightNow-main">
                  <div className="rightNow-body">
                    <p className="rightNow-text">
                      <Link className="rightNow-time" to="/prave-teraz">
                        <strong>Viac &gt;</strong>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
          <div className="moduleFooter clearfix">
            <strong className="pull-right">
              <Link to="/prave-teraz">Viac &gt;</Link>
            </strong>
          </div>
        </div>
      </div>
    );
  }
}

const Item: React.FC<ItemI> = (props) => (
  <div className="rightNow-item owl-item">
    <time className="rightNow-time" dateTime="">
      {props.time || <Line />}
    </time>
    <div className="rightNow-main">
      <div className="rightNow-body">
        <p className="rightNow-text">
          {props.link ? (
            <Link to={props.link}>
              <strong>{props.title || <Line />}</strong>
            </Link>
          ) : (
            <strong>{props.title || <Line />}</strong>
          )}{" "}
          {<InnerHTML span html={props.annotation} id={props.id} /> || <Line />}
        </p>
      </div>
    </div>
  </div>
);

/* global $ */
