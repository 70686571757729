import React, { Component } from "react";
import Submenu from "~/components/Submenu";
import LoaderIndex from "~/containers/LoaderIndex";
import { imgSprite } from "~/config/images";
import asideMapping from "~/utility/aside";
import { Link, Line } from "~/components/Elements";
import TasrTheme from "~/components/aside/single/TasrTheme";
import Pagination from "~/components/Pagination";
import { facebookAppId } from "~/config/socialMedia";
import InnerHTML from "../base/InnerHtml";
export default class RightNow extends Component {
  render() {
    const itemsArr = this.props.items || Array(20).fill({});
    const inItems = itemsArr.map((v, i) => (
      <RightNowItem {...v} key={i} gaInfo={this.props.gaInfo} />
    ));
    const z = 5;
    const items = [
      inItems.slice(0 * z, 1 * z),
      inItems.slice(1 * z, 2 * z),
      inItems.slice(2 * z, 3 * z),
      inItems.slice(3 * z),
    ];
    const aside = (this.props.aside || []).map(asideMapping);
    const splits = (this.props.splits || []).map(asideMapping);
    const pagination = (
      <Pagination
        page={this.props.page}
        page_count={this.props.page_count}
        pathname={this.props.location.pathname}
      />
    );
    return (
      <div>
        <section className="section">
          <Submenu submenu={this.props.submenu} />

          {pagination}
          <div className="container border-top border-bottom">
            <div className="l-columns clearfix">
              <div className="l-main pull-left">
                <div className="module">
                  <div className="moduleHeader">
                    <h2 className="moduleHeader-title">
                      Práve teraz{" "}
                      <svg className="icon color-primary">
                        <use
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          xlinkHref={imgSprite + "#rss"}
                        />
                      </svg>
                    </h2>
                  </div>
                  <div className="moduleMain">
                    <div className="rightNow rightNow--vertical">
                      {items[0]}
                    </div>
                  </div>
                </div>
              </div>
              <aside className="l-sidebar pull-right">
                {aside.slice(0, 1)}
              </aside>
            </div>
          </div>
        </section>

        {splits[0]}
        <section className="section">
          <div className="container border-top">
            {items[1].length > 0 ? (
              <div className="l-columns clearfix">
                <div className="l-main pull-left">
                  <h2 className="visuallyhidden">Úvod</h2>
                  <div className="module">
                    <div className="moduleMain">
                      <div className="rightNow rightNow--vertical">
                        {items[1]}
                        <TasrTheme {...this.props.tasr_theme} />
                        {items[2]}
                      </div>
                    </div>
                  </div>
                </div>
                <aside className="l-sidebar pull-right">
                  {aside.slice(1, 2)}
                </aside>
              </div>
            ) : null}

            {items[3].length > 0
              ? [
                  splits[1],
                  <div className="l-columns clearfix" key="main">
                    <div className="l-main pull-left">
                      <div className="module">
                        <div className="moduleMain">
                          <div className="rightNow rightNow--vertical">
                            {items[3]}
                          </div>
                        </div>
                      </div>
                    </div>
                    <aside className="l-sidebar pull-right">
                      {aside.slice(2)}
                    </aside>
                  </div>,
                ]
              : null}
          </div>
          {pagination}
        </section>
        <LoaderIndex />
      </div>
    );
  }
}
export const RightNowItem = (props) => {
  const url = "https://www.teraz.sk/prave-teraz/";
  const title = <strong>{props.title || <Line />}</strong>;
  return (
    <div className="rightNow-item">
      <div className="row">
        <div className="col col-2 text-center">
          <time className="rightNow-time" dateTime="">
            {props.time || "00:00"}
          </time>
        </div>
        <div className="col col-8">
          <div className="rightNow-body">
            <p className="rightNow-text">
              {props.link ? (
                <Link to={props.link} gaInfo={props.gaInfo}>
                  {title}
                </Link>
              ) : (
                title
              )}{" "}
              <br /> {<InnerHTML span html={props.annotation} /> || <Line />}
            </p>
            {props.image ? (
              <div className="rightNow-img">
                <img src={props.image} title={props.title} alt="" loading="lazy"/>
              </div>
            ) : null}
          </div>
        </div>
        <div className="col col-2 text-right">
          <a
            className="rightNow-action"
            target="_blank"
            rel="noopener"
            href={`https://www.facebook.com/dialog/share?app_id=${facebookAppId}&display=popup&href=${encodeURIComponent(
              url
            )}&redirect_uri=${encodeURIComponent(url)}`}
          >
            <svg className="icon">
              <use
                xmlnsXlink="http://www.w3.org/1999/xlink"
                xlinkHref={imgSprite + "#facebook-square"}
              />
            </svg>
            <span className="visuallyhidden">Share on Facebook</span>
          </a>
          <a
            className="rightNow-action"
            target="_blank"
            rel="noopener"
            href={`http://twitter.com/share?text=${encodeURIComponent(
              props.title
            )}&url=${encodeURIComponent(url)}&hashtags=prave-teraz`}
          >
            <svg className="icon">
              <use
                xmlnsXlink="http://www.w3.org/1999/xlink"
                xlinkHref={imgSprite + "#twitter"}
              />
            </svg>
            <span className="visuallyhidden">Share on Twitter</span>
          </a>
        </div>
      </div>
    </div>
  );
};
