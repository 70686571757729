import React, { PureComponent } from "react";
import ReactDOM from "react-dom";
import axios from 'axios';
import TipsHorizontal from "~/components/TipsHorizontal";
import BannerHorizontal from "~/components/banners/BannerHorizontal";
import Submenu from "~/components/Submenu";
import ArticleAudioPlayer from "../base/ArticleAudioPlayer";
import { imgSprite } from "~/config/images";
import { LinkImg, Link, PublishingTime, Line } from "~/components/Elements";
import { asideMappingFactory } from "~/utility/aside";
import Modal from "react-modal";
import MetaTags from "~/components/base/MetaTags";
import type { MetaTagsProps } from "../base/MetaTags";
import { reportBug } from "~/utility/api";
// graph api /?fields=engagement{comments_count}&id=http://www.teraz.sk/regiony/shmu-na-strbskom-plese-bolo-rano-bie/350727-clanok.html
import { CommentsCount, Comments, LikeButton } from "~/components/Facebook";
import { Web2MediaInterscrollerClanky } from "~/components/specialBanners/Web2MediaInterscrollerClanky";
// import InnerHTML from 'dangerously-set-inner-html';
import InnerHTML from "../../components/base/InnerHtml";
// <OWL>
import { findDOMNode } from "react-dom";
import $ from "jquery";
import SectionHeader from "../../containers/SectionHeader";
import { GAInfo } from "~/utility/gaInfo";
import log from "~/utility/log";
import "~/components/owl.carousel.css";

// import { Helmet } from 'react-helmet'
if (process.env.BUILD_TARGET !== "server") {
  require("owl.carousel");

  // require("owl.carousel/dist/assets/owl.carousel.css");

  Modal.setAppElement("#rcBody");
}

// </OWL>
const customModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    width: "600px",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
//type ArticleDefaultProps = any
type ArticleSuggestedProps = Array<{
  url: string;
  image: string;
  title: string;
}>;
type ArticleActionsProps = {
  url: string;
  title: string;
  reportButtons?: boolean;
  totalShareCount: number;
  openModal: (arg0: Event) => void;
  openDiscussion: (arg0: Event) => void;
  discussionEnabled: boolean;
};
type ArticleProps = {
  gaInfo?: GAInfo;
  canonical_url: string;
  totalShareCount: number;
  image: string;
  image_caption: string;
  description: string;
  title: string;
  url: string;
  time_created_ISO: string;
  time_updated_ISO: string;
  annotation: string;
  tips: any;
  suggested: ArticleSuggestedProps;
  tags: Array<{
    id: string;
    name: string;
    url: string;
  }>;
  prevPath: string;
  editor: {
    id: number;
    name: string;
    url: string;
  };
  author: {
    id: number;
    name: string;
    url: string;
  };
  aside: Array<any>;
  section: string;
  html: string;
  articleBody: string;
  history: any;
  loader_after: any;
  submenu: Array<any>;
  social_media_image: string;
  audio_file: string;
  audio_duration: string;
};
type ArticleState = {
  modalIsOpen: boolean;
  discussionIsOpen: boolean;
};

class Article extends React.Component<ArticleProps, ArticleState> {
  discussion: null | ArticleDiscussion;
  articleMain: null | HTMLDivElement;

  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      discussionIsOpen: false,
    };
    (this as any).openModal = this.openModal.bind(this);
    (this as any).afterOpenModal = this.afterOpenModal.bind(this);
    (this as any).closeModal = this.closeModal.bind(this);
    (this as any).openDiscussion = this.openDiscussion.bind(this);
    (this as any).reportBug = this.reportBug.bind(this);
    (this as any).afterHtmlLoad = this.afterHtmlLoad.bind(this);
    (this as any).myRef = React.createRef();
  }

  afterHtmlLoad() {
    // const comments_count = window.FB.api(`/?fields=engagement{comments_count}&id=${encodeURIComponent(this.props.canonical_url)}`)
    // debugger;
    const options = {
      margin: 4,
      items: 5,
      autoWidth: false,
      nav: true,
      navElement: "button",
      // loop: false,
      navText: [
        `<span class="visuallyhidden">predchádzajúce</span><svg class="icon"><use xlink:href="${imgSprite}#chevron-double-left"></use></svg>`,
        `<span class="visuallyhidden">ďalšie</span><svg class="icon"><use xlink:href="${imgSprite}#chevron-double-right"></use></svg>`,
      ],
      dots: false, //lazyLoad: true
    };

    if (this.articleMain) {
      ($(findDOMNode(this.articleMain)).find(
        ".fotogaleriavclanku"
      ) as any).owlCarousel(options);
    }

  }


  componentDidMount() {
    this.afterHtmlLoad();
  }

  componentDidUpdate() {
    this.afterHtmlLoad();
  }

  reportBug(e: Event) {
    e.preventDefault();
    this.closeModal();

    if (e.target.text && typeof e.target.text.value === "string") {
      const text: string = e.target.text.value;
      reportBug(text);
    }
  }

  openDiscussion(e: Event) {
    this.setState({
      discussionIsOpen: true,
    });
    const el = ReactDOM.findDOMNode(this.discussion);

    if (el && el.offsetTop && typeof el.offsetTop === "number") {
      window.scroll(0, el.offsetTop - 100);
      e.preventDefault();
    }
  }

  openModal(e: Event) {
    this.setState({
      modalIsOpen: true,
    });
    e.preventDefault();
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  closeModal() {
    this.setState({
      modalIsOpen: false,
    });
  }

  render() {
    const props = this.props;
    const gaInfo = props.gaInfo;
    const asideMapping = asideMappingFactory({
      headerSuffix: "",
      gaInfo: gaInfo,
    });

    const ArticleActionsBound = (curriedProps) => (
      <ArticleActions
        totalShareCount={props.totalShareCount}
        url={props.canonical_url}
        title={props.title}
        openModal={this.openModal}
        discussionEnabled={props.discussion_enabled}
        openDiscussion={this.openDiscussion}
        {...curriedProps}
      />
    );

    const aside = (props.aside || []).map(asideMapping);
    const metaData: MetaTagsProps = {
      url: props.canonical_url,
      title: props.title,
      description: props.annotation,
      image: props.social_media_image,
      authorUrl: props.author ? props.author.url : "TASR",
      tags: props.tags ? props.tags.map((x) => x.name) : [],
      time: {
        published: props.time_created_ISO,
        modified: props.time_updated_ISO,
        year: new Date(props.time_created_ISO).getFullYear(),
      },
      objectType: "article",
      sectionName: props.section,
    };
    const jsonLdData = {
      "@context": "http://schema.org",
      "@type": "NewsArticle",
      mainEntityOfPage: props.canonical_url,

      /*"mainEntityOfPage": {
      "@type": "WebPage",
      "@id": props.url
      }, // FIXME netusim asi treba urobit entity s tym id*/
      headline: props.title,
      image: [props.image],
      datePublished: props.time_created_ISO,
      dateModified: props.time_updated_ISO,
      author: {
        "@type": "Organization",
        name: "TASR",
      },
      articleBody: props.articleBody,
      publisher: {
        "@type": "Organization",
        name: "TASR",
        logo: {
          "@type": "ImageObject",
          url: `http://www.tasr.sk/templates/tasr/assets/images/footerlogo_tasr.png`,
          width: 82,
          height: 15,
        },
      },
      description: props.annotation,
    };

    return (
      <div>
        {props.canonical_url ? <MetaTags data={metaData} /> : null}
        {props.submenu ? (
          <Submenu submenu={this.props.submenu} gaInfo={gaInfo} />
        ) : null}
        <main>
          <article className="article">
            <header className="articleHeader">
              <div className="container">
                {/* <a onClick={props.history.goBack}>&lt; Sekcia {props.prevPath}</a> */}
                <SectionHeader
                  className="container"
                  prefix="&lt; sekcia "
                  wrappingComponent="span"
                  gaInfo={gaInfo}
                />
                <h1 className="articleTitle">
                  {props.title ||
                    Array(2)
                      .fill({})
                      .map((v, i) => <Line key={i} />)}
                </h1>
                <ArticleActionsBound />
              </div>
            </header>
            <div className="container">
              <div className="l-columns l-columns--article clearfix">
                <div className="articleContent l-main pull-left">
                  <div className="articleImage aspectRatio">
                    {
                      props.image ? (
                        <img src={props.image} title={props.title} alt="" />
                      ) : (
                        <div className="aspectRatio-fill placeholder" />
                      )
                      /*<div className="aspectRatio-content"/>*/
                    }
                    <div className="articleImageTop-caption">
                      <figcaption
                        dangerouslySetInnerHTML={{
                          __html: props.image_caption,
                        }}
                      />
                    </div>
                  </div>

                  <p className="articlePerex">
                    {props.annotation ||
                      Array(3)
                        .fill({})
                        .map((v, i) => <Line key={i} />)}
                  </p>

                  

                  <div className="articleMeta">
                    <div className="clearfix">
                      {props.author ? (
                        <span className="articleMeta-person">
                          Autor{" "}
                          <strong>
                            <Link
                              inline={true}
                              to={"/author/" + props.author.id}
                              gaInfo={gaInfo}
                            >
                              {props.author.name}
                            </Link>
                          </strong>
                        </span>
                      ) : null}
                      {props.editor ? (
                        <span className="articleMeta-person">
                          Editor{" "}
                          <strong>
                            {props.editor.name}
                            {/* <Link inline={true} to={"/author/" + props.editor.id}>{props.editor.name}</Link> */}
                          </strong>
                        </span>
                      ) : null}
                    </div>
                    <PublishingTime {...props} longformat={true} />
                  </div>

                  {(props.audio_file && props.audio_duration !== '0') &&
                  <div className="article-audio-player-container">
                    <ArticleAudioPlayer audioFile={props.audio_file} />
                  </div>
                  }

                  <ArticleContent
                    refFn={(el) => {
                      if (el) {
                        this.articleMain = el.elWrapper;
                      }
                    }}
                    htmlArray={props.htmlArray}
                    html={props.html}
                  />

                  <FacebookLikeTeraz />

                  <ArticleSuggested items={props.suggested} />

                  <div
                    className="l-main-box border-top border-bottom"
                    key="ArticleActionsBound"
                  >
                    <ArticleActionsBound reportButtons={true} />
                    <Modal
                      aria={{
                        labelledby: "modalHeading",
                      }}
                      isOpen={this.state && this.state.modalIsOpen}
                      onAfterOpen={this.afterOpenModal}
                      onRequestClose={this.closeModal}
                      style={customModalStyles}
                      contentLabel="Nahlás chybu"
                    >
                      <button
                        className="btn-close-modal"
                        title="Zavrieť"
                        alt="Zavrieť"
                        onClick={this.closeModal}
                      >
                        &#x2716;
                      </button>
                      <h1 id="modalHeading">Nahlás chybu</h1>
                      <form
                        id="articleReportBug"
                        name="articleReportBug"
                        onSubmit={this.reportBug}
                      >
                        <textarea name="text" />
                        <button type="submit">Odošli</button>
                      </form>
                    </Modal>
                    <ArticleDiscussion
                      ref={(input) => {
                        this.discussion = input;
                      }}
                      isOpen={this.state && this.state.discussionIsOpen}
                      href={props.canonical_url}
                      discussionEnabled={props.discussion_enabled}
                    />
                  </div>
                </div>
                <aside className="l-sidebar pull-right">
                  {aside}
                  <Web2MediaInterscrollerClanky />
                </aside>
              </div>

              <TipsHorizontal items={props.tips} gaInfo={gaInfo} />
            </div>
            <script
              type="application/ld+json"
              dangerouslySetInnerHTML={{
                __html: JSON.stringify(jsonLdData),
              }}
            />
          </article>
        </main>
        <BannerHorizontal />
        {props.loader_after}
      </div>
    );
  }
}

const ArticleContent = React.forwardRef(
  (props: { htmlArray: any; html: any }, ref?) => {
    const { htmlArray, html } = props;
    const [loaded, setLoaded] = React.useState(1);
    const isUsingArray = htmlArray && htmlArray.length > 0;
    const processedHtml = isUsingArray
      ? htmlArray.filter((_v, i) => i < loaded).join("")
      : html;
    const notFinished = isUsingArray && htmlArray.length > loaded;
    return (
      <div>
        <InnerHTML
          className={"articleMain" + (notFinished ? " notFinished" : "")}
          ref={ref}
          html={processedHtml}
        />
        {notFinished && (
          <div className="articleSplitButtonWrapper">
            <button onClick={() => setLoaded(loaded + 1)}>Načítaj viac</button>
          </div>
        )}
      </div>
    );
  }
);

class ArticleActions extends PureComponent<ArticleActionsProps> {
  componentDidUpdate() {
    // window.FB.XFBML.parse();
  }

  componentDidMount() {
    // window.FB.XFBML.parse();
  }

  render() {
    const encodedUrl = encodeURIComponent(this.props.url);
    const mailSubject = encodeURIComponent(`Teraz.sk: ${this.props.title}`);
    const mailBody = encodeURIComponent(
      `Pozri si tento článok z Teraz.sk:\n\n${this.props.title}\n ${this.props.url}`
    );
    return (
      <div className="articleActions">
        {/*
        <div className="articleActions-block">
         <div className="data">
           <div className="data-value">{this.props.totalShareCount}</div>
           <div className="data-label">zdieľaní</div>
         </div>
        </div>
        */}
        <div className="articleActions-block">
          {/* {this.props.url ?
           <iframe
             src={`https://www.facebook.com/plugins/share_button.php?href=${encodedUrl}&layout=button_count&size=large&mobile_iframe=true&appId=${facebookAppId}&width=106&height=28`}
             width="106"
             height="28"
             style={{
               border: 'none',
               overflow: 'hidden',
             }}
             scrolling="no"
             frameBorder="0"
             allowTransparency="true"
           />
           : null} */}
          {this.props.url
            ? [
                // <div
                //   className="fb-share-button"
                //   data-href={encodedUrl}
                //   data-layout="button_count"
                //   data-size="large"
                //   data-mobile-iframe="true"
                // >
                //   <a
                //     target="_blank"
                //     href={`https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`}
                //     className="fb-xfbml-parse-ignore"
                //   >
                //     Zdieľať</a>
                // </div>,
                // <a
                //   className="socialWidget socialWidget--facebook"
                //   href="#"
                //   onClick={(e) => {
                //     e.preventDefault();
                //     window.FB.ui(
                //       {
                //         method: 'share',
                //         href: this.props.url
                //       }, function (response) { });
                //   }}>
                //   <span className="socialWidget-label">Zdieľaj na Facebooku</span>
                // </a>,
                <LikeButton key={1} encodedUrl={encodedUrl} />,
              ]
            : null}

          {/*
          <a
           className="socialWidget socialWidget--facebook"
           href={"http://www.facebook.com/share.php?u=" + encodedUrl}
           target="_blank"
           rel="noopener"
          >
           <span className="socialWidget-label">Zdieľaj na Facebooku</span>
          </a>
          */}
          <a
            className="socialWidget socialWidget--twitter"
            href={"https://twitter.com/intent/tweet?url=" + encodedUrl}
            target="_blank"
            rel="noopener"
          >
            <span className="socialWidget-label">
              <svg className="icon">
                <use xlinkHref={imgSprite + "#twitter"} />
              </svg>
            </span>
          </a>
          {this.props.discussionEnabled ? (
            <a
              className="socialWidget socialWidget--comments"
              href="#diskusia"
              onClick={this.props.openDiscussion}
            >
              <span className="socialWidget-label">
                <svg className="icon">
                  <use xlinkHref={imgSprite + "#comments"} />
                </svg>
              </span>
              <span className="socialWidget-value">
                <CommentsCount href={this.props.url}>.</CommentsCount>
                {/* <span class="fb-comments-count" data-href={this.props.url}>?</span> */}
              </span>
            </a>
          ) : null}
          {this.props.reportButtons
            ? [
                <a
                  className="socialWidget socialWidget"
                  href={`mailto:?subject=${mailSubject}&body=${mailBody}`}
                  key="mail"
                >
                  <span className="socialWidget-label">
                    <svg className="icon">
                      <use
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        xlinkHref={imgSprite + "#mail"}
                      />
                    </svg>
                  </span>
                </a>,
                <a
                  className="socialWidget socialWidget"
                  onClick={this.props.openModal}
                  href="mailto:teraz@tasr.sk"
                  key="warning"
                >
                  {/*TODO*/}
                  <span className="socialWidget-label">
                    <svg className="icon">
                      <use
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        xlinkHref={imgSprite + "#warning"}
                      />
                    </svg>
                  </span>
                </a>,
              ]
            : null}
        </div>
      </div>
    );
  }
}

class ArticleDiscussion extends PureComponent<{
  isOpen: boolean;
  href: string;
  discussionEnabled: boolean;
}> {
  render() {
    return this.props.discussionEnabled ? (
      <div id="diskusia">
        <div className={this.props.isOpen ? "" : "hidden"}>
          <Comments href={this.props.href} orderBy="time" numPosts={4} />
        </div>
      </div>
    ) : null;
  }
}

class ArticleSuggested extends PureComponent<{
  items: ArticleSuggestedProps;
}> {
  render() {
    const items = this.props.items || Array(6).fill({});

    if (items.length === 0) {
      return null;
    }

    return (
      <article className="module">
        <div className="moduleHeader">
          <h3 className="moduleHeader-title">Prečítajte si aj:</h3>
        </div>
        <div className="moduleMain">
          <div className="mediaListing row row--grid">
            {items.reduce((a, v, i) => {
              a.push(
                <div
                  className="mediaListing-item col col-4 col-md-6 col-xs-12"
                  key={i}
                >
                  <div className="media">
                    <LinkImg to={v.url} src={v.image} title={v.title} />
                    <div className="media-content">
                      <h4 className="media-title">
                        <Link to={v.url}>{v.title}</Link>
                      </h4>
                    </div>
                  </div>
                </div>
              );
              // if(i%2===0) a.push(<span className="clearfix hidden-xl hidden-lg hidden-xs" key={"s"+i}/>)
              return a;
            }, [])}
          </div>
        </div>
      </article>
    );
  }
}

class FacebookLikeTeraz extends React.Component {
  render() {
    /**
     * This can render only on frontend because
     * React has problem with rehydratation of this iframe
     */
    // if (process.env.BUILD_TARGET !== "server") {
      return (
        <InnerHTML
          className="l-main-box border-top moduleMain container"
          html={`<iframe 
          src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FTERAZ.sk%2F&tabs&width=340&height=70&small_header=true&adapt_container_width=true&hide_cover=true&show_facepile=true&appId=1569693840012926"
          width="340"
          height="70" 
          style="border:none;overflow:hidden" 
          scrolling="no" 
          frameborder="0"
          allowfullscreen="true"
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>`}
        />
      );
    // } else {
    //   return <div className="l-main-box border-top moduleMain container"></div>;
    // }
  }
}

export default Article;
