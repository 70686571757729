import React, { Component } from "react";
import HeaderMain from "./containers/HeaderMain";
import CookieConsent from "./containers/CookieConsent";
import Routes from "./containers/Routes";
import Footer from "./components/base/Footer";
import TopHeaderMenu from "./components/base/TopHeaderMenu";
import OffCanvas from "./containers/OffCanvas";
import ThemeChanger from "./containers/ThemeChanger";
import { MetaTagsDefault } from "./components/base/MetaTags"; //import {withRouter} from 'react-router'
import { connect } from "react-redux";
import { fetchWagonsConfig } from "./store/actions";
import Quantcast from "./containers/QuantCast";

class App extends Component {
  componentDidMount() {
    this.props.dispatch(fetchWagonsConfig());
  }
  render() {
    const gaInfo = ".";
    return (
      <div>
        <MetaTagsDefault />
        <ThemeChanger />
        <TopHeaderMenu gaInfo={gaInfo} />
        <OffCanvas gaInfo={gaInfo} />

        <div className="page">
          <div className="page-inner">
            <HeaderMain gaInfo={gaInfo} />
            <Routes gaInfo={gaInfo} />
            <Footer gaInfo={gaInfo} />
          </div>
        </div>

        <CookieConsent />
        <Quantcast/>
      </div>
    );
  }
}

export default connect(null)(App);
