import React, { Component } from "react";
import log from "../../utility/log";
import { requireMaster } from "./Advertisement";
export default class AdOceanSlave extends Component {
  constructor(props) {
    super(props);
    this.isValid = this.isValid.bind(this);
  }

  shouldComponentUpdate() {
    return false;
  }

  isValid() {
    return (
      this.props.master &&
      this.props.code &&
      this.props.master.length > 0 &&
      this.props.code.length > 0
    );
  }

  componentDidMount() {
    // podla spravnosti by to malo registrovat slave-a do reduxu a poslat az ked bude adocean ready
    setTimeout(() => {
      if (window.ado && this.isValid()) {
        requireMaster(this.props.master);
        // window.ado.onMaster(this.props.master, () => {
        window.ado.slave(this.props.code, {
          myMaster: this.props.master,
        }); // });
      } else {
        log.error("NOT VALID ADOCEAN", this.props, window.ado);
      }
    }, 300);
  }

  render() {
    return (
      <div className={this.props.className}>
        <div id={this.props.code} />
      </div>
    );
  }
}
