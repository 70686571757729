import React, { Component } from "react";
import RightNow from "~/components/pages/RightNow";
import { apiUrlTeraz } from "~/utility/api";
import {
  themeClass,
  headerSuffix,
  selectPage,
  fetchPage,
} from "~/store/actions";
import { connect } from "react-redux";
import { pageMapStateToProps } from "~/store/mappers"; //import AssignmentActions from 'containers/AssignmentActions'

class PageRightNow extends Component {
  update(props) {
    const qs = new URLSearchParams(props.location.search);
    const page_n = qs.get("p") || 1;
    //this.setState({page_n: page_n, loading: true})
    const { dispatch } = props;
    const url = `${apiUrlTeraz}api_rest.php?module=RestApi&method=rightNow&page=${page_n}`;

    if (props.selectedPage !== url) {
      dispatch(selectPage(url));
      dispatch(fetchPage(url));
    }

    dispatch(headerSuffix("Práve teraz", "/prave-teraz"));
    dispatch(themeClass("theme_prave-teraz"));
  }

  UNSAFE_componentWillMount() {
    this.update(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.update(nextProps);
  }

  componentDidMount() {
    //AssignmentActions.addAssignment({ headerSuffix: '.SK' });
    //AssignmentActions.set({ headerSuffix: 'Práve teraz', themeClass: 'theme_prave-teraz'});
    this.update(this.props);
  }

  render() {
    return <RightNow {...this.props} />;
    /*
      page_n={this.props.page_n}
      page_count={this.props.page_count}
      location={this.props.location}
      items={this.props.items}
      aside={this.props.aside}
      splits={this.prosp.splits}
      */
  }
}

export default connect(pageMapStateToProps)(PageRightNow);
