import React, {
    Dispatch,
    SetStateAction,
    useState,
    useRef,
    MutableRefObject,
  } from "react";
  import ReactPlayer from "react-player";
//   import { IoPlay, IoPause } from "react-icons/io5";
  
  const ArticleAudioPlayer = (props) => {
    const audioFile = props.audioFile;
    const [playing, setPlaying] = useState(false);
    const [durationSeconds, setDurationSeconds] = useState(0);
    const [playedSeconds, setPlayedSeconds] = useState(0);
    const [playbackRate, setPlaybackRate] = useState(1);
    const playerRef = useRef() as MutableRefObject<ReactPlayer>;
    return (
      <div>
        <div className="audio-info-text">
          Ak si želáte, aby počítač prečítal text článku, použite prehrávač nižšie.
        </div>
        <div className="react-player-wrapper">
            <ReactPlayer
            ref={playerRef}
            controls={false}
            playing={playing}
            url={audioFile}
            onProgress={({ playedSeconds }) => setPlayedSeconds(playedSeconds)}
            onSeek={setPlayedSeconds}
            onDuration={setDurationSeconds} // This is called when the player has the duration
            playbackRate={playbackRate}
            config={{ file: {
                forceAudio: true,
            }}}
            />
        </div>
        <Controls
          playerRef={playerRef}
          playing={playing}
          setPlaying={setPlaying}
          playedSeconds={playedSeconds}
          duration={durationSeconds}
          playbackRate={playbackRate}
          setPlaybackRate={setPlaybackRate}
        />
      </div>
    );
  };
  
  type ControlsProps = {
    playing: boolean;
    setPlaying: Dispatch<SetStateAction<boolean>>;
    playedSeconds: number;
    duration: number;
    playerRef: MutableRefObject<ReactPlayer>;
    setPlaybackRate: Dispatch<SetStateAction<number>>;
    playbackRate: number;
  };

  
  
  const Controls = (props: ControlsProps) => {
    const states= ['1.0', '1.5', '2.0'];
    const [actualPlayback, setActualPlayback] = useState(0);

    const seek = (e: React.ChangeEvent<HTMLInputElement>) => {
      props.playerRef.current.seekTo(+e.target.value, "seconds");
    };   

    const secondsFormat = (seconds) => {
        var formatted = new Date(seconds * 1000).toISOString().substring(14, 19)
        return formatted;
    };

    const playbackRateChange = () => {
        var i = actualPlayback;        

        return function(){
            i = ++i%states.length; 
            setActualPlayback(i);
            props.setPlaybackRate(parseFloat(states[i]));
            console.log(parseFloat(states[i]));
        };
    }
    
  
    return (


        <div className="audio-player-wrapper">
            <div className="timeline-wrapper">
            <input
                type="range"
                value={props.playedSeconds}
                min="0"
                max={props.duration}
                onChange={seek}
                step="0.01"
                style={{'background': `linear-gradient(to right, #24568A ${(props.playedSeconds-0)*100/(props.duration-0)}%, #ccc 0px`}}
            />
            </div>
            <div className="duration-wrapper">
                {secondsFormat(props.playedSeconds)} / {secondsFormat(props.duration)}
            </div>
            <div className="controls-wrapper">
                <button className="playback-rate" onClick={playbackRateChange()} >
                    {states[actualPlayback]}x
                </button>
                <button onClick={() => props.playerRef.current.seekTo(props.playedSeconds-10, "seconds")}>
                    <div className="icon icon--prev-10"></div>
                </button>        
                <button onClick={() => props.setPlaying(!props.playing)}>
                    {props.playing ? <div className="icon icon--pause"></div> : <div className="icon icon--play"></div>}
                </button>        
                <button onClick={() => props.playerRef.current.seekTo(props.playedSeconds+10, "seconds")}>
                    <div className="icon icon--skip-10"></div>
                </button>    
                <div className="blank-placeholder"></div>    
            </div>
        </div>
    );
  };
  
  export default ArticleAudioPlayer;
  