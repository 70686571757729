import React, { PureComponent } from "react";
import { imgSprite } from "~/config/images";
import { Link } from "~/components/Elements";
import { getGaInfo } from "~/utility/gaInfo";
export default class Pagination extends PureComponent {
  render() {
    const gaInfo = getGaInfo(this.props, "pagination");
    const pathname = this.props.pathname;
    const searchOrig = new URLSearchParams(this.props.search);
    const n_around = 2;
    const page_count = this.props.page_count || 0;
    let current_page = Number(this.props.page);

    //if (isNaN(current_page)){ current_page = 1}
    const num = (page_to) => {
      const search = new URLSearchParams(searchOrig);
      search.set("p", page_to);
      return (
        <li
          className={current_page === page_to ? "is-active" : ""}
          key={page_to}
        >
          <Link
            to={{
              pathname,
              search: search.toString(),
            }}
            gaInfo={gaInfo}
          >
            {page_to}
          </Link>
        </li>
      );
    };

    const pages_around = [...Array(n_around * 2 + 1)]
      .map((_, i) => i + current_page - n_around)
      .filter((i) => 0 < i && i <= page_count);
    const searchPrev = new URLSearchParams(searchOrig);
    searchPrev.set("p", Math.max(current_page - 1, 1));
    const searchNext = new URLSearchParams(searchOrig);
    searchNext.set("p", Math.min(current_page + 1, page_count));
    return (
      <nav className="container border-top">
        <ul className="pagination list-reset clearfix text-center">
          <li
            className={
              "pagination-previous " + (current_page === 1 ? "is-disabled" : "")
            }
            key="prev"
          >
            {current_page !== 1 ? (
              <Link
                to={{
                  pathname,
                  search: searchPrev.toString(),
                }}
                aria-label="Previous"
                gaInfo={gaInfo}
              >
                <svg className="icon">
                  <use xlinkHref={imgSprite + "#chevron-double-left"} />
                </svg>
                <span className="visuallyhidden">Previous</span>
              </Link>
            ) : (
              <svg className="icon">
                <use xlinkHref={imgSprite + "#chevron-double-left"} />
              </svg>
            )}
          </li>
          {
            //if we are on first
            pages_around[0] === 1
              ? null
              : pages_around[0] === 2
              ? [num(1)]
              : [num(1), <li key="hellipstart">&hellip;</li>]
          }
          {pages_around.map(num)}
          {
            //if we are on last
            pages_around[pages_around.length - 1] === page_count
              ? null
              : [<li key="hellipend">&hellip;</li>, num(page_count)]
          }
          <li
            className={
              "pagination-next " +
              (current_page === page_count ? "is-disabled" : "")
            }
          >
            {current_page !== page_count ? (
              <Link
                to={{
                  pathname,
                  search: searchNext.toString(),
                }}
                aria-label="Next"
                gaInfo={gaInfo}
              >
                <svg className="icon">
                  <use xlinkHref={imgSprite + "#chevron-double-right"} />
                </svg>
                <span className="visuallyhidden">Next</span>
              </Link>
            ) : (
              <svg className="icon">
                <use xlinkHref={imgSprite + "#chevron-double-right"} />
              </svg>
            )}
          </li>
        </ul>
      </nav>
    );
  }
}
