import React, { useEffect, useState } from "react";
import asideMapping from "../../utility/aside";
import DataTable from 'react-data-table-component';
import dataLegend from '../../components/pages/volby2023_legend.json';

// import LoaderIndex from "../../containers/LoaderIndex"

const TableFilters = (props) => {

    const [selectedRegion, setSelectedRegion] = useState('disabled');
    const [selectedWard, setSelectedWard] = useState('disabled');
    const [selectedDistrict, setSelectedDistrict] = useState('disabled');

    // const graphLegend = props.dataLegend;
    const graphLegend = props.graphLegend;
    const [regions, setRegions] = useState(graphLegend?.regions ?? [])
    const [wards, setWards] = useState(graphLegend?.wards ?? [])
    const [districts, setDistricts] = useState(graphLegend?.districts ?? [])

    const allRegions = graphLegend?.regions ?? [];
    const allWards = graphLegend?.wards ?? [];
    const allDistricts = graphLegend?.districts ?? [];


    const clearHandle = () => {
        setSelectedRegion("disabled");
        setSelectedWard("disabled");
        setSelectedDistrict("disabled");   
        props.handleFilter({
            selecting: 'all',
            code: null,
        });     
    }

    const handleRegions = (code) => {
        var filteredWards = [];
        Object.keys(allWards).forEach((regionKey) => {
            const region = allWards[regionKey];
            if (region.region_code === code) {
                filteredWards[regionKey] = region;
            }
        });
        // const filteredWards = allWards.filter(obj => {
        //     return obj.region_code === code;
        //   });
        setWards(filteredWards);
        setSelectedRegion(code);    
        setSelectedWard("disabled");
        setSelectedDistrict("disabled");
        props.handleFilter({
            selecting: 'region',
            code: code,
        });
    }

    const handleWards = (code) => {
        setSelectedWard(null);
        var filteredDistricts = [];
        Object.keys(allDistricts).forEach((wardKey) => {
            const ward = allDistricts[wardKey];
            if (ward.ward_code === code) {
                filteredDistricts[wardKey] = ward;
            }
        });
        // const filteredDistricts = allDistricts.filter(obj => {
        //     return obj.ward_code === code;
        //   });
        setDistricts(filteredDistricts);
        setSelectedWard(code);
        setSelectedDistrict("disabled");
        props.handleFilter({
            selecting: 'ward',
            code: code,
        });
    }

    const handleDistricts = (code) => {
        setSelectedDistrict(null);
        var filteredDistricts = [];
        Object.keys(allDistricts).forEach((wardKey) => {
            const ward = allDistricts[wardKey];
            if (ward.ward_code === code) {
                filteredDistricts[wardKey] = ward;
            }
        });
        // const filteredDistricts = allDistricts.filter(obj => {
        //     return obj.ward_code === code;
        //   });
        setSelectedDistrict(code);
        props.handleFilter({
            selecting: 'district',
            code: code,
        });
    }

    return (
        <div className="data-table-filters">
                <div className="custom-select-filter sm">
                    <select>
                        <option selected disabled aria-readonly>SR</option>
                    </select>
                </div>
                <div className="custom-select-filter">
                    <select
                        onChange={(e) => handleRegions(e.target.value)}
                        value={selectedRegion}
                    >                   
                        <option value="disabled" disabled aria-readonly>Vyberte kraj</option> 
                        {Object.values(regions).map((option, index) => (
                            <option key={index} value={option.code}>
                            {option.name}
                            </option>
                        ))}           
                    </select>
                </div>

                <div className="custom-select-filter">
                    <select
                        onChange={(e) => handleWards(e.target.value)}
                        disabled={selectedRegion === null || selectedRegion == 'disabled'}
                        value={selectedWard}
                    >           
                        <option value="disabled" disabled aria-readonly>Vyberte obvod</option>         
                        {Object.values(wards).map((option, index) => ( 
                            <option key={index} value={option.code}>
                            {option.name}
                            </option>
                        ))}                    
                    </select>
                </div>

                <div className="custom-select-filter">
                    <select
                        onChange={(e) => handleDistricts(e.target.value)}
                        disabled={selectedWard === null || selectedWard == 'disabled'}
                        value={selectedDistrict}
                    >                    
                        <option value="disabled" disabled aria-readonly>Vyberte okres</option>
                        {Object.values(districts).map((option, index) => ( 
                            <option key={index} value={option.code}>
                            {option.name}
                            </option>
                        ))}                    
                    </select>
                </div>
                { selectedRegion != 'disabled' && selectedRegion != null &&
                    <button onClick={clearHandle}>Zrušiť filter</button>
                }
                </div>
    )
}

export default TableFilters;