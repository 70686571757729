import { Component } from "react";
import setHttpStatus from "~/utility/httpStatus";

class Status extends Component<
  {
    code: string;
  },
  {},
  { router: { staticContext: object } }
> {
  componentDidMount() {
    setHttpStatus(this, this.props.code);
  }

  render() {
    return null;
  }
}

export default Status;
