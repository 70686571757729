import React, { Component } from "react";
import { apiPocasie } from "~/utility/api";

function provideWeather(WrappedComponent) {
  return class PP extends Component {
    state = {
      loading: true,
      symbol: 0,
      temperature: 0,
      description: "",
    };
    cancel_fetch = false;
    componentDidMount() {
      apiPocasie("bratislava", this);
    }

    render() {
      return this.state && !this.state.loading ? (
        <WrappedComponent {...this.state} />
      ) : null;
    }
  };
}

export default provideWeather;
