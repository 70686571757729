import React, { createRef, PropsWithChildren } from "react";
import { imgSprite } from "~/config/images";
// import $ from "jquery";
import { Helmet } from "react-helmet";
import "./owl.carousel.css";

if (process.env.BUILD_TARGET !== "server") {
  require("owl.carousel");
  // require("owl.carousel/dist/assets/owl.carousel.css");
}

type Props = PropsWithChildren<{
  onChange?: OwlCarousel.HandlerCallback;
  options?: { autoHeight?: boolean; items?: number; autoWidth?: boolean };
  className?: string;
}>;
export default class OwlCarousel extends React.Component<Props> {
  wrapper = null;
  constructor(props) {
    super(props);

    this.wrapper = createRef();
  }

  next = () => {
    return $(this.getSelfNode()).trigger("next.owl.carousel");
  };
  prev = () => {
    return $(this.getSelfNode()).trigger("prev.owl.carousel");
  };
  to = (x) => {
    return $(this.getSelfNode()).trigger("to.owl.carousel", x);
  };
  play = () => {
    return $(this.getSelfNode()).trigger("play.owl.autoplay");
  };
  stop = () => {
    return $(this.getSelfNode()).trigger("stop.owl.autoplay");
  };
  refresh = () => {
    return $(this.getSelfNode()).trigger("refresh.owl.carousel");
  };

  componentDidMount() {
    this.createCarousel();

    if (this.props.onChange) {
      $(this.getSelfNode()).on("changed.owl.carousel", this.props.onChange);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.destroyCarosel();
  }

  componentDidUpdate(prevProps, prevState) {
    // this.destroyCarosel()
    this.createCarousel();

    if (this.props.options && this.props.options.autoHeight) {
      if (process.env.BUILD_TARGET !== "server") {
        window.setTimeout(this.refresh, 1000);
      }
    }
  }

  componentWillUnmount() {
    this.destroyCarosel();
  }

  createCarousel() {
    if (process.env.BUILD_TARGET !== "server") {
      $(this.getSelfNode()).owlCarousel(this.getOptions());
    }
  }

  destroyCarosel() {
    $(this.getSelfNode()).trigger("destroy.owl.carousel");
  }

  getSelfNode() {
    return this.wrapper.current;
  }

  // shouldComponentUpdate(nextProps, nextState){
  //   nextProps.options !== this.props.options
  //   || nextProps.className !== this.props.className
  //   ||
  //     return false
  // }
  getOptions() {
    const options = {
      margin: 8,
      autoWidth: true,
      nav: true,
      navElement: "button",
      loop: false,
      navText: [
        `<span class="visuallyhidden">predchádzajúce</span><svg class="icon"><use xlink:href="${imgSprite}#chevron-double-left"></use></svg>`,
        `<span class="visuallyhidden">ďalšie</span><svg class="icon"><use xlink:href="${imgSprite}#chevron-double-right"></use></svg>`,
      ],
      dots: false, //lazyLoad: true
    };
    return { ...options, ...this.props.options };
  }

  render() {
    const cls = this.props.className ? this.props.className : "";
    return (
      <div className={`${cls} owl-carousel`} ref={this.wrapper}>
        {/* <Helmet>
          <link
            rel="stylesheet"
            type="text/css"
            href="/owl.carousel/dist/assets/owl.carousel.css"
          />
        </Helmet> */}
        {this.props.children}
      </div>
    );
  }
}
/*
OwlCarousel.propTypes = {
  children : PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element.isRequired),
  ]).isRequired,
};
*/

/*
const owlProps = {
        items: 3,
        loop: false,
        center: false,
        rewind: false,

        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        freeDrag: false,

        margin: 0,
        stagePadding: 0,

        merge: false,
        mergeFit: true,
        autoWidth: false,

        startPosition: 0,
        rtl: false,

        smartSpeed: 250,
        fluidSpeed: false,
        dragEndSpeed: false,

        responsive: {},
        responsiveRefreshRate: 200,
        responsiveBaseElement: window,

        fallbackEasing: 'swing',

        info: false,

        nestedItemSelector: false,
        itemElement: 'div',
        stageElement: 'div',

        refreshClass: 'owl-refresh',
        loadedClass: 'owl-loaded',
        loadingClass: 'owl-loading',
        rtlClass: 'owl-rtl',
        responsiveClass: 'owl-responsive',
        dragClass: 'owl-drag',
        itemClass: 'owl-item',
        stageClass: 'owl-stage',
        stageOuterClass: 'owl-stage-outer',
        grabClass: 'owl-grab'
};*/

/*eslint no-webpack-loader-syntax: 0*/
