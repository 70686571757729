import React from "react";
import { LinkImgFree, Link } from "~/components/Elements";

const PhotoAside = (props) => (
  <div className="module">
    <div className="moduleHeader">
      <h3 className="moduleHeader-title">Foto dňa</h3>
    </div>
    <div className="moduleMain">
      <div className="media media--fotoDna">
        <LinkImgFree
          to={props.url}
          src={props.image}
          title={props.title}
          gaInfo={props.gaInfo}
        />
        <div className="media-content">
          <h4 className="media-title">
            <Link to={props.url} gaInfo={props.gaInfo}>
              {props.title}
            </Link>
          </h4>
        </div>
        <div className="media-footer clearfix">
          <strong className="pull-right">
            <Link to="/fotografia-dna" gaInfo={props.gaInfo}>
              Viac &gt;
            </Link>
          </strong>
        </div>
      </div>
    </div>
  </div>
);

export default PhotoAside;
