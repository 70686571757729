import React, { PureComponent } from "react";
export default class BoxPreferencie extends PureComponent {
  render() {
    return (
      <iframe
        src="https://www.imporel.com/tasr/api/tasr-77/"
        frameBorder="0"
        scrolling="no"
        allowFullScreen={false}
        referrerPolicy="no-refferer"
        className="module-iframe--preferencie"
      />
    );
  }
}
