import React, { Component } from "react";
import PropTypes from "prop-types";
export default class BannerSquare extends Component {
  static propTypes = {
    url: PropTypes.string,
    src: PropTypes.string,
    title: PropTypes.string,
  };

  render() {
    return this.props.src ? (
      <div className="module">
        <a
          className="banner"
          href={this.props.url}
          target="_blank"
          rel="noopener"
        >
          <img src={this.props.src} title={this.props.title} alt="banner" loading="lazy"/>
        </a>
      </div>
    ) : null;
  }
}
