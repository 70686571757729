import React, { Component } from "react";
import { ImgFree, Link } from "~/components/Elements";
import PhotoDayListing from "~/components/photogallery/PhotoDayListing";
import { imgSprite } from "~/config/images";
import { facebookAppId } from "~/config/socialMedia";
import MetaTags from "~/components/base/MetaTags";
import type { MetaTagsProps } from "../base/MetaTags";
import ScrollHere from "../ScrollHere";
import { FacebookShareButton } from "../Facebook";

type PhotoDayProps = {
  listing: any;
  gallery: Array<any>;
  top_photo: {
    url: string;
    canonical_url: string;
    image: string;
    title: string;
    date: string;
    date_ISO: string;
    source: string;
    desc: string;
  };
  url_next: string;
  url_prev: string;
  title: string;
  // titleHeader?: string,
  // next_galleries: Array<*>,
  history: any;
};
export default class PhotoDay extends Component<PhotoDayProps> {
  constructor(props: PhotoDayProps) {
    super(props);
    (this as any).handleKey = this.handleKey.bind(this);
  }

  handleKey(e: Event) {
    if ((e.key && e.key === "ArrowLeft") || (e.which && e.which === 12)) {
      if (this.props.url_prev) {
        this.props.history.push(this.props.url_prev);
      }
    } else if (
      (e.key && e.key === "ArrowRight") ||
      (e.which && e.which === 12)
    ) {
      if (this.props.url_next) {
        this.props.history.push(this.props.url_next);
      }
    }
  }

  componentDidMount() {
    if (process.env.BUILD_TARGET !== "server") {
      window.addEventListener("keydown", this.handleKey);
    }
  }

  componentWillUnmount() {
    if (process.env.BUILD_TARGET !== "server") {
      window.removeEventListener("keydown", this.handleKey);
    }
  }

  render() {
    const topPhoto = this.props.top_photo || {};
    const metaData: MetaTagsProps = {
      url: topPhoto.canonical_url,
      title: topPhoto.title,
      description: topPhoto.desc,
      image: topPhoto.image,
      authorUrl: "TASR",
      time: {
        year: new Date().getFullYear(),
      },
      objectType: "article",
    };
    const jsonLdData = {
      "@context": "http://schema.org",
      "@type": "Article",
      mainEntityOfPage: topPhoto.canonical_url,
      headline: topPhoto.title,
      image: {
        "@type": "ImageObject",
        url: topPhoto.image,
      },
      datePublished: topPhoto.date_ISO,
      publisher: {
        "@type": "Organization",
        name: "TASR",
        logo: {
          "@type": "ImageObject",
          url: `http://www.tasr.sk/templates/tasr/assets/images/footerlogo_tasr.png`,
          width: 82,
          height: 15,
        },
      },
      author: {
        "@type": "Organization",
        name: "TASR",
      },
      description: topPhoto.desc,
    };
    const canonicalUrl =
      process.env.BUILD_TARGET !== "server" ? window.location.href : "";
    return (
      <section className="section section--foto">
        <div className="container">
          <div className="row">
            <div className="col col-10 col-sm-12 col-push-1 col-push-sm-0">
              <div className="fotoHeader row">
                <div className="col col-3 col-sm-12">
                  <Link className="fotoHeader-back" to="/fotografia-dna">
                    &lt; späť na fotografie dňa
                  </Link>
                </div>

                <div className="col col-6 col-sm-12">
                  <h2 className="fotoHeader-title text-center">
                    Fotografia dňa
                  </h2>
                </div>
              </div>

              <article className="fotoArticle module">
                <h1 className="fotoArticleTitle text-center">
                  {this.props.title}
                </h1>
                <ScrollHere />
                <div className="slider slider--foto">
                  <div className="media media--xl media--foto">
                    <ImgFree
                      to={topPhoto.url}
                      src={topPhoto.image}
                      title={topPhoto.title}
                    />
                    {metaData ? <MetaTags data={metaData} /> : null}
                    <script
                      type="application/ld+json"
                      dangerouslySetInnerHTML={{
                        __html: JSON.stringify(jsonLdData),
                      }}
                    />
                    <div className="media-content">
                      <div className="media-text row">
                        <div className="col col-10 col-sm-12 col-push-1 col-push-sm-0">
                          <time dateTime={topPhoto.date_ISO}>
                            {topPhoto.date}
                          </time>
                          <FacebookShareButton canonicalUrl={canonicalUrl} />
                          <h3> {topPhoto.title} </h3>
                          <div>{topPhoto.desc}</div>
                        </div>
                      </div>
                    </div>
                    <div className="media-footer text-right">
                      <small className="color-gray-dark">
                        Zdroj: {topPhoto.source}
                      </small>
                    </div>
                  </div>

                  {this.props.url_prev ? (
                    <Link to={this.props.url_prev}>
                      <button className="owl-prev">
                        <span className="visuallyhidden">predchádzajúce</span>
                        <svg className="icon">
                          <use xlinkHref={`${imgSprite}#chevron-double-left`} />
                        </svg>
                      </button>
                    </Link>
                  ) : null}

                  {this.props.url_next ? (
                    <Link to={this.props.url_next}>
                      <button className="owl-next">
                        <span className="visuallyhidden">ďalšie</span>
                        <svg className="icon">
                          <use
                            xlinkHref={`${imgSprite}#chevron-double-right`}
                          />
                        </svg>
                      </button>
                    </Link>
                  ) : null}
                </div>
              </article>

              <PhotoDayListing {...this.props.listing} />
            </div>
          </div>
        </div>
      </section>
    );
  }
}
