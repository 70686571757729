import React, { Component } from "react";
import PhotoGalleriesListing from "~/components/photogallery/PhotoGalleriesListing";
import { apiUrlTeraz } from "~/utility/api";
// import data from 'data/api/pages/photo_galleries_listing'
import { headerSuffix, selectPage, fetchPage } from "~/store/actions";
import { connect } from "react-redux";
import { pageMapStateToProps } from "~/store/mappers";
import LoaderIndex from "~/containers/LoaderIndex";
/**
 * Listing fotogalerii
 *
 */

class PhotoTASRListingContainer extends Component {
  update(props) {
    const { dispatch } = props;
    const qs = new URLSearchParams(props.location.search);
    const page = qs.get("p") || 1;
    const url = `${apiUrlTeraz}api_rest.php?module=RestApi&method=photoTasrListing&page=${page}`;
    // if(!this.props.isLoading) {
    //   const cat = this.props && this.props.category
    //     ? this.props.category.name : null
    //   const catUrl = (this.props && this.props.category && this.props.category.url)
    //     ? this.props.category.url : null
    //   const themeClassName = catUrl ? "theme_"+catUrl : null
    //   dispatch( headerSuffix(cat, catUrl) )
    //   dispatch( themeClass(themeClassName) )
    // }
    dispatch(headerSuffix("Foto TASR", "foto-tasr"));
    dispatch(selectPage(url));
    dispatch(fetchPage(url));
  }

  //componentDidMount(){
  UNSAFE_componentWillMount() {
    this.update(this.props);
  }

  // componentDidUpdate(){
  //   this.update(this.props)
  // }
  render() {
    return (
      <div>
        <PhotoGalleriesListing {...this.props} />
        <LoaderIndex gaInfo={this.props.gaInfo} />
      </div>
    );
  }
}

export default connect(pageMapStateToProps)(PhotoTASRListingContainer);
