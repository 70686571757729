import React from "react";

export type GAInfo = string;

export interface GAInfoProp {gaInfo: GAInfo};

export function getGaInfo(props, current_identifier) {
  const prev_identifier: GAInfo = props.gaInfo || "";
  const out = `${prev_identifier};${current_identifier}`;
  return out;
}
export function withGaInfo(Component, gaInfo: GAInfo) {
  return ({ ...props }) => <Component gaInfo={gaInfo} {...props} />;
}
export function getGaInfoUtm(gaInfo: GAInfo) {
  const params = new URLSearchParams({
    utm_source: "teraz",
    utm_medium: "organic",
    utm_campaign: "click",
    utm_content: encodeURIComponent(gaInfo),
  });
  return params;
}
