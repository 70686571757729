import React, { Component } from "react";
import { apiUrlWebmagazin } from "~/utility/api";
import { connect } from "react-redux";
import { fetchWagon } from "~/store/actions";
import { wagonMapStateToProps } from "~/store/mappers";
import WagonWebmagazin from "~/components/wagons/WagonWebmagazin";

class WagonWebmagazinContainer extends Component {
  constructor(props) {
    super(props);
    const tipsPage = this.props.tipsPage || 0;
    this.url = `${apiUrlWebmagazin}api_rest.php?module=RestApi&method=wagonSections&sections=2,1,5,6,3,4,7,8,9,10&type=webmagazin&tipsPage=${tipsPage}`; //this.url = `${apiUrlWebmagazin}api_rest.php?module=RestApi&method=wagonSections&tipsPage=${tipsPage}`
  }

  UNSAFE_componentWillMount() {
    const { dispatch } = this.props;
    dispatch(fetchWagon(this.url, this.props.required));
  }

  render() {
    return (
      <WagonWebmagazin
        name={this.props.name}
        coluleTitle="Webmagazin"
        {...this.props[this.url]}
        asideHeaderSuffix="na Webmagazíne"
        gaInfo={this.props.gaInfo}
      />
    );
  }
}

export default connect(wagonMapStateToProps)(WagonWebmagazinContainer);
