import React, { Component, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import { LinkImg, Link, PublishingTime, Line } from "~/components/Elements";
import PropTypes from "prop-types";
import { asideMappingFactory } from "~/utility/aside";
import RightNowVertical from "~/components/RightNowVertical";
import BoxPriamePrenosyNRSR from "~/components/aside/single/BoxPriamePrenosyNRSR";
import { Web2MediaDesktopSkinny } from "~/components/specialBanners/Web2MediaDesktopSkinny";
import { Web2MediaDesktopSkinnyUpdate } from "~/components/specialBanners/Web2MediaDesktopSkinnyUpdate";
import { getGaInfo } from "~/utility/gaInfo";
import LiveBroadcastsVideos from "./LiveBroadcastVideos";
export default class TopArticlesGrid extends Component {
  static propTypes = {
    top_article: PropTypes.object,
    articles: PropTypes.array,
    aside: PropTypes.array,
    asideHeaderSuffix: PropTypes.string,
  };  

  

  render() {        

    const articles = (this.props.articles || Array(6).fill({})) // .filter((v, i) => i < 3)
      .map((v, i) => (
        <MediaListingItem {...v} key={i} gaInfo={this.props.gaInfo} />
      ));
    const aside = (this.props.aside || []).map(
      asideMappingFactory({
        headerSuffix: this.props.asideHeaderSuffix,
        gaInfo: this.props.gaInfo,
        isIndex: true,
      })
    );
    //const asideTopArticles = this.props.aside ? this.props.aside.top_articles : null
    //const asideBanner = this.props.aside ? this.props.aside.banner : null
    return (
      <div className="l-columns clearfix">
        <div className="top-articles-grid-container">
          
          <div className="top-article-top">
            <MediaTopItem
                {...this.props.top_article}
                gaInfo={getGaInfo(this.props, "top")}
              />
          </div>
          <div className="top-articles-list">
            {articles}
          </div>
          <div className="right-now-list">
            <RightNowVertical
              items={this.props.right_now}
              gaInfo={this.props.gaInfo}
            />
          </div>          
          <div className="live-broadcasts-list">
            <LiveBroadcastsVideos
              items={this.props.broadcasts}
              gaInfo={this.props.gaInfo}
            />
          </div>          
          <div className="right-col-grid">
            {aside}
            <BoxPriamePrenosyNRSR />
            {/* <Web2MediaDesktopSkinny />       */}
          </div>
        </div>

        {/* <div className="topArticles-column topArticles-column-main">
          <h2 className="visuallyhidden">Úvod</h2>
          <div className="module">
            <div className="moduleMain">
              <div className="row row--verticalGutter row--grid">
                <MediaTopItem
                  {...this.props.top_article}
                  gaInfo={getGaInfo(this.props, "top")}
                />
                {articles}
              </div>
            </div>
          </div>
        </div>

        <div className="topArticles-column">
          <RightNowVertical
            items={this.props.right_now}
            gaInfo={this.props.gaInfo}
          />
          <LiveBroadcastsVideos
            items={this.props.broadcasts}
            gaInfo={this.props.gaInfo}
          />
        </div>

        <aside className="topArticles-column topArticles-column-right">
          {aside}
          <BoxPriamePrenosyNRSR />
        </aside> */}
      </div>
    );
  }
}
export const MediaListingItem = (props) => (
  <div className="mediaListing-item-vertical">
    <div className="media row">
      <div className="col col-4 col-md-4 col-sm-4">
        <LinkImg
          to={props.url}
          src={props.image}
          title={props.title}
          gaInfo={props.gaInfo}
        />
      </div>
      <div className="col col-8 col-md-8 col-sm-8">
        <div className="media-content">
          <h4 className="media-title">
            <Link to={props.url} gaInfo={props.gaInfo}>
              {props.title}
            </Link>
          </h4>
          <PublishingTime {...props} />
        </div>
      </div>
    </div>
  </div>
);
MediaListingItem.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.string,
};

const MediaTopItem = (props) => (
  <div className="mediaListing-item col col-12">
    <div className="media media--lg media--horizontal">
      <div className="row row--sm">
        <div className="col col-12 col-md-12" key="img">
          <LinkImg
            to={props.url}
            src={props.image}
            title={props.title}
            gaInfo={props.gaInfo}
          />
        </div>
        <div className="col col-12 col-md-12" key="title">
          <div className="media-content">
            <h4 className="media-title">
              <Link to={props.url} gaInfo={props.gaInfo}>
                {props.title}
              </Link>
            </h4>
            <p className="media-text">
              {props.annotation ||
                Array(3)
                  .fill({})
                  .map((_, i) => <Line key={i} />)}
            </p>
            <PublishingTime {...props} />
          </div>
        </div>
      </div>
    </div>
  </div>
);

MediaTopItem.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.string,
  annotation: PropTypes.string,
};
