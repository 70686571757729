import WagonTabletTV from "~/containers/wagons/WagonTabletTV";
import WagonNews from "~/containers/wagons/WagonNews";
import WagonSport from "~/containers/wagons/WagonSport";
import WagonKaleidoskop from "~/containers/wagons/WagonKaleidoskop";
import WagonVtedy from "~/containers/wagons/WagonVtedy";
import WagonWebmagazin from "~/containers/wagons/WagonWebmagazin";
import WagonObjektivom from "~/containers/wagons/WagonObjektivom";
import WagonPublicistika from "~/containers/wagons/WagonPublicistika";
import WagonWebyTASR from "~/containers/wagons/WagonWebyTASR";
import createWagonSectionOne from "~/containers/wagons/WagonSectionOne";

// require(`${process.env.RAZZLE_API_TERAZ}api_rest.php?module=RestApi&amp;method=homepageWagonOrdering&amp;v=20200226`)

const wagonsMap = {
  spravy: {
    name: "spravy",
    component: WagonNews,
    hasTips: true,
  },
  publicistika: {
    name: "publicistika",
    component: WagonPublicistika,
    hasTips: true,
  },
  tv: {
    name: "tv",
    component: WagonTabletTV,
  },
  sport: {
    name: "sport",
    component: WagonSport,
    hasTips: true,
  },
  kaleidoskop: {
    name: "kaleidoskop",
    component: WagonKaleidoskop,
    hasTips: true,
  },
  vtedy: {
    name: "vtedy",
    component: WagonVtedy,
  },
  webmagazin: {
    name: "webmagazin",
    component: WagonWebmagazin,
  },
  weby_tasr: {
    name: "weby_tasr",
    component: WagonWebyTASR,
    hasTips: true,
  },
  objektivom: {
    name: "objektivom",
    component: WagonObjektivom,
  },
  zoh2018: {
    name: "zoh2018",
    component: createWagonSectionOne({
      id: 5669,
      coluleTitle: "ZOH 2018",
      coluleUrl: "/zoh-2018",
      coluleHeaders: [],
    }),
    hasTips: false,
  },
};
const defaultWagonsConfig = [
  "spravy",
  "politika_fb",
  "publicistika",
  "tv",
  "sport",
  "kaleidoskop",
  "vtedy",
  "webmagazin",
  "weby_tasr",
  "objektivom",
];

const withTipsPage = (wagons) => {
  wagons
    .filter((c) => c.hasTips && c.hasTips === true)
    .forEach((c, i) => {
      c.tipsPage = i + 1;
    });
  return wagons;
};

const isLiteral = (a) => !!a && a.constructor === Object;

const toWagons = (wagonDef) => {
  const wagonConfig = wagonsMap[wagonDef];

  if (wagonConfig && isLiteral(wagonConfig)) {
    return Object.assign({}, wagonConfig);
  } else if (isLiteral(wagonDef)) {
    // debugger;
    return {
      name: "section" + wagonDef.id,
      component: createWagonSectionOne({
        id: wagonDef.id,
        coluleTitle: wagonDef.title,
        coluleUrl: wagonDef.url,
        coluleHeaders: wagonDef.coluleHeaders,
      }),
      hasTips: ["4", "5708", "5645"].includes(wagonDef.id),
    };
  }

  return null;
};

export function transformToWagonsConfig(wagonsConfig) {
  return withTipsPage(wagonsConfig.map(toWagons).filter((x) => x));
}

// function getWagonsConfig() {

//   const wagonsConfig =
//     typeof __wagonsConfig !== "undefined" && __wagonsConfig
//       ? __wagonsConfig
//       : defaultWagonsConfig;

//   return transformToWagonsConfig(wagonsConfig);
// }

// const loaderConfig = {
//   wagons: getWagonsConfig(),
// };
// export default loaderConfig;
/* global __wagonsConfig */
