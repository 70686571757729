import React, { Component } from "react";
import { LinkImg, Link } from "~/components/Elements";
import PropTypes from "prop-types";
import { getClassNamesSize } from "~/utility/ui";
import { getGaInfo } from "~/utility/gaInfo";
export default class TipsHorizontal extends Component {
  render() {
    if (this.props.items && this.props.items.length === 0) return null;
    const items = this.props.items || Array(6).fill({});
    const headerText =
      typeof this.props.headerText === "undefined"
        ? "Neprehliadnite"
        : this.props.headerText;
    const gaInfo = getGaInfo(this.props, headerText || "tipsH");
    return (
      <div className="module">
        {this.props.noHeaderColumnLine ? (
          headerText === null ? null : (
            <div className="moduleHeader">
              <h3 className="moduleHeader-title">{headerText}</h3>
            </div>
          )
        ) : (
          <div className="l-columns clearfix">
            <div className="moduleHeader l-main">
              <h3 className="moduleHeader-title">{headerText}</h3>
            </div>
          </div>
        )}
        <div
          className={
            "moduleMain" +
            (this.props.noHeaderColumnLine ? "" : " moduleMain--borderTop")
          }
        >
          <div className="row row--xs row--verticalGutter row--grid">
            {items.map((v, i) => (
              <Item
                url={v.url}
                image={v.image}
                title={v.title}
                nTotal={items.length}
                key={i}
                gaInfo={gaInfo}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
}
TipsHorizontal.propTypes = {
  items: PropTypes.array,
};

const Item = (props) => {
  return (
    <div className={`mediaListing-item mobile-row ${getClassNamesSize(props.nTotal)}`}>
      <div className="media media--sm">
        <LinkImg
          to={props.url}
          src={props.image}
          title={props.title}
          forceA={props.url && props.url.startsWith("http")}
          gaInfo={props.gaInfo}
        />
        <div className="media-content">
          <h4 className="media-title">
            <Link
              to={props.url}
              forceA={props.url && props.url.startsWith("http")}
              gaInfo={props.gaInfo}
            >
              {props.title}
            </Link>
          </h4>
        </div>
      </div>
    </div>
  );
};

Item.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
  nTotal: PropTypes.number,
};
/* global $ */
