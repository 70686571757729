import React from "react";
import { Link } from "~/components/Elements";
import "./PlosneCovidTestovanie.css";
import img from "./img/PlosneCovidTestovanie-banner.png";
const url = "/testovanie/";

const HeaderBanner = (props) => {
  return (
    <div>
      <div className="banner bannerPlosneCovidTestovanie">
        <div className="container">
          <Link to={url}>
            <img
              src={img}
              alt="Všetko o celoslovenskom testovaní Covid-19"
              loading="lazy"
            ></img>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HeaderBanner;
