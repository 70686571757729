import React from "react";
import { Line } from "../Elements";
import asideMapping from "../../utility/aside";
import LoaderIndex from "~/containers/LoaderIndex";
// import LoaderIndex from "../../containers/LoaderIndex"

const NewsNowRedirect = (props) => {
  const aside = (props.aside || []).map(asideMapping);
  const url = decodeURIComponent(props.to);
  const title = props.title;
  // "Premier: Measure to Boost Beginner Teachers Salaries on Its Way";
  const image =
    props.image ||
    "http://newsnow.tasr.sk/wp-content/uploads/2016/01/544x180.png";
  //"http://newsnow.tasr.sk/wp-content/uploads/2018/09/skola.jpg";
  return (
    <div>
      <main>
        <article className="article">
          <header className="articleHeader">
            <div className="container">
              <h1 className="articleTitle">
                {title ||
                  Array(2)
                    .fill({})
                    .map((v, i) => <Line key={i} />)}
              </h1>
            </div>
          </header>
          <div className="container">
            <div className="l-columns l-columns--article clearfix">
              <div className="articleContent l-main pull-left">
                <div className="articleImage aspectRatio">
                  {image ? (
                    <img src={image} title={title} alt="" loading="lazy" />
                  ) : (
                    <div className="aspectRatio-fill placeholder" />
                  )}
                </div>

                {/* <p className="articlePerex">
                 {JSON.stringify(url)}
                 {annotation ||
                   Array(3)
                     .fill({})
                     .map((v, i) => <Line key={i} />)}
                </p> */}
                <div className="newsnowBox">
                  <div>
                    This article is <b>available for free</b> on our news portal
                    in English language
                  </div>
                  <a href={url} className="bigBtn bitBtns">
                    Read full article here
                  </a>
                  <img
                    src="http://newsnow.tasr.sk/wp-content/uploads/2016/01/544x180.png"
                    alt="NewsNow logo"
                    loading="lazy"
                  />
                </div>
              </div>

              <aside className="l-sidebar pull-right">
                <div>
                  <h3>About NewsNow service</h3>
                  <img
                    src="http://newsnow.tasr.sk/wp-content/uploads/2016/01/544x180.png"
                    alt="NewsNow logo"
                    loading="lazy"
                  />
                  NewsNow is news portal in English language providing news from
                  The News Agency of the Slovak Republic. Current Affairs
                  (Domestic and foreign "policies"), Economy, Culture and Sport.
                  <br />
                  <a href="https://newsnow.tasr.sk" target="_blank">
                    https://newsnow.tasr.sk
                  </a>
                </div>
                {aside}
              </aside>
            </div>
          </div>
        </article>
      </main>

      <LoaderIndex />
    </div>
  );
};

export default NewsNowRedirect;
