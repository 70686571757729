import { RootState } from "./store";

export const pageMapStateToProps = (state: RootState) => {
  const { pageByUrl, selectedPage } = state;
  return { ...pageByUrl[selectedPage], selectedPage };
};
export const wagonMapStateToProps = (state: RootState) => {
  const { wagonByUrl } = state;
  //should filter by url, no idea how to get url from component here
  return { ...wagonByUrl };
};
