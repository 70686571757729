import React, { Component } from "react";
import { Web2MediaBanner300 } from "~/components/specialBanners/Web2MediaBanner300x600";
import store, { AppStateStore, getStore } from "~/store/store";
import { setCookie } from "~/utility/cookies";


class Quantcast extends Component {
    constructor(props) {
        super(props);
        this.state = {
            approvedCookie: false,
            approvedAds: false,
            approvedRelevantAds: false
        };
    }

    state: {
        approvedCookie: boolean;
        approvedAds: boolean;
        approvedRelevantAds: boolean;
    };
    
    componentDidMount() {
        const self = this;
        
        if (process.env.BUILD_TARGET !== "server") {
            window.__tcfapi('addEventListener', 2, function(tcData, listenerSuccess) {
                const saved = localStorage.getItem("_cmpRepromptHash") != null;
                // console.log('saved: ', saved)
                // if user checked approval now or in past
                if (listenerSuccess && (tcData.eventStatus == "useractioncomplete" || (saved && tcData.eventStatus == "tcloaded"))) {
                    let event = new CustomEvent('changeConsentString', {
                        'detail': tcData.tcString}
                    )
                    // setCookie('consentString', tcData.tcString, null);
                    // localStorage.setItem('consentstring', tcData.tcString);

                    window.dispatchEvent(event);

                    if (tcData.purpose !== undefined && tcData.purpose.consents !== undefined) {
                        
                        let approves = {
                            1: tcData.purpose.consents['1'] ? true: false, 
                            2: tcData.purpose.consents['2'] ? true: false, 
                            3: tcData.purpose.consents['3'] ? true: false, 
                            4: tcData.purpose.consents['4'] ? true: false, 
                            5: tcData.purpose.consents['5'] ? true: false, 
                            6: tcData.purpose.consents['6'] ? true: false, 
                            7: tcData.purpose.consents['7'] ? true: false, 
                            8: tcData.purpose.consents['8'] ? true: false, 
                            9: tcData.purpose.consents['9'] ? true: false, 
                            10: tcData.purpose.consents['10'] ? true: false
                        }

                        let stateApproves = {approvedCookie: false, approvedAds: false, approvedRelevantAds: false}

                        stateApproves.approvedCookie = approves['1'];
                        if (approves['1'] && approves['2'] && approves['7'] && approves['9'] && approves['10']) {
                            stateApproves.approvedAds = true;
                        }
                        if (approves['1'] && approves['2'] && approves['3'] && approves['4'] && approves['7'] && approves['9'] && approves['10']) {
                            stateApproves.approvedRelevantAds = true;
                        }

                        self.handler(stateApproves);
                    }

                }
            });
        }

    }

    handler = (stateApproves) => {
        this.setState({
            approvedCookie: stateApproves.approvedCookie,
            approvedAds: stateApproves.approvedAds,
            approvedRelevantAds: stateApproves.approvedRelevantAds
        }, () => {            
            let event = new CustomEvent('changeApproves', {
                'detail': this.state}
            )
            document.dispatchEvent(event);
        });
    };

    dispatchApproves = (dispatch) => {
        return {
            approves: () => dispatch(this.state)
        }
    }

    addConsentString(text) {
        let consentString = {consentString : text};
        let storeValues = store.getState()
        storeValues = {
            storeValues,
            ...consentString
        }
        return storeValues;
      }

    render() {
        // return <Web2MediaBanner300/>;
        return <></>;
    }
}

export default Quantcast;

