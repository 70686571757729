import React, {
  Component,
  ReactComponentElement,
  useEffect,
  useState,
} from "react";
import LazyLoad from "react-lazyload";
import WagonIndex from "~/containers/wagons/WagonIndex";
import Scroll from "react-scroll";
import { connect, MapStateToPropsParam } from "react-redux";
import { GAInfo, getGaInfo } from "~/utility/gaInfo";
import { RootState } from "~/store/store";
const h = 1000;

export class LoaderIndex extends Component<{
  isRoot?: boolean;
  gaInfo?: GAInfo;
  wagonsConfig: { component; tipsPage: number; name: string }[];
  wagonHeight;
}> {
  componentDidMount() {
    Scroll.scrollSpy.update();
  }

  render() {
    // google filter
    if (!this.props.isRoot) {
      if (process.env.BUILD_TARGET !== "server") {
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);
        const isGoogleBot = params.has("iamgooglebot");

        if (isGoogleBot) {
          return null;
        }
      } else {
        return null;
      }
    }

    const wagonsConfig = this.props.wagonsConfig;
    const gaInfo = getGaInfo(this.props, "w");
    const wagons = wagonsConfig.map((c, i) => {
      const height = this.props.wagonHeight.entities[c.name]?.height || h;
      return (
        <Scroll.Element name={c.name} key={i + 1}>
          <LazyLoad height={height} once offset={400}>
            {React.createElement(c.component, {
              tipsPage: c.tipsPage,
              gaInfo: gaInfo,
              name: c.name,
            })}
          </LazyLoad>
        </Scroll.Element>
      );
    });
    return (
      <>
        <Scroll.Element name="index" key="index">
          <WagonIndex tipsPage={0} required={true} gaInfo={gaInfo} />
        </Scroll.Element>
        {wagons}
      </>
    );
  }
}

export const mapStateToProps = (state: RootState) => {
  const { wagonsConfig, wagonHeight } = state;
  return { wagonsConfig, wagonHeight };
};

export default connect(mapStateToProps)(LoaderIndex);
