import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import log from "~/utility/log";

type Requirement = { id: string };

const requirementsAdapter = createEntityAdapter<Requirement>();

export const requirementsSlice = createSlice({
  name: "requirements",
  initialState: requirementsAdapter.getInitialState(),
  // reducers: {
  //   [ADD_REQUIREMENT]: requirementsAdapter.addOne,
  //   [REMOVE_REQUIREMENT]: requirementsAdapter.removeOne
  // },
  reducers: {
    addOne: (state, entity) => {
      log.info(`requirement.addOne: ${entity.payload.id}`);
      return requirementsAdapter.addOne(state, entity);
    },
    removeOne: (state, key) => {
      log.info(`requirement.removeOne: ${key.payload}`);
      return requirementsAdapter.removeOne(state, key);
    },
  },
});
