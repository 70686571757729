
/* Actions types */
export const REQUEST_PAGE = "REQUEST_PAGE";
export const RECEIVE_PAGE = "RECEIVE_PAGE";
export const SELECT_PAGE = "SELECT_PAGE";

export const RECEIVE_FB_SHARES = "RECEIVE_FB_SHARES";

export const RECEIVE_WAGON = "RECEIVE_WAGON";

export const REQUEST_HEADER = "REQUEST_HEADER";
export const RECEIVE_HEADER = "RECEIVE_HEADER";

export const HEADER_DATE = "HEADER_DATE";
export const HEADER_SUFFIX = "HEADER_SUFFIX";
export const THEME_CLASS = "THEME_CLASS";
export const THEME_SCROLL_CLASS = "THEME_SCROLL_CLASS";

export const ADD_REQUIREMENT = "ADD_REQUIREMENT";
export const REMOVE_REQUIREMENT = "REMOVE_REQUIREMENT";

export const TOGGLE_OFFCANVAS = "TOGGLE_OFFCANVAS";

export const RECEIVE_WAGONS_CONFIG = "RECEIVE_WAGONS_CONFIG";