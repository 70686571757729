import React from "react";
import { Line, LinkImg, Link, LinkImgFree } from "~/components/Elements";
export const PhotoGalleryRowItem = (props) => {
  const maxLen = 38;
  let title = null;

  if (props.title) {
    if (props.title.indexOf(" ") > -1) {
      title = props.title;
    } else {
      title = props.title.replace(/_/g, " ");
    }

    if (title.length > maxLen) {
      title = title.substring(0, maxLen) + "...";
    }
  }

  return (
    <div
      className={
        (props.fullWidth
          ? "col-12"
          : props.halfWidth
          ? " col-6"
          : " col-3 col-md-4 ") + " col col-sm-6 col-xs-12"
      }
    >
      <div className="media media--foto">
        <LinkImg
          to={props.url}
          src={props.image}
          title={title}
          gaInfo={props.gaInfo}
        />
        <div className="media-content">
          <h4 className="media-title">
            <Link to={props.url} gaInfo={props.gaInfo}>
              {title || <Line />}
            </Link>
          </h4>
        </div>
      </div>
    </div>
  );
};
export const PhotoGalleryRow = (props) => {
  const items = props.items.reduce((a, v, i) => {
    a.push(
      <PhotoGalleryRowItem
        halfWidth={props.halfWidth}
        fullWidth={props.fullWidth}
        key={i}
        gaInfo={props.gaInfo}
        {...v}
      />
    );
    // if(i%2===0) a.push(<span className="clearfix hidden-xl hidden-lg hidden-xs" key={"s" + i} />
    // )
    return a;
  }, []);
  return (
    <div className="module module--foto">
      {props.title ? (
        <div className="moduleHeader">
          <h3 className="moduleHeader-title">
            <Link to={props.url} gaInfo={props.gaInfo}>
              {props.title || <Line />}
            </Link>
          </h3>
        </div>
      ) : null}
      <div className="moduleMain">
        <div className="row row--sm row--verticalGutter-sm row--grid">
          {items}
        </div>
      </div>
      {props.url ? (
        <div className="moduleFooter clearfix">
          <strong className="pull-right">
            <Link to={props.url} gaInfo={props.gaInfo}>
              {props.link_text} &gt;
            </Link>
          </strong>
        </div>
      ) : null}
    </div>
  );
};
export const PhotoTop = (props) => (
  <div className="media media--xl media--foto">
    <LinkImgFree
      to={props.url}
      src={props.image}
      title={props.title}
      gaInfo={props.gaInfo}
    />
    <div className="media-content">
      <h4 className="media-title">
        <Link to={props.url} gaInfo={props.gaInfo}>
          {props.title}
        </Link>
      </h4>
    </div>
  </div>
);
