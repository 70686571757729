import React, { Component } from "react";
import { apiUrlTeraz } from "~/utility/api";
import { connect } from "react-redux";
import { fetchWagon } from "~/store/actions";
import { wagonMapStateToProps } from "~/store/mappers";
import { MediaListingTopArticle } from "~/components/Colule";
// import BannerHorizontal from 'components/banners/BannerHorizontal';
import asideMapping from "~/utility/aside";
import TipsHorizontal from "~/components/TipsHorizontal";
import { asideMappingFactory } from "~/utility/aside";
import { LinkImgCrop, Link, PublishingTime } from "~/components/Elements";
import { getClassNamesSize } from "~/utility/ui";
import { getGaInfo } from "~/utility/gaInfo";
import { classWagonHeightSave } from "~/components/wagons/wagonHeightHook";

class WagonWebyTASR extends Component {
  constructor(props) {
    super(props);
    const tipsPage = this.props.tipsPage || 0;
    this.url = `${apiUrlTeraz}api_rest.php?module=RestApi&method=wagonWebyTASR&sections=3,5583,5578,5506&tipsPage=${tipsPage}`;
    this.wagonHeightRef = React.createRef();
  }

  UNSAFE_componentWillMount() {
    const { dispatch } = this.props;
    dispatch(fetchWagon(this.url, this.props.required));
  }

  componentDidUpdate(){
    classWagonHeightSave(this);
  }

  render() {
    /*
    const coluleTitle = 'Weby TASR';
    const coluleHeaders = [
      
      {url: 'http://skolskyservis.teraz.sk', name: 'Školský servis'},
      {url: 'http://utulkovo.teraz.sk', name: 'Útulkovo'},
      {url: 'http://zamestnajsa.teraz.sk', name: 'Zamestnaj sa'},
      {url: 'http://vovlaku.teraz.sk', name: 'Vo vlaku'},
    ];
    return <Wagon {...this.props[this.url]}
      coluleTitle={coluleTitle}
      coluleHeaders={coluleHeaders}
    />
    */
    const props = this.props[this.url] || {};
    const gaInfo = getGaInfo(this.props, "wWebyTASR");
    const topArticleElement = props.colule ? (
      <MediaListingTopArticle
        baseUrl={props.colule.baseUrl}
        cropImage={true}
        gaInfo={gaInfo}
        {...props.colule.top_article}
      />
    ) : null;
    // debugger;
    const loading = props.colule && props.colule.sections;
    const sectionElements = (loading
      ? props.colule.sections
      : Array(4).fill({})
    )
      .concat([
        {
          name: "TASR",
          url: "/",
          baseUrl: "http://www.tasr.sk",
          top_article: {
            title: "Tlačová agentúra Slovenskej republiky",
            baseUrl: "http://www.tasr.sk",
            url: "/",
            image:
              "http://www.tasr.sk/templates/tasr/assets/images/tasr-box.png",
            annotation: "",
          },
        },
      ])
      .map((a) => ({
        top_article: { ...a.top_article, annotation: null },
        name: a.name,
        url: a.url,
        baseUrl: a.baseUrl,
        noSectionHeaders: a.noSectionHeaders,
      }))
      .map((v, i, arr) => (
        <MediaListingItem key={i} nTotal={arr.length} {...v} gaInfo={gaInfo} />
      ));
    const aside = ((props.colule && props.colule.aside) || []).map(
      asideMappingFactory({
        headerSuffix: props.asideHeaderSuffix,
        gaInfo: gaInfo,
      })
    );

    
    return (
      <section className="section" ref={this.wagonHeightRef}>
        <div className="container">
          <div className="l-columns clearfix">
            <div className="l-main pull-left">
              <div className="module">
                <div className="moduleHeader">
                  <h2 className="moduleHeader-title">Weby TASR</h2>
                </div>
                <div className="moduleMain">
                  <div className="row row--sm row--verticalGutter row--grid">
                    {topArticleElement}
                  </div>
                </div>
              </div>
            </div>
            <aside className="l-sidebar pull-right">{aside}</aside>
          </div>

          <div className="moduleMain">
            <div className="row row--sm row--verticalGutter row--grid">
              {sectionElements}
            </div>
          </div>

          <TipsHorizontal items={props.tips} gaInfo={gaInfo} />
          {/* <BannerHorizontal {...props.banner_horizontal} /> */}
          {(props.splits || []).map(asideMapping)}
        </div>
      </section>
    );
  }
}

class MediaListingItem extends React.PureComponent {
  render() {
    const loading = this.props.top_article;
    const top_article = loading ? this.props.top_article : {};
    const gaInfo = getGaInfo(this.props, this.props.name);
    return (
      <div className={getClassNamesSize(this.props.nTotal)}>
        <div className="module">
          <h3 className="module-title">
            {this.props.noSectionHeaders ? null : (
              <Link
                baseUrl={this.props.baseUrl}
                to={this.props.url}
                gaInfo={gaInfo}
              >
                {this.props.name}
              </Link>
            )}
          </h3>
          <div className="media media--sm">
            <LinkImgCrop
              baseUrl={this.props.baseUrl}
              to={top_article.url}
              src={top_article.image}
              title={top_article.title}
              gaInfo={gaInfo}
            />
            <div className="media-content">
              <h4 className="media-title">
                <Link
                  baseUrl={this.props.baseUrl}
                  to={top_article.url}
                  gaInfo={gaInfo}
                >
                  {top_article.title}
                </Link>
              </h4>
              <p className="media-text">{top_article.annotation}</p>
              {top_article.time_added ? (
                <PublishingTime {...top_article} />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(wagonMapStateToProps)(WagonWebyTASR);
