import React, { Component } from "react";
import { Link, Line } from "~/components/Elements";
import { imgSprite } from "~/config/images";
import Icon from "./base/Icon";
import InnerHTML from "./base/InnerHtml";
import { getGaInfo } from "~/utility/gaInfo";
import { ItemI, itemId, PropT } from "./RightNow";
export default class RightNowVertical extends Component<PropT> {
  render() {
    const gaInfo = getGaInfo(this.props, "PraveTerazV");
    const items = (this.props.items || Array(6).fill({})).map((v, i) => (
      <Item {...v} key={i} gaInfo={gaInfo} />
    ));
    return (
      <div className="rightNow--topArticles">
        <div className="moduleHeader">
          <h3 className="moduleHeader-title">
            <Link to="/prave-teraz" gaInfo={gaInfo}>
              Práve teraz{" "}
              <svg className="icon color-primary">
                <use xlinkHref={imgSprite + "#rss"} />
              </svg>
            </Link>
          </h3>
        </div>
        <div className="moduleMain moduleMain--rightNow">
          <div className="rightNow rightNow--vertical fadeout trimmed">
            {items}
            <div className="rightNow-item">
              <div className="rightNow-main">
                <div className="rightNow-body">
                  <p className="rightNow-text">
                    <Link
                      className="rightNow-time"
                      to="/prave-teraz"
                      gaInfo={gaInfo}
                    >
                      <strong>Viac &gt;</strong>
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="moduleFooter clearfix">
            <strong className="pull-right">
              <Link
                to="/prave-teraz"
                className="link link--default link--withIcon"
                gaInfo={gaInfo}
              >
                Viac <Icon name="chevron-double-down" />
              </Link>
            </strong>
          </div>
        </div>
      </div>
    );
  }
}


const Item: React.FC<ItemI> = (props) => (
  <div className="rightNow-item">
    <div className="rightNow-body">
      <p className="rightNow-text">
        <time className="rightNow-time" dateTime="">
          {props.time || <Line />}
        </time>{" "}
        -{" "}
        {props.link ? (
          <Link to={props.link} gaInfo={props.gaInfo}>
            <strong>{props.title || <Line />}</strong>
          </Link>
        ) : (
          <strong>{props.title || <Line />}</strong>
        )}{" "}
        {(
          <InnerHTML
            span
            html={props.annotation}
            id={itemId(props.time, props.title, props.link)}
          />
        ) || <Line />}
      </p>
    </div>
  </div>
);
