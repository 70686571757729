import React, { Component } from "react";
import { connect } from "react-redux";
import OffCanvas from "~/components/base/OffCanvas";
import { RootState } from "~/store/store";

class OffCanvasContainer extends Component {
  render() {
    return <OffCanvas {...this.props} />;
  }
}

const mapStateToProps = (state: RootState) => {
  const { header } = state;
  return {
    menu: header.menu,
    headerSuffixUrl: header.headerSuffixUrl,
  };
};

export default connect(mapStateToProps)(OffCanvasContainer);
