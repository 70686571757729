export let windowLet: any =
  typeof window !== "undefined"
    ? window
    : {
        history: [],
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: null,
        __PRELOADED_STATE__: null,
      };

export const documentLet =
  typeof document !== "undefined" ? document : global.document;
