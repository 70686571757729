import React, { Component } from "react";
import { apiUrlTabletTv } from "~/utility/api";
import { connect } from "react-redux";
import { fetchWagon } from "~/store/actions";
import { wagonMapStateToProps } from "~/store/mappers";
import { imgSprite, imgTabletLogo } from "~/config/images";
import { PlaceholderImg } from "~/components/placeholders/Image";
import { LinkImgFree, Link } from "~/components/Elements";
import { getGaInfo } from "~/utility/gaInfo";
import { classWagonHeightSave } from "~/components/wagons/wagonHeightHook";

class WagonTabletTV extends Component {
  constructor(props) {
    super(props);
    this.url = `${apiUrlTabletTv}react/`;
    this.wagonHeightRef = React.createRef();
  }

  UNSAFE_componentWillMount() {
    const { dispatch } = this.props;
    dispatch(fetchWagon(this.url, this.props.required));
  }


  componentDidUpdate(){
    classWagonHeightSave(this);
  }

  render() {
    const gaInfo = getGaInfo(this.props, "wTV");
    const state = this.props[this.url] || {};
    const baseUrl = "http://www.tasrtv.sk";
    const articles = state.articles || Array(4).fill({});
    return (
      <section className="section section--tabletTV"  ref={this.wagonHeightRef}>
        <div className="container border-top">
          <div className="l-columns clearfix">
            <div className="l-main pull-left">
              <div className="module module--tabletTV">
                <div className="moduleHeader">
                  <h2 className="moduleHeader-title">
                    <Link to={baseUrl} gaInfo={gaInfo}>
                      <span className="visuallyhidden">TASR TV</span>
                      <img
                        src={imgTabletLogo}
                        title="TASR TV Logo"
                        alt="TASR TV Logo"
                        loading="lazy"
                      />
                    </Link>
                  </h2>
                  <ul className="moduleHeader-menu moduleHeader-menu--tabletTV menu clearfix">
                    <li>
                      <Link className="play" to={baseUrl} gaInfo={gaInfo}>
                        <svg className="icon">
                          <use xlinkHref={imgSprite + "#play"} />
                        </svg>
                        Vysielanie
                      </Link>
                    </li>
                    <li>
                      <Link baseUrl={baseUrl} to={"/relacie"} gaInfo={gaInfo}>
                        Relácie
                      </Link>
                    </li>
                    <li>
                      <Link
                        baseUrl={baseUrl}
                        to={"/spravodajstvo"}
                        gaInfo={gaInfo}
                      >
                        Spravodajstvo
                      </Link>
                    </li>
                  </ul>
                </div>
                <TabletTopArticle {...state.top_article} gaInfo={gaInfo} />
              </div>
            </div>
            <aside className="l-sidebar pull-right">
              <div className="module">
                <div className="row row--sm row--verticalGutter row--grid">
                  {articles.map((v, i) => (
                    <div className="col col-12 col-sm-6 col-xs-12" key={i}>
                      <div className="media media--sm media--horizontal">
                        <div className="row row--xs">
                          <div className="col col-6 col-sm-4">
                            <LinkImgFree
                              to={v.url}
                              baseUrl={baseUrl}
                              src={v.image}
                              title={v.title}
                              gaInfo={gaInfo}
                            />
                          </div>
                          <div className="col col-6 col-sm-8">
                            <div className="media-content">
                              <h4 className="media-title media-title--tabletTV">
                                <Link to={v.url} gaInfo={gaInfo}>
                                  {v.title}
                                </Link>
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </aside>
          </div>
        </div>
      </section>
    );
  }
}

export default connect(wagonMapStateToProps)(WagonTabletTV);

class TabletTopArticle extends Component {
  render() {
    return (
      <div className="moduleMain moduleMain--tabletTV">
        <div className="mediaListing row">
          <div className="mediaListing-item mobile-row col col-12">
            <div className="media media--lg media--tabletTV">
              <Link
                to={this.props.url}
                className="media-img"
                gaInfo={this.props.gaInfo}
              >
                <button className="playBtn" type="button">
                  <span className="playBtn-icon">
                    <svg className="icon pull-left">
                      <use xlinkHref={imgSprite + "#play"} />
                    </svg>
                  </span>
                  <span className="playBtn-label">Prehrať</span>
                </button>
                {this.props.image ? (
                  <img src={this.props.image} title={this.props.title} alt="" loading="lazy"/>
                ) : (
                  <PlaceholderImg />
                )}
              </Link>
              <div className="media-content">
                <h4 className="media-title">
                  <Link to={this.props.url} gaInfo={this.props.gaInfo}>
                    {this.props.title}
                  </Link>
                </h4>
                <div className="media-text">{this.props.annotation}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
