import React, { PureComponent } from "react"; // import {imgWeather} from 'data/config/images'

export default class TopWeather extends PureComponent<{
  symbol: string;
  temperature: string;
  description: string;
}> {
  render() {
    return (
      <div className="headerTopWeather pull-right">
        <a href="http://pocasie.teraz.sk" rel="noopener" target="_blank">
          <span className="headerTopWeather-number">
            {this.props.temperature}
            &deg;C
          </span>
          <img
            className={`headerTopWeather-icon`}
            src={`/img/weather/icons/weather${
              !isNaN(parseInt(this.props.symbol)) ? this.props.symbol : 1
            }.svg`}
            alt={this.props.description}
          />
        </a>
      </div>
    );
  }
}
