import React, { useState, useEffect } from "react";
import asideMapping from "../../utility/aside";
import DataTable from 'react-data-table-component';
import LoaderIndex from "~/containers/LoaderIndex";
import TableFilters from "../elections/TableFilters";
import { ResponsiveBar } from '@nivo/bar';
import dataResults from './volby2023_results.json';
import dataLegendFile from './volby2023_legend.json';
import { Link } from "../Elements";
import { FacebookEmbed, InstagramEmbed, YouTubeEmbed } from 'react-social-media-embed';
const tasrTVVideaURL = "https://www.youtube.com/c/TASRTV";
const terazIgURL = "https://www.instagram.com/terazsk/";
const TerazFBUrl = "https://www.facebook.com/teraz.sk";
const tasrFBUrl = "https://www.facebook.com/AgenturaTASR";

// import LoaderIndex from "../../containers/LoaderIndex"

const SpotifyPlayer = ({ embedUrl }) => {

    useEffect(() => {
        if (process.env.BUILD_TARGET !== "server") {
            window.onSpotifyIframeApiReady = (IFrameAPI) => {
                const element = document.getElementById('spotify-embed-iframe');
                const options = {
                    uri: 'spotify:episode:' + embedUrl
                };
                const callback = (EmbedController) => {};
                IFrameAPI.createController(element, options, callback);
            };
        }
    }, []);

    return (
        <div id="spotify-embed-iframe"></div>
    );
  };

  


const AgregatorSocSietiPage = (props) => {
  
    const [searchResults, setSearchResults] = useState([]);
    const [searchVideoResults, setSearchVideoResults] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchVideoTerm, setSearchVideoTerm] = useState('');

    const [embeddedFbPost, setEmbeddedFbPost] = useState('');
    const [embeddedIgPost, setEmbeddedIgPost] = useState('');
    const [embeddedYtPost, setEmbeddedYtPost] = useState('');
    const [embeddedSpotifyPost, setEmbeddedSpotifyPost] = useState('');
    const [showPopup, setShowPopup] = useState(false);


    const [activeVideoFilter, setActiveVideoFilter] = useState('');
    const [activePostFilter, setActivePostFilter] = useState('');
    const [socialData, setSocialData] = useState();
             

    useEffect(() => {
        const handleScroll = () => {
            if (process.env.BUILD_TARGET !== "server") {
                const innerDiv = document.querySelector('.inner');   
                if (innerDiv) {
                    innerDiv.style.marginTop = `${window.scrollY}px`;
                }
            }
        };

        const handleKeyUp = () => {
            if (event.key === 'Escape') {
                closePopup();
            }          
        };       

        if (process.env.BUILD_TARGET !== "server") {
            window.addEventListener('keyup', handleKeyUp);
            window.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (process.env.BUILD_TARGET !== "server") {
                window.removeEventListener('scroll', handleScroll);
                window.removeEventListener('keyup', handleKeyUp);
            }
        };
       
    }, []);
    


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://www.teraz.sk/api/social_network_content/list.php");
        const data = await response.json();
        setSocialData(data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  

    function openEmbedPopup(url: string, networkType: string): void {    
        switch (networkType) {
            case 'FACEBOOK':
                setEmbeddedFbPost(url);
                break;
            case 'INSTAGRAM':
                setEmbeddedIgPost(url);
                break;
            case 'YOUTUBE':
                setEmbeddedYtPost(url);
                break;
            case 'SPOTIFY':
                setEmbeddedSpotifyPost(url);
                break;
            default:
                break;
        }
        setShowPopup(true);
        if (process.env.BUILD_TARGET !== "server") {
            const innerDiv = document.querySelector('.inner');
            if (innerDiv) {
                innerDiv.style.marginTop = `${window.scrollY}px`;
            }
        }
    }

    

    function closePopup(): void {        
        setEmbeddedFbPost('');
        setEmbeddedIgPost('');
        setEmbeddedYtPost('');
        setEmbeddedSpotifyPost('');
        setShowPopup(false);
    }

    


    function handleFilterToggle(item: string): void {
        setActivePostFilter(item);
    }    
    function handleVideoFilterToggle(item: string): void {
        setActiveVideoFilter(item);
    }    

    const handleSearch = async (searchQuery) => {
        try {
            const response = await fetch(`https://www.teraz.sk/api/social_network_content/list.php?q=${searchQuery}`);
            const data = await response.json();
            
            setSearchTerm(searchQuery);

            // remove youtube from results

            delete data.YOUTUBE;            

            const flatResults = Object.entries(data).flatMap(([networkType, items]) =>
                items.by_date.map(item => ({
                    ...item,
                    networkType, 
                }))
            );

            

            setSearchResults(flatResults);
        } catch (error) {
            console.error(error);
        }
    };

    const handleSearchVideo = async (searchQuery) => {
        try {
            const response = await fetch(`https://www.teraz.sk/api/social_network_content/list.php?q=${searchQuery}`);
            const data = await response.json();
            
            setSearchVideoTerm(searchQuery);

            const flatResults = data.YOUTUBE?.by_date?.map(item => ({
                ...item,
                networkType: "YOUTUBE"
            }))


            setSearchVideoResults(flatResults);
        } catch (error) {
            console.error(error);
        }
    };

  return (
    <div>
        <div className="container static-page ass">                
            <h1>Politika na sociálnych sieťach</h1>            
            
            {showPopup && 
            <div className="embedded-post-popup">
                <div className="inner" style={{ marginTop: `${window.scrollY}px` }}>
                    <div className="close-popup" onClick={() => closePopup()}>Zavrieť</div>
                    {embeddedFbPost && <FacebookEmbed url={embeddedFbPost} width={500} />}
                    {embeddedIgPost && <InstagramEmbed url={embeddedIgPost} width={450} />}
                    {embeddedYtPost && <YouTubeEmbed url={embeddedYtPost} width={500} />}
                    {embeddedSpotifyPost && <div id="spotify-player"><SpotifyPlayer embedUrl={embeddedSpotifyPost} /></div>}
                </div>                  
            </div>
            }
            
            <div className="ass-page-wrapper ass-2-cols">
                <div className="row-ass">
                    <div className="col-ass">
                        <div className="top-col">
                            <i className="icon icon-yt"></i>
                            <div className="text">
                                <div className="title">Najsledovanejšie videá</div>
                                <div className="desc">za posledných 24 hodín</div>
                            </div>
                        </div>

                        <div className="ass-banner-list">
                            {socialData?.YOUTUBE?.by_views?.slice(0, 4).map((item, index) => (
                                <div className="ass-banner-item yt" onClick={() => openEmbedPopup(item.url, item.network_type)} key={index}>
                                <div
                                    className={`img-wrapper ${item.thumbnail == '' ? 'no-img' : ''}`}
                                    style={{ backgroundImage: `url(${item.thumbnail})` }}
                                ></div>
                                <div className="text">
                                    <div className="title xl">{item.title}</div>
                                </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="col-ass">
                    <div className="top-col">
                        <i className="icon icon-yt"></i>
                        <div className="text">
                            <div className="title">Najnovšie videá</div>
                        </div>
                    </div>

                    <div className="ass-banner-list">
                        {socialData?.YOUTUBE?.by_date?.slice(4, 8).map((item, index) => (
                            <div className="ass-banner-item yt" onClick={() => openEmbedPopup(item.url, item.network_type)} key={index}>
                            <div
                                className={`img-wrapper ${item.thumbnail == '' ? 'no-img' : ''}`}
                                style={{ backgroundImage: `url(${item.thumbnail})` }}
                            ></div>
                            <div className="text">
                                <div className="title xl">{item.title}</div>
                            </div>
                            </div>
                        ))}
                    </div>
                </div>  
                </div>                

                


                <div className="col-search-bar">
                    <div className="title">
                        <i className="icon icon-search"></i>
                        <span>Vyhľadávanie vo videách</span>
                    </div>
                    <form
                        className="search-form"
                        onSubmit={(e) => {
                            e.preventDefault();
                            const searchQuery = e.target.elements['search-yt'].value;
                            handleSearchVideo(searchQuery);
                        }}
                    >
                        <div className="input-wrapper">
                            <input type="text" name="search-yt" placeholder="Hľadaný výraz" id="" />
                            <button type="submit">
                                <i className="icon icon-search sm"></i>
                            </button>
                        </div>
                    </form>

                    <ul className="filter-bar">
                        <li>
                            <div
                                className={`filter-toggle ${activeVideoFilter === "last3days" ? "active" : ""}`}
                                onClick={() => handleVideoFilterToggle("last3days")}
                            >
                                Posledné 3 dni
                            </div>
                        </li>
                        <li>
                            <div
                                className={`filter-toggle ${activeVideoFilter === "lastweek" ? "active" : ""}`}
                                onClick={() => handleVideoFilterToggle("lastweek")}
                            >
                                Posledný týždeň
                            </div>
                        </li>
                        <li>
                            <div
                                className={`filter-toggle ${activeVideoFilter === "lastmonth" ? "active" : ""}`}
                                onClick={() => handleVideoFilterToggle("lastmonth")}
                            >
                                Posledný mesiac
                            </div>
                        </li>
                    </ul>
                </div>

                { searchVideoTerm != '' &&
                <div className="ass-search-results-wrapper">
                    <div className="title">Výsledky vyhľadávania pre výraz "{searchVideoTerm}":</div>

                    { searchVideoResults.length > 0 ? (
                    <div className="search-results-list">
                    
                        {searchVideoResults.map((item, index) => (
                                <div 
                                    onClick={() => openEmbedPopup(item.url, item.networkType)}
                                    className={'search-result-item ' + item.networkType} key={index}>
                                    <div
                                        className={`img-wrapper ${item.thumbnail == '' ? 'no-img' : ''}`}
                                        style={{ backgroundImage: `url(${item.thumbnail})` }}
                                    ></div>
                                    <div className="text">
                                        <div className="title">
                                            {item.title.length > 120
                                                ? item.title.substring(0, 120) + '...'
                                                : item.title}
                                        </div>
                                        {item.text_content && (
                                            <div className="desc">
                                                {item.text_content.length > 120
                                                    ? item.text_content.substring(0, 120) + '...'
                                                    : item.text_content}
                                            </div>
                                        )}
                                    </div>
                                </div>
                        ))}
                    </div>
                    ): (
                        <div>
                            Ľutujeme, nenašli sa žiadne výsledky pre Váš hľadaný výraz.
                        </div>
                    )}
                
                </div>                
                }
                              
            </div>
            
            <div className="ass-page-socials">
                <div className="title">Sledujte nás na sociálnych sieťach</div>

                <ul>
                    <li><Link to={tasrTVVideaURL}><i className="icon icon-yt sm"></i><span>TASR TV Videá</span></Link></li>
                    <li><Link to={terazIgURL}><i className="icon icon-ig"></i><span>Teraz.sk</span></Link></li>
                    <li><Link to={TerazFBUrl}><i className="icon icon-fb"></i><span>Teraz.sk</span></Link></li>
                    <li><Link to={tasrFBUrl}><i className="icon icon-fb"></i><span>TASR.SK</span></Link></li>
                </ul>
            </div>

            <div className="ass-page-wrapper ass-4-cols">
                <div className="row-ass">
                    <div className="col-ass">
                        <div className="top-col">
                            <i className="icon icon-spotify"></i>
                            <div className="text">
                                <div className="title">Najnovšie podcasty</div>
                            </div>
                        </div>

                        <div className="ass-banner-list bg">
                            {socialData?.SPOTIFY?.by_date?.slice(0, 6).map((item, index) => (
                                    <div className="ass-banner-item sm" onClick={() => openEmbedPopup(item.social_network_id, item.network_type)} key={index}>
                                    <div
                                        className={`img-wrapper circle ${item.thumbnail == '' ? 'no-img' : ''}`}
                                        style={{ backgroundImage: `url(${item.thumbnail})` }}
                                    ></div>
                                    <div className="text">
                                        <div className="title">
                                            {item.title.length > 55 ? item.title.substring(0, 55) + "..." : item.title}
                                        </div>
                                    </div>
                                    </div>
                                ))}
                        </div>                        

                    </div>

                    <div className="col-ass">
                        <div className="top-col">
                            <i className="icon icon-tiktok"></i>
                            <div className="text">
                                <div className="title">Najnovšie statusy</div>
                            </div>
                        </div>

                        <div className="ass-banner-list bg">
                            {socialData?.INSTAGRAM?.by_date?.slice(0, 6).map((item, index) => (
                                <div className="ass-banner-item sm" onClick={() => openEmbedPopup(item.url, item.network_type)} key={index}>
                                <div
                                    className={`img-wrapper ${item.thumbnail == '' ? 'no-img' : ''}`}
                                    style={{ backgroundImage: `url(${item.thumbnail})` }}
                                ></div>
                                <div className="text">
                                    <div className="title">
                                        {item.title.length > 55 ? item.title.substring(0, 55) + "..." : item.title}
                                    </div>
                                </div>
                                </div>
                            ))}
                        </div>

                    </div>

                    <div className="col-ass">
                        <div className="top-col">
                            <i className="icon icon-ig"></i>
                            <div className="text">
                                <div className="title">Najnovšie príspevky</div>
                            </div>
                        </div>

                        <div className="ass-banner-list bg">
                            {socialData?.INSTAGRAM?.by_date?.slice(3, 9).map((item, index) => (
                                <div className="ass-banner-item sm" onClick={() => openEmbedPopup(item.url, item.network_type)} key={index}>
                                <div
                                    className={`img-wrapper ${item.thumbnail == '' ? 'no-img' : ''}`}
                                    style={{ backgroundImage: `url(${item.thumbnail})` }}
                                ></div>
                                <div className="text">
                                    <div className="profile-name">@{item.creator_handle}</div>
                                    <div className="title">
                                        {item.title.length > 55 ? item.title.substring(0, 55) + "..." : item.title}
                                    </div>
                                </div>
                                </div>
                            ))}
                        </div>

                    </div>

                    <div className="col-ass">
                        <div className="top-col">
                            <i className="icon icon-fb"></i>
                            <div className="text">
                                <div className="title">Najnovšie statusy</div>
                            </div>
                        </div>

                        <div className="ass-banner-list bg">
                            {socialData?.FACEBOOK?.by_date?.slice(0, 6).map((item, index) => (
                                <div className="ass-banner-item sm" onClick={() => openEmbedPopup(item.url, item.network_type)} key={index}>
                                    <div
                                        className={`img-wrapper ${item.thumbnail == '' ? 'no-img' : ''}`}
                                        style={{ backgroundImage: `url(${item.thumbnail})` }}
                                    ></div>
                                    <div className="text">
                                        <div className="profile-name">{item.creator_handle}</div>
                                        <div className="title">
                                            {item.title.length > 55 ? item.title.substring(0, 55) + "..." : item.title}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                    </div>
                </div>


                <div className="col-search-bar">
                    <div className="title">
                        <i className="icon icon-search"></i>
                        <span>Vyhľadávanie v príspevkoch</span>
                    </div>
                        <form
                            className="search-form"
                            onSubmit={(e) => {
                                e.preventDefault();
                                const searchQuery = e.target.elements['search-posts'].value;
                                handleSearch(searchQuery);
                            }}
                        >
                            <div className="input-wrapper">
                                <input type="text" name="search-posts" placeholder="Hľadaný výraz" id="" />
                                <button type="submit">
                                    <i className="icon icon-search sm"></i>
                                </button>
                            </div>
                        </form>

                    <ul className="filter-bar">
                        <li>
                            <div
                                className={`filter-toggle ${activePostFilter === "last3days" ? "active" : ""}`}
                                onClick={() => handleFilterToggle("last3days")}
                            >
                                Posledné 3 dni
                            </div>
                        </li>
                        <li>
                            <div
                                className={`filter-toggle ${activePostFilter === "lastweek" ? "active" : ""}`}
                                onClick={() => handleFilterToggle("lastweek")}
                            >
                                Posledný týždeň
                            </div>
                        </li>
                        <li>
                            <div
                                className={`filter-toggle ${activePostFilter === "lastmonth" ? "active" : ""}`}
                                onClick={() => handleFilterToggle("lastmonth")}
                            >
                                Posledný mesiac
                            </div>
                        </li>
                    </ul>
                </div>

                { searchTerm != '' &&
                <div className="ass-search-results-wrapper">
                    <div className="title">Výsledky vyhľadávania pre výraz "{searchTerm}":</div>

                    { searchResults.length > 0 ? (
                    <div className="search-results-list">
                    
                        {searchResults.map((item, index) => (                                
                            <div
                                onClick={() => openEmbedPopup(item.url, item.networkType)}
                                className={'search-result-item ' + item.networkType} key={index}>
                                <div
                                    className={`img-wrapper ${item.thumbnail == '' ? 'no-img' : ''}`}
                                    style={{ backgroundImage: `url(${item.thumbnail})` }}
                                ></div>
                                <div className="text">
                                    <div className="title">
                                        {item.title.length > 120
                                            ? item.title.substring(0, 120) + '...'
                                            : item.title}
                                    </div>
                                    {item.text_content && (
                                        <div className="desc">
                                            {item.text_content.length > 120
                                                ? item.text_content.substring(0, 120) + '...'
                                                : item.text_content}
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                    ): (
                        <div>
                            Ľutujeme, nenašli sa žiadne výsledky pre Váš hľadaný výraz.
                        </div>
                    )}
                
                </div>                
                }

            </div>

            
           
        </div>
        

      <LoaderIndex />
    </div>
  );
};

export default AgregatorSocSietiPage;