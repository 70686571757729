const imgSprite = "/sprite.svg";
const imgLogoTasr = "/img/logo-tasr.svg";
const imgLogoAd = "/img/logo-ad.svg";
const imgLike = "/img/like.png";
const imgTabletLogo = "/img/tasrTV-logo.png";
// import imgWeather from 'img/weather/partly-cloudy.svg'
export { imgSprite };
export { imgLogoTasr };
export { imgLogoAd };
export { imgLike };
export { imgTabletLogo }; // export {imgWeather}
