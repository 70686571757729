import React from "react";
import loadScript from "../utility/loadScript";
import { fnGemiusEvent } from '../components/base/Advertisement';

function isAccepted() {
  if (process.env.BUILD_TARGET === "server") {
    global.COOKIES_ACCEPTED = false;
    return COOKIES_ACCEPTED;
  }

  var enabled_regex = /(teraz|localhost)/i;

  if (process.env.BUILD_TARGET !== "server") {
  if (
    global.document.cookie.replace(
      /(?:(?:^|.*;\s*)cookiePolicyConfirmed\s*=\s*([^;]*).*$)|^.*$/,
      "$1"
    ) === "true" ||
    !enabled_regex.test(window.location.hostname)
  ) {
    global.COOKIES_ACCEPTED = true;
  } else {
    global.COOKIES_ACCEPTED = false;
  }

  return COOKIES_ACCEPTED;
  }
}

isAccepted();


class CookieConsent extends React.Component {
  constructor(props) {
    super(props);
    // global.COOKIE_SETTINGS = {
    //   text:
    //     /*html*/
    //     `Súhlasím s použitím cookies na TERAZ.SK, ktoré využívame na
    //   zlepšenie služieb v súlade s&nbsp;
    //   <a href="/pravidla-ochrany-osobnych-udajov" target="_blank">
    //     Pravidlami ochrany osobných údajov
    //   </a>
    //   a&nbsp;
    //   <a href="/podmienky-pouzivania" target="_blank">
    //     Podmienkami používania
    //   </a>
    //   .`,
    //   urlMore: "/pravidla-ochrany-osobnych-udajov",
    //   cbAccept: function () {
    //     if (process.env.BUILD_TARGET !== "server") {
    //       location.reload();
    //     }
    //   },
    // };
    if (process.env.BUILD_TARGET !== "server") {
      document.addEventListener("changeApproves", function (e) {
        let approves = e.detail;
        if (approves.approvedCookie) {
          document.cookie =
          "cookiePolicyConfirmed=true; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/";
          global.COOKIES_ACCEPTED = true;
          fnGemiusEvent(true);
        } else {
          document.cookie =
          "cookiePolicyConfirmed=false; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/";
          global.COOKIES_ACCEPTED = false;
          fnGemiusEvent(false);
        }
      });
    }

    // if (process.env.BUILD_TARGET !== "server") {
    //   loadScript("https://cookies.teraz.sk/main.js");
    // }
  }

  render() {
    return null;
  }
} // eslint-disable-next-line no-unused-vars

class CookieConsentOld extends React.Component {
  constructor(props) {
    super(props);
    this.accept = this.accept.bind(this);
    this.decline = this.decline.bind(this);
    const acceptedInPast = isAccepted();
    this.state = {
      accepted: acceptedInPast,
      declined: acceptedInPast,
    };
  }

  decline() {
    this.setState({
      declined: true,
    });
  }

  accept() {
    if (process.env.BUILD_TARGET !== "server") {
      document.cookie =
        "cookiePolicyConfirmed=true; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/";
      this.setState({
        accepted: true,
      });
      // eslint-disable-line no-global-assign
      // eslint-disable-next-line no-native-reassign
      COOKIES_ACCEPTED = true; // eslint-disable-line no-global-assign

      location.reload();
    }
  }

  render() {
    return this.state.accepted || this.state.declined ? null : (
      <div className="cookies">
        <div className="cookies-inner">
          <div className="cookieConsentBoxWrapper">
            <p className="cookieConsentText">
              Súhlasím s použitím cookies na TERAZ.SK, ktoré využívame na
              zlepšenie služieb v súlade s&nbsp;
              <a href="/pravidla-ochrany-osobnych-udajov" target="_blank">
                Pravidlami ochrany osobných údajov
              </a>{" "}
              a&nbsp;
              <a href="/podmienky-pouzivania" target="_blank">
                Podmienkami používania
              </a>
              .
            </p>
          </div>
          <div className="cookieConsentButtons">
            <button
              className="cookies-close cookies-accept"
              type="button"
              onClick={this.accept}
            >
              Súhlasím
            </button>
            <button
              className="cookies-close cookies-decline"
              type="button"
              onClick={this.decline}
            >
              Nesúhlasím
            </button>
          </div>
        </div>
      </div>
    );
  }
} // eslint-disable-next-line no-unused-vars

class CookieConsentWithCheckbox extends React.Component {
  constructor(props) {
    super(props);
    // this.isAccepted = this.isAccepted.bind(this)
    this.accept = this.accept.bind(this);
    this.decline = this.decline.bind(this);
    // this.onChangeCheckbox = this.onChangeCheckbox.bind(this)
    const acceptedInPast = isAccepted();
    this.state = {
      accepted: acceptedInPast,
      declined: acceptedInPast,
      checkboxChecked: acceptedInPast,
    };
  }

  decline() {
    this.setState({
      declined: true,
    });
  }

  accept() {
    if (this.state.checkboxChecked) {
      document.cookie =
        "cookiePolicyConfirmed=true; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/";
      this.setState({
        accepted: true,
      });
      // eslint-disable-line no-global-assign
      // eslint-disable-next-line no-native-reassign
      COOKIES_ACCEPTED = true; // eslint-disable-line no-global-assign

      if (process.env.BUILD_TARGET !== "server") {
        location.reload();
      }
    } // RELOAD PAGE?
  }

  onChangeCheckbox = (e) => {
    this.setState({
      checkboxChecked: e.target.checked,
    });
  };

  render() {
    return null
    // return this.state.accepted || this.state.declined ? null : (
      // <div className="cookies">
      //   <div className="cookies-inner">
      //     <div className="cookieConsentBoxWrapper">
      //       <label htmlFor="cookieConsentCheckbox">
      //         <div className="cookieConsentCheckboxWrapper">
      //           <input
      //             id="cookieConsentCheckbox"
      //             type="checkbox"
      //             checked={this.state.checkboxChecked}
      //             onChange={this.onChangeCheckbox}
      //           />
      //         </div>
      //         <p className="cookieConsentText">
      //           Súhlasím s použitím cookies na TERAZ.SK, ktoré využívame na
      //           zlepšenie služieb v súlade s&nbsp;
      //           <a href="/pravidla-ochrany-osobnych-udajov" target="_blank">
      //             Pravidlami ochrany osobných údajov
      //           </a>{" "}
      //           a&nbsp;
      //           <a href="/podmienky-pouzivania" target="_blank">
      //             Podmienkami používania
      //           </a>
      //           .
      //         </p>
      //       </label>
      //     </div>
      //     <div>
      //       <button
      //         className="cookies-close cookies-accept"
      //         type="button"
      //         disabled={!this.state.checkboxChecked}
      //         onClick={this.accept}
      //       >
      //         Súhlasím
      //       </button>
      //       <button
      //         className="cookies-close cookies-decline"
      //         type="button"
      //         onClick={this.decline}
      //       >
      //         Nesúhlasím
      //       </button>
      //     </div>
      //   </div>
      // </div>
    // );
  }
}

export default CookieConsent;
/* global COOKIES_ACCEPTED */
