import React, { Component } from "react";
import { apiUrlTeraz } from "~/utility/api";
import { connect } from "react-redux";
import { fetchWagon } from "~/store/actions";
import { wagonMapStateToProps } from "~/store/mappers";
import Wagon from "~/components/wagons/Wagon";

class WagonKaleidoskop extends Component {
  constructor(props) {
    super(props);
    const tipsPage = this.props.tipsPage || 0;
    this.url = `${apiUrlTeraz}api_rest.php?module=RestApi&method=wagonSections&sections=3,7,5583,5578&type=kaleidoskop&tipsPage=${tipsPage}`;
  }

  UNSAFE_componentWillMount() {
    const { dispatch } = this.props;
    dispatch(fetchWagon(this.url, this.props.required));
  }

  render() {
    const coluleTitle = "Zábava a poučenie";
    const coluleHeaders = [
      /*
      {url: '/kultura', name: 'Kultúra'},
      {url: '/zaujimavosti', name: 'Zaujímavosti'},
      {url: '/veda', name: 'Unesco a veda'},
      {url: '/zdravie', name: 'Zdravie'},
      */
    ];
    return (
      <Wagon
        name={this.props.name}
        {...this.props[this.url]}
        coluleTitle={coluleTitle}
        coluleUrl={"/kaleidoskop"}
        coluleHeaders={coluleHeaders}
        asideHeaderSuffix="z Kaleidoskopu"
        gaInfo={this.props.gaInfo}
      />
    );
  }
}

export default connect(wagonMapStateToProps)(WagonKaleidoskop);
