import React, { Component } from "react";
import { LinkImgFree, Link } from "~/components/Elements";
import { getGaInfo } from "~/utility/gaInfo";
import "~/style/font_vtedy.css";
import { useWagonHeight } from "./wagonHeightHook";
export default function WagonVtedy(props) {
  const ref = useWagonHeight(props.name);
  const baseUrl = "http://www.vtedy.sk/";
  const gaInfo = getGaInfo(props, "wVtedy");
  const articles = props.items.reduce((a, v, i) => {
    a.push(<Article {...v} key={i} baseUrl={baseUrl} gaInfo={gaInfo} />);
    // if(i%2===0) a.push(<span className="clearfix" key={"s"+i}/>)
    return a;
  }, []);
  return (
    <section className="section section--vtedy" ref={ref}>
      <link
        href="https://fonts.googleapis.com/css?family=Oswald"
        rel="stylesheet"
      />
      <div className="container border-top">
        <div className="clearfix">
          <div className="module module--vtedy">
            <div className="moduleHeader">
              <h2 className="moduleHeader-title">
                <a href="http://www.vtedy.sk/" target="_blank">
                  <span className="visuallyhidden">Vtedy.sk</span>
                  <img
                    src="/img/logo-vtedy.svg"
                    title="Vtedy.sk"
                    alt="Vtedy.sk"
                    loading="lazy"
                  />
                </a>
              </h2>
              <ul className="moduleHeader-menu menu list-reset clearfix">
                {[
                  1945,
                  1950,
                  1955,
                  1960,
                  1965,
                  1970,
                  1975,
                  1980,
                  1985,
                  1990,
                  1995,
                ].map((r) => (
                  <li key={r}>
                    <a
                      rel="noopener"
                      target="_blank"
                      href={`http://www.vtedy.sk/rok/${r}`}
                    >
                      {r}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="moduleMain">
              <div className="row row--grid row--sm row--verticalGutter-sm">
                <TopArticle
                  {...props.top_article}
                  baseUrl={baseUrl}
                  gaInfo={gaInfo}
                />
                <div className="col col-7 col-sm-12">
                  <div className="row row--sm row--verticalGutter-sm row--grid">
                    {articles}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

WagonVtedy.defaultProps = {
  items: [],
};

const TopArticle = (props) => (
  <div className="col col-5 col-sm-12">
    <article className="media media--vtedy media--lg">
      <span className="media-category">{props.tag}</span>
      <LinkImgFree
        baseUrl={props.baseUrl}
        to={props.url}
        className="media-img"
        src={props.image}
        title={props.title}
        alt=""
        gaInfo={props.gaInfo}
      />
      <div className="media-content">
        <h3 className="media-title">
          <Link baseUrl={props.baseUrl} to={props.url} gaInfo={props.gaInfo}>
            {props.title}
          </Link>
        </h3>
        <p className="media-text">{props.annotation}</p>
      </div>
    </article>
  </div>
);

const Article = (props) => (
  <div className="col col-6 col-xs-12">
    <article className="media media--vtedy">
      <span className="media-category">{props.tag}</span>
      <LinkImgFree
        baseUrl={props.baseUrl}
        to={props.url}
        src={props.image}
        title={props.title}
        gaInfo={props.gaInfo}
      />
      <div className="media-content">
        <h3 className="media-title">
          <Link baseUrl={props.baseUrl} to={props.url} gaInfo={props.gaInfo}>
            {props.title}
          </Link>
        </h3>
      </div>
    </article>
  </div>
);
