import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchHeader, headerDate } from "~/store/actions";
import HeaderMain from "~/components/base/HeaderMain";
import FlashNews from "~/components/base/FlashNews";

class HeaderMainContainer extends Component {
  //componentDidMount(){
  UNSAFE_componentWillMount() {
    const { dispatch } = this.props;
    dispatch(headerDate(new Date()));
    dispatch(fetchHeader());
  }

  render() {
    return (
      <div>
        <HeaderMain
          promo_right={this.props.promo_right}
          promo_left={this.props.promo_left}
          dateName={this.props.dateName}
          dowName={this.props.dowName}
          anniversary={this.props.anniversary}
          nameday={this.props.nameday} // headerSuffix={this.props.headerSuffix}
          // headerSuffixUrl={this.props.headerSuffixUrl}
          headerSuffix=".SK"
          headerSuffixUrl="/"
          gaInfo={this.props.gaInfo}
        />
        {this.props.flash_news ? (
          <FlashNews
            flash_news={this.props.flash_news}
            gaInfo={this.props.gaInfo}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { header } = state;
  return { ...header };
};

export default connect(mapStateToProps)(HeaderMainContainer);
