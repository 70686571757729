import React from "react";
import { apiUrlTeraz } from "~/utility/api";
import Article from "~/components/pages/Article";
import LiveArticle from "~/components/pages/LiveArticle";
import LiveArticleGeneral from "~/components/pages/LiveArticleGeneral";
import LoaderIndex from "~/containers/LoaderIndex";
import {
  themeClass,
  headerSuffix,
  selectPage,
  fetchPage,
} from "~/store/actions";
import { connect, ConnectedProps } from "react-redux";
import { pageMapStateToProps } from "~/store/mappers";
import setHttpStatus, {
  shouldHandleHttpStatus,
  handleHttpStatus,
} from "~/utility/httpStatus";
import { getGaInfo } from "~/utility/gaInfo";
import log from "~/utility/log";
import { RouteComponentProps } from "react-router";
import UpdatingPage from "./UpdatingPage";

class ArticleContainer extends UpdatingPage<ArticleContainerProps> {
  state = {
    interval: null,
  };

  update() {
    const { dispatch } = this.props;

    if (!this.props.isLoading) {
      const cat =
        this.props && this.props.category ? this.props.category.name : null;
      const catUrl =
        this.props && this.props.category && this.props.category.url
          ? this.props.category.url
          : null;
      const themeClassName = catUrl ? "theme_" + catUrl : null;
      dispatch(headerSuffix(cat, catUrl));
      dispatch(themeClass(themeClassName));

      const url = this.getUrl(this.props);
      log.info(`Articles ${url}`);

      if (url) {
        dispatch(selectPage(url));
        dispatch(fetchPage(url)); //dispatch( fetchFbShares(canonical_url))
      }
    }
  }

  getUrl(props: ArticleContainerProps) {
    const params = props.match.params;
    const matches = /(\d+)-(clanok|nahlad)\.html/.exec(params.article_slug);

    if (!matches) {
      //Throw 404
      setHttpStatus(this, "404");
      return null;
    } else {
      const articleId = matches[1];
      const kind = matches[2];
      const url = `${apiUrlTeraz}api_rest.php?module=RestApi&method=article&articleId=${articleId}&section=${params.section_url}&kind=${kind}`;
      return url;
    }
  }

  render() {
    if (shouldHandleHttpStatus(this.props)) {
      log.info("handling HTTP status", this.props);
      return handleHttpStatus(this.props);
    } else {
      const gaInfo = getGaInfo(this.props, "article");
      return this.props.live ? (
        this.props.live_type === "general" ? (
          <LiveArticleGeneral
            {...this.props}
            history={this.props.history}
            loader_after={<LoaderIndex gaInfo={gaInfo} />}
          />
        ) : (
          <LiveArticle
            {...this.props}
            history={this.props.history}
            loader_after={<LoaderIndex gaInfo={gaInfo} />}
            gaInfo={getGaInfo(this.props, "LiveArticle")}
          />
        )
      ) : (
        <Article
          {...this.props}
          history={this.props.history}
          loader_after={<LoaderIndex gaInfo={gaInfo} />}
          gaInfo={getGaInfo(this.props, "Article")}
        />
      );
    }
  }
}

const connector = connect(pageMapStateToProps);
type ArticleContainerProps = ConnectedProps<typeof connector> &
  RouteComponentProps<{ article_slug: string }>;
export default connector(ArticleContainer);
