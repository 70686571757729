import React, { Component } from "react";
import { LinkImg, Link, PublishingTime, Line } from "~/components/Elements";
import PropTypes from "prop-types";
export default class MediaListingItem extends Component {
  static propTypes = {
    url: PropTypes.string,
    image: PropTypes.string,
    title: PropTypes.string,
    annotation: PropTypes.string,
  };

  render() {
    return (
      <div className="mediaListing-item mobile-row">
        <div className="media media--lg media--horizontal">
          <div className="row row--sm">
            <div className="col col-4 col-md-12">
              <LinkImg
                to={this.props.url}
                src={this.props.image}
                title={this.props.title}
                gaInfo={this.props.gaInfo}
              />
            </div>
            <div className="col col-8 col-md-12">
              <div className="media-content">
                <h4 className="media-title">
                  <Link to={this.props.url} gaInfo={this.props.gaInfo}>
                    {this.props.title}
                  </Link>
                </h4>
                <p className="media-text">
                  {this.props.annotation || <Line />}
                  <PublishingTime {...this.props} />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
