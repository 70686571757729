import React from "react";
import { imgSprite } from "~/config/images";
export default function Icon(props: { name: string }) {
  return (
    <svg className="icon color-primary">
      <use
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xlinkHref={imgSprite + "#" + props.name}
      />
    </svg>
  );
}
