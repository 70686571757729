import React, { Component } from "react";
import PhotoGalleriesListing from "~/components/photogallery/PhotoGalleriesListing";
import { apiUrlTeraz } from "~/utility/api";
// import data from 'data/api/pages/photo_galleries_listing'
import { headerSuffix, selectPage, fetchPage } from "~/store/actions";
import { connect } from "react-redux";
import { pageMapStateToProps } from "~/store/mappers";
import SubscribeXMLPage from "~/components/pages/SubscribeXMLPage";
import LoaderIndex from "~/containers/LoaderIndex";

class PageSubscribeXML extends Component {
  update(props) {
    const { dispatch } = props;
    const qs = new URLSearchParams(props.location.search);
    const page = qs.get("p") || 1;
    const url = `${apiUrlTeraz}api_rest.php?module=RestApi&method=photoTasrListing&page=${page}`;

    dispatch(headerSuffix("Foto TASR", "foto-tasr"));
    dispatch(selectPage(url));
    dispatch(fetchPage(url));
  }

  //componentDidMount(){
  UNSAFE_componentWillMount() {
    this.update(this.props);
  }


  render() {
    return (
      <div>
        <SubscribeXMLPage />
        <LoaderIndex gaInfo={this.props.gaInfo} />
      </div>
    );
  }
}

export default connect(pageMapStateToProps)(PageSubscribeXML);
