import React, { useEffect } from "react";
import InnerHTML from "../base/InnerHtml";
import { useConsenstString } from "./Web2mediaHooks";
import { getCookie } from "~/utility/cookies";


const Banner = () => {
  // let consentString = "consentstring";
  let consentString = getCookie("euconsent-v2") || '';
  let fisrtPartConsent = '<ins class="asm_async_creative" style="display:inline-block; width:1px; height:1px;text-align:left; text-decoration:none;" data-asm-cdn="cdn.web2media.sk" data-asm-host="turbo.web2media.sk" data-asm-params="pid=716&gdpr_consent=['
  let lastPartConsent = ']"></ins><script src="//cdn.web2media.sk/adasync.min.js" async type="text/javascript"></script> '
  
  // let htmlConsentString = useConsenstString(fisrtPartConsent, consentString, lastPartConsent);
  let htmlConsentString = fisrtPartConsent + consentString + lastPartConsent;
  
  return (
    <InnerHTML
      className=""
      html={htmlConsentString}
    ></InnerHTML>
  );
};

export const Web2MediaInterscrollerClanky = Banner;

export default Banner;
