import React from "react";
import Status from "~/containers/Status";

const NotFound = (props) => (
  <div className="container">
    <Status code="404" />
    <p>
      Úprimne ľutujeme, že sme nenašli odkaz na ktorý ste boli nasmerovaní, ale
      stránka ktorú hľadáte pravdepodobne nikdy neexistovala
    </p>
  </div>
);

export default NotFound;
