import React, { Component } from "react";
import { apiUrlTeraz } from "~/utility/api";
import Section from "~/components/pages/Section";
import NotFound from "~/containers/pages/NotFound";
import AuthorSection from "~/components/pages/AuthorSection";
import LoaderIndex from "~/containers/LoaderIndex";
import StaticPage from "~/components/pages/StaticPage";
import "url-search-params-polyfill";
import {
  themeClass,
  headerSuffix,
  selectPage,
  fetchPage,
} from "~/store/actions";
import { connect, ConnectedProps } from "react-redux";
import { pageMapStateToProps } from "~/store/mappers";
import setHttpStatus from "~/utility/httpStatus";
import { GAInfo } from "~/utility/gaInfo";
import UpdatingPage from "./UpdatingPage";
import log from "~/utility/log";

class CatchAll extends UpdatingPage<PropT> {
  getUrl(props) {
    const params = props.location;
    const sectionUrl = encodeURIComponent(params.pathname); //this.props.match.params.section_url

    const qs = new URLSearchParams(params.search);
    const page_n = qs.get("p") || 1;
    const url = `${apiUrlTeraz}api_rest.php?module=RestApi&method=dataForUrl&page=${page_n}&url=${sectionUrl}`;
    return url;
  }

  update() {
    const props = this.props;
    const url = this.getUrl(props);

    const { dispatch } = props;
    if (!props.isLoading) {
      //FIXME
      // debugger;
      const cat = props && props.category ? props.category.name : null;
      const catUrl =
        props && props.category && props.category.url
          ? props.category.url
          : null;
      const themeClassName = catUrl ? "theme_" + catUrl : null;
      dispatch(headerSuffix(cat, catUrl));
      dispatch(themeClass(themeClassName));

      if (url) {
        dispatch(selectPage(url));
        dispatch(fetchPage(url));
      } else {
        log.warn(`empty url`);
      }
    }
  }

  render() {
    let component;

    if (this.props == null || this.props.isLoading) {
      setHttpStatus(this, 200);
      component = <Section {...this.props} />;
    } else if (this.props.type === "page") {
      setHttpStatus(this, 200);
      component = <StaticPage {...this.props} />;
    } else if (this.props.type === "section") {
      setHttpStatus(this, 200);
      component = <Section {...this.props} />;
    } else if (this.props.type === "tag") {
      setHttpStatus(this, 200);
      component = <Section {...this.props} />;
    } else if (this.props.type === "author_section") {
      setHttpStatus(this, 200);
      component = <AuthorSection {...this.props} />;
    } else {
      // setHttpStatus(this, 404)
      component = <NotFound />;
    }

    return (
      <div>
        {component}
        <LoaderIndex gaInfo={this.props.gaInfo} />
      </div>
    );
  }
}

const connector = connect(pageMapStateToProps);
type PropT =
  | ConnectedProps<typeof connector>
  | {
      type: "page" | "section" | "tag" | "author_section" | string;
      isLoading: boolean;
      gaInfo: GAInfo;
    };
export default connector(CatchAll);
