import React from "react";
// import SnpVyrocie75 from "../specialBanners/SnpVyrocie75";
// import NeznaVyrocie from "../specialBanners/NeznaVyrocie";
// import BannerImporelVote from "../specialBanners/ImporelVote";
// import BannerRokSlovenskehoDivadla from "../specialBanners/RokSlovenskehoDivadla";
// import BannerVolby2020 from "../specialBanners/Volby2020"
import PlosneCovidTestovanie from "../specialBanners/PlosneCovidTestovanie";

/**
 * Configured Header banner
 */
const defaultComponent = null;
const config = [
  // { from: "2019-07-10T00:00:00+02:00", to: "2019-07-11T00:00:00+02:00", component: SnpVyrocie75 },
  {
    from: "2020-10-23T00:00:00+02:00",
    to: "2020-11-15T00:00:00+02:00",
    component: PlosneCovidTestovanie,
  }, // { from: "2019-12-02T00:00:00+02:00", to: "2020-01-13T00:00:00+02:00", component: BannerImporelVote },
  // { from: "2019-12-02T00:00:00+02:00", to: "2020-02-04T00:00:00+02:00", component: BannerRokSlovenskehoDivadla },
  // { from: "2020-02-04T00:00:00+02:00", to: "2020-03-11T00:00:00+02:00", component: BannerVolby2020 }
];

const HeaderBannerConfig = () => {
  const now = new Date();
  const current = config.find(
    (c) =>
      (!c.from || new Date(c.from) <= now) && (!c.to || now < new Date(c.to))
  );

  if (current && current.component) {
    return React.createElement(current.component, {});
  } else {
    if (defaultComponent !== null) {
      return React.createElement(defaultComponent, {});
    } else {
      return null;
    }
  }
};

export default HeaderBannerConfig;
