import React, { Component } from "react";
import SectionBase from "~/components/SectionBase";
import Colule from "~/components/Colule";
import MediaListingItem from "~/components/SectionMediaListingItem";
import { getGaInfo } from "~/utility/gaInfo";

class AuthorSection extends Component {
  render() {
    const inArticles = this.props.articles || Array(12).fill({});
    const articles = [
      inArticles.slice(0, 4),
      inArticles.slice(4, 8),
      inArticles.slice(8),
    ];
    const inAside = this.props.aside || Array(5).fill({});
    const aside = [inAside.slice(0, 2), inAside.slice(2)];
    const topArticles = (
      <Colule
        sections={[]}
        aside={aside[0]}
        top_article={{
          url: null,
          image: this.props.author.image,
          title: this.props.author.name,
          annotation: this.props.author.description,
        }}
        noSectionHeaders={true}
        gaInfo={getGaInfo(this.props, "author")}
      />
    );
    //const topArticles = this.props.top_articles || null
    return (
      <SectionBase
        top_article={this.props.top_article}
        page={this.props.page}
        page_count={this.props.page_count}
        splits={this.props.splits}
        location={this.props.location}
        innerItems={[
          articles[0].map((v, i) => <MediaListingItem {...v} key={i} />),
          articles[1].map((v, i) => <MediaListingItem {...v} key={"a" + i} />),
          articles[2].map((v, i) => <MediaListingItem {...v} key={"b" + i} />),
        ]}
        aside={aside[1]}
        topArticles={topArticles}
      />
    );
  }
}

export default AuthorSection;
