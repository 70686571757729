import React from "react";
import { PhotoGalleryRow } from "~/components/photogallery/PhotoGalleryElements";
import { Link } from "~/components/Elements";
import { PhotoDayRowItem } from "~/components/photogallery/PhotoDayElements";
export const dateToMonthRepr = (date) => date.toISOString().slice(0, 7);
export const dateToMonthString = (date) =>
  date.toLocaleString("sk-sk", {
    month: "long",
    year: "numeric",
  });

const PhotoDayListing = (props) => {
  const mediaItems = (props.items || Array(20).fill({})).map((v, i) => (
    <PhotoDayRowItem {...v} key={i} />
  ));
  let now;

  if (!props.month || !props.month.length === 7) {
    now = new Date();
  } else {
    now = new Date(props.month);
  }

  // const now = new Date(props.match.params.month)
  const prevDate = new Date(now);
  const nextDate = new Date(now);

  if (now.getUTCMonth() === 11) {
    nextDate.setUTCFullYear(now.getUTCFullYear() + 1, 0, 1);
  } else {
    nextDate.setUTCFullYear(now.getUTCFullYear(), now.getUTCMonth() + 1, 1);
  }

  if (now.getUTCMonth() === 0) {
    prevDate.setUTCFullYear(now.getUTCFullYear() - 1, 11, 1);
  } else {
    prevDate.setUTCFullYear(now.getUTCFullYear(), now.getUTCMonth() - 1, 1);
  }

  const disableNext = new Date() < nextDate;
  return (
    <section className="section section--foto">
      <div className="container">
        <div className="row">
          <div className="col col-10 col-sm-12 col-push-1 col-push-sm-0">
            <div className="fotoHeader row">
              <div className="col col-3 col-sm-6 text-left">
                <Link
                  className="fotoHeader-control fotoPager"
                  to={`/fotografia-dna/${dateToMonthRepr(prevDate)}`}
                >
                  &lt; {dateToMonthString(prevDate)}
                </Link>
              </div>
              <div className="col col-3 col-sm-6 col-push-6 col-push-sm-0 text-right">
                <Link
                  className={
                    "fotoHeader-control fotoPager " +
                    (disableNext ? " is-disabled" : "")
                  }
                  to={`/fotografia-dna/${dateToMonthRepr(nextDate)}`}
                >
                  &gt; {dateToMonthString(nextDate)}
                </Link>
              </div>

              <div className="col col-6 col-sm-12 col-pull-3 col-pull-sm-0">
                <h2 className="fotoHeader-title text-center">Foto dňa</h2>
                <h3 className="fotoHeader-subtitle text-center">
                  {dateToMonthString(now)}
                </h3>
              </div>
            </div>

            <div className="module">
              <div className="moduleMain">
                <div className="row row--sm row--grid row--verticalGutter">
                  {mediaItems}
                </div>
              </div>
              <div className="moduleFooter row">
                <div className="col col-6 text-left">
                  <Link
                    className="fotoPager"
                    to={`/fotografia-dna/${dateToMonthRepr(prevDate)}`}
                  >
                    &lt; {dateToMonthString(prevDate)}
                  </Link>
                </div>
                <div className="col col-6 text-right">
                  <Link
                    className={
                      "fotoPager " + (disableNext ? " is-disabled" : "")
                    }
                    to={`/fotografia-dna/${dateToMonthRepr(nextDate)}`}
                  >
                    {dateToMonthString(nextDate)} &gt;
                  </Link>
                </div>
              </div>
            </div>
            {props.next_galleries ? (
              <PhotoGalleryRow
                title="Ďalšie fotogalérie"
                url="/fotodennik"
                link_text="Ďalšie fotogalérie"
                items={props.next_galleries || []}
                key="dalsie"
              />
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PhotoDayListing;
