import React from "react";

import { imgSprite } from "../config/images";
import { GAInfo, getGaInfo } from "../utility/gaInfo";
import { Link } from "./Elements";
import Icon from "./base/Icon";
import { PlaceholderImg } from "./placeholders/Image";

export default function LiveBroadcastsVideos(props: {
  items: {
    url: string;
    image: string;
    title: string;
  }[];
  gaInfo: GAInfo;
}) {
  console.log(props.items);
  const gaInfo = getGaInfo(props, "PriamePrenosyAVidea");
  const items = (props.items || Array(4).fill({}))
    .filter((_, i) => i < 4)
    .map((v, i) => <Item {...v} key={i} gaInfo={gaInfo} />);
  return (
    <div className="liveBroadcastsVideos">
      <div className="moduleHeader">
        <h3 className="moduleHeader-title  sm-r-mg">
          <Link to="/priame-prenosy-a-videa-tasr-tv" gaInfo={gaInfo}>
            Videá a&nbsp;prenosy TASR&nbsp;TV
          </Link>
        </h3>
      </div>
      <div className="moduleMain moduleMain--rightNow">
        <div className="rightNow rightNow--vertical">
          {items}
          <div className="rightNow-item">
            <div className="rightNow-main">
              <div className="rightNow-body"></div>
            </div>
          </div>
        </div>
        <div className="moduleFooter clearfix">
          <strong className="pull-right">
            <Link
              to="/priame-prenosy-a-videa-tasr-tv"
              className="link link--default link--withIcon"
              gaInfo={gaInfo}
            >
              Viac <Icon name="chevron-double-down" />
            </Link>
          </strong>
        </div>
      </div>
    </div>
  );
}

function Item(props: {
  url: string;
  image: string;
  gaInfo: GAInfo;
  title: string;
}) {
  return (
    <div className="moduleMain moduleMain--liveBroadcastsVideos">
      <div className="mediaListing row">
        <div className="mediaListing-item col col-12">
          <div className="media media--liveBroadcastsVideos">
            <Link to={props.url} className="media-img" gaInfo={props.gaInfo}>
              <button className="playBtn" type="button">
                <span className="playBtn-icon">
                  <svg className="icon">
                    <use xlinkHref={imgSprite + "#play"} />
                  </svg>
                </span>
              </button>
              {props.image ? (
                <img src={props.image} title={props.title} alt="" />
              ) : (
                <PlaceholderImg />
              )}
            </Link>
            <div className="media-content">
              <h4 className="media-title">
                <Link to={props.url} gaInfo={props.gaInfo}>
                  {props.title}
                </Link>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
