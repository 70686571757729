import App from "./App";
import React from "react";
import { hydrate } from "react-dom";
import { version } from "./utility/version";

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/firebase-messaging-sw.js')
      .then((registration) => {
        console.log('FireBase Service worker registered:', registration);
        return registration.scope;
      })
      .catch((error) => {
        console.error('Error registering service worker:', error);
        return error;
      });
  });
}

hydrate(<App />, document.getElementById("rcBody"));

console.log(`version ${version}`);

if (module.hot) {
  module.hot.accept();
}
