import React, { Component } from "react";
import MediaListingItem from "~/components/SectionMediaListingItem";
import { RightNowItem } from "~/components/pages/RightNow";
import Submenu from "~/components/Submenu";
import asideMapping from "~/utility/aside";
import Pagination from "~/components/Pagination";
import SectionHeader from "~/containers/SectionHeader";
import { getGaInfo } from "~/utility/gaInfo";
export default class Search extends Component {
  render() {
    const gaInfo = getGaInfo(this.props, "search");
    const inArticles = this.props.items || Array(12).fill({});
    const inAside = this.props.aside || Array(5).fill({});
    const sectionAside = inAside;
    const innerItems = inArticles.map((v, i) =>
      v.type === "r" ? (
        <RightNowItem {...v} key={`${i}`} gaInfo={gaInfo} />
      ) : (
        <MediaListingItem {...v} key={`${i}`} gaInfo={gaInfo} />
      )
    );
    const aside = (sectionAside || []).map(asideMapping);
    const pagination = (
      <Pagination
        page={this.props.page}
        page_count={this.props.page_count}
        pathname={this.props.location.pathname}
        search={this.props.location.search}
      />
    );
    return (
      <div>
        <section className="section">
          {this.props.submenu ? <Submenu submenu={this.props.submenu} /> : null}
          <SectionHeader
            className="container text-center sectionPageTitle"
            wrappingComponent="h1"
          />
          {pagination}
          <div className="container border-top border-bottom">
            <div className="l-columns clearfix">
              <div className="l-main pull-left">
                <div className="module">
                  <div className="moduleMain">
                    <div className="mediaListing clearfix">{innerItems}</div>
                  </div>
                </div>
              </div>

              <aside className="l-sidebar pull-right">
                {aside.slice(0, 1)}
              </aside>
            </div>
          </div>
          {pagination}
        </section>
      </div>
    );
  }
}
