import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "~/components/Elements";
import { GAInfoProp } from "~/utility/gaInfo";

class SectionHeaderContainer extends Component<SectionHeaderProps> {
  render() {
    const WrappingComponent = this.props.wrappingComponent
      ? this.props.wrappingComponent
      : "h2";
    return (
      <WrappingComponent className={this.props.className}>
        <Link
          to={
            this.props.headerSuffixUrl ? "/" + this.props.headerSuffixUrl : "/"
          }
          gaInfo={this.props.gaInfo}
        >
          {this.props.prefix}
          {this.props.headerSuffix}
        </Link>
      </WrappingComponent>
    );
  }
}

const mapStateToProps = (state) => {
  const { header } = state;
  return { ...header };
};

const connector = connect(mapStateToProps);
type SectionHeaderProps = typeof connector & {
  wrappingComponent: Component;
  prefix: string;
  headerSuffix: string;
  className: string;
  headerSuffixUrl?: string;
} & GAInfoProp;

export default connector(SectionHeaderContainer);
