import React, { PureComponent } from "react";
//import {Link} from 'components/Elements'
import PropTypes from "prop-types";
export default class FlashNews extends PureComponent {
  static propTypes = {
    flash_news: PropTypes.shape({
      url: PropTypes.string,
      title: PropTypes.string,
    }),
  };

  render() {
    const flashNews = this.props.flash_news
      ? this.props.flash_news
      : {
          url: "https://teraz.sk",
          title: "TERAZ",
        };

    if (flashNews) {
      return (
        <div className="hotTopics hotTopics--flashNews">
          <div className="container">
            {/* <span className="hotTopics-heading">Mimoriadna správa:</span> */}
            <ul className="hotTopics-nav list-reset">
              <li>
                <strong>
                  <a href={flashNews.url}>{flashNews.title}</a>
                </strong>
              </li>
            </ul>
          </div>
        </div>
      );
    }

    return null;
  }
}
