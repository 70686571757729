export const dowName = {
  1: "Pondelok",
  2: "Utorok",
  3: "Streda",
  4: "Štvrtok",
  5: "Piatok",
  6: "Sobota",
  0: "Nedela",
};
export const monthName = {
  0: "január",
  1: "február",
  2: "marec",
  3: "apríl",
  4: "máj",
  5: "jún",
  6: "júl",
  7: "august",
  8: "september",
  9: "október",
  10: "november",
  11: "december",
};
