import React, { Component } from "react";
import { apiUrlTeraz } from "~/utility/api";
import { connect } from "react-redux";
import { fetchWagon } from "~/store/actions";
import { wagonMapStateToProps } from "~/store/mappers";
import Wagon from "~/components/wagons/Wagon";

class WagonNews extends Component {
  constructor(props) {
    super(props);
    const tipsPage = this.props.tipsPage || 0;
    this.url = `${apiUrlTeraz}api_rest.php?module=RestApi&method=wagonSections&sections=1,2,5,6&type=spravy&tipsPage=${tipsPage}`;
  }

  UNSAFE_componentWillMount() {
    const { dispatch } = this.props;
    dispatch(fetchWagon(this.url, this.props.required));
  }

  render() {
    const coluleTitle = "Správy";
    const coluleHeaders = [
      /*
      {url: '/slovensko', name: 'Slovensko'},
      {url: '/zahranicie', name: 'Zahraničie'},
      {url: '/ekonomika', name: 'Ekonomika'},
      {url: '/regiony', name: 'Regióny'},
      */
    ];
    return (
      <Wagon
        name={this.props.name}
        {...this.props[this.url]}
        coluleTitle={coluleTitle}
        coluleUrl={"/spravy"}
        coluleHeaders={coluleHeaders}
        asideHeaderSuffix="zo Správ"
        gaInfo={this.props.gaInfo}
      />
    );
  }
}

export default connect(wagonMapStateToProps)(WagonNews);
