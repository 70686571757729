import React, { useState, useEffect } from "react";
import asideMapping from "../../utility/aside";
import DataTable from 'react-data-table-component';
import LoaderIndex from "~/containers/LoaderIndex";
import TableFilters from "../elections/TableFilters";
import { ResponsiveBar } from '@nivo/bar';
// import dataResults from './prez2024_results_emp.json';
import dataLegendFile from './volby2024ep_legend';

const Eurovolby2024Page = (props) => {
    const graphData = props.dataGraph;
    // const graphLegend = props.dataLegend;
  
    const [dataResults, setDataResults] = useState({}); 
    const [tempPage, setTempPage] = useState(false);

    const results_data = props.dataResults || [];
    const data_legend = dataLegendFile || [];
    // const data_legend = props.dataLegend || {};
    const parties = props.dataLegend?.parties || {};

  
    const colorsArr = ["hsl(0, 90.86%, 65.69%)", 
    "hsla(4, 60%, 40%)", 
    "hsl(15.5, 60%, 43.92%)", 
    "hsl(20.53, 60%, 49.8%)", 
    "hsl(30.72, 60%, 48.24%)", 
    "hsl(40.15, 60%, 59.22%)", 
    "hsl(50.73, 60%, 64.9%)", 
    "hsl(60, 60%, 60.69%)", 
    "hsl(70.16, 60%, 60.75%)",
    "hsl(85.27, 60%, 71.61%)", 
    "hsl(100.5, 60%, 53.92%)", 
    "hsl(120, 60%, 65.69%)", 
    "hsl(130.16, 60%, 52.75%)", 
    "hsl(171.5, 60%, 63.92%)", 
    "hsl(220.53, 60%, 59.8%)", 
    "hsl(200.72, 60%, 58.24%)", 
    "hsl(240.15, 60%, 69.22%)", 
    "hsl(260.73, 60%, 84.9%)", 
    "hsl(290, 60%, 60.69%)", 
    "hsl(270.16, 60%, 55.75%)", 
    "hsl(150.27, 60%, 60.61%)", 
    "hsl(121.5, 60%, 60.92%)", 
    "hsl(72.16, 60%, 60.75%)", 
    "hsl(111.27, 53%, 81.61%)", 
    "hsl(1.5, 60%, 60.92%)"];
    
//   const results_votes_total_segmented = props.dataResults.votes_total_segmented || [];
    const results_votes_total_segmented = props.dataResults?.votes_total_segmented || [];
    const elected = props.dataResults?.elected || [];
    const results_votes_by_party_segmented = props.dataResults?.votes_by_party_segmented || [];
    const results_candidate_votes_by_party_summary = props.dataResults?.candidate_votes_by_party_summary || [];
    const results_candidate_votes_by_party_segmented = props.dataResults?.candidate_votes_by_party_segmented || [];

    const participation_ratio = props.dataResults?.summary_sr?.participation_ratio || 0;
    const voters_processed = props.dataResults?.summary_sr?.voters_processed || 0;
    const voters_allowed = props.dataResults?.summary_sr?.voters_allowed || 0;
    const voters_from_abroad_ratio = props.dataResults?.summary_sr?.voters_from_abroad_ratio || 0;
    const voters_from_abroad_count = props.dataResults?.summary_sr?.voters_from_abroad_count || 0;
    const votes = props.dataResults?.summary_sr?.votes || 0;
    const voters_voted_ratio = props.dataResults?.summary_sr?.voters_voted_ratio || 0;
    const voters_voted_count = props.dataResults?.summary_sr?.voters_voted_count || 0;
    const precincts_processed_count = props.dataResults?.summary_sr?.precincts_processed_count || 0;
    const precincts_total = props.dataResults?.summary_sr?.precincts_total || 0;
    const precincts_processed_ratio = props.dataResults?.summary_sr?.precincts_processed_ratio || '0';


    const [dataSummaryTable, setDataSummaryTable] = useState([]); 
    const [dataElected, setDataElected] = useState([]); 
    const [summaryTableClass, setSummaryTableClass] = useState('all'); 
    const [summaryPartiesTableClass, setSummaryPartiesTableClass] = useState('all'); 
    const [selectedParty, setSelectedParty] = useState(null); 
    const [dataPriorityVotingTable, setDataPriorityVotingTable] = useState([]); 

    const [dataSummaryPartiesTable, setDataSummaryPartiesTable] = useState([]); 
    const [dataPartiesGraph, setDataPartiesGraph] = useState([]); 
    const [dataPartiesGraphReversed, setDataPartiesGraphReversed] = useState([]); 
    const [columnsPartiesTable, setColumnsPartiesTable] = useState([]); 
    const [dataCountAndPercTable02, setDataCountAndPercTable02] = useState([]); 


  function getDistrict(code){    
    const x = data_legend.districts[code];
    return x ? x.name : '';
  }

  function getWard(code){    
    const x = data_legend.wards[code];
    return x ? x.name : '';
  }

  function getRegion(code){    
    const x = data_legend.regions[code];
    return x ? x.name : '';
  }
  
  function getPartyName(code){    
    const x = data_legend.parties.find(function (el) {
        return el.code == code;
    });
    return x ? x.short_name : '';
  }  
  function getCandidateName(code, partyCode){    
    const x = data_legend.candidates.find(function (el) {
        return el.code == code && el.party_code == partyCode;
    });
    return x ? x.first_name  : '';
  }  
  function getCandidateSurname(code, partyCode){    
    const x = data_legend.candidates.find(function (el) {
        return el.code == code && el.party_code == partyCode;
    });
    return x ? x.last_name  : '';
  }  

  const paginationComponentOptions = {
    rowsPerPageText: 'Výsledkov na stranu',
    rangeSeparatorText: 'z',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Zobraziť všetko',
};



  const columnsSummaryResultsTable = [
    {
        name: 'Okres',
        selector: row => row.district,
        sortable: true,
    },
    {
        name: 'Obvod',
        selector: row => row.ward,
        sortable: true,
    },
    {
        name: 'Kraj',
        selector: row => row.region,
        sortable: true,
    },
    {
        name: 'Počet okrskov',
        selector: row => row.districts,
        sortable: true,
    },
    {
        name: 'Počet zapísaných voličov',
        selector: row => row.votersCount,
        sortable: true,
    },
    {
        name: 'Počet zúčastnených voličov',
        selector: row => row.involvedVotersCount,
        sortable: true,
    },
    {
        name: 'Účasť voličov v %',
        selector: row => row.votersAttendance,
        sortable: true,
    },
    // {
    //     name: 'Počet odovzdaných obálok osobne',
    //     selector: row => row.envelopesCount,
    //     sortable: true,
    // },
    // {
    //     name: 'Podiel odovzdaných obálok osobne v %',
    //     selector: row => row.envelopesCountPerc,
    //     sortable: true,
    // },
    {
        name: 'Počet platných hlasov spolu',
        selector: row => row.votesCount,
        sortable: true,
    },
    {
        name: 'Podiel platných hlasov spolu v %:',
        selector: row => row.votesCountPerc,
        sortable: true,
    },
    ];
      const columnsTable01 = [
    {
        name: 'Číslo politického subjektu',
        selector: row => row.partyId,
        sortable: true,
    },
    {
        name: 'Názov politického subjektu',
        selector: row => row.partyName,
        sortable: true,
    },
    {
        name: 'Počet platných hlasov',
        selector: row => row.votesCount,
        sortable: true,
    },
    {
        name: 'Podiel platných hlasov v %',
        selector: row => row.votesPerc,
        sortable: true,
    },
    // {
    //     name: 'Počet získaných mandátov',
    //     selector: row => row.mandatesCount,
    //     sortable: true,
    // }
];

const columnsPartiesTableBase = [
    {
        name: 'Okres',
        selector: row => row.district,
        sortable: true,
    },
    {
        name: 'Obvod',
        selector: row => row.ward,
        sortable: true,
    },
    {
        name: 'Kraj',
        selector: row => row.region,
        sortable: true,
    },
    {
        name: 'Počet platných hlasov spolu',
        selector: row => row.votesCount,
        sortable: true,
    },
];


const columnsTablePriorityVoting = [
    {
        name: 'Poradie po zohľadnení prednostného hlasovania',
        selector: row => row.orderPriority,
        sortable: true,
    },
    {
        name: 'Poradie na hlasovacom lístku',
        selector: row => row.orderOnPaper,
        sortable: true,
    },
    {
        name: 'Meno',
        selector: row => row.name,
        sortable: true,
    },
    {
        name: 'Priezvisko',
        selector: row => row.surname,
        sortable: true,
    },
    {
        name: 'Počet platných prednostných hlasov',
        selector: row => row.votesCount,
        sortable: true,
    },
    {
        name: 'Kandidát',
        selector: row => row.elected,
        sortable: true,
    },
];

const columnsTableMandates = [    
    {
        name: 'Meno',
        selector: row => row.name,
        sortable: true,
    },
    {
        name: 'Priezvisko',
        selector: row => row.surname,
        sortable: true,
    },
    {
        name: 'Poradie na hlasovacom lístku',
        selector: row => row.orderOnPaper,
        sortable: true,
    },
    {
        name: 'Politický subjekt',
        selector: row => row.partyName,
        sortable: true,
    },
];

const [summaryTableRegions, setSummaryTableRegions] = useState([]); 
const [summaryTableWards, setSummaryTableWards] = useState([]); 
const [summaryTableDistricts, setSummaryTableDistricts] = useState([]); 


var dataSummaryTableRegions = [];
var dataSummaryTableWards = [];
var dataSummaryTableDistricts = [];

    
var dataSummaryPartiesTableRegions = [];
var dataSummaryPartiesTableWards = [];
var dataSummaryPartiesTableDistricts = [];

const [summaryPartiesTableRegions, setSummaryPartiesTableRegions] = useState([]); 
const [summaryPartiesTableWards, setSummaryPartiesTableWards] = useState([]); 
const [summaryPartiesTableDistricts, setSummaryPartiesTableDistricts] = useState([]); 


var dataPartiesTableRegions = [];
var dataPartiesTableWards = [];
var dataPartiesTableDistricts = [];

var dataPriorityVotingTableAll = [];
var dataElectedAll = [];
const [priorityVotingTableAll, setPriorityVotingTableAll] = useState([]); 

const [partiesTableRegions, setPartiesTableRegions] = useState([]); 
const [partiesTableWards, setPartiesTableWards] = useState([]); 
const [partiesTableDistricts, setPartiesTableDistricts] = useState([]); 


function mapData(){
    const dataSummaryTableRAW = results_votes_total_segmented.map((d) => {
        const dRow = {
            district: getDistrict(d.district_code),
            ward: getWard(d.ward_code),
            region: d.region_code == '' ? 'Spolu' : getRegion(d.region_code),
            districts: d.precincts_count,
            votersCount: d.voters_allowed,
            involvedVotersCount: d.voters_processed,
            votersAttendance: d.participation_ratio,
            envelopesCount: 423503,
            envelopesCountPerc: 98.09,
            votesCount: d.votes_count,
            votesCountPerc: d.votes_ratio,
            regionCode: d.region_code,
            wardCode: d.ward_code,
            districtCode: d.district_code,
        }
        if(d.ward_code == '' && d.district_code == ''){
            dataSummaryTableRegions.push(dRow);
        }else if(d.ward_code != '' && d.district_code == ''){
            dataSummaryTableWards.push(dRow);
        }else{
            dataSummaryTableDistricts.push(dRow);
        }    
        });

        setSummaryTableRegions(dataSummaryTableRegions);
        setSummaryTableWards(dataSummaryTableWards);
        setSummaryTableDistricts(dataSummaryTableDistricts);


        const dataCountAndPercTableRAW = results_votes_by_party_segmented.map((d) => {
            const dRow = {
                partyId: d.party_code,
                partyName: getPartyName(d.party_code),
                votesCount: d.votes_count,
                votesPerc: d.votes_ratio,
                mandatesCount: 0,
                district: getDistrict(d.district_code),
                ward: getWard(d.ward_code),
                region: getRegion(d.region_code),
                regionCode: d.region_code,
                wardCode: d.ward_code,
                districtCode: d.district_code,
            }
            if(d.ward_code == '' && d.district_code == '' && d.region_code == ''){
                dataSummaryPartiesTableRegions.push(dRow);
            }else if(d.ward_code != '' && d.district_code == ''){
                dataSummaryPartiesTableWards.push(dRow);
            }else{
                dataSummaryPartiesTableDistricts.push(dRow);
            } 
          });

          setSummaryPartiesTableRegions(dataSummaryPartiesTableRegions)
          setSummaryPartiesTableWards(dataSummaryPartiesTableWards)
          setSummaryPartiesTableDistricts(dataSummaryPartiesTableDistricts)


        //   console.log("dataSummaryPartiesTableRegions: ", dataSummaryPartiesTableRegions);
          
          const dataPartiesTableRegionsRAW = dataSummaryTableRegions.map((d) => {
            var dRow = {
              region: d.region,
              district: d.district,
              ward: d.ward,
              votesCount: d.votesCount,
              districtCode: d.districtCode,
              wardCode: d.wardCode,
              regionCode: d.regionCode,
            }
          
            results_votes_by_party_segmented.map((p) => {
              if(p.ward_code == dRow.wardCode && p.district_code == dRow.districtCode && p.region_code == dRow.regionCode){
                  dRow[p.party_code] = p.votes_count;
              }
            });
          
            if(d.regionCode != '' && d.wardCode == '' && d.districtCode == ''){
              dataPartiesTableRegions.push(dRow);
            }
          });

          setPartiesTableRegions(dataPartiesTableRegions);
          
          const dataPartiesTableWardsRAW = dataSummaryTableWards.map((d) => {
            var dRow = {
              region: d.region,
              district: d.district,
              ward: d.ward,
              votesCount: d.votesCount,
              districtCode: d.districtCode,
              wardCode: d.wardCode,
              regionCode: d.regionCode,
            }
          
            results_votes_by_party_segmented.map((p) => {
              if(p.ward_code == dRow.wardCode && p.district_code == dRow.districtCode && p.region_code == dRow.regionCode){
                  dRow[p.party_code] = p.votes_count;
              }
            });
          
            if(d.wardCode != '' && d.districtCode == ''){
              dataPartiesTableWards.push(dRow);
            }
          
          });

          setPartiesTableWards(dataPartiesTableWards);
          
          const dataPartiesTableDistrictsRAW = dataSummaryTableDistricts.map((d) => {
            var dRow = {
              region: d.region,
              district: d.district,
              ward: d.ward,
              votesCount: d.votesCount,
              districtCode: d.districtCode,
              wardCode: d.wardCode,
              regionCode: d.regionCode,
            }
          
            results_votes_by_party_segmented.map((p) => {
              if(p.ward_code == dRow.wardCode && p.district_code == dRow.districtCode && p.region_code == dRow.regionCode){
                  dRow[p.party_code] = p.votes_count;
              }
            });
          
            if(d.regionCode != '' && d.wardCode != '' && d.districtCode != ''){
              dataPartiesTableDistricts.push(dRow);
            }
          });

          setPartiesTableDistricts(dataPartiesTableDistricts);

          const dataPriorityVotingTableRAW = results_candidate_votes_by_party_segmented.map((d) => {
            const dRow = {
                orderPriority: d.rank_after_count,
                orderOnPaper: d.rank_on_voting_sheet,
                name: getCandidateName(d.code, d.party_code),
                surname: getCandidateSurname(d.code, d.party_code),
                votesCount: d.votes_count,
                partyId: d.party_code,
                elected: "Náhradník"
            }
            dataPriorityVotingTableAll.push(dRow);
        });
        setPriorityVotingTableAll(dataPriorityVotingTableAll);

        const dataElectedRAW = elected.map((d) => {
            const dRow = {
                orderOnPaper: d.rank_on_voting_sheet,
                name: d.first_name,
                surname: d.last_name,
                votesCount: d.votes_count,
                partyId: d.party_code,
                partyName: d.party_name,
            }
            dataElectedAll.push(dRow);
        });
        setDataElected(dataElectedAll);
}

useEffect(() => {   
    
    if(props.dataResults && Object.keys(props.dataResults).length) {
        
        mapData();

        setDataSummaryTable(dataSummaryTableRegions);    
        setDataSummaryPartiesTable(dataSummaryPartiesTableRegions);    
        setDataCountAndPercTable02(dataPartiesTableRegions);

        dataSummaryPartiesTableRegions.filter((a, i) => dataSummaryPartiesTableRegions.findIndex((s) => a.country === s.country) === i);

        let sortedParties = dataSummaryPartiesTableRegions.sort(
            (p1, p2) => (p1.votesCount < p2.votesCount) ? 1 : (p1.votesCount > p2.votesCount) ? -1 : 0);
        // let sortedParties = dataSummaryPartiesTableRegions.sort(
        //     (p1, p2) => (p1.votesPerc < p2.votesPerc) ? 1 : (p1.votesPerc > p2.votesPerc) ? -1 : 0);

        var top10Parties = [];

        var top10PartiesTemp = [
            {
                "country": 'PATRIOT',
                "Podiel hlasov": 1.89,
                "Podiel hlasovColor": colorsArr[0],
            },
            {
                "country": 'MySlovensko',
                "Podiel hlasov": 1.15,
                "Podiel hlasovColor": colorsArr[1],
            },
            {
                "country": 'Demokrati',
                "Podiel hlasov": 3.9,
                "Podiel hlasovColor": colorsArr[2],
            },
            {
                "country": 'SZÖVETSÉG - ALIANCIA',
                "Podiel hlasov": 3.04,
                "Podiel hlasovColor": colorsArr[3],
            },
            {
                "country": 'SDKÚ - DS',
                "Podiel hlasov": 2.45,
                "Podiel hlasovColor": colorsArr[4],
            },
            {
                "country": 'SOSK',
                "Podiel hlasov": 0.91,
                "Podiel hlasovColor": colorsArr[5],
            },
            {
                "country": 'Piráti',
                "Podiel hlasov": 0.81,
                "Podiel hlasovColor": colorsArr[6],
            },
            {
                "country": 'SOS',
                "Podiel hlasov": 0.35,
                "Podiel hlasovColor": colorsArr[7],
            },
            {
                "country": 'Socialisti',
                "Podiel hlasov": 2.14,
                "Podiel hlasovColor": colorsArr[8],
            },
            {
                "country": 'SNS',
                "Podiel hlasov": 7.2,
                "Podiel hlasovColor": colorsArr[9],
            },
            {
                "country": 'REPUBLIKA',
                "Podiel hlasov": 4.31,
                "Podiel hlasovColor": colorsArr[10],
            },
            {
                "country": 'SĽS',
                "Podiel hlasov": 0.2,
                "Podiel hlasovColor": colorsArr[11],
            },
            {
                "country": 'SRDCE - SNJ',
                "Podiel hlasov": 0.12,
                "Podiel hlasovColor": colorsArr[12],
            },
            {
                "country": 'ĽSNS',
                "Podiel hlasov": 3.2,
                "Podiel hlasovColor": colorsArr[13],
            },
            {
                "country": 'ZR',
                "Podiel hlasov": 0.07,
                "Podiel hlasovColor": colorsArr[14],
            },
            {
                "country": 'KSS',
                "Podiel hlasov": 0.11,
                "Podiel hlasovColor": colorsArr[15],
            },
            {
                "country": 'KÚ',
                "Podiel hlasov": 0.09,
                "Podiel hlasovColor": colorsArr[16],
            },
            {
                "country": 'SMER - SD',
                "Podiel hlasov": 16.49,
                "Podiel hlasovColor": colorsArr[17],
            },
            {
                "country": 'KDH',
                "Podiel hlasov": 8.91,
                "Podiel hlasovColor": colorsArr[18],
            },
            {
                "country": 'SaS',
                "Podiel hlasov": 9.1,
                "Podiel hlasovColor": colorsArr[19],
            },
            {
                "country": 'PS',
                "Podiel hlasov": 21.2,
                "Podiel hlasovColor": colorsArr[20],
            },
            {
                "country": 'SLOVENSKO, ZA ĽUDÍ',
                "Podiel hlasov": 8.1,
                "Podiel hlasovColor": colorsArr[21],
            },
            {
                "country": 'HLAS - SD',
                "Podiel hlasov": 11.09,
                "Podiel hlasovColor": colorsArr[22],
            },
            {
                "country": 'Volt SK',
                "Podiel hlasov": 0.05,
                "Podiel hlasovColor": colorsArr[24],
            },
        ]

        let sortedPartiesTemp = top10PartiesTemp.sort(
            (p1, p2) => (p1['Podiel hlasov'] < p2['Podiel hlasov']) ? 1 : (p1['Podiel hlasov'] > p2['Podiel hlasov']) ? -1 : 0);


            console.log('sortedParties: ', sortedParties);


        const top10PartiesRAW = sortedParties.map((d, index) => {
            const dRow = {
                "country": d.partyName,
                "Podiel hlasov": parseFloat(d.votesPerc.replace(',','.')),
                "Podiel hlasovColor": colorsArr[index],
            }
            top10Parties.push(dRow);

        });    
        setDataPartiesGraph(top10Parties.reverse());
        // setDataPartiesGraph(sortedPartiesTemp.reverse());
        var columnsPartiesTableNext = [];
        Object.values(parties).map((option, index) => {
            var row = {
                name: option.name,
                selector: row => row[option.code],
                sortable: true,
            };
            columnsPartiesTableNext.push(row);
        });

        columnsPartiesTableBase.push(...columnsPartiesTableNext);
        setColumnsPartiesTable(columnsPartiesTableBase);  

    }
          
  }, [props.dataResults]); 


  const getFilterPropsSummary = (data) => {   
    if(data?.selecting === 'region'){
        const filteredSummaryData = summaryTableWards.filter(obj => {
            return obj.regionCode === data?.code;
        });
        setDataSummaryTable(filteredSummaryData);
        setSummaryTableClass('regions');        
    }else if(data?.selecting === 'ward'){
        const filteredSummaryData = summaryTableDistricts.filter(obj => {
            return obj.wardCode === data?.code;
        });
        setDataSummaryTable(filteredSummaryData);        
        setSummaryTableClass('wards');
    }else if(data?.selecting === 'district'){
        const filteredSummaryData = summaryTableDistricts.filter(obj => {
            return obj.districtCode === data?.code;
        });
        setDataSummaryTable(filteredSummaryData);        
        setSummaryTableClass('districts');
    }else if(data?.selecting === 'all'){
        setDataSummaryTable(summaryTableRegions);
        setSummaryTableClass('all');        
    }
  }

  const getFilterPropsSummaryParties = (data) => {    
    if(data?.selecting === 'region'){
        const filteredSummaryData = summaryPartiesTableWards.filter(obj => {
            return obj.regionCode === data?.code;
        });
        setDataSummaryPartiesTable(filteredSummaryData);       
    }else if(data?.selecting === 'ward'){
        const filteredSummaryData = summaryPartiesTableDistricts.filter(obj => {
            return obj.wardCode === data?.code;
        });
        setDataSummaryPartiesTable(filteredSummaryData);        
    }else if(data?.selecting === 'district'){
        const filteredSummaryData = summaryPartiesTableDistricts.filter(obj => {
            return obj.districtCode === data?.code;
        });
        setDataSummaryPartiesTable(filteredSummaryData);        
    }else if(data?.selecting === 'all'){
        setDataSummaryPartiesTable(summaryPartiesTableRegions);  
    }
  }


  const getFilterPropsParties = (data) => {    
    if(data?.selecting === 'region'){
        const filteredSummaryData = summaryPartiesTableWards.filter(obj => {
            return obj.regionCode === data?.code;
        });
        setDataSummaryPartiesTable(filteredSummaryData);       
    }else if(data?.selecting === 'ward'){
        const filteredSummaryData = summaryPartiesTableDistricts.filter(obj => {
            return obj.wardCode === data?.code;
        });
        setDataSummaryPartiesTable(filteredSummaryData);        
    }else if(data?.selecting === 'district'){
        const filteredSummaryData = summaryPartiesTableDistricts.filter(obj => {
            return obj.districtCode === data?.code;
        });
        setDataSummaryPartiesTable(filteredSummaryData);        
    }
  }

  const getFilterPropsSegmentedParties = (data) => {    
    if(data?.selecting === 'region'){
        const filteredSummaryData = partiesTableWards.filter(obj => {
            return obj.regionCode === data?.code;
        });
        setDataCountAndPercTable02(filteredSummaryData); 
        setSummaryPartiesTableClass('regions');
    }else if(data?.selecting === 'ward'){
        const filteredSummaryData = partiesTableDistricts.filter(obj => {
            return obj.wardCode === data?.code;
        });
        setDataCountAndPercTable02(filteredSummaryData);   
        setSummaryPartiesTableClass('wards');     
    }else if(data?.selecting === 'district'){
        const filteredSummaryData = partiesTableDistricts.filter(obj => {
            return obj.districtCode === data?.code;
        });
        setDataCountAndPercTable02(filteredSummaryData);    
        setSummaryPartiesTableClass('districts');    
    }else if(data?.selecting === 'all'){
        setDataCountAndPercTable02(partiesTableRegions);   
        setSummaryPartiesTableClass('all');     
    }
  }

  const HorizontalTick = ({ textAnchor, textBaseline, value, x, y }) => {
    const MAX_LINE_LENGTH = 16;
    const MAX_LINES = 2;
    const LENGTH_OF_ELLIPSIS = 3;
    const TRIM_LENGTH = MAX_LINE_LENGTH * MAX_LINES - LENGTH_OF_ELLIPSIS;
    const trimWordsOverLength = new RegExp(`^(.{${TRIM_LENGTH}}[^\\w]*).*`);
    const groupWordsByLength = new RegExp(
      `([^\\s].{0,${MAX_LINE_LENGTH}}(?=[\\s\\W]|$))`,
      'gm',
    );
        const splitValues = value?.replace(trimWordsOverLength, '$1...')
        .match(groupWordsByLength)
        .slice(0, 2)
        .map((val, i) => (
            <tspan
            key={val}
            dy={12 * i}
            x={-10}
            y={18}
            style={{ fontFamily: 'sans-serif', fontSize: '11px', paddingTop: '20px', marginTop: '20px' }}
            >
            {val}
            </tspan>
        ));
        
        return (
        <g transform={`translate(${x},${y})`}>
            <text alignmentBaseline={textBaseline} textAnchor={textAnchor}>
            {splitValues}
            </text>
        </g>
        );
  };

    

  const handleParties = (code) => {    
    const filteredPartyData = priorityVotingTableAll.filter(obj => {
        return obj.partyId == code;
    });

    setDataPriorityVotingTable(filteredPartyData);
    setSelectedParty(code);
  }

  function getPartyVotes(code){    
    const x = results_candidate_votes_by_party_summary.find(function (el) {
        return el.party_code == code;
    });
    return x ? x.votes : '';
  }  
  

  

  const data = [
    {
        "zratanost_hlasov": ' ',
        "Spočítané hlasy": parseFloat(precincts_processed_ratio.replace(',','.')),
        "Spočítané hlasyColor": "hsl(213, 87%, 34%)",
        "Nespočítané hlasy": (100 - parseFloat(precincts_processed_ratio.replace(',','.'))),
        "Nespočítané hlasyColor": "#cfcfcf"
    },
  ];

  if(tempPage){
    return(
        <div>
            <div className="container static-page elections">
                <h1>EUROVOĽBY 2024</h1>  
                <div className="chart-row temp-text">
                    <p>Na tejto stránke budú v nedeľu po 23:00 zverejnené výsledky volieb do Európskeho parlamentu.</p>
                </div>             
            </div>
        </div>
    )
  }

  return (
    <div>
        <div className="container static-page elections">                
            <h1>EUROVOĽBY 2024</h1>            


            <h2>Oficiálne výsledky za SR</h2>
            <div className="elections-section">                

                {results_votes_total_segmented.length != 0 && 
                <>
                <div className="chart-box parties eu desktop">
                {dataPartiesGraph &&
                <ResponsiveBar
                data={dataPartiesGraph}
                keys={[
                    'Podiel hlasov',
                ]}
                colors={({id, data}) => data[`${id}Color`]}
                indexBy="country"
                margin={{ top: 10, right: 0, bottom: 30, left: 140 }}
                padding={0.2}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                layout="horizontal"
                valueFormat={function(e){return (e) + " %"}}
                defs={[
                    {
                        id: 'dots',
                        type: 'patternDots',
                        background: 'inherit',
                        color: '#38bcb2',
                        size: 10,
                        padding: 3,
                        stagger: true
                    },
                    {
                        id: 'lines',
                        type: 'patternLines',
                        background: 'inherit',
                        color: '#eed312',
                        rotation: -45,
                        lineWidth: 6,
                        spacing: 10
                    }
                ]}
                fill={[
                    {
                        match: {
                            id: 'fries'
                        },
                        id: 'dots'
                    },
                    {
                        match: {
                            id: 'sandwich'
                        },
                        id: 'lines'
                    }
                ]}
                borderColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            1.6
                        ]
                    ]
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 3,
                    tickPadding: 5,
                    tickRotation: 0,
                    // tickSize: 8,
                    // tickPadding: 4,
                    // renderTick: HorizontalTick,
                }}
                axisLeft={{
                    tickSize: 0,
                    tickPadding: 7,
                    tickRotation: 0,
                }}
                labelSkipWidth={14}
                labelSkipHeight={14}
                labelTextColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            2
                        ]
                    ]
                }}
                role="application"
                ariaLabel="Podiel hlasov politickych stran"
            />
                }
                </div>

<div className="chart-box eu parties mobile">
    
{dataPartiesGraph &&
<ResponsiveBar
    data={dataPartiesGraph}
    keys={[
        'Podiel hlasov',
    ]}
    colors={({id, data}) => data[`${id}Color`]}
    indexBy="country"
    margin={{ top: 50, right: 0, bottom: 50, left: 130 }}
    padding={0.3}
    valueScale={{ type: 'linear' }}
    indexScale={{ type: 'band', round: true }}
    layout="horizontal"
    valueFormat={function(e){return (e) + " %"}}
    defs={[
        {
            id: 'dots',
            type: 'patternDots',
            background: 'inherit',
            color: '#38bcb2',
            size: 4,
            padding: 1,
            stagger: true
        },
        {
            id: 'lines',
            type: 'patternLines',
            background: 'inherit',
            color: '#eed312',
            rotation: -45,
            lineWidth: 6,
            spacing: 10
        }
    ]}
    fill={[
        {
            match: {
                id: 'fries'
            },
            id: 'dots'
        },
        {
            match: {
                id: 'sandwich'
            },
            id: 'lines'
        }
    ]}
    borderColor={{
        from: 'color',
        modifiers: [
            [
                'darker',
                1.6
            ]
        ]
    }}
    axisTop={null}
    axisRight={null}
    axisBottom={{
        tickSize: 2,
        tickPadding: 5,
        tickRotation: 0,
        // tickSize: 8,
        // tickPadding: 4,
        // renderTick: HorizontalTick,
    }}
    axisLeft={{
        tickSize: 1,
        tickPadding: 1,
        tickRotation: 0,
    }}
    labelSkipWidth={12}
    labelSkipHeight={12}
    labelTextColor={{
        from: 'color',
        modifiers: [
            [
                'darker',
                2
            ]
        ]
    }}
    role="application"
    ariaLabel="Podiel hlasov politickych stran"
/>
}
</div>
</>
                }
                

                <div className="valued-stats-wrapper">
                    <div className="valued-stats-col border red text-right">
                        <div className="valued-stats-item">
                            <div className="val">{participation_ratio} %</div>
                            <div className="label">účasť</div>
                        </div>
                        {/* <div className="valued-stats-item">
                            <div className="val">{voters_from_abroad_ratio} %</div>
                            <div className="label">voličov z cudziny</div>
                        </div> */}
                        <div className="valued-stats-item">
                            <div className="val">{voters_voted_ratio} %</div>
                            <div className="label">voličov, ktorí odovzdali obálku</div>
                        </div>
                    </div>

                    <div className="valued-stats-col red">
                        <div className="valued-stats-item">
                            <div className="val">{voters_processed}</div>
                            <div className="label">zúčastnených</div>
                        </div>
                        {/* <div className="valued-stats-item">
                            <div className="val">{voters_from_abroad_count}</div>
                            <div className="label">voličov z cudziny</div>
                        </div> */}
                        <div className="valued-stats-item">
                            <div className="val">{voters_voted_count}</div>
                            <div className="label">voličov, ktorí odovzdali obálku</div>
                        </div>
                    </div>

                    <div className="valued-stats-col">
                        <div className="valued-stats-item mobile-center">
                            <div className="val">{voters_allowed}</div>
                            <div className="label">voličov zapísaných v zoznamoch voličov</div>
                        </div>
                        <div className="valued-stats-item mobile-center">
                            <div className="val">{votes}</div>
                            <div className="label">platných hlasov spolu</div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="elections-section">
                <h2>SÚHRNNÉ VÝSLEDKY HLASOVANIA PODĽA ÚZEMNÉHO ČLENENIA</h2>
                
                {results_votes_total_segmented.length != 0 && 
                <>
                    <TableFilters handleFilter={getFilterPropsSummary} graphLegend={data_legend} />
                    <div className={'data-table-wrapper summary ' + summaryTableClass}>
                        <DataTable columns={columnsSummaryResultsTable} data={dataSummaryTable} pagination paginationComponentOptions={paginationComponentOptions} noDataComponent="Žiadne výsledky" />
                    </div>
                </>
                }
            </div>


            <div className="elections-section">
                <h2>POČET A PODIEL PLATNÝCH HLASOV ODOVZDANÝCH PRE POLITICKÉ SUBJEKTY VO VYBRANOM ÚZEMNOM CELKU</h2>

                {results_votes_total_segmented.length != 0 && 
                <>
                    <TableFilters handleFilter={getFilterPropsSummaryParties} graphLegend={data_legend} />
                    <div className="data-table-wrapper">
                        <DataTable columns={columnsTable01} data={dataSummaryPartiesTable}  noDataComponent="Žiadne výsledky" />
                    </div>                
                </>
                }
            </div>


            <div className="elections-section">                

                {elected.length != 0 && 
                <>
                    <h2>ZVOLENÍ POSLANCI EURÓPSKEHO PARLAMENTU</h2>
                    <div className="data-table-wrapper">
                        <DataTable columns={columnsTableMandates} data={dataElected} noDataComponent="Žiadne výsledky" />
                    </div>                
                </>
                }
            </div>


            {/* <div className="elections-section">
                <h2>VÝSLEDKY PREDNOSTNÉHO HLASOVANIA POSTUPUJÚCICH POLITICKÝCH SUBJEKTOV</h2>
                
                <div className="center-filter mb-20">
                    <div className="custom-select-filter party">
                    <select
                        onChange={(e) => handleParties(e.target.value)}
                    >                    
                        {Object.values(parties).map((option, index) => ( 
                            <option key={index} value={option.code}>
                            {option.name}
                            </option>
                        ))} 
                        <option selected disabled aria-readonly>Vyberte politický subjekt</option>
                    </select>
                    </div>
                </div>                

                {selectedParty &&
                <div>
                    <h3 className="elections-results-party">Výsledky hlasovania za: <b> {getPartyName(selectedParty)}</b></h3>                
                    <div className="valued-stats-wrapper">
                        <div className="valued-stats-item mobile-center">
                            <div className="val">{getPartyVotes(selectedParty)}</div>
                            <div className="label">platných hlasov odovzdaných pre politický subjekt</div>
                        </div>
                    </div>
                </div>
                }

                <h2>ZMENENÉ PORADIE KANDIDÁTOV PO ZOHĽADNENÍ PREDNOSTNÉHO HLASOVANIA Všetci kandidáti</h2>
                
                <div className="data-table-wrapper">
                    <DataTable columns={columnsTablePriorityVoting} data={dataPriorityVotingTable} pagination paginationComponentOptions={paginationComponentOptions} noDataComponent="Výsledky sa zobrazia po zvolení politického subjektu" />
                </div>
            </div>             */}

            {/* <div className="elections-section">
                <h2>POČET A PODIEL PLATNÝCH HLASOV ODOVZDANÝCH PRE POLITICKÉ SUBJEKTY PODĽA ÚZEMNÉHO ČLENENIA</h2>

                {results_votes_total_segmented.length != 0 && 
                <>
                    <TableFilters handleFilter={getFilterPropsSegmentedParties} graphLegend={data_legend} />                
                    <div className={'data-table-wrapper summary ' + summaryPartiesTableClass}>
                        <DataTable columns={columnsPartiesTable} data={dataCountAndPercTable02} pagination paginationComponentOptions={paginationComponentOptions} noDataComponent="Žiadne výsledky" />
                    </div>
                </>
                }
            </div> */}

            
           
        </div>
        

      <LoaderIndex />
    </div>
  );
};

export default Eurovolby2024Page;



