import React, { PureComponent, Component } from "react";
import ReactDOM from "react-dom";
import TipsHorizontal from "~/components/TipsHorizontal";
import BannerHorizontal from "~/components/banners/BannerHorizontal";
import Submenu from "~/components/Submenu";
import { imgSprite, imgLike } from "~/config/images";
import { facebookAppId } from "~/config/socialMedia";
import { LinkImg, Link, PublishingTime, Line } from "~/components/Elements";
import asideMapping from "~/utility/aside";
import Modal from "react-modal";
import MetaTags from "~/components/base/MetaTags";
import type { MetaTagsProps } from "../base/MetaTags";
import { reportBug } from "~/utility/api";
import { getGaInfo } from "~/utility/gaInfo";
import { apiUrlTeraz, fetchJson } from "~/utility/api";
import {
  FacebookProvider,
  CommentsCount,
  Comments,
} from "~/components/Facebook";
// import InnerHTML from 'dangerously-set-inner-html';
import InnerHTML from "../../components/base/InnerHtml";
// <OWL>
// import { findDOMNode } from 'react-dom';
// import $ from 'jquery';
import SectionHeader from "../../containers/SectionHeader";
import "~/components/owl.carousel.css"

// import { Helmet } from 'react-helmet'
if (process.env.BUILD_TARGET !== "server") {
  require("owl.carousel");

  // require("owl.carousel/dist/assets/owl.carousel.css");
}

// </OWL>
//type ArticleDefaultProps = any
type LiveArticleSuggestedProps = Array<{
  url: string;
  image: string;
  title: string;
}>;
type ArticleActionsProps = {
  url: string;
  title: string;
  reportButtons?: boolean;
  totalShareCount: number;
  openModal: (arg0: Event) => void;
  openDiscussion: (arg0: Event) => void;
};
type LiveArticleProps = {
  id: number;
  canonical_url: string;
  totalShareCount: number;
  image: string;
  image_caption: string;
  description: string;
  title: string;
  url: string;
  time_created_ISO: string;
  time_updated_ISO: string;
  annotation: string;
  tips: any;
  suggested: LiveArticleSuggestedProps;
  tags: Array<{
    id: string;
    name: string;
    url: string;
  }>;
  prevPath: string;
  editor: {
    id: number;
    name: string;
    url: string;
  };
  author: {
    id: number;
    name: string;
    url: string;
  };
  aside: Array<any>;
  section: string;
  html: string;
  history: any;
  loader_after: any;
  submenu: Array<any>;
  live: boolean;
  live_data: any;
};
type ArticleState = {
  modalIsOpen: boolean;
  discussionIsOpen: boolean;
};

class LiveArticle extends React.Component<LiveArticleProps, ArticleState> {
  discussion: null | ArticleDiscussion;
  articleMain: null | HTMLDivElement;

  constructor() {
    super();
    this.state = {
      modalIsOpen: false,
      discussionIsOpen: false,
    };
    (this as any).openModal = this.openModal.bind(this);
    (this as any).afterOpenModal = this.afterOpenModal.bind(this);
    (this as any).closeModal = this.closeModal.bind(this);
    (this as any).openDiscussion = this.openDiscussion.bind(this);
    (this as any).reportBug = this.reportBug.bind(this);
  }

  reportBug(e: Event) {
    e.preventDefault();
    this.closeModal();

    if (e.target.text && typeof e.target.text.value === "string") {
      const text: string = e.target.text.value;
      reportBug(text);
    }
  }

  openDiscussion(e: Event) {
    this.setState({
      discussionIsOpen: true,
    });
    const el = ReactDOM.findDOMNode(this.discussion);

    if (el && el.offsetTop && typeof el.offsetTop === "number") {
      window.scroll(0, el.offsetTop - 100);
      e.preventDefault();
    }
  }

  openModal(e: Event) {
    this.setState({
      modalIsOpen: true,
    });
    e.preventDefault();
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  closeModal() {
    this.setState({
      modalIsOpen: false,
    });
  }

  render() {
    const props = this.props;
    const gaInfo = getGaInfo(this.props, "liveArticle");

    const ArticleActionsBound = (curriedProps) => (
      <ArticleActions
        totalShareCount={props.totalShareCount}
        url={props.canonical_url}
        title={props.title}
        openModal={this.openModal}
        openDiscussion={this.openDiscussion}
        {...curriedProps}
      />
    );

    const aside = (props.aside || []).map(asideMapping);
    const metaData: MetaTagsProps = {
      url: props.canonical_url,
      title: props.title,
      description: props.annotation,
      image: props.image,
      authorUrl: props.author ? props.author.url : "TASR",
      tags: props.tags ? props.tags.map((x) => x.name) : [],
      time: {
        published: props.time_created_ISO,
        modified: props.time_updated_ISO,
        year: new Date(props.time_created_ISO).getFullYear(),
      },
      objectType: "article",
      sectionName: props.section,
    };
    const jsonLdData = {
      "@context": "http://schema.org",
      "@type": "NewsArticle",
      mainEntityOfPage: props.canonical_url,

      /*"mainEntityOfPage": {
      "@type": "WebPage",
      "@id": props.url
      }, // FIXME netusim asi treba urobit entity s tym id*/
      headline: props.title,
      image: [props.image],
      datePublished: props.time_created_ISO,
      dateModified: props.time_updated_ISO,
      author: {
        "@type": "Organization",
        name: "TASR",
      },
      articleBody: props.html,
      publisher: {
        "@type": "Organization",
        name: "TASR",
        logo: {
          "@type": "ImageObject",
          url: `http://www.tasr.sk/templates/tasr/assets/images/footerlogo_tasr.png`,
          width: 82,
          height: 15,
        },
      },
      description: props.annotation,
    };
    return (
      <div>
        {props.canonical_url ? <MetaTags data={metaData} /> : null}
        {props.submenu ? <Submenu submenu={this.props.submenu} /> : null}
        <main>
          <FacebookProvider appId={facebookAppId}>
            <article className="article">
              <header className="articleHeader">
                <div className="container">
                  {/* <a onClick={props.history.goBack}>&lt; Sekcia {props.prevPath}</a> */}
                  <SectionHeader
                    className="container"
                    prefix="&lt; sekcia "
                    wrappingComponent="span"
                  />
                  <h1 className="articleTitle">
                    {props.title ||
                      Array(2)
                        .fill({})
                        .map((v, i) => <Line key={i} />)}
                  </h1>
                  <ArticleActionsBound />
                </div>
              </header>
              <div className="container">
                <div className="l-columns l-columns--article clearfix">
                  <div className="articleContent l-main pull-left">
                    <p className="articlePerex">
                      {props.annotation ||
                        Array(3)
                          .fill({})
                          .map((v, i) => <Line key={i} />)}
                    </p>

                    <div className="articleMeta">
                      <div className="clearfix">
                        {props.author ? (
                          <span className="articleMeta-person">
                            Autor{" "}
                            <strong>
                              <Link
                                inline={true}
                                to={"/author/" + props.author.id}
                                gaInfo={gaInfo}
                              >
                                {props.author.name}
                              </Link>
                            </strong>
                          </span>
                        ) : null}
                        {props.editor ? (
                          <span className="articleMeta-person">
                            Editor{" "}
                            <strong>
                              {props.editor.name}
                              {/* <Link inline={true} to={"/author/" + props.editor.id}>{props.editor.name}</Link> */}
                            </strong>
                          </span>
                        ) : null}
                      </div>
                      <PublishingTime {...props} longformat={true} />
                    </div>

                    <LiveArticleData {...this.props} />

                    <ArticleSuggested items={props.suggested} />

                    <div className="l-main-box border-top border-bottom">
                      <ArticleActionsBound reportButtons={true} />
                      <Modal
                        aria={{
                          labelledby: "modalHeading",
                        }}
                        isOpen={this.state && this.state.modalIsOpen}
                        onAfterOpen={this.afterOpenModal}
                        onRequestClose={this.closeModal}
                        contentLabel="Nahlás chybu"
                      >
                        <h1 id="modalHeading">Nahlás chybu</h1>
                        <form
                          id="articleReportBug"
                          name="articleReportBug"
                          onSubmit={this.reportBug}
                        >
                          <textarea name="text" />
                          <button type="submit">Odošli</button>
                        </form>
                      </Modal>
                      <ArticleDiscussion
                        ref={(input) => {
                          this.discussion = input;
                        }}
                        isOpen={this.state && this.state.discussionIsOpen}
                        href={props.canonical_url}
                      />
                    </div>

                    <a
                      className="likeBox"
                      href="https://www.facebook.com/teraz.sk/"
                      target="_blank"
                      rel="noopener"
                    >
                      <img className="likeBox-icon" src={imgLike} alt="" />
                      <h2 className="likeBox-title">
                        Páči sa mi <strong>TERAZ.SK</strong> na Facebooku
                      </h2>
                      <div className="likeBox-copy">
                        Staňte sa fanúšikom a sledujte dôležité správy z TASR
                        priamo na vašom Facebooku.
                      </div>
                    </a>
                  </div>
                  <aside className="l-sidebar pull-right">{aside}</aside>
                </div>

                <TipsHorizontal items={props.tips} gaInfo={gaInfo} />
              </div>
              <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                  __html: JSON.stringify(jsonLdData),
                }}
              />
            </article>
          </FacebookProvider>
        </main>
        <BannerHorizontal />
        
        {props.loader_after}
      </div>
    );
  }
}

class ArticleActions extends PureComponent<ArticleActionsProps> {
  render() {
    const encodedUrl = encodeURIComponent(this.props.url);
    const mailSubject = encodeURIComponent(`Teraz.sk: ${this.props.title}`);
    const mailBody = encodeURIComponent(
      `Pozri si tento článok z Teraz.sk:\n\n${this.props.title}\n ${this.props.url}`
    );
    return (
      <div className="articleActions">
        {/*
        <div className="articleActions-block">
         <div className="data">
           <div className="data-value">{this.props.totalShareCount}</div>
           <div className="data-label">zdieľaní</div>
         </div>
        </div>
        */}
        <div className="articleActions-block">
          {this.props.url ? (
            <iframe
              src={`https://www.facebook.com/plugins/share_button.php?href=${encodedUrl}&layout=button_count&size=large&mobile_iframe=true&appId=${facebookAppId}&width=106&height=28`}
              width="106"
              height="28"
              style={{
                border: "none",
                overflow: "hidden",
              }}
              scrolling="no"
              frameBorder="0"
              allowTransparency="true"
            />
          ) : null}
          {/*
          <a
           className="socialWidget socialWidget--facebook"
           href={"http://www.facebook.com/share.php?u=" + encodedUrl}
           target="_blank"
           rel="noopener"
          >
           <span className="socialWidget-label">Zdieľaj na Facebooku</span>
          </a>
          */}
          <a
            className="socialWidget socialWidget--twitter"
            href={"https://twitter.com/intent/tweet?url=" + encodedUrl}
            target="_blank"
            rel="noopener"
          >
            <span className="socialWidget-label">
              <svg className="icon">
                <use xlinkHref={imgSprite + "#twitter"} />
              </svg>
            </span>
          </a>
          <a
            className="socialWidget socialWidget--comments"
            href="#diskusia"
            onClick={this.props.openDiscussion}
          >
            <span className="socialWidget-label">
              <svg className="icon">
                <use xlinkHref={imgSprite + "#comments"} />
              </svg>
            </span>
            <span className="socialWidget-value">
              <CommentsCount />
            </span>
          </a>
          {this.props.reportButtons
            ? [
                <a
                  className="socialWidget socialWidget"
                  href={`mailto:?subject=${mailSubject}&body=${mailBody}`}
                  key="mail"
                >
                  <span className="socialWidget-label">
                    <svg className="icon">
                      <use
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        xlinkHref={imgSprite + "#mail"}
                      />
                    </svg>
                  </span>
                </a>,
                <a
                  className="socialWidget socialWidget"
                  onClick={this.props.openModal}
                  href="mailto:teraz@tasr.sk"
                  key="warning"
                >
                  {/*TODO*/}
                  <span className="socialWidget-label">
                    <svg className="icon">
                      <use
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        xlinkHref={imgSprite + "#warning"}
                      />
                    </svg>
                  </span>
                </a>,
              ]
            : null}
        </div>
      </div>
    );
  }
}

class ArticleDiscussion extends PureComponent<{
  isOpen: boolean;
  href: string;
}> {
  render() {
    return (
      /*TODO pripojit na fb*/
      <div id="diskusia">
        <div className={this.props.isOpen ? "" : "hidden"}>
          <Comments href={this.props.href} />
        </div>
      </div>
    );
  }
}

class ArticleSuggested extends PureComponent<{
  items: LiveArticleSuggestedProps;
}> {
  render() {
    const items = this.props.items || Array(6).fill({});

    if (items.length === 0) {
      return null;
    }

    return (
      <article className="module">
        <div className="moduleHeader">
          <h3 className="moduleHeader-title">Prečítajte si aj:</h3>
        </div>
        <div className="moduleMain">
          <div className="mediaListing row row--grid">
            {items.reduce((a, v, i) => {
              a.push(
                <div
                  className="mediaListing-item col col-4 col-md-6 col-xs-12"
                  key={i}
                >
                  <div className="media">
                    <LinkImg to={v.url} src={v.image} title={v.title} />
                    <div className="media-content">
                      <h4 className="media-title">
                        <Link to={v.url}>{v.title}</Link>
                      </h4>
                    </div>
                  </div>
                </div>
              );
              // if(i%2===0) a.push(<span className="clearfix hidden-xl hidden-lg hidden-xs" key={"s"+i}/>)
              return a;
            }, [])}
          </div>
        </div>
      </article>
    );
  }
}

type LiveArticleDataState = {
  live_events: Array<any>;
  live_data: any;
};

class LiveArticleData extends Component<
  LiveArticleProps,
  LiveArticleDataState
> {
  state = {
    live_events: [],
    live_data: this.props.live_data,
  };
  timeout = null;

  // constructor(props){
  //   super(props);
  // }
  getData() {
    return fetchJson(
      `${apiUrlTeraz}api_rest.php?module=RestApi&method=liveArticleEvents&articleId=${this.props.id}`
    ).then((data) => {
      this.setState({ ...data });
      this.timeout = null;
    });
  }

  setTimeout() {
    if (
      process.env.BUILD_TARGET !== "server" &&
      !this.timeout &&
      this.props.live
    ) {
      this.timeout = setTimeout(this.getData.bind(this), 10000);
    }
  }

  unsetTimeout() {
    if (
      process.env.BUILD_TARGET !== "server" &&
      this.timeout &&
      this.props.live
    ) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
  }

  componentDidMount() {
    this.getData();
    this.setTimeout();
  }

  componentDidUpdate() {
    if (process.env.BUILD_TARGET !== "server") {
      this.setTimeout.bind(this)();
    }
  }

  componentWillUnmount() {
    this.unsetTimeout();
  }

  render() {
    const livedata = this.state.live_data;
    return (
      <section className="liveprenos">
        <div className="match hockey">
          <span className="event_info">{livedata.event_info}</span>
          <span className="live">LIVE</span>
          <div className="teams">
            <div className="teams_leftteam">
              <img
                src={livedata.hometeam_logo}
                height="64"
                className="hometeam_logo"
                alt="hometeam logo"
              />
              <span className="hometeam_name">{livedata.hometeam_name}</span>
            </div>

            <span className="score">{livedata["score"]}</span>
            <span className="score_thirds">{livedata["score_partial"]}</span>

            <div className="teams_rightteam">
              <img
                src={livedata["awayteam_logo"]}
                height="64"
                className="awayteam_logo"
                alt="awayteam logo"
              />
              <span className="awayteam_name">{livedata["awayteam_name"]}</span>
            </div>
          </div>

          <div className="leftscores">
            <p>{livedata.hometeam_scores}</p>
          </div>
          <div className="rightscores">
            <p>{livedata.awayteam_scores}</p>
          </div>
        </div>
        <div className="players">
          <div className="players_leftteam">
            <p>{livedata.hometeam_players}</p>
          </div>
          <div className="players_rightteam">
            <p>{livedata.awayteam_players}</p>
          </div>
          <div className="clear" />
        </div>

        <div className="stream">
          {this.state.live_events.map((item, i) => (
            <p key={i}>
              <span className="time">{item["time_description"]}</span>
              <span className={"icon " + item["icon"]} />
              {/* {item["description"]} */}

              <InnerHTML className="item_description" html={item.description} />
            </p>
          ))}
        </div>
      </section>
    );
  }
}

export default LiveArticle;
