import React, { useState, useEffect } from "react";
import asideMapping from "../../utility/aside";
import DataTable from 'react-data-table-component';
import LoaderIndex from "~/containers/LoaderIndex";
import TableFilters from "../elections/TableFilters";
import { ResponsiveBar } from '@nivo/bar';
import dataResults from './volby2023_results.json';
import dataLegendFile from './temp_legend_24.json';


const Volby2024PageTemp = (props) => {
    const graphData = props.dataGraph;
    // const graphLegend = dataLegendFile;
  
    const [dataResults, setDataResults] = useState({}); 
    const [tempPage, setTempPage] = useState(true);

    const results_data = props.dataResults || [];
    const data_legend = dataLegendFile || [];
    // const data_legend = dataLegendFile || {};
    const parties = dataLegendFile?.parties || {};
  
    const colorsArr = ["hsl(207, 90.86%, 65.69%)", "hsl(242.16, 86.06%, 72.75%)", "hsl(131.5, 65.22%, 63.92%)", "hsl(16.53, 81.46%, 59.8%)", "hsl(219.72, 33.33%, 58.24%)", "hsl(280.15, 84.71%, 69.22%)", "hsl(169.73, 62.01%, 64.9%)", "hsl(87, 50.86%, 79.69%)", "hsl(232.16, 46.06%, 82.75%)", "hsl(51.27, 74.81%, 41.61%)", "hsl(131.5, 65.22%, 33.92%)"];
    
    const results_votes_total_segmented = props.dataResults?.votes_total_segmented || [];
    const results_votes_by_party_segmented = props.dataResults?.candidate_votes_segmented || [];
    const results_candidate_votes_by_party_summary = props.dataResults?.candidate_votes_by_party_summary || [];
    const results_candidate_votes_by_party_segmented = props.dataResults?.candidate_votes_by_party_segmented || [];

    const participation_ratio = props.dataResults?.summary_sr?.participation_ratio || 0;
    const voters_processed = props.dataResults?.summary_sr?.voters_processed || 0;
    const voters_allowed = props.dataResults?.summary_sr?.voters_allowed || 0;
    const voters_from_abroad_ratio = props.dataResults?.summary_sr?.voters_from_abroad_ratio || 0;
    const voters_from_abroad_count = props.dataResults?.summary_sr?.voters_from_abroad_count || 0;
    const votes = props.dataResults?.summary_sr?.votes || 0;
    const voters_voted_ratio = props.dataResults?.summary_sr?.voters_voted_ratio || 0;
    const voters_voted_count = props.dataResults?.summary_sr?.voters_voted_count || 0;
    const precincts_processed_count = props.dataResults?.summary_sr?.precincts_processed_count || 0;
    const precincts_total = props.dataResults?.summary_sr?.precincts_total || 0;
    const precincts_processed_ratio = props.dataResults?.summary_sr?.precincts_processed_ratio || '0';


    const [dataSummaryTable, setDataSummaryTable] = useState([]); 
    const [summaryTableClass, setSummaryTableClass] = useState('all'); 
    const [summaryPartiesTableClass, setSummaryPartiesTableClass] = useState('all'); 
    const [selectedParty, setSelectedParty] = useState(null); 
    const [dataPriorityVotingTable, setDataPriorityVotingTable] = useState([]); 

    const [dataSummaryPartiesTable, setDataSummaryPartiesTable] = useState([]); 
    const [dataPartiesGraph, setDataPartiesGraph] = useState([]); 
    const [dataPartiesGraphReversed, setDataPartiesGraphReversed] = useState([]); 
    const [columnsPartiesTable, setColumnsPartiesTable] = useState([]); 
    const [dataCountAndPercTable02, setDataCountAndPercTable02] = useState([]); 


  function getDistrict(code){    
    const x = data_legend.districts[code];
    return x ? x.name : '';
  }

  function getWard(code){    
    const x = data_legend.wards[code];
    return x ? x.name : '';
  }

  function getRegion(code){    
    const x = data_legend.regions[code];
    return x ? x.name : '';
  }
  
  function getPartyName(code){    
    const x = data_legend.candidates.find(function (el) {
        return el.id == code;
    });
    return x ? x.first_name + ' ' + x.last_name : '';
  }  
  function getCandidateName(code, partyCode){    
    const x = data_legend.candidates.find(function (el) {
        return el.code == code && el.party_code == partyCode;
    });
    return x ? x.first_name  : '';
  }  
  function getCandidateSurname(code, partyCode){    
    const x = data_legend.candidates.find(function (el) {
        return el.code == code && el.party_code == partyCode;
    });
    return x ? x.last_name  : '';
  }  

  const paginationComponentOptions = {
    rowsPerPageText: 'Výsledkov na stranu',
    rangeSeparatorText: 'z',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Zobraziť všetko',
};



  const columnsSummaryResultsTable = [
    {
        name: 'Okres',
        selector: row => row.district,
        sortable: true,
    },
    {
        name: 'Obvod',
        selector: row => row.ward,
        sortable: true,
    },
    {
        name: 'Kraj',
        selector: row => row.region,
        sortable: true,
    },
    {
        name: 'Počet okrskov',
        selector: row => row.districts,
        sortable: true,
    },
    {
        name: 'Počet zapísaných voličov',
        selector: row => row.votersCount,
        sortable: true,
    },
    {
        name: 'Počet zúčastnených voličov',
        selector: row => row.involvedVotersCount,
        sortable: true,
    },
    {
        name: 'Účasť voličov v %',
        selector: row => row.votersAttendance,
        sortable: true,
    },
    // {
    //     name: 'Počet odovzdaných obálok osobne',
    //     selector: row => row.envelopesCount,
    //     sortable: true,
    // },
    // {
    //     name: 'Podiel odovzdaných obálok osobne v %',
    //     selector: row => row.envelopesCountPerc,
    //     sortable: true,
    // },
    {
        name: 'Počet platných hlasov spolu',
        selector: row => row.votesCount,
        sortable: true,
    },
    {
        name: 'Podiel platných hlasov spolu v %:',
        selector: row => row.votesCountPerc,
        sortable: true,
    },
    ];
      const columnsTable01 = [
    {
        name: 'Číslo kandidáta',
        selector: row => row.partyId,
        sortable: true,
    },
    {
        name: 'Meno a priezvisko kandidáta',
        selector: row => row.partyName,
        sortable: true,
    },
    {
        name: 'Počet platných hlasov',
        selector: row => row.votesCount,
        sortable: true,
    },
    {
        name: 'Podiel platných hlasov v %',
        selector: row => row.votesPerc,
        sortable: true,
    },
];

const columnsPartiesTableBase = [
    {
        name: 'Okres',
        selector: row => row.district,
        sortable: true,
    },
    {
        name: 'Obvod',
        selector: row => row.ward,
        sortable: true,
    },
    {
        name: 'Kraj',
        selector: row => row.region,
        sortable: true,
    },
    {
        name: 'Počet platných hlasov spolu',
        selector: row => row.votesCount,
        sortable: true,
    },
];


const [summaryTableRegions, setSummaryTableRegions] = useState([]); 
const [summaryTableWards, setSummaryTableWards] = useState([]); 
const [summaryTableDistricts, setSummaryTableDistricts] = useState([]); 


var dataSummaryTableRegions = [];
var dataSummaryTableWards = [];
var dataSummaryTableDistricts = [];

    
var dataSummaryPartiesTableRegions = [];
var dataSummaryPartiesTableRegionsSummary = [];
var dataSummaryPartiesTableWards = [];
var dataSummaryPartiesTableDistricts = [];

const [summaryPartiesTableRegions, setSummaryPartiesTableRegions] = useState([]); 
const [summaryPartiesTableRegionsSummary, setSummaryPartiesTableRegionsSummary] = useState([]); 
const [summaryPartiesTableWards, setSummaryPartiesTableWards] = useState([]); 
const [summaryPartiesTableDistricts, setSummaryPartiesTableDistricts] = useState([]); 


var dataPartiesTableRegions = [];
var dataPartiesTableWards = [];
var dataPartiesTableDistricts = [];

var dataPriorityVotingTableAll = [];
const [priorityVotingTableAll, setPriorityVotingTableAll] = useState([]); 

const [partiesTableRegions, setPartiesTableRegions] = useState([]); 
const [partiesTableWards, setPartiesTableWards] = useState([]); 
const [partiesTableDistricts, setPartiesTableDistricts] = useState([]); 


function mapData(){
    const dataSummaryTableRAW = results_votes_total_segmented.map((d) => {
        const dRow = {
            district: getDistrict(d.district_code),
            ward: getWard(d.ward_code),
            region: d.region_code == '' ? 'Spolu' : getRegion(d.region_code),
            districts: d.precincts_count,
            votersCount: d.voters_allowed,
            involvedVotersCount: d.voters_processed,
            votersAttendance: d.participation_ratio,
            envelopesCount: 423503,
            envelopesCountPerc: 98.09,
            votesCount: d.votes_count,
            votesCountPerc: d.votes_ratio,
            regionCode: d.region_code,
            wardCode: d.ward_code,
            districtCode: d.district_code,
        }
        if(d.ward_code == '' && d.district_code == ''){
            dataSummaryTableRegions.push(dRow);
        }else if(d.ward_code != '' && d.district_code == ''){
            dataSummaryTableWards.push(dRow);
        }else{
            dataSummaryTableDistricts.push(dRow);
        }    
        });

        setSummaryTableRegions(dataSummaryTableRegions);
        setSummaryTableWards(dataSummaryTableWards);
        setSummaryTableDistricts(dataSummaryTableDistricts);


        const dataCountAndPercTableRAW = results_votes_by_party_segmented.map((d) => {
            const dRow = {
                partyId: d.candidate_id,
                partyName: getPartyName(d.candidate_id),
                votesCount: d.votes_count,
                votesPerc: d.votes_ratio,
                district: getDistrict(d.district_code),
                ward: getWard(d.ward_code),
                region: getRegion(d.region_code),
                regionCode: d.region_code,
                wardCode: d.ward_code,
                districtCode: d.district_code,
            }
            if(d.ward_code == '' && d.district_code == '' && d.region_code == ''){
                dataSummaryPartiesTableRegions.push(dRow);
            }else if(d.region_code != '' && d.ward_code == ''){
                dataSummaryPartiesTableRegionsSummary.push(dRow);
            }else if(d.ward_code != '' && d.district_code == ''){
                dataSummaryPartiesTableWards.push(dRow);
            }else{
                dataSummaryPartiesTableDistricts.push(dRow);
            } 
          });

          setSummaryPartiesTableRegions(dataSummaryPartiesTableRegions)
          setSummaryPartiesTableRegionsSummary(dataSummaryPartiesTableRegionsSummary)
          setSummaryPartiesTableWards(dataSummaryPartiesTableWards)
          setSummaryPartiesTableDistricts(dataSummaryPartiesTableDistricts)

          console.log("TEST: ", dataSummaryPartiesTableRegionsSummary);

          const dataPartiesTableRegionsRAW = dataSummaryTableRegions.map((d) => {
            var dRow = {
              region: d.region,
              district: d.district,
              ward: d.ward,
              votesCount: d.votesCount,
              districtCode: d.districtCode,
              wardCode: d.wardCode,
              regionCode: d.regionCode,
            }
          
            results_votes_by_party_segmented.map((p) => {
              if(p.ward_code == dRow.wardCode && p.district_code == dRow.districtCode && p.region_code == dRow.regionCode){
                  dRow[p.candidate_id] = p.votes_count;
              }
            });
          
            if(d.regionCode != '' && d.wardCode == '' && d.districtCode == ''){
              dataPartiesTableRegions.push(dRow);
            }
          });

          setPartiesTableRegions(dataPartiesTableRegions);

            console.log("dataPartiesTableRegions: ", dataPartiesTableRegions);
          
          const dataPartiesTableWardsRAW = dataSummaryTableWards.map((d) => {
            var dRow = {
              region: d.region,
              district: d.district,
              ward: d.ward,
              votesCount: d.votesCount,
              districtCode: d.districtCode,
              wardCode: d.wardCode,
              regionCode: d.regionCode,
            }
          
            results_votes_by_party_segmented.map((p) => {
              if(p.ward_code == dRow.wardCode && p.district_code == dRow.districtCode && p.region_code == dRow.regionCode){
                  dRow[p.candidate_id] = p.votes_count;
              }
            });
          
            if(d.wardCode != '' && d.districtCode == ''){
              dataPartiesTableWards.push(dRow);
            }
          
          });

          setPartiesTableWards(dataPartiesTableWards);

          console.log("dataPartiesTableWards: ", dataPartiesTableWards);
          
          const dataPartiesTableDistrictsRAW = dataSummaryTableDistricts.map((d) => {
            var dRow = {
              region: d.region,
              district: d.district,
              ward: d.ward,
              votesCount: d.votesCount,
              districtCode: d.districtCode,
              wardCode: d.wardCode,
              regionCode: d.regionCode,
            }
          
            results_votes_by_party_segmented.map((p) => {
              if(p.ward_code == dRow.wardCode && p.district_code == dRow.districtCode && p.region_code == dRow.regionCode){
                  dRow[p.candidate_id] = p.votes_count;
              }
            });
          
            if(d.regionCode != '' && d.wardCode != '' && d.districtCode != ''){
              dataPartiesTableDistricts.push(dRow);
            }
          });

            // console.log("dataPartiesTableDistricts: ", dataPartiesTableDistricts);
          setPartiesTableDistricts(dataPartiesTableDistricts);

          const dataPriorityVotingTableRAW = results_candidate_votes_by_party_segmented.map((d) => {
            const dRow = {
                orderPriority: d.rank_after_count,
                orderOnPaper: d.rank_on_voting_sheet,
                name: getCandidateName(d.code, d.party_code),
                surname: getCandidateSurname(d.code, d.party_code),
                votesCount: d.votes_count,
                partyId: d.party_code,
            }
            dataPriorityVotingTableAll.push(dRow);
        });
        setPriorityVotingTableAll(dataPriorityVotingTableAll);
}

useEffect(() => {   
    
    if(props.dataResults && Object.keys(props.dataResults).length) {
        
        mapData();

        setDataSummaryTable(dataSummaryTableRegions);    
        setDataSummaryPartiesTable(dataSummaryPartiesTableRegions);    
        setDataCountAndPercTable02(dataPartiesTableRegions);

        dataSummaryPartiesTableRegions.filter((a, i) => dataSummaryPartiesTableRegions.findIndex((s) => a.country === s.country) === i);

        let sortedParties = dataSummaryPartiesTableRegions.sort(
            (p1, p2) => (p1.votesCount < p2.votesCount) ? 1 : (p1.votesCount > p2.votesCount) ? -1 : 0);

        var top10Parties = [];
        // var top10PartiesReverse = [];
        const top10PartiesRAW = sortedParties.slice(0, 11).map((d, index) => {
            const dRow = {
                "country": d.partyName,
                "Podiel hlasov": parseFloat(d.votesPerc.replace(',','.')),
                "Podiel hlasovColor": colorsArr[index],
            }
            top10Parties.push(dRow);
        });           

        setDataPartiesGraph(top10Parties);
        var top10PartiesReverse = [...top10Parties];
        setDataPartiesGraphReversed(top10PartiesReverse.reverse())
        var columnsPartiesTableNext = [];
        Object.values(parties).map((option, index) => {
            var row = {
                name: option.name,
                selector: row => row[option.code],
                sortable: true,
            };
            columnsPartiesTableNext.push(row);
        });

        columnsPartiesTableBase.push(...columnsPartiesTableNext);
        setColumnsPartiesTable(columnsPartiesTableBase);  

    }
          
  }, [props.dataResults]); 


  const getFilterPropsSummary = (data) => {   
    if(data?.selecting === 'region'){
        const filteredSummaryData = summaryTableWards.filter(obj => {
            return obj.regionCode === data?.code;
        });
        setDataSummaryTable(filteredSummaryData);
        setSummaryTableClass('regions');        
    }else if(data?.selecting === 'ward'){
        const filteredSummaryData = summaryTableDistricts.filter(obj => {
            return obj.wardCode === data?.code;
        });
        setDataSummaryTable(filteredSummaryData);        
        setSummaryTableClass('wards');
    }else if(data?.selecting === 'district'){
        const filteredSummaryData = summaryTableDistricts.filter(obj => {
            return obj.districtCode === data?.code;
        });
        setDataSummaryTable(filteredSummaryData);        
        setSummaryTableClass('districts');
    }else if(data?.selecting === 'all'){
        setDataSummaryTable(summaryTableRegions);
        setSummaryTableClass('all');        
    }
  }

  const getFilterPropsSummaryParties = (data) => {        
    if(data?.selecting === 'region'){
        const filteredSummaryData = summaryPartiesTableWards.filter(obj => {
            return obj.regionCode === data?.code;
        });
        setDataSummaryPartiesTable(filteredSummaryData);    
        console.log(filteredSummaryData);   
    }else if(data?.selecting === 'ward'){
        const filteredSummaryData = summaryPartiesTableDistricts.filter(obj => {
            return obj.wardCode === data?.code;
        });
        setDataSummaryPartiesTable(filteredSummaryData);        
    }else if(data?.selecting === 'district'){
        const filteredSummaryData = summaryPartiesTableDistricts.filter(obj => {
            return obj.districtCode === data?.code;
        });
        setDataSummaryPartiesTable(filteredSummaryData);        
    }else if(data?.selecting === 'all'){
        setDataSummaryPartiesTable(summaryPartiesTableRegions);  
    }
  }

  const getFilterPropsSummaryParties2 = (data) => {        
    if(data?.selecting === 'region'){
        const filteredSummaryData = summaryPartiesTableRegionsSummary.filter(obj => {
            return obj.regionCode === data?.code;
        });
        setDataSummaryPartiesTable(filteredSummaryData);    
        console.log(filteredSummaryData);   
    }else if(data?.selecting === 'ward'){
        const filteredSummaryData = summaryPartiesTableWards.filter(obj => {
            return obj.wardCode === data?.code;
        });
        setDataSummaryPartiesTable(filteredSummaryData);        
        console.log(filteredSummaryData);   
    }else if(data?.selecting === 'district'){
        const filteredSummaryData = summaryPartiesTableDistricts.filter(obj => {
            return obj.districtCode === data?.code;
        });
        setDataSummaryPartiesTable(filteredSummaryData);        
    }else if(data?.selecting === 'all'){
        setDataSummaryPartiesTable(summaryPartiesTableRegions);  
    }
  }


  const getFilterPropsParties = (data) => {    
    if(data?.selecting === 'region'){
        const filteredSummaryData = summaryPartiesTableWards.filter(obj => {
            return obj.regionCode === data?.code;
        });
        setDataSummaryPartiesTable(filteredSummaryData);       
    }else if(data?.selecting === 'ward'){
        const filteredSummaryData = summaryPartiesTableDistricts.filter(obj => {
            return obj.wardCode === data?.code;
        });
        setDataSummaryPartiesTable(filteredSummaryData);        
    }else if(data?.selecting === 'district'){
        const filteredSummaryData = summaryPartiesTableDistricts.filter(obj => {
            return obj.districtCode === data?.code;
        });
        setDataSummaryPartiesTable(filteredSummaryData);        
    }
  }

  const getFilterPropsSegmentedParties = (data) => {    
    if(data?.selecting === 'region'){
        const filteredSummaryData = partiesTableWards.filter(obj => {
            return obj.regionCode === data?.code;
        });
        setDataCountAndPercTable02(filteredSummaryData); 
        setSummaryPartiesTableClass('regions');
    }else if(data?.selecting === 'ward'){
        const filteredSummaryData = partiesTableDistricts.filter(obj => {
            return obj.wardCode === data?.code;
        });
        setDataCountAndPercTable02(filteredSummaryData);   
        setSummaryPartiesTableClass('wards');     
    }else if(data?.selecting === 'district'){
        const filteredSummaryData = partiesTableDistricts.filter(obj => {
            return obj.districtCode === data?.code;
        });
        setDataCountAndPercTable02(filteredSummaryData);    
        setSummaryPartiesTableClass('districts');    
    }else if(data?.selecting === 'all'){
        setDataCountAndPercTable02(partiesTableRegions);   
        setSummaryPartiesTableClass('all');     
    }
  }

  const HorizontalTick = ({ textAnchor, textBaseline, value, x, y }) => {
    const MAX_LINE_LENGTH = 16;
    const MAX_LINES = 2;
    const LENGTH_OF_ELLIPSIS = 3;
    const TRIM_LENGTH = MAX_LINE_LENGTH * MAX_LINES - LENGTH_OF_ELLIPSIS;
    const trimWordsOverLength = new RegExp(`^(.{${TRIM_LENGTH}}[^\\w]*).*`);
    const groupWordsByLength = new RegExp(
      `([^\\s].{0,${MAX_LINE_LENGTH}}(?=[\\s\\W]|$))`,
      'gm',
    );
        const splitValues = value?.replace(trimWordsOverLength, '$1...')
        .match(groupWordsByLength)
        .slice(0, 2)
        .map((val, i) => (
            <tspan
            key={val}
            dy={12 * i}
            x={-10}
            y={18}
            style={{ fontFamily: 'sans-serif', fontSize: '11px', paddingTop: '20px', marginTop: '20px' }}
            >
            {val}
            </tspan>
        ));
        
        return (
        <g transform={`translate(${x},${y})`}>
            <text alignmentBaseline={textBaseline} textAnchor={textAnchor}>
            {splitValues}
            </text>
        </g>
        );
  };

    

  const handleParties = (code) => {    
    const filteredPartyData = priorityVotingTableAll.filter(obj => {
        return obj.partyId == code;
    });

    setDataPriorityVotingTable(filteredPartyData);
    setSelectedParty(code);
  }

  function getPartyVotes(code){    
    const x = results_candidate_votes_by_party_summary.find(function (el) {
        return el.party_code == code;
    });
    return x ? x.votes : '';
  }  
  

  

  const data = [
    {
        "zratanost_hlasov": ' ',
        "Spočítané hlasy": parseFloat(precincts_processed_ratio.replace(',','.')),
        "Spočítané hlasyColor": "hsl(213, 87%, 34%)",
        "Nespočítané hlasy": (100 - parseFloat(precincts_processed_ratio.replace(',','.'))),
        "Nespočítané hlasyColor": "#cfcfcf"
    },
  ];

//   if(tempPage){
//     return(
//         <div>
//             <div className="container static-page elections">
//                 <h1>VOĽBY do národnej rady slovenskej republiky 2023</h1>  
//                 <div className="chart-row temp-text">
//                     <p>Na tejto stránke budete môcť po uzatvorení volebných miestností ONLINE sledovať vývoj spočítavania hlasov a priebežné neoficiálne výsledky jednotlivých politických subjektov.</p>
//                 </div>             
//             </div>
//         </div>
//     )
//   }

  return (
    <div>
        <div className="container static-page elections">                
            <h1>PREZIDENTSKÉ VOĽBY 2024</h1>            


            { precincts_processed_count !== 0 &&
            <div className="chart-row counted-graph-wrapper">
            <div className="chart-col full">
                <div className="chart-hdl">
                    Spracovaných <b>{precincts_processed_count}</b> z celkového počtu <b>{precincts_total}</b> zápisníc | <b>{precincts_processed_ratio} %</b>
                </div>
                <div className="chart-box line thin">
                <ResponsiveBar
                    data={data}
                    keys={[
                        'Spočítané hlasy',
                        'Nespočítané hlasy'
                    ]}
                    colors={({id, data}) => data[`${id}Color`]}
                    indexBy="zratanost_hlasov"
                    margin={{ top: 0, right: 0, bottom: 20, left: 0 }}
                    padding={0.6}
                    minValue={0}
                    maxValue={100}
                    layout="horizontal"
                    valueScale={{ type: 'linear' }}
                    label={""}                    
                    valueFormat={function(e){return (e) + " %"}}
                    fill={[
                        {
                            match: {
                                id: 'fries'
                            },
                            id: 'dots'
                        },
                        {
                            match: {
                                id: 'sandwich'
                            },
                            id: 'lines'
                        }
                    ]}
                    borderRadius={3}
                    borderColor={{
                        from: 'color',
                        modifiers: [
                            [
                                'brighter',
                                1.6
                            ]
                        ]
                    }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={null}
                    enableGridY={false}
                    labelSkipWidth={12}
                    labelSkipHeight={12}
                    labelTextColor="#FFFFFF"
                    role="application"
                    ariaLabel="Počet spočítaných zápisníc"
                    barAriaLabel={function(e){return e.id+": "+e.formattedValue+" in country: "+e.indexValue}}
                />
                </div>
            </div>

            </div>
            }

            {/* <h2>Priebežné neoficiálne výsledky za SR</h2> */}
            <h2>Neoficiálne výsledky za SR</h2>
            <div className="elections-section">                

                {results_votes_total_segmented.length != 0 && 
                <>
                <div className="chart-box parties desktop">
                {dataPartiesGraph &&
                <ResponsiveBar
                    data={dataPartiesGraph}
                    keys={[
                        'Podiel hlasov',
                    ]}
                    colors={({id, data}) => data[`${id}Color`]}
                    indexBy="country"
                    margin={{ top: 50, right: 0, bottom: 50, left: 0 }}
                    padding={0.3}
                    valueScale={{ type: 'linear' }}
                    indexScale={{ type: 'band', round: true }}
                    valueFormat={function(e){return (e) + " %"}}
                    defs={[
                        {
                            id: 'dots',
                            type: 'patternDots',
                            background: 'inherit',
                            color: '#38bcb2',
                            size: 4,
                            padding: 1,
                            stagger: true
                        },
                        {
                            id: 'lines',
                            type: 'patternLines',
                            background: 'inherit',
                            color: '#eed312',
                            rotation: -45,
                            lineWidth: 6,
                            spacing: 10
                        }
                    ]}
                    fill={[
                        {
                            match: {
                                id: 'fries'
                            },
                            id: 'dots'
                        },
                        {
                            match: {
                                id: 'sandwich'
                            },
                            id: 'lines'
                        }
                    ]}
                    borderColor={{
                        from: 'color',
                        modifiers: [
                            [
                                'darker',
                                1.6
                            ]
                        ]
                    }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        tickSize: 10,
                        tickPadding: 5,
                        tickRotation: 0,
                        // tickSize: 8,
                        // tickPadding: 4,
                        // renderTick: HorizontalTick,
                    }}
                    axisLeft={{
                        tickSize: 2,
                        tickPadding: 0,
                        tickRotation: 0,
                    }}
                    labelSkipWidth={12}
                    labelSkipHeight={12}
                    labelTextColor={{
                        from: 'color',
                        modifiers: [
                            [
                                'darker',
                                2
                            ]
                        ]
                    }}
                    role="application"
                    ariaLabel="Podiel hlasov politickych stran"
                />
                }
                </div>

<div className="chart-box parties mobile">
    
{dataPartiesGraphReversed &&
<ResponsiveBar
    data={dataPartiesGraphReversed}
    keys={[
        'Podiel hlasov',
    ]}
    colors={({id, data}) => data[`${id}Color`]}
    indexBy="country"
    margin={{ top: 0, right: 0, bottom: 50, left: 110 }}
    padding={0.3}
    valueScale={{ type: 'linear' }}
    indexScale={{ type: 'band', round: true }}
    layout="horizontal"
    valueFormat={function(e){return (e) + " %"}}
    defs={[
        {
            id: 'dots',
            type: 'patternDots',
            background: 'inherit',
            color: '#38bcb2',
            size: 4,
            padding: 1,
            stagger: true
        },
        {
            id: 'lines',
            type: 'patternLines',
            background: 'inherit',
            color: '#eed312',
            rotation: -45,
            lineWidth: 6,
            spacing: 10
        }
    ]}
    fill={[
        {
            match: {
                id: 'fries'
            },
            id: 'dots'
        },
        {
            match: {
                id: 'sandwich'
            },
            id: 'lines'
        }
    ]}
    borderColor={{
        from: 'color',
        modifiers: [
            [
                'darker',
                1.6
            ]
        ]
    }}
    axisTop={null}
    axisRight={null}
    axisBottom={{
        tickSize: 1.5,
        tickPadding: 6,
        tickRotation: 0,
        // tickSize: 8,
        // tickPadding: 4,
        // renderTick: HorizontalTick,
    }}
    axisLeft={{
        tickSize: 4,
        tickPadding: 1,
        tickRotation: 0,
    }}
    labelSkipWidth={12}
    labelSkipHeight={12}
    labelTextColor={{
        from: 'color',
        modifiers: [
            [
                'darker',
                4
            ]
        ]
    }}
    role="application"
    ariaLabel="Podiel hlasov politickych stran"
/>
}
</div>
</>
}
                

                <div className="valued-stats-wrapper">
                    <div className="valued-stats-col border red text-right">
                        <div className="valued-stats-item">
                            <div className="val">{participation_ratio} %</div>
                            <div className="label">účasť</div>
                        </div>
                        {/* <div className="valued-stats-item">
                            <div className="val">{voters_from_abroad_ratio} %</div>
                            <div className="label">voličov z cudziny</div>
                        </div> */}
                        <div className="valued-stats-item">
                            <div className="val">{voters_voted_ratio} %</div>
                            <div className="label">voličov, ktorí odovzdali obálku</div>
                        </div>
                    </div>

                    <div className="valued-stats-col red">
                        <div className="valued-stats-item">
                            <div className="val">{voters_processed}</div>
                            <div className="label">zúčastnených</div>
                        </div>
                        {/* <div className="valued-stats-item">
                            <div className="val">{voters_from_abroad_count}</div>
                            <div className="label">voličov z cudziny</div>
                        </div> */}
                        <div className="valued-stats-item">
                            <div className="val">{voters_voted_count}</div>
                            <div className="label">voličov, ktorí odovzdali obálku</div>
                        </div>
                    </div>

                    <div className="valued-stats-col">
                        <div className="valued-stats-item mobile-center">
                            <div className="val">{voters_allowed}</div>
                            <div className="label">voličov zapísaných v zoznamoch voličov</div>
                        </div>
                        <div className="valued-stats-item mobile-center">
                            <div className="val">{votes}</div>
                            <div className="label">platných hlasov spolu</div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="elections-section">
                <h2>SÚHRNNÉ VÝSLEDKY HLASOVANIA PODĽA ÚZEMNÉHO ČLENENIA</h2>
                
                {results_votes_total_segmented.length != 0 && 
                <>
                    <TableFilters handleFilter={getFilterPropsSummary} graphLegend={dataLegendFile} />
                    <div className={'data-table-wrapper summary ' + summaryTableClass}>
                        <DataTable columns={columnsSummaryResultsTable} data={dataSummaryTable} pagination paginationComponentOptions={paginationComponentOptions} noDataComponent="Žiadne výsledky" />
                    </div>
                </>
                }

            </div>

            <div className="elections-section">
                <h2>POČET A PODIEL PLATNÝCH HLASOV ODOVZDANÝCH PRE KANDIDÁTOV VO VYBRANOM ÚZEMNOM CELKU</h2>
                {/* <h2>POČET A PODIEL PLATNÝCH HLASOV ODOVZDANÝCH PRE POLITICKÉ SUBJEKTY PODĽA ÚZEMNÉHO ČLENENIA</h2> */}

                {results_votes_total_segmented.length != 0 && 
                <>
                    <TableFilters handleFilter={getFilterPropsSummaryParties2} graphLegend={dataLegendFile} />
                    <div className="data-table-wrapper">
                        <DataTable columns={columnsTable01} data={dataSummaryPartiesTable}  noDataComponent="Žiadne výsledky" />
                    </div>                
                </>
                }
            </div>
            
           
        </div>
        

      <LoaderIndex />
    </div>
  );
};

export default Volby2024PageTemp;
