import React, { Component } from "react";
import { imgSprite } from "~/config/images";
import { Link as LinkE } from "~/components/Elements";
import { GAInfoProp, getGaInfo, withGaInfo } from "~/utility/gaInfo";
import clsx from "clsx";

interface Articles {
  url: string;
  time_updated_ISO: string;
  time: string;
  title: string;
}
interface AsideListingProps {
  skipHeaderPrefix?: boolean;
  headerSuffix?: string;
  url_title: string;
  url_next?: string;
  baseUrl?: string;
  className?: string;
  articles: Articles[];
}
export default class AsideListing extends Component<
  AsideListingProps & GAInfoProp
> {
  render() {
    const gaInfo = getGaInfo(
      this.props,
      this.props.skipHeaderPrefix
        ? ""
        : `Najnovšie ${this.props.headerSuffix || ""}`
    );
    const Link = withGaInfo(LinkE, gaInfo);
    const className = this.props.className || "";
    const articles = this.props.articles || [];
    const items = articles.map((a, i) => (
      <li className="listingItem clearfix" key={i}>
        <Link
          className="listingItem-link"
          to={a.url}
          baseUrl={this.props.baseUrl}
        >
          <span className="listingItem-time" dateTime={a.time_updated_ISO}>
            {a.time}
          </span>
          <h4 className="listingItem-title">{a.title}</h4>
        </Link>
      </li>
    ));
    return (
      <div className={clsx("module", className)}>
        <div className="moduleHeader">
          <h3 className="moduleHeader-title">
            <Link to={this.props.url_title}>              
              {!this.props.skipHeaderPrefix && "Najnovšie "}
              {this.props.headerSuffix}
            </Link>
          </h3>
        </div>
        <div className="moduleMain">
          <ol
            className={
              "listing list-reset" + (items.length > 1 ? " fadeout " : "")
            }
          >
            {items}
          </ol>
          {this.props.url_next ? (
            <strong className="pull-right">
              <Link
                className="link link--default link--withIcon"
                to={this.props.url_next}
                baseUrl={this.props.baseUrl}
              >
                Viac{" "}
                <svg className="icon color-primary">
                  <use xlinkHref={imgSprite + "#chevron-double-down"} />
                </svg>
              </Link>
            </strong>
          ) : null}
        </div>
      </div>
    );
  }
}
