import React, { Component } from "react";
import { headerSuffix } from "~/store/actions";
import { connect } from "react-redux";

class SearchGoogleContainer extends Component {
  componentDidMount() {
    var cx = "002984951474253467051:ivju6xulgga";
    var gcse = document.createElement("script");
    gcse.type = "text/javascript";
    gcse.async = true;
    // gcse.src = `https://cse.google.com/cse.js?cx=${cx}&sort=modified_time:d:s`;
    gcse.src = `https://cse.google.com/cse.js?cx=${cx}`;
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(gcse, s);
    this.props.dispatch(headerSuffix("Hľadaj", "/"));
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    //var cx = '002984951474253467051:ivju6xulgga';

    /*<script type="text/javascript" async="true" src={"https://cse.google.com/cse.js?cx="+cx} />*/
    return (
      <div
        className="gsearch container"
        dangerouslySetInnerHTML={{
          __html:
            '<gcse:search enableAutoComplete="true" sort_by="date" defaultToRefinement="TERAZ.SK"></gcse:search>',
        }}
      />
    );
  }
}

export default connect(null)(SearchGoogleContainer);
