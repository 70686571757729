import React, { PureComponent } from "react";
import {
  LinkImg,
  LinkImgCrop,
  Link,
  PublishingTime,
} from "~/components/Elements";
import PropTypes from "prop-types";
import { asideMappingFactory } from "~/utility/aside";
import { getGaInfo } from "~/utility/gaInfo";
import $ from "jquery";
import type { AsideProps } from "~/utility/aside";
import type { Node } from "react";
import type { PublishingTimeProps } from "~/components/Elements";
import { mapOrFail } from "./Colule";
type ColuleHeaderProps = {
  name: string;
  url: string;
};
type ArticleProps = {
  url: string;
  title: string;
};
type TopArticleProps = {
  image: string;
  annotation: string;
} & ArticleProps;
type MediaListingTopArticleProps = {
  baseUrl?: string;
  cropImage?: boolean;
} & TopArticleProps &
  PublishingTimeProps;
type ColuleMainProps = {
  sections: Array<any>;
  baseUrl: string;
  noSectionHeaders: boolean;
  coluleTitle: string;
  coluleUrl: string;
  coluleHeaders: Array<ColuleHeaderProps>;
  top_article: MediaListingTopArticleProps;
};
type MediaListingSectionProps = {
  name: string;
  url: string;
  baseUrl?: string;
  noSectionHeaders: boolean;
} & TopArticleProps;
type ColuleProps = {
  aside: Array<AsideProps>;
  asideHeaderSuffix: string;
  children: Node;
} & ColuleMainProps;
export default class Colule extends PureComponent<ColuleProps> {
  static propTypes = {
    coluleTitle: PropTypes.string,
    coluleUrl: PropTypes.string,
    coluleHeaders: PropTypes.array,
    sections: PropTypes.array,
    aside: PropTypes.array,
    baseUrl: PropTypes.string,
    noSectionHeaders: PropTypes.bool,
    asideHeaderSuffix: PropTypes.string,
  };

  render() {
    const aside = (this.props.aside || []).map(
      asideMappingFactory({
        headerSuffix: this.props.asideHeaderSuffix,
        gaInfo: getGaInfo(this.props, "aside"),
      })
    );
    return (
      <section className="section">
        <div className="container">
          <div className="l-columns clearfix">
            <div className="l-main pull-left">
              <ColuleMain
                coluleHeaders={this.props.coluleHeaders}
                coluleUrl={this.props.coluleUrl}
                coluleTitle={this.props.coluleTitle}
                sections={this.props.sections}
                top_article={this.props.top_article}
                baseUrl={this.props.baseUrl}
                noSectionHeaders={this.props.noSectionHeaders}
                gaInfo={getGaInfo(this.props, "main")}
              />
            </div>
            <aside className="l-sidebar pull-right">{aside}</aside>
          </div>

          {this.props.children}
        </div>
      </section>
    );
  }
}

class SectionElements extends PureComponent<{
  sections: any;
  baseUrl: string;
  noSectionHeaders: boolean;
}> {
  el: Node;

  constructor(props) {
    super(props);
    (this as any).doIt = this.doIt.bind(this);
  }

  componentDidMount() {
    this.doIt();
  }

  componentDidUpdate() {
    this.doIt();
  }

  doIt() {
    var $modules = ($ as any)(this.el).find("div.module");
    let maxHeights = [];

    for (let i = 0; i < $modules.length; i++) {
      const $section = $($modules[i]).find("div.jsHeight");

      for (let j = 0; j < $section.length; j++) {
        // const $el = $($section[j])
        // const height = $el.height();
        const height = $section[j].scrollHeight;
        maxHeights[j] = Math.max(height, maxHeights[j] || 0);
      }
    }

    for (let i = 0; i < $modules.length; i++) {
      const $section = $($modules[i]).find("div.jsHeight");

      for (let j = 0; j < $section.length; j++) {
        $($section[j]).height(maxHeights[j]);
      }
    }
  }

  render() {
    const sections = this.props.sections ? this.props.sections : [];
    return (
      <div ref={(el) => (this.el = el)}>
        {sections.map((v, i) => (
          <div
            className="mediaListing-item mobile-row col col-4 col-md-6 col-xs-12"
            key={i}
          >
            <div className="media">
              <LinkImg to={v.url} src={v.image} title={v.title} />
              <div className="media-content">
                <h4 className="media-title">
                  <Link to={v.url}>{v.title}</Link>
                </h4>
                {v.annotation}
                {/* <PublishingTime {...props} /> */}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

class ColuleMain extends PureComponent<ColuleMainProps> {
  render() {
    const sectionElements = (
      <SectionElements
        sections={this.props.sections}
        baseUrl={this.props.baseUrl}
        noSectionHeaders={this.props.noSectionHeaders}
      />
    );
    const headerElements = mapOrFail(this.props.coluleHeaders, (v, i) => (
      <li key={i}>
        <Link baseUrl={this.props.baseUrl} to={v.url}>
          {v.name}
        </Link>
      </li>
    ));
    const topArticleElement = (
      <MediaListingTopArticle
        baseUrl={this.props.baseUrl}
        {...this.props.top_article}
      />
    );
    return (
      <div className="module">
        <div className="moduleHeader">
          <h2 className="moduleHeader-title">
            {this.props.coluleUrl ? (
              <Link to={this.props.coluleUrl} baseUrl={this.props.baseUrl}>
                {this.props.coluleTitle}
              </Link>
            ) : (
              this.props.coluleTitle
            )}
          </h2>
          <ul className="moduleHeader-menu menu clearfix list-reset">
            {headerElements}
          </ul>
        </div>
        <div className="moduleMain">
          <div className="row row--sm row--verticalGutter row--grid">
            {topArticleElement}
            {sectionElements}
          </div>
        </div>
      </div>
    );
  }
}

const MediaListingTopArticle = (props: MediaListingTopArticleProps) => {
  const LImage = props.cropImage ? LinkImgCrop : LinkImg;
  return (
    <div className=" col col-12">
      <div className="media media--lg media--horizontal">
        <div className="row row--sm">
          <div className="col col-8 col-md-12">
            <LImage
              baseUrl={props.baseUrl}
              to={props.url}
              src={props.image}
              title={props.title}
            />
          </div>
          <div className="col col-4 col-md-12">
            <div className="media-content">
              <h4 className="media-title">
                <Link baseUrl={props.baseUrl} to={props.url}>
                  {props.title}
                </Link>
              </h4>
              <p className="media-text">{props.annotation}</p>
              <PublishingTime {...props} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

MediaListingTopArticle.propTypes = {
  url: PropTypes.string,
  src: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.string,
  annotation: PropTypes.string,
};

class MediaListingSection extends PureComponent<MediaListingSectionProps> {
  // static propTypes = {
  //   name: PropTypes.string,
  //   url: PropTypes.string,
  //   noSectionHeaders: PropTypes.bool,
  //   articles: PropTypes.arrayOf(
  //     PropTypes.shape({
  //       url: PropTypes.string,
  //       title: PropTypes.string,
  //     })
  //   ),
  //   top_article: PropTypes.shape({
  //     title: PropTypes.string,
  //     image: PropTypes.string,
  //     url: PropTypes.string,
  //     annotation: PropTypes.string,
  //   })
  // }
  render() {
    return (
      <div className="col col-3 col-md-6 col-xs-12">
        <div className="module">
          <h3 className="module-title">
            {this.props.noSectionHeaders && false ? null : (
              <Link
                baseUrl={this.props.baseUrl}
                to={this.props.url}
                gaInfo={this.props.gaInfo}
              >
                {this.props.title}
              </Link>
            )}
          </h3>
          {/*
          {top_article ?
          <div className="media media--sm">
           <LinkImg baseUrl={this.props.baseUrl} to={top_article.url} src={top_article.image} title={top_article.title} />
           <div className="media-content jsHeight">
             <h4 className="media-title">
               <Link baseUrl={this.props.baseUrl} to={top_article.url}>{top_article.title}</Link>
             </h4>
             <p className="media-text">
               {top_article.annotation}
             </p>
             <PublishingTime {...top_article} />
           </div>
          </div> : null
          }
          {articles ? articles.map((v, i) =>
          <div className="media media--minimal jsHeight" key={i}>
           <div className="media-content">
             <h4 className="media-title">
               <Link baseUrl={this.props.baseUrl} to={v.url}>{v.title}</Link>
             </h4>
           </div>
          </div>
          ) : null}
          */}
        </div>
      </div>
    );
  }
}

export { MediaListingTopArticle, MediaListingSection };
