import React, { PureComponent } from "react";
import { Helmet } from "react-helmet";
//const JsxArray = (props) => props.children
const fbAppId = "282867128448169";
const fbAdminsConf = [
  // "637790534", // Dorcak
  // "1076853708", // tomas
  "100000789967003",
  "1178990524",
  "1597324791",
  "1481590385",
  "100001507657756",
  "1402834241",
  "100000944240713",
  "1087125644",
  "1277051192",
  "702763004",
  "1511665091", // Patrik Illith
];
export interface MetaTagsProps {
  url: string;
  title: string;
  description: string;
  image: string;
  authorUrl: string;
  tags?: string[];
  time: {
    published: string;
    modified: string;
    year: number;
  };
  objectType: "article";
  sectionName?: string;
}
export interface MetaTagsStaticProps {
  url: string;
  title: string;
  objectType: "page";
}
export default class MetaTags extends PureComponent<{
  data: MetaTagsProps;
}> {
  render() {
    /*
    const data = {
      url: "https://www.teraz.sk/shareurl",
      title: "Zomrel známy spevák zo skupiny Soundgarden",
      description: "Hudobník Chris Cornell je známy vďaka kapelám Soundgarden a Audioslave.", // limit 160 chars
      image: "http://www1.teraz.sk/usercontent/photos/0/4/a/2-04a191f6b02df1dbf35794e311eac9a00f84faaf.jpg",
      author: {
        url: "https://teraz.sk/autorska-url",
      },
      time: {
        published: "2017-05-18T13:21:42-04:00", //ISO 8601
        modified: "2017-05-18T13:21:42-04:00", //ISO 8601
      },
      objectType: "article",
      sectionName: "Svet",
    }
    */
    const data = this.props.data;
    return (
      /*<JsxArray>*/
      <Helmet>
        <title>
          {data.title ||
            "Teraz.sk - spravodajský portál tlačovej agentúry TASR"}
        </title>

        {/* Facebook / OpenGraph */}
        <meta property="og:type" content="article" />
        <meta property="og:url" content={data.url} />
        {/*<meta property="og:type" content={data.objectType}/>*/}
        <meta property="og:title" content={data.title} />
        <meta property="og:description" content={data.description} />
        <meta property="og:image" content={data.image} />
        <meta property="og:image:type" content={"image/jpeg"} />
        {data.tags ? (
          <meta name="keywords" lang="sk" content={data.tags.join(",")} />
        ) : null}

        {/* Plain meta */}
        <meta name="description" content={data.description} />

        <meta property="article:author" content={data.authorUrl} />
        {data.time.published ? (
          <meta
            property="article:published_time"
            content={data.time.published}
          />
        ) : null}
        {data.time.modified ? (
          <meta property="article:modified_time" content={data.time.modified} />
        ) : null}
        {data.sectionName ? (
          <meta property="article:section" content={data.sectionName} />
        ) : null}
        <meta
          name="copyright"
          content={`Copyright (C) ${data.time.year} TASR, tasr.sk`}
        />
        {/* Twitter */}
        <meta name="twitter:url" content={data.url} />
      </Helmet>
      /*</JsxArray>*/
    );
  }
}

export class MetaTagsStatic extends PureComponent<{data: MetaTagsStaticProps}>{
  render() {
    const data = this.props.data;
    return (
      /*<JsxArray>*/
      <Helmet>
        <title>
          {data.title ||
            "Teraz.sk - spravodajský portál tlačovej agentúry TASR"}
        </title>

        {/* Facebook / OpenGraph */}
        <meta property="og:title" content={data.title} />
        <meta property="og:type" content="page" />
        <meta property="og:url" content={data.url} />        
        <meta property="og:site_name" content="TERAZ.sk" />
        <meta property="og:image" content="/img/fb-share-teraz.png"/>
        <meta property="og:image:type" content={"image/png"} />

        {/* Plain meta */}
        {/* <meta name="description" content={data.description} /> */}

        {/* <meta property="article:author" content={data.authorUrl} /> */}
        {/* Twitter */}
        <meta name="twitter:url" content={data.url} />
      </Helmet>
      /*</JsxArray>*/
    );
  }
}
export class MetaTagsDefault extends PureComponent<void> {
  render() {
    const fbAdmins = fbAdminsConf.map((a, i) => (
      <meta property="fb:admins" content={a} key={i} />
    ));
    return (
      <Helmet>
        <title>Teraz.sk - spravodajský portál tlačovej agentúry TASR</title>

        <link rel="manifest" href="/manifest.json" />
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/favicon/apple-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/favicon/apple-icon-60x60.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/favicon/apple-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/favicon/apple-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/favicon/apple-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/favicon/apple-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/favicon/apple-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/favicon/apple-icon-152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicon/apple-icon-180x180.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/favicon/android-icon-192x192.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="/favicon/favicon-96x96.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon/favicon-16x16.png"
        />
        <meta
          name="msapplication-TileImage"
          content="/favicon/ms-icon-144x144.png"
        />
        <meta name="msapplication-TileColor" content="#137fc5" />
        <meta name="theme-color" content="#137fc5" />

        <meta name="google-site-verification" content="sHkCahai37YoEmQmocAHdLzDsNXrzYjalRtiinbpu_4" />

        {/* Plain meta */}
        <meta name="author" content="Teraz.sk" />
        <meta name="creator" content="TASR" />
        <meta name="publisher" content="Teraz.sk" />
        {/* <meta 
         name="keywords" 
         lang="sk" 
         content="správy, spravodajstvo, noviny, ekonomika, šport, politika, teraz, tasr, portal"
        /> */}

        <meta property="article:publisher" content="https://www.teraz.sk/" />
        <meta name="copyright" content="Copyright (C) TASR, tasr.sk" />
        <meta name="webdesign" content="ADIT Agency s.r.o." />

        {/* Facebook / OpenGraph */}
        <meta property="og:site_name" content="TERAZ.sk" />
        {/* <meta property="og:title" content="Teraz.sk - spravodajský portál tlačovej agentúry TASR" /> */}
        <meta property="og:image" content="/img/fb-share-teraz.png"/>
        <meta property="og:locale" content="sk_SK" />
        {fbAdmins}
        <meta property="fb:app_id" content={fbAppId} />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="terazsk" />
        <meta name="twitter:creator" content="terazsk" />
      </Helmet>
    );
  }
}
