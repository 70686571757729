import React, { Component } from "react";
import { apiUrlTeraz } from "~/utility/api";
import { connect, ConnectedProps } from "react-redux";
import { fetchWagon } from "~/store/actions";
import { wagonMapStateToProps } from "~/store/mappers";
import Wagon from "~/components/wagons/Wagon";
import { GAInfoProp } from "~/utility/gaInfo";

function createWagonSectionOne({ id, coluleTitle, coluleUrl, coluleHeaders }) {
  class WagonSectionContainer extends Component<WagonSectionOneProps> {
    url: string;
    constructor(props) {
      super(props);
      const tipsPage = this.props.tipsPage || 0;
      this.url = `${apiUrlTeraz}api_rest.php?module=RestApi&method=wagonSections&sections=${id}&type=sport&tipsPage=${tipsPage}`;
    }

    UNSAFE_componentWillMount() {
      const { dispatch } = this.props;
      dispatch(fetchWagon(this.url, this.props.required));
    }

    render() {
      return (
        <Wagon
          name={this.props.name}
          {...this.props[this.url]}
          coluleTitle={coluleTitle}
          coluleUrl={coluleUrl}
          skipTips={!(this.props.tipsPage && this.props.tipsPage > 0)}
          coluleHeaders={coluleHeaders}
          noSectionHeaders={true}
          gaInfo={this.props.gaInfo} // asideHeaderSuffix="v Športe"
        />
      );
    }
  }

  const connector = connect(wagonMapStateToProps);
  type WagonSectionOneProps = ConnectedProps<typeof connector> & {
    tipsPage: number;
    required: boolean;
    name: string;
  } & GAInfoProp;

  return connector(WagonSectionContainer);
}

export default createWagonSectionOne;
