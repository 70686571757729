import { useEffect, useState } from "react";

export function useConsenstString(fisrtPartConsent, consentString, lastPartConsent) {
    const [htmlConsentString, setHtmlConsentString] = useState("");
    useEffect(function mount() {
      const composeConsentString = function(event) {
        consentString = event.detail;
        setHtmlConsentString(fisrtPartConsent + consentString + lastPartConsent) 
      }
      
      if (process.env.BUILD_TARGET !== "server") {
        window.addEventListener("changeConsentString", composeConsentString, false);
      }

      return function cleanup() {
        
        if (process.env.BUILD_TARGET !== "server") {
          window.removeEventListener('changeConsentString', composeConsentString, false);
        }
      };
    }, []);
  
    return htmlConsentString;
  }