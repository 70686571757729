import React, { PureComponent } from "react";
import {
  LinkImg,
  LinkImgCrop,
  Link,
  Line,
  PublishingTime,
} from "~/components/Elements";
import PropTypes from "prop-types";
import { asideMappingFactory } from "~/utility/aside";
import { getGaInfo } from "~/utility/gaInfo";
import $ from "jquery";
import type { AsideProps } from "~/utility/aside";
import type { Node } from "react";
import type { PublishingTimeProps } from "~/components/Elements";

/* eslint-disable no-use-before-define */
export const mapOrFail = (
  data: Array<ColuleHeaderProps>,
  fn: (v: ColuleHeaderProps, i: number) => Node
) => (data && data !== null ? data.map(fn) : null);
//const renderOrFail = (data, fn) => (data && data !==null) ? fn(data) : null

/* eslint-enable no-use-before-define */
type ColuleHeaderProps = {
  name: string;
  url: string;
};
type ArticleProps = {
  url: string;
  title: string;
};
type TopArticleProps = {
  image: string;
  annotation: string;
} & ArticleProps;
type MediaListingTopArticleProps = {
  baseUrl?: string;
  cropImage?: boolean;
} & TopArticleProps &
  PublishingTimeProps;
type ColuleMainProps = {
  sections: Array<any>;
  baseUrl: string;
  noSectionHeaders: boolean;
  coluleTitle: string;
  coluleUrl: string;
  coluleHeaders: Array<ColuleHeaderProps>;
  top_article: MediaListingTopArticleProps;
};
type MediaListingSectionProps = {
  name: string;
  url: string;
  baseUrl?: string;
  noSectionHeaders: boolean;
  articles: Array<ArticleProps>;
  top_article: TopArticleProps;
};
type ColuleProps = {
  aside: Array<AsideProps>;
  asideHeaderSuffix: string;
  children: Node;
} & ColuleMainProps;
export default class Colule extends PureComponent<ColuleProps> {
  static propTypes = {
    coluleTitle: PropTypes.string,
    coluleUrl: PropTypes.string,
    coluleHeaders: PropTypes.array,
    sections: PropTypes.array,
    aside: PropTypes.array,
    baseUrl: PropTypes.string,
    noSectionHeaders: PropTypes.bool,
    asideHeaderSuffix: PropTypes.string,
  };

  render() {
    const gaInfo = getGaInfo(this.props, this.props.coluleTitle);
    const asideMapping = asideMappingFactory({
      headerSuffix: this.props.asideHeaderSuffix,
      gaInfo: gaInfo,
    });
    const aside = (this.props.aside || []).map(asideMapping);
    return (
      <section className="section">
        <div className="container">
          <div className="l-columns clearfix">
            <div className="l-main pull-left">
              <ColuleMain
                coluleHeaders={this.props.coluleHeaders}
                coluleUrl={this.props.coluleUrl}
                coluleTitle={this.props.coluleTitle}
                sections={this.props.sections}
                top_article={this.props.top_article}
                baseUrl={this.props.baseUrl}
                noSectionHeaders={this.props.noSectionHeaders}
                gaInfo={gaInfo}
              />
            </div>
            <aside className="l-sidebar pull-right">{aside}</aside>
          </div>

          {this.props.children}
        </div>
      </section>
    );
  }
}

class SectionElements extends PureComponent<{
  sections: any;
  baseUrl: string;
  noSectionHeaders: boolean;
}> {
  el: Node;

  constructor(props) {
    super(props);
    (this as any).doIt = this.doIt.bind(this);
  }

  componentDidMount() {
    this.doIt();
  }

  componentDidUpdate() {
    this.doIt();
  }

  doIt() {
    var $modules = ($ as any)(this.el).find("div.module");
    let maxHeights = [];

    for (let i = 0; i < $modules.length; i++) {
      const $section = $($modules[i]).find("div.jsHeight");

      for (let j = 0; j < $section.length; j++) {
        // const $el = $($section[j])
        // const height = $el.height();
        const height = $section[j].scrollHeight;
        maxHeights[j] = Math.max(height, maxHeights[j] || 0);
      }
    }

    for (let i = 0; i < $modules.length; i++) {
      const $section = $($modules[i]).find("div.jsHeight");

      for (let j = 0; j < $section.length; j++) {
        $($section[j]).height(maxHeights[j]);
      }
    }
  }

  render() {
    const loading = !!this.props.sections;
    const sections = loading ? this.props.sections : Array(4).fill({});
    return (
      <div ref={(el) => (this.el = el)}>
        {sections.map((v, i) => (
          <MediaListingSection
            key={i}
            baseUrl={this.props.baseUrl}
            noSectionHeaders={this.props.noSectionHeaders}
            gaInfo={getGaInfo(this.props, v.name)}
            {...v}
          />
        ))}
      </div>
    );
  }
}

class ColuleMain extends PureComponent<ColuleMainProps> {
  render() {
    const sectionElements = (
      <SectionElements
        sections={this.props.sections}
        baseUrl={this.props.baseUrl}
        noSectionHeaders={this.props.noSectionHeaders}
        gaInfo={this.props.gaInfo}
      />
    );
    const headerElements = mapOrFail(this.props.coluleHeaders, (v, i) => (
      <li key={i}>
        <Link
          baseUrl={this.props.baseUrl}
          to={v.url}
          gaInfo={this.props.gaInfo}
        >
          {v.name}
        </Link>
      </li>
    ));
    const topArticleElement = (
      <MediaListingTopArticle
        baseUrl={this.props.baseUrl}
        {...this.props.top_article}
        gaInfo={getGaInfo(this.props, "topArticle")}
      />
    );
    return (
      <div className="module">
        <div className="moduleHeader">
          <h2 className="moduleHeader-title">
            {this.props.coluleUrl ? (
              <Link
                to={this.props.coluleUrl}
                baseUrl={this.props.baseUrl}
                gaInfo={getGaInfo(this.props, "header")}
              >
                {this.props.coluleTitle}
              </Link>
            ) : (
              this.props.coluleTitle
            )}
          </h2>
          <ul className="moduleHeader-menu menu clearfix list-reset">
            {headerElements}
          </ul>
        </div>
        <div className="moduleMain">
          <div className="row row--sm row--verticalGutter row--grid">
            {topArticleElement}
            {sectionElements}
          </div>
        </div>
      </div>
    );
  }
}

const MediaListingTopArticle = (props: MediaListingTopArticleProps) => {
  const LImage = props.cropImage ? LinkImgCrop : LinkImg;
  return (
    <div className=" col col-12">
      <div className="media media--lg media--horizontal">
        <div className="row row--sm">
          <div className="col col-8 col-md-12">
            <LImage
              baseUrl={props.baseUrl}
              to={props.url}
              src={props.image}
              title={props.title}
              gaInfo={props.gaInfo}
            />
          </div>
          <div className="col col-4 col-md-12">
            <div className="media-content">
              <h4 className="media-title">
                <Link
                  baseUrl={props.baseUrl}
                  to={props.url}
                  gaInfo={props.gaInfo}
                >
                  {props.title}
                </Link>
              </h4>
              <p className="media-text">{props.annotation}</p>
              <PublishingTime {...props} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

MediaListingTopArticle.propTypes = {
  url: PropTypes.string,
  src: PropTypes.string,
  title: PropTypes.string,
  annotation: PropTypes.string,
};

class MediaListingSection extends PureComponent<MediaListingSectionProps> {
  static propTypes = {
    name: PropTypes.string,
    url: PropTypes.string,
    noSectionHeaders: PropTypes.bool,
    articles: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        title: PropTypes.string,
      })
    ),
    top_article: PropTypes.shape({
      title: PropTypes.string,
      image: PropTypes.string,
      url: PropTypes.string,
      annotation: PropTypes.string,
    }),
  };

  render() {
    const loading = this.props.articles || this.props.top_article;
    const articles = loading
      ? this.props.articles
      : Array(6).fill({
          url: "",
          title: <Line />,
        });
    const top_article = loading ? this.props.top_article : {};

    if (this.props.top_article instanceof Array) {
      console.error(this.props.top_article);
    }

    return (
      <div className="col col-3 col-md-6 col-xs-12">
        <div className="module">
          <h3 className="module-title">
            {this.props.noSectionHeaders ? null : (
              <Link
                baseUrl={this.props.baseUrl}
                to={this.props.url}
                gaInfo={this.props.gaInfo}
              >
                {this.props.name}
              </Link>
            )}
          </h3>

          {top_article ? (
            <div className="media media--sm mobile-row">
              <LinkImg
                baseUrl={this.props.baseUrl}
                to={top_article.url}
                src={top_article.image}
                title={top_article.title}
                gaInfo={this.props.gaInfo}
              />
              <div className="media-content jsHeight">
                <h4 className="media-title">
                  <Link
                    baseUrl={this.props.baseUrl}
                    to={top_article.url}
                    gaInfo={this.props.gaInfo}
                  >
                    {top_article.title}
                  </Link>
                </h4>
                <p className="media-text">{top_article.annotation}</p>
                <PublishingTime {...top_article} />
              </div>
            </div>
          ) : null}

          {articles
            ? articles.map((v, i) => (
                <div className="media media--minimal jsHeight" key={i}>
                  <div className="media-content">
                    <h4 className="media-title">
                      <Link
                        baseUrl={this.props.baseUrl}
                        to={v.url}
                        gaInfo={this.props.gaInfo}
                      >
                        {v.title}
                      </Link>
                    </h4>
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
    );
  }
}

export { MediaListingTopArticle, MediaListingSection };
