import { Component } from "react";
import { connect } from "react-redux";

class ThemeChanger extends Component {
  render() {
    if (process.env.BUILD_TARGET !== "server") {
      document.getElementsByTagName("body")[0].className = `${
        this.props.themeClass
      } ${this.props.themeScrollClass || ""} ${
        this.props.isOffCanvasActive
          ? "is-offCanvasActive"
          : this.props.isOffCanvasActive
      }`;
    } else {
      //TODO: upravit dom na serveri
    }

    return null;
  }
}

const mapStateToProps = (state) => {
  const { theme } = state;
  return { ...theme };
};

export default connect(mapStateToProps)(ThemeChanger);
