import React from "react";
import { facebookAppId } from "./index";

function FacebookShareButton(props) {
  return props.canonicalUrl ? (
    <iframe
      className={props.className}
      src={`https://www.facebook.com/plugins/share_button.php?href=${encodeURIComponent(
        props.canonicalUrl
      )}&layout=button_count&size=large&mobile_iframe=true&appId=${facebookAppId}&width=106&height=28`}
      width="106"
      height="28"
      style={{
        border: "none",
        overflow: "hidden",
      }}
      scrolling="no"
      frameBorder="0"
      allowtransparency="true"
    />
  ) : null;
}

export default FacebookShareButton;
