import React, { PureComponent } from "react";
import InnerHTML from "../base/InnerHtml";
import { MetaTagsStatic } from "~/components/base/MetaTags";
import type { MetaTagsStaticProps } from "../base/MetaTags";
import { registerRSS } from "~/utility/api";

type StaticPageProps = {
  // canonical_url: string;
  // totalShareCount: number;
  image: string;
  // image_caption: string;
  // description: string;
  title: string;
  url: string;
  html: string;
}
export default class StaticPage extends PureComponent<StaticPageProps> {
  constructor(props) {
    super(props);
    this.state = {
      RSSMessage: null
    };
  }


  registerRSS(e: Event) {
    e.preventDefault();

    if (e.target.email && typeof e.target.email.value === "string") {
      const text: string = e.target.email.value;

      registerRSS(text).then((data) => {
        this.setState({ RSSMessage: data });
      });
    }
  }

  render() {
    const props = this.props;    

    const canonical_url = process.env.BUILD_TARGET !== "server" ? window.location.href : "";
    const rss_url = process.env.BUILD_TARGET !== "server" ? window.location.pathname : "";

    const metaData: MetaTagsStaticProps = {
      url: canonical_url,
      title: props.title,
      objectType: "page",
    };
    (this as any).registerRSS = this.registerRSS.bind(this);

    return (
      <div>
        <MetaTagsStatic data={metaData} />
        <InnerHTML className="container static-page" html={props.html} />

        {rss_url == '/rss' &&
          <div className="container static-page">
            <span>Prihláste sa k tejto službe. <strong>Zadajte svoj e-mail:</strong></span>
            <form
              id="registerRSSForm"
              name="registerRSSForm"
              onSubmit={this.registerRSS}
            >
              <input type="email" name="email" required />
              <button type="submit">Prihlásiť</button>
            </form>
            { (this.state.RSSMessage != null) && (this.state.RSSMessage.success) &&
              <div className="formMessage">{this.state.RSSMessage.success}</div>
            }
            { (this.state.RSSMessage != null) && (this.state.RSSMessage.error) &&
              <div className="formMessage error">{this.state.RSSMessage.error}</div>
            }
          </div>        
        }
      </div>
    );
  }
}
