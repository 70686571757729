import { configureStore, EnhancedStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import thunk from "redux-thunk";
import appReducers from "./reducers";

export function getStore() {
  /* 
  Use this function if you need new instance of store for example 
  when doing server side 
  */
  if (process.env.BUILD_TARGET === "client") {
    const preloadedState = window.__PRELOADED_STATE__;
    delete window.__PRELOADED_STATE__;
    return configureStore({
      reducer: appReducers,
      middleware: [thunk],
      preloadedState: preloadedState,
    });
  }
  return configureStore({
    reducer: appReducers,
    middleware: [thunk],
  });
}

const store = getStore();

export type RootState = ReturnType<typeof store.getState>;
export type AppStateStore = EnhancedStore<RootState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export default store;
