import React, { Component } from "react";
import log from "~/utility/log";

export default abstract class UpdatingPage<PropsT> extends Component<PropsT> {
  abstract getUrl(props: PropsT): string;
  abstract update();

  componentDidMount() {
    log.info(`UpdatingPage.componentDidMount`);
    this.update();
  }

  componentDidUpdate(prevProps: PropsT) {
    log.info("UpdatingPage.componentDidUpdate");
    if (this.getUrl(this.props) !== this.getUrl(prevProps)) {
      this.update();
    }
  }

  UNSAFE_componentWillMount() {
    this.update();
  }

  UNSAFE_componentWillReceiveProps(nextProps: PropsT) {
    if (this.getUrl(this.props) !== this.getUrl(nextProps)) {
      this.update();
    }
  }

  // getDerivedStateFromProps(props, state) {
  //   this.update();
  // }
}
