import React, { Component } from "react";
import PropTypes from "prop-types";
import { Line } from "~/components/Elements";
const baseUrl = "http://webmagazin.teraz.sk/";
export default class WebmagazinTabbedAsideListing extends Component {
  static propTypes = {
    articles: PropTypes.object,
  };
  static defaultProps = {
    tabs: [
      {
        name: "48h",
        key: "last_48h",
        header: "Najčítanejšie",
        active: true,
      },
      {
        name: "14d",
        key: "last_14d",
        header: "Najčítanejšie",
      },
    ],
  };

  constructor(props) {
    super(props);
    this.state = {
      current_tab: this.props.tabs.filter((t) => t.active)[0].key,
    };
  }

  setActiveTabFactory(key) {
    return (event) => {
      event.preventDefault();
      this.setState({
        current_tab: key,
      });
    };
  }

  render() {
    const currentTabArticles =
      (this.props.articles
        ? this.props.articles[this.state.current_tab]
        : null) || Array(7).fill({});
    const headerTitle = this.props.tabs.find(
      (x) => x.key === this.state.current_tab
    ).header;
    return (
      <div className="module">
        <div className="moduleHeader moduleHeader--light moduleHeader--sidebar">
          <h3 className="moduleHeader-title">
            {headerTitle} {this.props.headerSuffix}
          </h3>
          <ul className="moduleHeader-tabs pull-right list-reset">
            {this.props.tabs.map((v, i) => (
              <li
                className={v.key === this.state.current_tab ? "is-active" : ""}
                key={i}
              >
                <a href="#" onClick={this.setActiveTabFactory(v.key)}>
                  {v.name || <Line />}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="moduleMain">
          <ol className="listing listing--light list-reset">
            {currentTabArticles.map((v, i) => (
              <Item i={i} url={baseUrl + v.url} title={v.title} key={i} />
            ))}
          </ol>
        </div>
      </div>
    );
  }
}

const Item = (props) => (
  <li className="listingItem">
    <a href={props.url} className="listingItem-link">
      <span className="listingItem-number">{props.i + 1}</span>
      <h4 className="listingItem-title">{props.title || <Line />}</h4>
    </a>
  </li>
);
