import React, { Component } from "react";
import PropTypes from "prop-types";
import TabbedAsideListing, {
  TabbedAsideListingArticle,
} from "./TabbedAsideListing";
import AsideListing from "../single/AsideListing";
import { GAInfoProp, getGaInfo } from "~/utility/gaInfo";
import Banner from "~/components/specialBanners/Kalendarium";
import BannerCofoe from "~/components/specialBanners/CoFoE";
import NajnovsieSpravy from "~/components/specialBanners/NajnovsieSpravy";
import clsx from "clsx";
import BannerJinx from "~/components/specialBanners/jinx";

type NewsTabbedAsideListingProps = {
  headerSuffix: string;
  articles: TabbedAsideListingArticle[][];
  baseUrl?: string;
  isIndex?: boolean;
};
export default class NewsTabbedAsideListing extends Component<
  NewsTabbedAsideListingProps & GAInfoProp
> {
  static propTypes = {
    articles: PropTypes.object,
  };

  render() {
    const hs = this.props.headerSuffix;
    const tabs = [
      // {name: 'nové', key: 'new', header: 'Najnovšie ' + hs, url: '/najnovsie'},
      {
        name: "6h",
        key: "last_6h",
        header: "Najčítanejšie " + hs,
        active: true,
      },
      {
        name: "24h",
        key: "last_24h",
        header: "Najčítanejšie " + hs,
      },
      {
        name: "7d",
        key: "last_7d",
        header: "Najčítanejšie " + hs,
      },
    ];
    const showOnMobile = !!this.props.isIndex;
    const newestArticles = (this.props.articles["new"] || []).slice(0, 1);
    return (
      <div>
        {this.props.isIndex ? (
          <>
          {/* <BannerCofoe className={clsx({ mobileHidden: !showOnMobile })} /> */}
          <Banner className={clsx({ mobileHidden: !showOnMobile })} />
          {/* <BannerJinx className={clsx({ mobileHidden: !showOnMobile })} /> */}
          </>
        ) : null}
        <TabbedAsideListing
          articles={this.props.articles}
          tabs={tabs}
          showOnMobile={showOnMobile}
          gaInfo={getGaInfo(this.props, `Najčítanejšie ${hs}`)}
        />
        {newestArticles.length > 0 ? (
          // <AsideListing
          //   articles={newestArticles || []}
          //   url_next="/najnovsie"
          //   url_title="/najnovsie"
          //   className={clsx({ mobileHidden: !showOnMobile })}
          //   gaInfo={getGaInfo(this.props, `Najnovšie ${hs}`)}
          // />
          <NajnovsieSpravy />
        ) : null}
      </div>
    );
  }
}
