import React, { Component } from "react";
import PhotoDayListing from "~/components/photogallery/PhotoDayListing";
//import data from 'data/api/pages/photo_day_listing'
import LoaderIndex from "~/containers/LoaderIndex";
import { apiUrlTeraz } from "~/utility/api";
import {
  themeClass,
  headerSuffix,
  selectPage,
  fetchPage,
} from "~/store/actions";
import { connect } from "react-redux";
import { pageMapStateToProps } from "~/store/mappers";

class PhotoDayListingContainer extends Component {
  update() {
    const { dispatch } = this.props;

    if (!this.props.isLoading) {
      const cat =
        this.props && this.props.category ? this.props.category.name : null;
      const catUrl =
        this.props && this.props.category && this.props.category.url
          ? this.props.category.url
          : null;
      const themeClassName = catUrl ? "theme_" + catUrl : null;
      dispatch(headerSuffix(cat, catUrl));
      dispatch(themeClass(themeClassName));
    }
  }

  //componentDidMount(){
  UNSAFE_componentWillMount() {
    const month = this.props.match.params.month;
    const url = `${apiUrlTeraz}api_rest.php?module=RestApi&method=photoDayListing&month=${month}`;
    const { dispatch } = this.props;
    dispatch(selectPage(url));
    dispatch(fetchPage(url)); //this.update() //FIXME
  }

  componentDidUpdate() {
    this.update();
  }

  render() {
    return (
      <div>
        <PhotoDayListing
          {...this.props}
          month={this.props.match.params.month}
        />
        <LoaderIndex gaInfo={this.props.gaInfo} />
      </div>
    );
  }
}

export default connect(pageMapStateToProps)(PhotoDayListingContainer);
