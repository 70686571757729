import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from 'react-router-dom';
// import BannerHorizontal from 'components/banners/BannerHorizontal'
// import RightNow from "~/components/RightNow";
import TipsHorizontal from "~/components/TipsHorizontal";
import TopArticles from "~/components/TopArticles";
import TopArticlesGrid from "~/components/TopArticlesGrid";
import HotTopics from "~/components/HotTopics";
import { Web2MediaDesktopSkinnyUpdate } from "~/components/specialBanners/Web2MediaDesktopSkinnyUpdate";
// import ElectionsBanner from "~/components/specialBanners/ElectionsBanner";
import AgregatorSocSieti from "~/components/specialBanners/AgregatorSocSieti";
import ElectionsBanner from "~/components/specialBanners/Eurovolby2024";
import ReferendumBanner from "~/components/specialBanners/Referendum2023";
import { asideMappingFactory } from "~/utility/aside";
import { getGaInfo } from "~/utility/gaInfo";
import { useWagonHeight } from "./wagonHeightHook";

const WagonIndex = (props) => {
  const gaInfo = getGaInfo(props, "wIndex");
  const [showElectionsBanner, setShowElectionsBanner] = useState(false);
  const location = useLocation();

  const forceRemountKey = location.pathname + location.search;

  useEffect(() => {    
    if (typeof document !== 'undefined'){
      const adguru_side_left = document.getElementById('adguru_side_left');
      const adguru_side_right = document.getElementById('adguru_side_right');
      const adguru = document.getElementById('adguru');
      if (adguru_side_left) {
        adguru_side_left.parentNode.removeChild(adguru_side_left);
      }
      if (adguru_side_right) {
        adguru_side_right.parentNode.removeChild(adguru_side_right);
      }
      if (adguru) {
        adguru.parentNode.removeChild(adguru);
      }
    }

    return () => {
    };
  }, [forceRemountKey]);

  const asideMapping = asideMappingFactory({
    headerSuffix: "",
    gaInfo: gaInfo,
  });

  const getIp = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    return res.data.ip;
  };

  const ipTASR = '195.46.72.';
  const ipKubo = '87.197.146.153';
  const ipKuboDoma = '95.102.218.189';
  const ipKarci = '81.92.253.99';

  // getIp().then((ip)=>{
  //   if(ip === ipKubo ||
  //     ip === ipKuboDoma ||
  //     ip === ipKarci ||
  //   ip === '62.197.241.122' ||
  //   ip.startsWith(ipTASR)){
  //     setShowElectionsBanner(true);
  //   }
  // }) 


  const ref = useWagonHeight("index");
  return (
    <div ref={ref}>     
    {/* { showElectionsBanner &&
      <ElectionsBanner></ElectionsBanner>
    } */}
      {/* <AgregatorSocSieti></AgregatorSocSieti> */}
      {props.flash_news ? null : (        
        <HotTopics hotTopics={props.hot_topics} gaInfo={gaInfo} />
      )}      
      <section className="section">
        <div className="banner-wrap">
          <Web2MediaDesktopSkinnyUpdate key={forceRemountKey} />
        </div>
        <div className="border-top container">
          <TopArticlesGrid
            aside={props.aside}
            articles={props.articles}
            top_article={props.top_article}
            right_now={props.right_now}
            broadcasts={props.broadcasts}
            gaInfo={gaInfo}
            location={forceRemountKey}
          />
          {/* <RightNow items={props.right_now} /> */}
          <TipsHorizontal
            items={props.tips} // noHeaderColumnLine={true}
            gaInfo={gaInfo}
          />
          {/* <BannerHorizontal {...props.banner_horizontal} /> */}          
          {(props.splits || []).map(asideMapping)}
        </div>
      </section>
    </div>
  );
};

export default WagonIndex;
