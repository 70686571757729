import React from "react";
import { LinkImg, Link, Line } from "~/components/Elements";
export const PhotoDayRowItem = (props) => (
  <div className="col col-3 col-md-4 col-sm-6 col-xs-12">
    <div className="media media--foto media--withFloatLabel">
      <time className="media-floatLabel" dateTime={props.date_ISO}>
        {props.date}
      </time>
      <LinkImg to={props.url} src={props.image} title={props.title} />
      <div className="media-content">
        <h4 className="media-title">
          <Link to={props.url}>{props.title || <Line />}</Link>
        </h4>
      </div>
    </div>
  </div>
);
