import React from "react";
import BannerSquare from "~/components/banners/BannerSquare";
import NewsTabbedAsideListing from "~/components/aside/tabbed/NewsTabbedAsideListing";
import BannerHorizontal from "~/components/banners/BannerHorizontal";
import TipsHorizontal from "~/components/TipsHorizontal";
import WebmagazinTabbedAsideListing from "~/components/aside/tabbed/WebmagazinTabbedAsideListing";
import AsideListing from "~/components/aside/single/AsideListing";
import VyhlaseniaAside from "~/components/aside/single/VyhlaseniaAside";
import PhotoAside from "~/components/aside/single/PhotoAside";
import OtherWebsTASR from "~/components/aside/single/OtherWebsTASR";
import BoxPreferencie from "~/components/aside/single/BoxPreferencie";
import StaticPanel from "~/components/aside/single/StaticPanel";
import AdOceanSlave from "~/components/base/AdOceanSlave";
import { GAInfo } from "./gaInfo";
import { Web2MediaBanner300 } from "~/components/specialBanners/Web2MediaBanner300x600";
import { Web2MediaDesktopSkinny } from "~/components/specialBanners/Web2MediaDesktopSkinny";
// TYPES
type AsideBannerSquareProps = {
  type: "banner_square";
  baseUrl?: string;
  data: any;
};
type AsideNewsTabbedListingProps = {
  type: "top_articles";
  baseUrl?: string;
  data: any;
};
type AsideBannerHorizontalProps = {
  type: "banner_horizontal";
  baseUrl?: string;
  data: any;
};
type AsideTipsHorizontalProps = {
  type: "tips";
  baseUrl?: string;
  data: any;
};
type AsideListingProps = {
  type: "recent_articles";
  baseUrl?: string;
  data: any;
};
type AsidePhotoProps = {
  type: "photo_aside";
  baseUrl?: string;
  data: any;
};
type AsideOtherWebsProps = {
  type: "other_webs_tasr";
  baseUrl?: string;
  data: any;
};
type AsideBoxPreferencieProps = {
  type: "box_preferencie";
  baseUrl?: string;
};
type AsideStaticPanelProps = {
  type: "static_panel";
  data: {
    content: string;
  };
};
type AsideAdocean = {
  type: "adocean_banner_horizontal" | "adocean_banner_square";
  data: {};
};
type AsideVyhlasenia = {
  type: "vyhlasenia";
  data: {}
};
export type AsideProps =
  | AsideBannerSquareProps
  | AsideNewsTabbedListingProps
  | AsideBannerHorizontalProps
  | AsideTipsHorizontalProps
  | AsideListingProps
  | AsidePhotoProps
  | AsideOtherWebsProps
  | AsideBoxPreferencieProps
  | AsideStaticPanelProps
  | AsideAdocean
  | AsideVyhlasenia;

type AsideMappingFactoryProps = {
  headerSuffix: string;
  baseUrl?: string;
  gaInfo?: GAInfo;
  isIndex?: boolean;
};
export function asideMappingFactory(props: AsideMappingFactoryProps) {
  const baseUrl = props["baseUrl"];
  const headerSuffix = props["headerSuffix"];
  const gaInfo = props["gaInfo"];
  const isIndex = props['isIndex'];
  return function asideMapping(d: AsideProps, i: number) {
    let el = null;

    if (d && d.type) {
      if (d.type === "banner_square") {
        el = <BannerSquare {...d.data} key={i} />;
      } else if (d.type === "top_articles") {
        if (d.data && d.data.component_flavor === "webmagazin") {
          el = (
            <WebmagazinTabbedAsideListing
              articles={d.data.articles}
              headerSuffix={d.data.headerSuffix || headerSuffix || ""}
              baseUrl={d.data.baseUrl || baseUrl}
              key={i}
              gaInfo={gaInfo}
            />
          );
        } else {
          el = (
            <NewsTabbedAsideListing
              articles={d.data.articles}
              headerSuffix={d.data.headerSuffix || headerSuffix || ""}
              baseUrl={d.data.baseUrl || baseUrl}
              key={i}
              isIndex={isIndex}
              gaInfo={gaInfo}
            />
          );
        }
      } else if (d.type === "banner_horizontal") {
        el = <BannerHorizontal {...d.data} key={i} />;
      } else if (d.type === "tips") {
        el = <TipsHorizontal items={d.data} key={i} gaInfo={gaInfo} />;
      } else if (d.type === "recent_articles") {
        el = (
          <AsideListing
            {...d.data}
            headerSuffix={d.data.headerSuffix || headerSuffix || ""}
            baseUrl={d.data.baseUrl || baseUrl}
            key={i}
            gaInfo={gaInfo}
            className="mobileHidden"
          />
        );
      } else if (d.type === "photo_aside") {
        el = <PhotoAside {...d.data} key={i} gaInfo={gaInfo} />;
      } else if (d.type === "other_webs_tasr") {
        el = <OtherWebsTASR {...d.data} key={i} gaInfo={gaInfo} />;
      } else if (d.type === "vyhlasenia") {
        el = <VyhlaseniaAside {...d.data} key={i} gaInfo={gaInfo} />;
      } else if (d.type === "box_preferencie") {
        el = <BoxPreferencie key={i} />;
      } else if (d.type === "adocean_banner_square") {
        el = <AdOceanSlave className="module" {...d.data} key={i} />;
      } else if (d.type === "adocean_banner_horizontal") {
        el = (
          <AdOceanSlave
            className="banner banner--horizontal"
            {...d.data}
            key={i}
          />
        );
      } else if (d.type === "static_panel") {
        el = <StaticPanel {...d.data} key={i} />;
      } else if (d.type === "web2media_300") {
        el = <Web2MediaBanner300/>
      } else if (d.type === "web2media_skinny") {
        // el = <Web2MediaDesktopSkinny/>
      }
    }

    return el;
  };
}
const asideMapping = asideMappingFactory({
  headerSuffix: "",
});
export default asideMapping;
