import { current } from "immer";
import React, { ReactComponentElement, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAppDispatch } from "~/store/store";
import { wagonHeight, wagonHeightAdapter } from "~/store/wagonHeight.slice";

export function useWagonHeight(id: string) {
  // useSelector(wagonHeightAdapter.getSelectors().selectById);
  const ref = useRef(null);
  const dispatch = useAppDispatch();
  function saveHeight() {
    console.log(
      id,
      ref.current,
      ref.current.offsetHeight,
      !!id && !!ref.current.offsetHeight
    );
    if (!!id && !!ref.current.offsetHeight) {
      dispatch(
        wagonHeight.actions.upsertOne({
          id: id,
          height: ref.current.offsetHeight,
        })
      );
    }
  }
  useEffect(() => {
    saveHeight();
  });

  return ref;
}

export function classWagonHeightSave(component) {
  /* Requires from component:
    this.props.dispatch
    this.props.name
    this.wagonHeightRef
  */
  component.props.dispatch(
    wagonHeight.actions.upsertOne({
      id: component.props.name,
      height: component.wagonHeightRef.current.offsetHeight,
    })
  );
}
