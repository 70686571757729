import React from "react";
import { ga } from "react-ga";
import { Link as RouterLink } from "react-router-dom";
import { getGaInfo } from "~/utility/gaInfo";
import { getGaInfoUtm } from "../utility/gaInfo";

type ToType = string | { pathname: string; search: string };

type LinkProps = {
  children?: any;
  baseUrl?: string;
  forceA?: boolean;
  to?: ToType;
  className?: string;
  xref?: any;
  gaInfo?: any;
  inline?: boolean;
};
export type PublishingTimeProps = {
  time_created_ISO?: string;
  time_updated_ISO?: string;
  time_created?: string;
  time_updated?: string;
  className?: string;
};
type LinkImgProps = {
  src: string;
  title: string;
  to: string;
  baseUrl?: string;
  forceA?: boolean;
  className?: string;
};

function addSearchParams(url: ToType, params: URLSearchParams): ToType {
  if (typeof url === "string") {
    const urlParts = url.split("?");

    if (urlParts.length === 2) {
      return url + "&" + params.toString();
    }

    return url + "?" + params.toString();
  } else if (typeof url === "object") {
    url.search += "&" + params.toString();
    return url;
  }
}

function optStartsWith(optString, searchString) {
  return (
    optString && optString.startsWith && optString.startsWith(searchString)
  );
}

export const Line = () => <span className="placeholder placeholder--line" />;
export const SpanLine = () => <span> .... </span>;
export const Link = (props: LinkProps) => {
  // if (!props.gaInfo) {
  //   console.warn("empty🔗", props.gaInfo, props.to, props.children, props);
  // }
  const handleClick = (e) => {
    // e.preventDefault();
    // console.log(`🔗 ${props.gaInfo} ${props.to}`);
    if (props.gaInfo) {
      ga("send", {
        hitType: "event",
        eventCategory: "Link",
        eventAction: "click",
        eventLabel: props.gaInfo,
        eventValue: props.to,
      });
    }

    if (typeof window !== 'undefined' && typeof window.pp_gemius_cmd !== 'undefined' && typeof window.pp_gemius_hit !== 'undefined'){
      window.pp_gemius_cmd('referrer_override',window.location.href);
      window.pp_gemius_hit("dvXrEEbWwNvztr9fPtjVm7S4XrE_UmLYGSlrifBLijX.t7");
    }
  };

  const gaInfoUtm = getGaInfoUtm(props.gaInfo);
  const xprops = {
    ref: props.xref,
    onClick: handleClick,
  };

  if (props.children) {
    if (props.to) {
      const to = props.to ? addSearchParams(props.to, gaInfoUtm) : "";
      const isToAbsolute =
        optStartsWith(props.to, "http://") ||
        optStartsWith(props.to, "https://");

      if (props.baseUrl || props.forceA || isToAbsolute) {
        if (isToAbsolute) {
          if (
            optStartsWith(props.to, "https://newsnow.tasr.sk") ||
            optStartsWith(props.to, "http://newsnow.tasr.sk")
          ) {
            /* NewsNow absolute link */
            return (
              <a
                {...xprops}
                href={`/newsnow-redirect?to=${encodeURIComponent(to)}`}
                className={props.className}
                target="_blank"
              >
                {props.children}
              </a>
            );
          } else {
            /* normal absolute link */
            return (
              <a
                {...xprops}
                href={to}
                rel="noopener"
                target="_blank"
                className={props.className}
              >
                {props.children}
              </a>
            );
          }
        } else {
          /* "relative" link to outside */
          const toA = (props.baseUrl || "") + to;
          return (
            <a
              {...xprops}
              href={toA}
              rel="noopener"
              target="_blank"
              className={props.className}
            >
              {props.children}
            </a>
          );
        }
      } else {
        /* relative link inside SPA */
        return (
          <RouterLink {...xprops} to={to} className={props.className}>
            {props.children}
          </RouterLink>
        );
      }
    } else {
      /* empty link - eg. fake link button */
      return (
        <a {...xprops} className={props.className}>
          {props.children}
        </a>
      );
    }
  } else {
    return props.inline ? <SpanLine /> : <Line />;
  }
};

const LinkImgBase = (props: any, content: any) => {
  return (
    <Link className="media-img" {...props} gaInfo={getGaInfo(props, "img")}>
      {content}
    </Link>
  );
};

export const ImgFree = (props: LinkImgProps) => {
  return (
    <div className="media-img">
      <img src={props.src} title={props.title} alt="" loading="lazy" />
    </div>
  );
};
export const LinkImgFree = (props: LinkImgProps) => {
  const content = (
    <div>
      <img src={props.src} title={props.title} alt="" loading="lazy"/>
    </div>
  );
  return LinkImgBase(props, content);
};
export const LinkImg = (props: LinkImgProps) => {
  const content = (
    <img
      src={props.src}
      title={props.title}
      alt=""
      className={props.className || ""}
      loading="lazy"
    />
  );
  return LinkImgBase(props, content);
};
export const LinkImgCrop = (props: LinkImgProps) => {
  const content = (
    <div className="aspectRatio">
      <div className="aspectRatio-fill placeholder" />
      <img
        src={props.src}
        title={props.title}
        alt=""
        className={(props.className || "") + "aspectRatio-content"}
        loading="lazy"
      />
    </div>
  );
  return LinkImgBase(props, content);
};
export const PublishingTime = (props: PublishingTimeProps) => {
  const cls = props.className
    ? {
        className: props.className,
      }
    : {};
  const el_created = (
    <time key="created" dateTime={props.time_created_ISO}>
      {props.time_created || <SpanLine />}
    </time>
  );
  const el_updated = [
    <svg key="svg" className="icon">
      <use xlinkHref="/sprite.svg#repeat" />
    </svg>,
    <span key="text" className="visuallyhidden">
      aktualizované
    </span>,
    <span key="nbsp">&nbsp;</span>,
    <time key="updated" dateTime={props.time_updated_ISO}>
      {props.time_updated}
    </time>,
  ];
  return (
    <span className="publishingTime" {...cls}>
      {props.time_updated && props.time_updated !== props.time_created
        ? [el_created, ",", el_updated]
        : el_created}
      {/* {props.longformat ?
       (
         (props.time_updated && props.time_updated !== props.time_created) ?
           [el_created, ",", el_updated]
           : el_created
       )
       : (
         (props.time_updated && props.time_updated !== props.time_created) ?
           el_updated
           : el_created
       ) */}
    </span>
  );
}; //export const LinkImg = LinkImgFree
