// import bunyan from 'bunyan'
const noop = (...args: any[]) => {};

let logger;
// if (process.env.BUILD_TARGET === 'server') {
//   logger = bunyan.createLogger({
//     name: 'teraz.react',
//     streams: [
//       {
//         level: 'warn',
//         stream: {
//           write: function () {
//             console.log(arguments)
//           }
//         },
//         type: 'raw'
//       }
//     ]
//   })
// } else {

/* Stupid logging */
if (process.env.NODE_ENV == "development") {
  logger = {
    trace: console.trace,
    debug: console.debug,
    info: console.info,
    log: console.log,
    warn: console.warn,
    error: console.error,
    fatal: console.error,
    time: console.time,
    timeEnd: console.timeEnd,
  };
} else {
  logger = {
    trace: noop,
    debug: noop,
    info: noop,
    log: noop,
    warn: console.warn,
    error: console.error,
    fatal: console.error,
    time: noop,
    timeEnd: noop,
  }; // }
}

export default {
  trace: logger.trace,
  debug: logger.debug,
  info: logger.info,
  log: logger.log,
  warn: logger.warn,
  error: logger.error,
  fatal: logger.error,
  time: logger.time,
  timeEnd: logger.timeEnd,
};
