// import React, {Component} from 'react';
// import LazyLoad from 'react-lazyload'
import { themeClass, headerSuffix } from "~/store/actions";
import { connect } from "react-redux";
import { LoaderIndex, mapStateToProps } from "~/containers/LoaderIndex";

class PageIndex extends LoaderIndex {
  UNSAFE_componentWillMount() {
    const { dispatch } = this.props;
    dispatch(headerSuffix(".SK", "/"));
    dispatch(themeClass(""));
  }
}

export default connect(mapStateToProps)(PageIndex);
