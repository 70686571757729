import React from "react";
import { facebookAppId } from "./index";

export default class LikeButton extends React.Component<{
  encodedUrl: string;
}> {
  shouldComponentUpdate(nextProps) {
    return nextProps.encodedUrl !== this.props.encodedUrl;
  }

  render() {
    return (
      <>
      <iframe
        className="socialWidget--facebook-share-desktop"
        key="iframe"
        src={`https://www.facebook.com/plugins/share_button.php?href=${this.props.encodedUrl}&layout=button_count&size=large&mobile_iframe=false&appId=${facebookAppId}&width=110&height=32`}
        width="110"
        height="32"
        style={{
          border: "none",
          overflow: "hidden",
        }}
        scrolling="no"
        frameBorder="0"
        allowtransparency="true"
      />
      

      <a
           className="socialWidget socialWidget--facebook-share-mobile"
           href={"http://www.facebook.com/share.php?u=" + this.props.encodedUrl}
           target="_blank"
           rel="noopener"
          >
           <span className="socialWidget-label">Zdieľaj na Facebooku</span>
        </a>
         
      </>      
    );
  }
}
