import React, { Component } from "react";
import {
  themeClass,
  headerSuffix,
  selectPage,
  fetchPage,
} from "~/store/actions";
import { connect } from "react-redux";
import { pageMapStateToProps } from "~/store/mappers"; //import AssignmentActions from 'containers/AssignmentActions'
import axios from "axios";
import Volby2024PageTemp from "~/components/pages/Volby2024PageTemp";

class PageVolby2024temp extends Component {   
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            dataLegend: {},
            showElections: true,
        };
    }
        
     


    // loadJSON method to open the JSON file.
    loadChartJSON(path, success, error) {
    var xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          success(JSON.parse(xhr.responseText));
        }
        else {
          error(xhr);
        }
      }
    };
    xhr.open('GET', path, true);
    xhr.send();
  }
    
  
  myData = (data) => {
    // Output only the details on the first post    
    this.setState({
        data: data
      });
    // this.setState({ dataGraph: data});
  }
  myDataLegend = (data) => {
    // Output only the details on the first post    
    this.setState({
        dataLegend: data
      });
    // this.setState({ dataGraph: data});
  }

    

  update(props) {
    const qs = new URLSearchParams(props.location.search);
    const page_n = qs.get("p") || 1;
    //this.setState({page_n: page_n, loading: true})
    const { dispatch } = props;



    dispatch(headerSuffix("Referendum", "/referendum-2023"));
    dispatch(themeClass("theme_prave-teraz"));
  }

  UNSAFE_componentWillMount() {
    this.update(this.props);

    const getIp = async () => {
      const res = await axios.get("https://api.ipify.org/?format=json");
      return res.data.ip;
    };

    // ip tasr
    const ipTASR = '195.46.72.';
    const ipKubo = '87.197.146.153';
    const ipKuboDoma = '95.102.148.135';
    const ipKarci = '81.92.251.125';

    getIp().then((ip)=>{
      if(ip === ipKubo ||
        ip === ipKuboDoma ||
        ip === ipKarci ||
      ip === '62.197.241.122' ||
      ip.startsWith(ipTASR)){
        this.setState({
          showElections: true,
        })
      }
    }) 
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.update(nextProps);
  }

  componentDidMount() {
    const html = {};    

    this.update(this.props);
    // this.loadChartJSON("https://www.tasr.sk/referendum2023.json", this.myData,'jsonp');
    this.loadChartJSON("https://www.tasr.sk/prez2024_legend.json", this.myDataLegend,'jsonp');
    this.loadChartJSON("https://www.tasr.sk/prez2024_results.json", this.myData,'jsonp');
  }

  render() {
    const { data } = this.state;
    const { dataLegend } = this.state;
    return(
      (this.state.showElections) ? <Volby2024PageTemp dataResults={data} dataLegend={dataLegend} /> : <div></div>      
    )
    // return <Volby2023Page dataLegend={data} dataGraph={data} />;
  }
}

export default connect(pageMapStateToProps)(PageVolby2024temp);
