import { transformToWagonsConfig } from "~/config/LoaderIndex.config";
import { apiUrlTeraz } from "../utility/api";
import log from "../utility/log";
import { HEADER_DATE, HEADER_SUFFIX, RECEIVE_FB_SHARES, RECEIVE_HEADER, RECEIVE_PAGE, RECEIVE_WAGON, RECEIVE_WAGONS_CONFIG, REQUEST_HEADER, REQUEST_PAGE, SELECT_PAGE, THEME_CLASS, THEME_SCROLL_CLASS, TOGGLE_OFFCANVAS } from "./actionTypes";
import { requirementsSlice } from "./requirements.slice";


type URLT = string;
type CanonicalURLT = URLT;

/* Utils */
const guardRequest = (reason) => {
  log.error("Request failed", reason);
  throw reason;
};

const guardJson = (reason) => {
  log.error("JSON decode failed", reason); //throw new Error("JSON decode failed", reason)
};

/*
const shouldFetch = (state, path) => {
  //TODO
  const data = path.reduce((acc, v) => (acc ? acc[v] : null) ,state)
  if (!data){
    return true
  }
  if (data.isLoading){
    return false
  }
  return data.didInvalidate
}
*/

const getResponseJson = (response: Response) => {
  try {
    return response.json().catch((reason) => {
      log.error("response.json() error promise-catch", response.url, reason);
    });
  } catch (e) {
    log.error("response.json() error try-catch", response.url, e);
  }
};

const parseJson = (response: Response) => {
  if (response.ok) {
    return getResponseJson(response);
  } else {
    log.info("JSON error Response: ", response.text());
    throw new Error(`Response status is not OK - ${response.status}`);
  }
};

function fetchUrl(url: URLT) {
  function retryWithoutCredentials(reason) {
    if (reason.message == "Failed to fetch") {
      log.info(`Refetching without opts ${url}`)
      return fetch(url);
    }
    return reason;
  }

  log.info("fetch", url);
  const opts: RequestInit = {
    credentials:
      process.env.NODE_ENV == "development" ? "include" : "same-origin",
  };
  log.time(url);
  return fetch(url, opts)
    .catch(retryWithoutCredentials)
    .catch(guardRequest)
    .then((promise) => {
      log.timeEnd(url);
      return promise;
    });
}

/*
 * TODO:
 * dispatch(addRequirement(x))
 * dispatch(removeRequirement(x))
 * mozno nebudu zavolane v tom poradi? overit
 *
 */

/* Action creators */
//SSR Requirements
// export const addRequirement = (id) => ({
//   type: ADD_REQUIREMENT,
//   id,
// });
// export const removeRequirement = (id) => ({
//   type: REMOVE_REQUIREMENT,
//   id,
// });

const guardProcessing = (dispatch, url: URLT) => (reason) => {
  // dispatch(removeRequirement(url));
  dispatch(requirementsSlice.actions.addOne({ id: url }));
  log.error("Processing failed", url, reason); //throw new Error("Processing failed", url, reason)
};

// HEADER
export const requestHeader = () => ({
  type: REQUEST_HEADER,
});

export const receiveHeader = (json) => ({
  type: RECEIVE_HEADER,
  json,
});

export const fetchHeader = () => (dispatch) => {
  /*if(shouldFetch(getState())){
  }*/
  dispatch(requestHeader());
  const url = apiUrlTeraz + "api_rest.php?module=RestApi&method=header";
  const promise = fetchUrl(url).then(parseJson, guardJson);
  dispatch(requirementsSlice.actions.addOne({ id: url }));
  return promise
    .then((json) => {
      dispatch(receiveHeader(json));
      return json;
    })
    .catch(guardProcessing(dispatch, url))
    .then(() => dispatch(requirementsSlice.actions.removeOne(url)));
};

export const headerDate = (date) => ({
  type: HEADER_DATE,
  date,
});

export const headerSuffix = (name, url: URLT) =>
  name
    ? {
        type: HEADER_SUFFIX,
        name,
        url,
      }
    : {
        type: HEADER_SUFFIX,
        name: "",
      };

// PAGE
export const requestPage = (url: URLT) => ({
  type: REQUEST_PAGE,
  url,
});

export const receivePage = (url: URLT, json) => {
  log.info(`receivePage ${url}`);
  return {
    type: RECEIVE_PAGE,
    url,
    json,
    receivedAt: Date.now(),
  };
};

export const selectPage = (url: URLT) => {
  log.info(`selecting page ${url}`);
  return {
    type: SELECT_PAGE,
    url,
  };
};

export const fetchPageFake = (url: URLT, json) => (dispatch) => {
  dispatch(requestPage(url));
  dispatch(receivePage(url, json));
};

export const fetchPage = (url: URLT) => (dispatch, getState) => {
  //const state = getState()

  /*
  if(shouldFetch(state, ['pageByUrl', url])){
  }
  */
  dispatch(requestPage(url));
  const promise = fetchUrl(url);
  dispatch(requirementsSlice.actions.addOne({ id: url }));
  return promise
    .then((response) => {
      if (response.ok) {
        return getResponseJson(response)
          .then((json) => {
            return { ...json, httpStatus: response.status };
          }, guardJson)
          .then((json) => {
            if (json) {
              dispatch(receivePage(url, json));

              if (json.category && json.category.url) {
                dispatch(headerSuffix(json.category.name, json.category.url)); // FIXME toto je hack
              }
            }

            return json;
          });
      } else {
        log.warn(`requested page ${url} failed with ${response.status}`);
        return dispatch(
          receivePage(url, {
            body: response.text,
            httpStatus: response.status,
          })
        );
      }
    }, guardProcessing(dispatch, url))
    .then((_) => {
      dispatch(requirementsSlice.actions.removeOne(url));
      return _;
    });
};

// FB Shares
export const receiveFbShares = (url: URLT, count) => ({
  type: RECEIVE_FB_SHARES,
  url,
  count,
});

export const fetchFbShares = (canonical_url: CanonicalURLT) => (
  dispatch,
  getState
) => {
  fetchUrl(
    `https://graph.facebook.com/?fields=share&id=${encodeURIComponent(
      canonical_url
    )}`
  )
    .then(parseJson, guardJson)
    .then((json) => {
      if (json && json.shares && json.shares.count) {
        dispatch(receiveFbShares(canonical_url, json.share.share_count));
      }

      return json;
    });
};

//THEME
export const themeClass = (className) => ({
  type: THEME_CLASS,
  className,
});
export const themeScrollClass = (className) => ({
  type: THEME_SCROLL_CLASS,
  className,
});

//WAGON
export const receiveWagon = (url: URLT, json) => ({
  type: RECEIVE_WAGON,
  url,
  json,
});
export const fetchWagon = (url: URLT, required = false) => (
  dispatch,
  getState
) => {
  /*
  if(shouldFetch(getState(), ['wagonByUrl', url])){
  }
  */
  const promise = fetchUrl(url).then(parseJson, guardJson);
  if (required) {
    dispatch(requirementsSlice.actions.addOne({ id: url }));
  }
  return promise
    .then((json) => {
      dispatch(receiveWagon(url, json));
      return json;
    }, guardProcessing(dispatch, url))
    .then(() => dispatch(requirementsSlice.actions.removeOne(url)));
};

/* UI */
export const toggleOffCanvas = () => ({
  type: TOGGLE_OFFCANVAS,
});

/* WagonsConfig */
export const receiveWagonsConfig = (json) => ({
  type: RECEIVE_WAGONS_CONFIG,
  json,
});

export const fetchWagonsConfig = () => (dispatch, getState) => {
  const url = `${process.env.RAZZLE_API_TERAZ}api_rest.php?module=RestApi&method=homepageWagonOrderingJson&v=20200226`;
  dispatch(requirementsSlice.actions.addOne({ id: url }));
  fetchUrl(url)
    .then(parseJson, guardJson)
    .then((json) => {
      log.debug(json);

      if (json) {
        const wagonsConfig = transformToWagonsConfig(json);
        dispatch(receiveWagonsConfig(wagonsConfig));
      }

      return json;
    })
    .catch(guardProcessing(dispatch, url))
    .then(() => dispatch(requirementsSlice.actions.removeOne(url)));
};
