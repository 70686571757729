import React, { Component } from "react";
import Submenu from "~/components/Submenu";
import { asideMappingFactory } from "~/utility/aside";
import TasrTheme from "~/components/aside/single/TasrTheme";
import Pagination from "~/components/Pagination";
import SectionHeader from "../containers/SectionHeader";
import { Web2MediaBanner300 } from "~/components/specialBanners/Web2MediaBanner300x600";
import ElectionsBanner from "~/components/specialBanners/VolbyPrezident2024";
export default class SectionBase extends Component {
  render() {
    const gaInfo = this.props.gaInfo;
    const asideMapping = asideMappingFactory({
      headerSuffix: "",
      gaInfo: gaInfo,
    });
    const splits = (this.props.splits || []).map(asideMapping);
    const aside = (this.props.aside || []).map(asideMapping);
    const pagination = (
      <Pagination
        page={this.props.page}
        page_count={this.props.page_count}
        pathname={this.props.location.pathname}
        gaInfo={gaInfo}
      />
    );
    return (
      <div>
        <section className="section">
          {/* <h2 className="-visuallyhidden">{this.props.title}</h2> */}
          {/* <ElectionsBanner></ElectionsBanner> */}
          {this.props.submenu ? (
            <Submenu submenu={this.props.submenu} gaInfo={gaInfo} />
          ) : null}
          <SectionHeader
            className="container text-center sectionPageTitle"
            wrappingComponent="h1"
            gaInfo={gaInfo}
          />          
          {/* {this.props.topArticles || pagination} */}
          {this.props.topArticles}
          {pagination}
          <div className="container border-top border-bottom">
            <div className="l-columns clearfix">
              <div className="l-main pull-left">
                <div className="module">
                  <div className="moduleMain">
                    <div className="mediaListing clearfix">
                      {this.props.innerItems[0]}
                    </div>
                  </div>
                </div>
              </div>

              <aside className="l-sidebar pull-right">
                {aside.slice(0, 2)}
                <Web2MediaBanner300/>
              </aside>
            </div>
          </div>
        </section>

        {this.props.innerItems &&
        this.props.innerItems[1] &&
        this.props.innerItems[1].length > 0
          ? splits[0]
          : null}

        <section className="section">
          <div className="container border-top">
            {this.props.innerItems &&
            this.props.innerItems[1] &&
            this.props.innerItems[1].length > 0 ? (
              <div className="l-columns clearfix">
                <div className="l-main pull-left">
                  <div className="module">
                    <div className="moduleMain">
                      <div className="mediaListing clearfix">
                        {this.props.innerItems[1]}
                        <TasrTheme {...this.props.tasr_theme} />
                        {this.props.innerItems[2]}
                      </div>
                    </div>
                  </div>
                </div>
                <aside className="l-sidebar pull-right xxx">
                  {aside.slice(2, 3)}
                </aside>
              </div>
            ) : null}

            {this.props.innerItems &&
            this.props.innerItems[3] &&
            this.props.innerItems[3].length > 0
              ? [
                  <article key="splits3">{splits[1]}</article>,
                  <div className="l-columns clearfix" key="lastpart">
                    <div className="l-main pull-left">
                      <div className="module">
                        <div className="moduleMain">
                          <div className="mediaListing clearfix">
                            {this.props.innerItems[3]}
                          </div>
                        </div>
                      </div>
                    </div>
                    <aside className="l-sidebar pull-right">
                      {aside.slice(3)}
                    </aside>
                  </div>,
                ]
              : null}
            {this.props.location &&
            this.props.location.pathname.toLowerCase() === "/tag/cofoe" ? (
              <div className="cofoeDisclaimer">
                Tento projekt bol spolufinancovaný Európskou úniou v rámci
                grantového programu Európskeho parlamentu v oblasti komunikácie.
                Európsky parlament sa nepodieľal na jeho príprave a za údaje,
                informácie alebo názory uvedené v rámci tohto projektu, za ktorý
                nesú zodpovednosť výhradne autori, oslovené osoby, editori alebo
                vysielatelia tohto programu v súlade s príslušným právom, nie je
                žiadnym spôsobom zodpovedný ani nimi viazaný. Európsky parlament
                nemôže taktiež niesť zodpovednosť za priame alebo nepriame
                škody, ktoré by mohli vyplynúť z realizácie tohto projektu.
              </div>
            ) : null}
          </div>
          {pagination}
        </section>
      </div>
    );
  }
}
