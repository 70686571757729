import React, { Component } from "react";
import NewsNowRedirect from "../../components/pages/NewsNowRedirect";
import { selectPage, fetchPage } from "~/store/actions";
import { apiUrlTeraz } from "~/utility/api";
import { pageMapStateToProps } from "~/store/mappers";
import { connect } from "react-redux";
import setHttpStatus, { handleHttpStatus } from "~/utility/httpStatus";
export const isNewsNowUrl = (url) =>
  !!url &&
  !!url.startsWith &&
  (url.startsWith("http://newsnow.tasr.sk") ||
    url.startsWith("https://newsnow.tasr.sk"));

class NewsNowRedirectContainer extends Component {
  state = {
    to: null,
  };

  update(props) {
    const qs = new URLSearchParams(props.location.search);
    const { dispatch } = props;
    const to = qs.get("to");
    this.setState({
      to,
    });
    const url = `${apiUrlTeraz}api_rest.php?module=RestApi&method=ogInfo&url=${encodeURIComponent(
      to
    )}`;

    if (props.selectedPage !== url) {
      dispatch(selectPage(url));
      dispatch(fetchPage(url));
    } else {
    }
  }

  UNSAFE_componentWillMount() {
    this.update(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.update(nextProps);
  }

  componentDidMount() {
    this.update(this.props);
  }

  render() {
    if (isNewsNowUrl(this.state.to)) {
      return (
        <NewsNowRedirect
          to={this.state.to}
          title={this.props.title}
          image={this.props.image}
        />
      );
    } else {
      setHttpStatus(this, "404");
      return handleHttpStatus();
    }
  }
}

export default connect(pageMapStateToProps)(NewsNowRedirectContainer);
