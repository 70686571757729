import React from "react";
import NotFound from "~/containers/pages/NotFound";
import LoaderIndex from "~/containers/LoaderIndex";
import log from "./log";
export default function setHttpStatus(component, status) {
  log.log("setHttpStatus", status);
  if (
    component &&
    component.context &&
    component.context.router &&
    component.context.router.staticContext
  ) {
    component.context.router.staticContext.status = status;
  }
}
export function shouldHandleHttpStatus(props) {
  return props.httpStatus !== 200 && props.isLoading !== true;
}
export function handleHttpStatus(props) {
  return (
    <div>
      <NotFound />
      <LoaderIndex />
    </div>
  );
}
