import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import log from "~/utility/log";

type WagonHeight = { id: string; height: number };

export const wagonHeightAdapter = createEntityAdapter<WagonHeight>();

export const wagonHeight = createSlice({
  name: "wagonHeight",
  initialState: wagonHeightAdapter.getInitialState(),
  reducers: {
    upsertOne: (state, key) => {
      log.info(`wagonHeight.upsertOne: ${JSON.stringify(key.payload)}`);
      return wagonHeightAdapter.upsertOne(state, {
        height: key.payload.height,
        id: key.payload.id,
      });
    },
  },
});
