import React, { Component } from "react";
import { Link, Line } from "~/components/Elements";
import PropTypes from "prop-types";
import { GAInfoProp, getGaInfo } from "~/utility/gaInfo";
import clsx from "clsx";

export interface TabbedAsideListingTab {
  active?: boolean;
  header: string;
  key: string;
  url?: string;
  name: string;
}
export interface TabbedAsideListingArticle {
  url: string;
  title: string;
}
interface TabbedAsideListingProps {
  tabs?: TabbedAsideListingTab[];
  articles?: TabbedAsideListingArticle[][];
  showOnMobile?: boolean;
}
export default class TabbedAsideListing extends Component<
  TabbedAsideListingProps & GAInfoProp,
  { current_tab: any }
> {
  static propTypes = {
    tabs: PropTypes.array,
    articles: PropTypes.object, //title: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      current_tab: this.props.tabs.filter((t) => t.active)[0].key,
    };
  }

  setActiveTabFactory(key) {
    return (event) => {
      event.preventDefault();
      this.setState({
        current_tab: key,
      });
    };
  }

  render() {
    const currentTabArticles: TabbedAsideListingArticle[] =
      (this.props.articles
        ? this.props.articles[this.state.current_tab]
        : null) || Array(6).fill({});
    const headerTitle = this.props.tabs.find(
      (x) => x.key === this.state.current_tab
    ).header;
    const headerUrl = this.props.tabs.find(
      (x) => x.key === this.state.current_tab
    ).url;
    const tabs = this.props.articles
      ? this.props.tabs.filter((t) => this.props.articles[t.key])
      : this.props.tabs;
    return (
      <div
        className={clsx("module", { mobileHidden: !this.props.showOnMobile })}
      >
        <div className="moduleHeader moduleHeader--sidebar clearfix">
          <h3 className="moduleHeader-title">
            {headerUrl ? (
              <Link
                to={headerUrl}
                gaInfo={getGaInfo(this.props, "moduleHeader")}
              >
                {headerTitle}
              </Link>
            ) : (
              headerTitle
            )}
          </h3>
          <ul className="moduleHeader-tabs pull-right list-reset">
            {tabs.map((v, i) => (
              <li
                className={v.key === this.state.current_tab ? "is-active" : ""}
                key={i}
              >
                <a href="#" onClick={this.setActiveTabFactory(v.key)}>
                  {v.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="moduleMain">
          <ol className="listing list-reset">
            {currentTabArticles.map((v, i) => (
              <Item
                i={i}
                url={v.url}
                title={v.title}
                key={i}
                gaInfo={getGaInfo(this.props, `tab-${this.state.current_tab}`)}
              />
            ))}
          </ol>
        </div>
      </div>
    );
  }
}

type ItemProps = { i: number } & TabbedAsideListingArticle & GAInfoProp;
const Item = (props: ItemProps) => (
  <li className="listingItem">
    <Link className="listingItem-link" to={props.url} gaInfo={props.gaInfo}>
      <span className="listingItem-number">{props.i + 1}</span>
      <h4 className="listingItem-title">{props.title || <Line />}</h4>
    </Link>
  </li>
);
