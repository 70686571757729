import React, { Component } from "react";
import SectionBase from "~/components/SectionBase";
import Colule from "~/components/ColuleSection";
import MediaListingItem from "~/components/SectionMediaListingItem";
import { Helmet } from "react-helmet";
import { getGaInfo } from "~/utility/gaInfo";
import log from "~/utility/log";
export default class Section extends Component {
  render() {
    const title = this.props.category ? this.props.category.name : "";
    const gaInfo = getGaInfo(this.props, "s" + title);
    const inArticles = this.props.articles || Array(12).fill({});
    const z = 4;
    const articles = [
      inArticles.slice(0 * z, 1 * z),
      inArticles.slice(1 * z, 2 * z),
      inArticles.slice(2 * z, 3 * z),
      inArticles.slice(3 * z),
    ];
    const inAside = this.props.aside || Array(5).fill({});
    const aside = [inAside.slice(0, 1), inAside.slice(1)];
    const coluleData = this.props.colule;
    const renderTopArticles = // eslint-disable-next-line
      this.props.page == 1 &&
      coluleData && // && coluleData.articles
      coluleData.top_article;
    //&& coluleData.top_article.length
    const topArticles = renderTopArticles ? (
      <Colule
        sections={coluleData ? coluleData.articles : null}
        aside={aside[0]}
        top_article={coluleData ? coluleData.top_article : null}
        noSectionHeaders={true}
        gaInfo={gaInfo}
      />
    ) : null;
    const sectionAside = renderTopArticles ? aside[1] : inAside;
    log.info(this.props.location);
    return (
      <div>
        {this.props.location &&
        this.props.location.pathname === "/parlamentne-volby-2020" ? (
          <Helmet>
            <meta
              property="og:image"
              content="https://www.imporel.com/storage/imgupload/a11251418be13190e74acf08958006bc.png"
            />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta
              property="og:title"
              content="Všetko o parlamentných voľbách 2020 | TERAZ.SK"
            />
            <meta
              property="og:description"
              content="Informácie z prvej ruky."
            />
          </Helmet>
        ) : null}
        <SectionBase
          title={title}
          top_article={this.props.top_article}
          page={this.props.page}
          page_count={this.props.page_count}
          splits={this.props.splits}
          submenu={this.props.submenu}
          location={this.props.location}
          innerItems={articles.map((a, k) =>
            a.map((v, i) => (
              <MediaListingItem {...v} key={`${k}_${i}`} gaInfo={gaInfo} />
            ))
          )}
          aside={sectionAside}
          topArticles={topArticles}
          tasr_theme={this.props.tasr_theme}
          gaInfo={gaInfo}
        />
      </div>
    );
  }
}
