import { combineReducers } from "redux";
import { dowName, monthName } from "../utility/dateName";
import {
  //SSR
  REQUEST_PAGE,
  RECEIVE_PAGE,
  SELECT_PAGE,
  RECEIVE_FB_SHARES, //Wagon
  RECEIVE_WAGON, //Header
  REQUEST_HEADER,
  RECEIVE_HEADER,
  HEADER_DATE,
  HEADER_SUFFIX, //Theme
  THEME_CLASS,
  THEME_SCROLL_CLASS,
  TOGGLE_OFFCANVAS,
  RECEIVE_WAGONS_CONFIG,
} from "./actionTypes";

import { requirementsSlice } from "./requirements.slice";
import { wagonHeight } from "./wagonHeight.slice";

// Header
const initialHeaderState = {
  headerSuffix: "",
  headerSuffixUrl: null,
  isLoading: true,
};

const header = (state = initialHeaderState, action) => {
  switch (action.type) {
    case REQUEST_HEADER:
      return { ...state, isLoading: true };

    case RECEIVE_HEADER:
      return { ...state, ...action.json, isLoading: false };

    case HEADER_DATE:
      const date = action.date;
      return {
        ...state,
        dateName: `${date.getDate()}. ${
          monthName[date.getMonth()]
        } ${date.getFullYear()}`,
        dowName: dowName[date.getDay()],
      };

    case HEADER_SUFFIX:
      return {
        ...state,
        headerSuffix: action.name,
        headerSuffixUrl: action.url,
      };

    default:
      return state;
  }
};

//Theme
const initialThemeState = {
  isOffCanvasActive: false,
};

const theme = (state = initialThemeState, action) => {
  switch (action.type) {
    case THEME_CLASS:
      return { ...state, themeClass: action.className };

    case THEME_SCROLL_CLASS:
      return { ...state, themeScrollClass: action.className };

    case TOGGLE_OFFCANVAS:
      return { ...state, isOffCanvasActive: !state.isOffCanvasActive };

    default:
      return state;
  }
};

// Page

interface PhotodiaryPageI {}
type PageState = { lastUpdate?: string; isLoading: boolean } & PhotodiaryPageI;

const initialPageState: PageState = {
  isLoading: false,
};

const page = (state = initialPageState, action) => {
  switch (action.type) {
    case REQUEST_PAGE:
      return { ...state, isLoading: true };

    case RECEIVE_PAGE:
      return {
        ...state,
        isLoading: false,
        lastUpdate: action.receivedAt,
        ...action.json,
      };

    default:
      return state;
  }
};

const selectedPage = (
  state = "/",
  action: { type: string; url: string }
): string => {
  switch (action.type) {
    case SELECT_PAGE:
      return action.url;

    default:
      return state;
  }
};

type PageByUrlState = { [url: string]: PageState };
const pageByUrl = (state: PageByUrlState = {}, action) => {
  switch (action.type) {
    case REQUEST_PAGE:
    case RECEIVE_PAGE:
      return { ...state, [action.url]: page(state[action.url], action) };

    default:
      return state;
  }
};

//Wagon
const initialWagonState = {};

const wagon = (state = initialWagonState, action) => {
  switch (action.type) {
    case RECEIVE_WAGON:
      return { ...state, ...action.json };

    default:
      return state;
  }
};

const wagonByUrl = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_WAGON:
      return { ...state, [action.url]: wagon(state[action.url], action) };

    default:
      return state;
  }
};

// const requirements = (state = [], action) => {
//   switch (action.type) {
//     case ADD_REQUIREMENT:
//       return [...state, action.id];

//     case REMOVE_REQUIREMENT:
//       return state.filter((r) => r !== action.id);

//     default:
//       return state;
//   }
// };

const fbShares = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_FB_SHARES:
      return { ...state, [action.url]: action.count };

    default:
      return state;
  }
};

// Wagons Config
const wagonsConfig = (state = [], action) => {
  switch (action.type) {
    case RECEIVE_WAGONS_CONFIG:
      return action.json;

    default:
      return state;
  }
};

export default combineReducers({
  fbShares,
  header,
  theme,
  pageByUrl,
  selectedPage,
  wagonByUrl,
  requirements: requirementsSlice.reducer,
  wagonsConfig,
  wagonHeight: wagonHeight.reducer,
});
