import React, { PureComponent } from "react";
import AsideListing from "./AsideListing";
import { getGaInfo } from "~/utility/gaInfo";
export default class VyhlaseniaAside extends PureComponent {
  render() {
    return (
      <AsideListing
        {...this.props}
        skipHeaderPrefix={true}
        gaInfo={getGaInfo(this.props, "vyhlasenia")}
      />
    );
  }
}
