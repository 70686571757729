import React, { Component, createRef } from "react";
import { Link as LinkE } from "../Elements";
import Scroll from "react-scroll";
import { imgSprite } from "../../config/images";
import { connect } from "react-redux";
import { toggleOffCanvas } from "../../store/actions";
import TopWeather from "~/containers/TopWeather";
import log from "../../utility/log";
import $ from "jquery";
import { withGaInfo, getGaInfo } from "../../utility/gaInfo";

const ScrollLinkFactory = (dispatch) => (props) => (
  <Scroll.Link
    className="link link--default"
    spy={true}
    offset={-60}
    duration={900}
    smooth={true}
    isDynamic={true}
    // onSetActive={(to) => {
    //   log.debug("theme scroll ", to);
    //   //AssignmentActions.set({themeScrollClass: 'themeScroll_'+to})
    //   dispatch(themeScrollClass("themeScroll_" + to));
    // }}
    activeClass="is-active"
    to={props.to}
  >
    {props.children}
  </Scroll.Link>
);

class TopHeaderMenu extends Component {
  headerTop?: React.RefObject<HTMLElement>;
  constructor(props) {
    super(props);
    this.toggleOffCanvas = this.toggleOffCanvas.bind(this);
    this.headerTop = createRef();
  }

  transformHeaderTop() {
    const $headerTop = this.headerTop?.current;
    if (!$headerTop) {
      return;
    }

    let offset = 10;
    let scrollTop = 0;
    const prevScrollTop = scrollTop;
    scrollTop = $(document).scrollTop();

    if (scrollTop - prevScrollTop >= offset) {
      $headerTop.classList.add("is-transformed");
    }

    //} else {
    if (prevScrollTop - scrollTop > offset) {
      $headerTop.classList.remove("is-transformed");
    }
  }

  componentDidMount() {
    // TODO: this is so antipattern i dont even... PREROBIT NA REFS!

    const $headerTop = this.headerTop.current;

    //const $headerMain = $('.js-headerMain')
    //offset = $headerMain.offset().top + $headerMain.outerHeight() - $headerTop.outerHeight();
    //setInterval(transformHeaderTop, 10);

    document.addEventListener("scroll", this.transformHeaderTop);
    // Search button handler
    var $target;
    var $headerSearch = $(".js-headerSearch"),
      $headerSearchToggle = $(".js-headerSearchToggle"),
      $headerSearchInput = $(".js-headerSearchInput");
    $headerSearchToggle.click(function () {
      $headerTop.classList.toggle("is-searchActive");
      $headerSearch.one(
        "transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd",
        function () {
          $headerSearchInput.focus();
        }
      );
    });

    function clickOutsideMenu(e) {
      $target = $(e.target);

      if (
        !$target.closest(".js-headerSearch").length &&
        !$target.closest(".js-headerSearchToggle").length
      ) {
        $headerTop.classList.remove("is-searchActive");
      }
    }

    $(document).on("touchend click", function (e) {
      clickOutsideMenu(e);
    });
  }

  toggleOffCanvas() {
    this.props.dispatch(toggleOffCanvas());
  }

  componentWillUnmount() {
    //TODO
    log.debug("remove event listener");
    document.removeEventListener("scroll", this.transformHeaderTop);
  }

  render() {
    const gaInfo = getGaInfo(this.props, "TopMenu");
    const Link = withGaInfo(LinkE, gaInfo);
    const ScrollLink = ScrollLinkFactory(this.props.dispatch);
    return (
      <header className="headerTop js-headerTop" ref={this.headerTop}>
        <div className="container container--full">
          <button
            type="button"
            className="headerTopMenuBtn pull-left js-offCanvasBtn"
            onClick={this.toggleOffCanvas}
          >
            <div className="headerTopMenuBtn-icon hamburger">
              <div className="hamburger-bar" />
              <div className="hamburger-bar" />
              <div className="hamburger-bar" />
            </div>
            <span className="headerTopMenuBtn-label">Rubriky</span>
          </button>
          <div className="headerTopLogo logo logo--md logo--light">
            <Link to="/">
              <span className="logo-prefix">TERAZ</span>
              <span className="logo-suffix">.SK</span>
            </Link>
          </div>
          <div className="headerSearchWrapper pull-right clearfix">
            <form className="headerSearch js-headerSearch" action="/search">
              <label htmlFor="headerSearch" className="visuallyhidden">
                Hľadať
              </label>
              <input
                id="headerSearch"
                className="headerSearch-input js-headerSearchInput"
                type="text"
                name="q"
                placeholder="Hľadať"
              />
              <button className="headerSearch-submit" type="submit">
                <svg className="icon">
                  <use xlinkHref={imgSprite + "#search"} />
                </svg>
              </button>
            </form>
            <div className="headerTopBtns pull-right">
              <Link
                className="headerTopBtn hidden-md hidden-sm hidden-xs"
                to="/rss"
              >
                <svg className="icon">
                  <use xlinkHref={imgSprite + "#rss"} />
                </svg>
              </Link>
              <a
                className="headerTopBtn hidden-md hidden-sm hidden-xs"
                target="_blank"
                rel="noopener"
                href="https://www.facebook.com/teraz.sk/"
              >
                <svg className="icon">
                  <use xlinkHref={imgSprite + "#facebook-square"} />
                </svg>
              </a>
              <a
                className="headerTopBtn hidden-md hidden-sm hidden-xs"
                target="_blank"
                rel="noopener"
                href="https://twitter.com/terazsk?lang=en"
              >
                <i className="icon icon--x inv"></i>
              </a>
              <a
                className="headerTopBtn hidden-md hidden-sm hidden-xs"
                target="_blank"
                rel="noopener"
                href="https://www.instagram.com/terazsk/"
              >
                <i className="icon icon--instagram inv"></i>
              </a>
              <a
                className="headerTopBtn hidden-md hidden-sm hidden-xs"
                target="_blank"
                rel="noopener"
                href="https://www.threads.net/@terazsk"
              >
                <i className="icon icon--threads inv"></i>
              </a>
              <button
                className="headerTopBtn js-headerSearchToggle"
                type="button"
              >
                <svg className="icon">
                  <use xlinkHref={imgSprite + "#search"} />
                </svg>
              </button>
            </div>
            <TopWeather />
          </div>
          <nav className="headerTopNav" aria-label="Hlavné menu">
            <div className="headerTopNav-inner">
              <ul className="headerTopNav-menu">
                <li className="visuallyhidden">
                  <ScrollLink to="index">Index</ScrollLink>
                </li>
                <li>
                  <Link to="/sport">Šport</Link>
                </li>
                <li>
                  <Link to="/pocasie">Počasie</Link>
                </li>
                <li>
                  <Link to="/publicistika">Publicistika</Link>
                </li>
                <li>
                  <Link to="/slovensko">Slovensko</Link>
                </li>
                <li>
                  <Link to="/zahranicie">Zahraničie</Link>
                </li>
                <li>
                  <Link to="/ekonomika">Ekonomika</Link>
                </li>
                <li>
                  <Link to="/regiony">Regióny</Link>
                </li>
                <li>
                  <Link to="/kultura">Kultúra</Link>
                </li>
                <li>
                  <Link to="/veda">Veda</Link>
                </li>
                <li>
                  <Link to="/krimi">Krimi</Link>
                </li>
                <li>
                  <a href="/odber-xml">XML Odber</a>
                </li>                
                <li>
                  <Link to="/knihy">Knihy</Link>
                </li>
                <li>
                  <Link to="/utulkovo">Útulkovo</Link>
                </li>
                <li>
                  <Link to="/magazin">Magazín</Link>
                </li>
                <li>
                  <a href="https://newsnow.tasr.sk/?utm_source=teraz&utm_medium=organic&utm_campaign=click&utm_content=.%3Boffcanvas">News Now</a>
                </li>
                <li>
                  <Link to="/archiv">Archív</Link>
                </li>
                <li>
                  <a href="https://www.tasrtv.sk/?utm_source=teraz&utm_medium=organic&utm_campaign=click&utm_content=.%253Boffcanvas">TASR TV</a>
                  {/* <ScrollLink to="tv">TASR TV</ScrollLink> */}
                </li>
                <li>
                  <ScrollLink to="webmagazin">Webmagazín</ScrollLink>
                </li>
                <li>
                  <a href="//obce.teraz.sk">Obce</a>
                </li> 
                <li>
                  <Link to="/zdravie">Zdravie</Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
    );
  }
}

export default connect(null)(TopHeaderMenu);
