const data = {
  twitter: "https://twitter.com/terazsk",
  facebook: "https://www.facebook.com/teraz.sk/",
  instagram: "https://www.instagram.com/terazsk/",
  mail: "mailto:teraz@tasr.sk",
};
// export const facebookAppId = "1590410657850445"
// export const facebookAppId = "1122372481192449"
export const facebookAppId = "282867128448169";
export default data;
