import React from "react";
import Search from "~/components/pages/Search";
import { apiUrlTeraz } from "~/utility/api";
import {
  themeClass,
  headerSuffix,
  selectPage,
  fetchPage,
} from "~/store/actions";
import { pageMapStateToProps } from "~/store/mappers";
import { connect, ConnectedProps } from "react-redux"; // import timeData from 'data/api/sub/published_time'
import UpdatingPage from "./UpdatingPage";
import { RouteComponentProps } from "react-router";
import log from "~/utility/log";
// const articleData =
//   {
//     id: 1,
//     url: '/clanok/beblavy',
//     title: 'M. BEBLAVÝ: Po zrušení poslaneckej imunity existuje ešte papalášska',
//     annotation: 'Beblavý v roku 2014 upozornil, že konateľ štátnej firmy Ladislav Košecký nepodal majetkové priznania, ďalej podnikal aj súkromne a v mene štátu obchodoval s vlastnou firmou.',
//     image: 'http://placecage.com/480/270',
//     ...timeData
//   }

class SearchContainer extends UpdatingPage<SearchContainerProps> {
  update() {
    const { dispatch } = this.props;
    if (!this.props.isLoading) {
      const url = this.getUrl(this.props);

      dispatch(headerSuffix("Vyhľadávanie", "search"));
      dispatch(themeClass("theme_search"));

      log.info(`Search ${url}`);
      if (url) {
        dispatch(selectPage(url));
        dispatch(fetchPage(url)).then(console.error);
      }
    }
  }

  getUrl(props: SearchContainerProps) {
    const qs = new URLSearchParams(props.location.search);
    const page_n = qs.get("p") || 1;
    const query = qs.get("q") || "";
    const url = `${apiUrlTeraz}api_rest.php?module=RestApi&method=search&q=${encodeURIComponent(
      query
    )}&page=${page_n}`;
    return url;
  }

  render() {
    return (
      <Search // articles={Array(12).fill(articleData)}
        {...this.props}
      />
    );
  }
}

const connector = connect(pageMapStateToProps);
type SearchContainerProps = ConnectedProps<typeof connector> &
  RouteComponentProps<{ article_slug: string }>;

export default connector(SearchContainer);
