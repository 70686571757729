import React from "react";
import {
  MediaListingTopArticle,
  MediaListingSection,
} from "~/components/Colule";
import { asideMappingFactory } from "~/utility/aside";
import { getGaInfo } from "~/utility/gaInfo";
import { useWagonHeight } from "./wagonHeightHook";

const WagonWebmagazin = (props) => {
  const ref = useWagonHeight(props.name);
  const baseUrl = "http://webmagazin.teraz.sk";
  const gaInfo = getGaInfo(props, "wWebmag");
  const coluleTopArticle = props.colule ? props.colule.top_article : {};
  const sections = props.colule ? props.colule.sections : Array(4).fill({});
  const aside = (props.colule && props.colule.aside
    ? props.colule.aside
    : []
  ).map(
    asideMappingFactory({
      headerSuffix: "na Webmagazíne",
      baseUrl,
      gaInfo: gaInfo,
    })
  );
  return (
    <section className="section section--webmagazin " ref={ref}>
      <div className="container border-top">
        <div className="l-columns clearfix">
          <div className="l-main pull-left">
            <div className="module">
              <header className="moduleHeader">
                <a
                  href={baseUrl}
                  rel="noopener"
                  target="_blank"
                  className={props.className}
                >
                  <h2 className="moduleHeader-title">
                    <span className="visuallyhidden">{props.coluleTitle}</span>
                    <img
                      src="/img/logo-webmagazin.svg"
                      title="Webmagazín"
                      alt="Webmagazín"
                      loading="lazy"
                    />
                  </h2>
                </a>
              </header>

              <div className="moduleMain">
                <div className="row row--verticalGutter row--grid">
                  <MediaListingTopArticle
                    baseUrl={baseUrl}
                    {...coluleTopArticle}
                    gaInfo={gaInfo}
                  />

                  {sections.map((v, i) => (
                    <MediaListingSection
                      baseUrl={baseUrl}
                      key={i}
                      {...v}
                      gaInfo={gaInfo}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <aside className="l-sidebar pull-right">{aside}</aside>
        </div>
      </div>
    </section>
  );
};

export default WagonWebmagazin;
