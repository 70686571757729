import React, { Component } from "react";
import { Link } from "~/components/Elements";
export default class Submenu extends Component {
  render() {
    const submenu = this.props.submenu || Array(3).fill({});
    return (
      <nav className="container submenu">
        <ul className="submenu-menu menu menu--tabs list-reset clearfix">
          {submenu.map((v, i) => (
            <li key={i} className={v.active ? "is-active" : ""}>
              <Link to={v.url} gaInfo={this.props.gaInfo}>
                {v.name}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    );
  }
}
