import React, { Component } from "react";
import { apiUrlTeraz } from "~/utility/api";
import { connect } from "react-redux";
import { fetchWagon } from "~/store/actions";
//import {wagonMapStateToProps} from '"~/store/mappers'
import WagonIndex from "~/components/wagons/WagonIndex";
import { GAInfo } from "~/utility/gaInfo";

class WagonIndexContainer extends Component<{
  required: boolean;
  flash_news?: any;
  dispatch?: any;
  gaInfo?: GAInfo;
  tipsPage?: number;
}> {
  url: string;
  constructor(props) {
    super(props);
    const tipsPage = 0;
    this.url = `${apiUrlTeraz}api_rest.php?module=RestApi&method=index&tipsPage=${tipsPage}`;
  }

  //componentDidMount(){
  UNSAFE_componentWillMount() {
    const { dispatch } = this.props;
    dispatch(fetchWagon(this.url, this.props.required));
  }

  render() {
    return (
      <WagonIndex
        {...this.props[this.url]}
        flash_news={this.props.flash_news}
        gaInfo={this.props.gaInfo}
      />
    );
  }
}

const wagonMapStateToProps = (state) => {
  const { wagonByUrl, header } = state;
  return {
    flash_news: header && header.flash_news,
    ...wagonByUrl,
  };
};

export default connect(wagonMapStateToProps)(WagonIndexContainer);
