import React, { PureComponent } from "react";
import { Link } from "~/components/Elements";
import PropTypes from "prop-types";
type HotTopicsProps = {
  hotTopics: Array<{
    url: string;
    title: string;
  }>;
};

function renderNRSRAd() {
  return (
    <li
      key="ad"
      style={{
        paddingTop: 0,
        paddingBottom: 0,
      }}
    >
      <a
        href="/zive-vysielanie-tv-nrsr"
        style={{
          color: "white",
          backgroundColor: "#005A8C",
          display: "inline-block",
          padding: "16px 8px",
          fontWeight: "bolder",
        }}
      >
        PRIAME PRENOSY Z NR SR
      </a>
    </li>
  );
}

export default class HotTopics extends PureComponent<HotTopicsProps> {
  static propTypes = {
    hotTopics: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        title: PropTypes.string,
      })
    ),
  };
  static defaultProps = {
    hotTopics: null,
  };

  render() {
    return this.props.hotTopics ? (
      <div className="hotTopics">
        <div className="container">
          <ul className="hotTopics-nav list-reset">
            <li>
              <span className="hotTopics-heading">Aktuálne témy:</span>
            </li>
            {this.props.hotTopics.map((v, i) => (
              <li key={i}>
                <strong>
                  <Link to={v.url} gaInfo={this.props.gaInfo}>
                    {v.title}
                  </Link>
                </strong>
              </li>
            ))}
            {/* {renderNRSRAd()} */}
          </ul>
        </div>
      </div>
    ) : null;
  }
}
